import React, { useEffect, useRef } from 'react';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from 'effector-react';
import { TaskFilterInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { SessionStorage } from '@data/enums';
import { Endpoints } from '@data/consts';
import { PlatformTaskFilterData, TaskFilterQueryParameters } from './types';
import { PlatformTaskFilter as PlatformTaskFilterValueObject } from './valueObjects';
import { amountOfTasksOnPage, taskSortItemsInformation } from './consts';
import { $isQueryHydrationFinished, $page, $platformTaskFilterData, $tasks, $taskSortData } from './store/states';
import { fetchTasks, hydrateQueryParameters } from './store/effects';
import {
    resetIsQueryHydrationFinished,
    resetPage,
    resetPlatformTaskFilterData,
    resetTasks,
    resetTaskSortData,
    setIsQueryHydrationFinished,
    setPage,
    setPlatformTaskFilterData,
    setTaskSortData,
} from './store/events';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Pagination from '@components/Pagination';
import PlatformTaskFilter from './components/PlatformTaskFilter';
import TaskList from './components/TaskList';
import { MenuItem, Spinner } from '@blueprintjs/core';
import Heading from '@components/Heading';
import { usePageTitle, useScrollToTop } from '@app/hooks';
import { $permissions } from '@containers/store/states';
import { ItemRendererProps } from '@blueprintjs/select';
import Select from '@app/components/Select';
import { TaskSortField } from './enums';
import { SortData } from 'dy-frontend-http-repository/lib/data/types';
import { SortDirection } from 'dy-frontend-http-repository/lib/data/enums';

const Tasks: React.FC = () => {
    usePageTitle('Requests');
    const { scrollToTop } = useScrollToTop();

    const permissions = useStore($permissions);
    if (!permissions.isEnabled.task) {
        // TODO: Redirect to dashboard
    }

    const location = useLocation();
    const navigate = useNavigate();
    const prevPage = useRef<number>(1);

    const page = useStore($page);
    const tasks = useStore($tasks);
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformTaskFilterData = useStore($platformTaskFilterData);
    const taskSortData = useStore($taskSortData);
    const isFetchingTasks = useStore(fetchTasks.pending);

    const handleLoadPage = async (newPage: number, sortData: SortData<TaskSortField>, filter?: TaskFilterInput) => {
        const pageOffset = (newPage - 1) * amountOfTasksOnPage;

        try {
            await fetchTasks({
                pagination: {
                    _pagination: { limit: amountOfTasksOnPage, offset: pageOffset },
                    _sort: sortData,
                },
                filter: {
                    is_archived: '0',
                    ...filter,
                },
            });

            prevPage.current = newPage;
        } catch (e) {
            // TODO: handle error

            console.error(e);
        }
    };

    // Initial load, parse query string If exists and apply filter, pagination, order and range
    useEffect(() => {
        let urlQueryParameters = location.search.slice(1);

        if (urlQueryParameters.length === 0) {
            // Filter
            const filterQueryParameters = sessionStorage.getItem(SessionStorage.TASK_FILTER);
            if (filterQueryParameters && filterQueryParameters.length > 0) {
                urlQueryParameters = filterQueryParameters;
            }

            // Sort parameters
            const sortParameters = sessionStorage.getItem(SessionStorage.TASK_SORTING);
            if (sortParameters) {
                if (urlQueryParameters.length > 0) {
                    urlQueryParameters += `&${sortParameters}`;
                } else {
                    urlQueryParameters = sortParameters;
                }
            }
        }

        // Get initial sort data
        const allQueryParameters = qs.parse(urlQueryParameters);
        let initialSortData: SortData<TaskSortField> = {
            field: TaskSortField.PUBLISH_AT,
            direction: SortDirection.DESC,
        };
        if (allQueryParameters.field) {
            initialSortData.field = allQueryParameters.field as TaskSortField;
            initialSortData.direction = allQueryParameters.direction as SortDirection;
        }

        const handleHydrateQueryParameters = async () => {
            // Get query params, get hydration out of it and create initial filter data
            const queryParameters: TaskFilterQueryParameters = allQueryParameters;
            const hydrationInput = PlatformTaskFilterValueObject.queryToHydrationInput(queryParameters);
            const platformTaskFilterData: PlatformTaskFilterData = {
                query: '',
                clients: [],
                participants: [],
                teams: [],
                states: queryParameters.states ?? [],
                queue: queryParameters.queue !== undefined ? queryParameters.queue : null,
                taskCategories: [],
                isArchived: queryParameters.isArchived !== undefined ? Boolean(queryParameters.isArchived) : null,
                isPaused: queryParameters.isPaused !== undefined ? Boolean(queryParameters.isPaused) : null,
                publishTypes: queryParameters.publishTypes ?? [],
                plans: [],
                deadlineState: queryParameters.deadlineState !== undefined ? queryParameters.deadlineState : null,
            };

            try {
                // Hydrate & update filter data
                const { client_user, core_user, task_category, plan, team } = await hydrateQueryParameters(
                    hydrationInput
                );
                platformTaskFilterData.clients = client_user ?? [];
                platformTaskFilterData.participants = core_user ?? [];
                platformTaskFilterData.taskCategories = task_category ?? [];
                platformTaskFilterData.plans = plan ?? [];
                platformTaskFilterData.teams = team ?? [];
            } finally {
                // Update page, filter data and update hydration completion flag
                const page = queryParameters.page ? parseInt(queryParameters.page) : 1;
                prevPage.current = page;
                setPage(page);
                setTaskSortData(initialSortData);
                setPlatformTaskFilterData(platformTaskFilterData);
                setIsQueryHydrationFinished(true);
            }
        };

        handleHydrateQueryParameters();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            if (page !== 1 && page === prevPage.current) {
                // Filter updated, so we need to go to first page
                setPage(1);
                return;
            }

            // platform filter -> API filter input
            const tasksFilterInput =
                PlatformTaskFilterValueObject.platformFilterToAPIFilterInput(platformTaskFilterData);

            // API filter input -> query params
            const taskFilterQueryParametersString = qs.stringify(
                PlatformTaskFilterValueObject.platformFilterToQueryParameters(page, platformTaskFilterData)
            );

            // Get sort data query parameters
            const taskSortDataQueryParametersString = qs.stringify(taskSortData);

            // Update session storage & URL
            sessionStorage.setItem(SessionStorage.TASK_FILTER, taskFilterQueryParametersString);
            sessionStorage.setItem(SessionStorage.TASK_SORTING, taskSortDataQueryParametersString);
            navigate(`${Endpoints.TASKS}?${taskFilterQueryParametersString}&${taskSortDataQueryParametersString}`, {
                replace: true,
            });

            // Load page & scroll to top to see first items in the list
            handleLoadPage(page, taskSortData, tasksFilterInput);
            scrollToTop();
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished, page, platformTaskFilterData, taskSortData]);

    useEffect(() => {
        return () => {
            resetTasks();
            resetIsQueryHydrationFinished();
            resetPage();
            resetPlatformTaskFilterData();
            resetTaskSortData();
        };
    }, []);

    if (!tasks) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderTaskSortingSelect = () => {
        const currentLabel =
            taskSortItemsInformation[`${taskSortData.field}${taskSortData.direction}`] ??
            `${taskSortData.field} - ${taskSortData.direction}`;

        const renderItem = (item: SortData<TaskSortField>, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.direction === taskSortData.direction && item.field === taskSortData.field;
            const label =
                taskSortItemsInformation[`${item.field}${item.direction}`] ?? `${item.field} - ${item.direction}`;
            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={`${item.field}${item.direction}`}
                    text={label}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: SortData<TaskSortField>) => {
            setTaskSortData(item);
        };

        const getItems = (): SortData<TaskSortField>[] => {
            return [
                {
                    field: TaskSortField.DEADLINE_AT,
                    direction: SortDirection.DESC,
                },
                {
                    field: TaskSortField.DEADLINE_AT,
                    direction: SortDirection.ASC,
                },
                {
                    field: TaskSortField.LATEST_ACTIVITY_AT,
                    direction: SortDirection.DESC,
                },
                {
                    field: TaskSortField.LATEST_ACTIVITY_AT,
                    direction: SortDirection.ASC,
                },
                {
                    field: TaskSortField.PRIORITY_ELEVATION,
                    direction: SortDirection.DESC,
                },
                {
                    field: TaskSortField.PRIORITY_ELEVATION,
                    direction: SortDirection.ASC,
                },
                {
                    field: TaskSortField.PUBLISH_AT,
                    direction: SortDirection.DESC,
                },
                {
                    field: TaskSortField.PUBLISH_AT,
                    direction: SortDirection.ASC,
                },
                {
                    field: TaskSortField.STATE,
                    direction: SortDirection.ASC,
                },
                {
                    field: TaskSortField.STATE,
                    direction: SortDirection.DESC,
                },
            ];
        };

        return (
            <Select<SortData<TaskSortField>>
                items={getItems()}
                itemRenderer={renderItem}
                onItemSelect={handleItemSelect}
                popoverProps={{
                    matchTargetWidth: true,
                    usePortal: false,
                    className: 'ml-auto',
                }}
                selectButtonProps={{
                    rightIcon: 'double-caret-vertical',
                    icon: 'sort',
                    text: currentLabel,
                    style: { width: '250px' },
                }}
            />
        );
    };

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Requests</Heading>
                {renderTaskSortingSelect()}
            </Flex>
        );
    };

    const renderTasksList = () => {
        // TODO: add logic to check which type of view should be: table or card list
        return <TaskList />;
    };

    const renderPagination = () => {
        // Admins were not fetched yet
        if (tasks === null) {
            return null;
        }

        // Check if offset paginator exist
        if (tasks.paginator === null) {
            return;
        }

        // Only one page exist
        if (!tasks.paginator.has_more && tasks.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingTasks}
                    hasMore={tasks.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfTasksOnPage}
                    totalItems={tasks.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {/* Header */}
            {renderHeader()}

            {/* Filter */}
            <PlatformTaskFilter className="mb-2" />

            {/* Tasks list */}
            {renderTasksList()}

            {/* Pagination */}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default Tasks;
