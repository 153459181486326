import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import { Icon, Tag } from '@blueprintjs/core';
import React, { HTMLAttributes } from 'react';

export interface LicenceTagProps {
    suspended: boolean;
    name: string;
    quantity: number;
    color?: string | null;
}

export type Props = HTMLAttributes<HTMLDivElement> & LicenceTagProps;

const LicenceTag: React.FC<Props> = ({ id, name, color = undefined, quantity, suspended, ...props }) => {
    const renderQuantity = () => {
        if (quantity <= 1) {
            return null;
        }

        return (
            <DevText muted className="ml-small">
                x{quantity}
            </DevText>
        );
    };

    const renderSuspendedTag = () => {
        if (!suspended) {
            return null;
        }

        return (
            <Tag minimal large={false} icon="pause" className="ml-small">
                Paused
            </Tag>
        );
    };

    return (
        <Flex direction="row" align="center" {...props}>
            <Icon className="mr-1" icon="box" color={color} />
            <DevText>{name}</DevText>
            {renderQuantity()}
            {renderSuspendedTag()}
        </Flex>
    );
};

export default LicenceTag;
