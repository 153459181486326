import { Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';

export const MarkerPositioner = styled.div<{ $top: number; $left: number }>`
    position: absolute;
    transform: translate(-50%, -125%);

    ${({ $top, $left }) => css`
        top: ${$top}px;
        left: ${$left}px;
    `}
`;

export const Marker = styled.div<{ $color: string }>`
    position: relative;
    z-index: 1;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 26px;
    height: 26px;
    border-radius: 50%;
    color: ${Colors.LIGHT_GRAY5};

    ${({ $color }) => css`
        background: ${$color};
    `}

    &:before {
        content: '';

        position: absolute;
        z-index: 0;
        bottom: 0;
        left: 0;
        transform: translateY(50%);

        width: 0;
        height: 0;
        border-left: 13px solid transparent;
        border-right: 13px solid transparent;
        border-radius: 10px;

        ${({ $color }) => css`
            border-top: 15px solid ${$color};
        `}
    }
`;
