import React from 'react';
import MenuItemSection from '@containers/components/Layout/components/NextMenu/components/MenuItemSection';
import MenuItem from '@containers/components/Layout/components/NextMenu/components/MenuItem';
import { Endpoints } from '@data/consts';
import { useStore } from 'effector-react';
import { $workspaceInitData } from '@containers/store/states';

const WorkspaceMenuItems: React.FC = () => {
    const workspace = useStore($workspaceInitData);

    if (!workspace) {
        return null;
    }

    return (
        <>
            {/* <div className="mb-2">
                <MenuItem icon="control" title="Dashboard" link={Endpoints.APP} />
            </div> */}

            <MenuItemSection title="Your workspace" className="mb-3">
                <MenuItem icon="person" title="Clients" link={Endpoints.CLIENTS} />
                <MenuItem icon="full-stacked-chart" title="Requests" link={Endpoints.TASK_KANBAN_BOARD} />
            </MenuItemSection>

            <MenuItemSection collapsible title="Settings" className="mb-3">
                <MenuItem
                    icon="helper-management"
                    title="Portal info"
                    link={Endpoints.WORKSPACE_PORTAL.replace(':workspaceId', workspace.workspace.id)}
                />
                <MenuItem
                    icon="people"
                    title="Members"
                    link={Endpoints.WORKSPACE_MEMBERS.replace(':workspaceId', workspace.workspace.id)}
                />
                <MenuItem
                    icon="bank-account"
                    title="Billing"
                    link={Endpoints.WORKSPACE_BILLING.replace(':workspaceId', workspace.workspace.id)}
                />
                <MenuItem
                    icon="array"
                    title="Seat management"
                    link={Endpoints.WORKSPACE_LICENCES.replace(':workspaceId', workspace.workspace.id)}
                />
                <MenuItem
                    icon="cog"
                    title="Other"
                    link={Endpoints.WORKSPACE_SETTINGS.replace(':workspaceId', workspace.workspace.id)}
                />
            </MenuItemSection>
        </>
    );
};

export default WorkspaceMenuItems;
