import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { CreateTeamInput, TeamFilterInput } from 'dy-frontend-http-repository/lib/modules/Team/inputs';
import { TeamRef } from 'dy-frontend-http-repository/lib/modules/Team/refs';
import repository from 'dy-frontend-http-repository/lib/repository';
import domain from './domain';

// Fetch team list
export const fetchTeams = domain.createEffect<TeamFilterInput, CollectionResource<TeamResource, {}>>({
    name: 'fetchTeams',
    handler: async (filter) => {
        return await repository.team().get(filter);
    },
});

// Create new team
export const createTeam = domain.createEffect<CreateTeamInput, TeamRef>({
    name: 'createTeam',
    handler: async (input) => {
        return await repository.team().create(input);
    },
});
