import React, { useState } from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Callout, Card, Divider, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import InputFormField from '@app/components/InputFormField';
import PasswordLockButton from '@app/components/PasswordLockButton';
import { useTextFormField, useForm } from '@app/hooks';
import {
    getStringRequiredValidator,
    getStringMinLengthValidator,
    getStringMaxLengthValidator,
    getStringEqualToStringValidator,
} from '@app/hooks/validation/functions';
import { setupUserPasswordAuth } from './store/effects';

const passwordValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const passwordConfirmationValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

export interface SetupUserPasswordModalProps {
    userId: ID;
    onSetupUserPasswordAuthSuccess: () => void;
}

type Props = ModalProps<SetupUserPasswordModalProps>;

// TODO: Hints on password/repeat-password field?
const SetupUserPasswordModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isCompleted, setIsCompleted] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

    const nextPassword = useTextFormField({
        id: 'next-user-password',
        validators: passwordValidators,
        initialValue: '',
    });

    const nextPasswordConfirmation = useTextFormField({
        id: 'next-user-password-confirmation',
        validators: [
            ...passwordConfirmationValidators,
            getStringEqualToStringValidator({
                stringShouldBeEqualTo: nextPassword.value,
                stringShouldBeEqualToStringMessageFormatter: () => 'Should be equal to entered password',
            }),
        ],
        initialValue: '',
    });

    const form = useForm({
        fields: [nextPassword, nextPasswordConfirmation],
        apiCall: async () => {
            try {
                const userRef = await setupUserPasswordAuth({
                    id: data?.userId ?? '0',
                    input: { password: nextPassword.value },
                });
                data?.onSetupUserPasswordAuthSuccess();
                setIsCompleted(true);
            } catch (e) {
                // TODO: handle error
                throw e;
            }

            return { response: true };
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    const handleModalClose = () => {
        if (form.isSubmitting) {
            return;
        }

        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const renderProcessComplete = () => {
        if (!isCompleted) {
            return;
        }

        return (
            <div>
                <Flex align="center" className="mb-2">
                    <Icon icon="tick" size={35} intent={Intent.SUCCESS} className="mr-1" />
                    <Heading type="h5">Account password successfully setup</Heading>
                </Flex>
                <DevText muted className="mb-2">
                    User can now login using their email and password
                </DevText>
                <DevText muted className="mb-2">
                    You can now close this window
                </DevText>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <form onSubmit={form.handleFormSubmit}>
                <Callout intent={Intent.PRIMARY} className="mb-2">
                    After password setup, user will be able to login using their email and password pair
                </Callout>

                <div className="mb-3">
                    <InputFormField
                        field={nextPassword}
                        formGroupProps={{ label: 'New password' }}
                        inputProps={{
                            placeholder: 'Enter new password',
                            type: isPasswordVisible ? 'text' : 'password',
                            rightElement: (
                                <PasswordLockButton
                                    isPasswordVisible={isPasswordVisible}
                                    changePasswordVisibility={setIsPasswordVisible}
                                />
                            ),
                        }}
                    />

                    <InputFormField
                        field={nextPasswordConfirmation}
                        formGroupProps={{ label: 'Repeat new password' }}
                        inputProps={{
                            placeholder: 'Repeat new password',
                            type: isPasswordConfirmationVisible ? 'text' : 'password',
                            rightElement: (
                                <PasswordLockButton
                                    isPasswordVisible={isPasswordConfirmationVisible}
                                    changePasswordVisibility={setIsPasswordConfirmationVisible}
                                />
                            ),
                        }}
                    />
                </div>

                <Divider className="mb-2" />

                <Flex align="center" justify="space-between">
                    <DevText muted>Proceed with password setup?</DevText>
                    <div>
                        <Button minimal onClick={closeModal} className="mr-1" disabled={form.isSubmitting}>
                            No, cancel
                        </Button>
                        <Button
                            intent={Intent.PRIMARY}
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                        >
                            Yes, set password
                        </Button>
                    </div>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <div>
                        <Heading type="h4" className="mb-1">
                            Setup password
                        </Heading>
                    </div>
                    <Button minimal icon="cross" onClick={closeModal} disabled={form.isSubmitting} />
                </Flex>

                <Divider className="mb-2" />

                {isCompleted ? renderProcessComplete() : renderForm()}
            </Card>
        </Overlay>
    );
};

export default SetupUserPasswordModal;
