import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { $brandProfile } from '@pages/BrandProfile/store/states';
import { openModal } from '@containers/modals/store/events';
import Link from '@components/Link';
import Flex from '@components/Flex';
import { $permissions } from '@app/containers/store/states';
import { Button, Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import UpdateBrandProfileSocialMediaInformationModal, {
    UpdateBrandProfileSocialMediaInformationModalProps,
} from '@app/containers/pages/BrandProfile/modals/UpdateBrandProfileSocialMediaInformationModal';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';
import { UrlUtils } from 'dy-frontend-shared/lib/utils';

type Props = HTMLAttributes<HTMLDivElement>;

const SocialMedia: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const brandProfile = useStore($brandProfile);

    if (!brandProfile) {
        return null;
    }

    // Root permissions
    const isUpdateAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.UPDATE);

    const renderLinks = () => {
        if (brandProfile.links.length === 0) {
            return <DevText muted>No data</DevText>;
        }

        // TODO: parse domains by name and organize UI like in design

        return brandProfile.links.map((link) => (
            <div key={`social-media-link-"${link}"`}>
                <DevText ellipsis>
                    <Link href={UrlUtils.getValidUrl(link)} target="_blank">
                        {link}
                    </Link>
                </DevText>
            </div>
        ));
    };

    const renderBrandProfileLinks = () => {
        if (!isUpdateAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateBrandProfileSocialMediaInformationModalProps>({
                        ModalComponent: UpdateBrandProfileSocialMediaInformationModal,
                        data: {
                            brandProfileId: brandProfile.id,
                            links: brandProfile.links,
                        },
                    })
                }
            />
        );
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Links</Heading>

                {renderBrandProfileLinks()}
            </Flex>

            {renderLinks()}
        </Card>
    );
};

export default SocialMedia;
