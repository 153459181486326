import React from 'react';
import { Outlet } from 'react-router-dom';
import { Wrapper, Content } from './styled';
import Navbar from './components/Navbar';
import Box from '@components/Box';
import EnvironmentInformation from './components/EnvironmentInformation';
import { Breakpoint, Environment } from '@app/data/enums';
import NextMenu from '@containers/components/Layout/components/NextMenu';
import Notifications from '../Notifications';
import { useBreakpoint } from '@app/hooks';

const Layout: React.FC = () => {
    const { breakpoint } = useBreakpoint();

    const renderNotifications = () => {
        if (breakpoint !== Breakpoint.XL) {
            // Notification should only be present at all times on the extra large screens
            return null;
        }

        return (
            <Box height="100%" width="400px" minWidth="400px">
                <Notifications isOpen />
            </Box>
        );
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column' }} width="100%">
            <EnvironmentInformation environment={(process.env.ENVIRONMENT as Environment) ?? null} />

            <Wrapper>
                <NextMenu />

                <Content className="main-content custom-thin-scroll">
                    <Navbar className="mb-2" />
                    <Outlet />
                </Content>

                {renderNotifications()}
            </Wrapper>
        </Box>
    );
};

export default Layout;
