import {
    NotificationListResource,
    NotificationResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import React from 'react';
import InvoicePaidNotification from '../components/notifications/invoice/InvoicePaidNotification';
import SubscriptionAutoRenewEnabledNotification from '../components/notifications/subscription/autoRenew/SubscriptionAutoRenewEnabledNotification';
import SubscriptionPauseDisabledNotification from '../components/notifications/subscription/pause/SubscriptionPauseDisabledNotification';
import SubscriptionPauseEnabledNotification from '../components/notifications/subscription/pause/SubscriptionPauseEnabledNotification';
import SubscriptionPauseScheduleActivatedNotification from '../components/notifications/subscription/pauseSchedule/SubscriptionPauseScheduleActivatedNotification';
import SubscriptionPauseScheduleCanceledNotification from '../components/notifications/subscription/pauseSchedule/SubscriptionPauseScheduleCanceledNotification';
import SubscriptionPauseScheduleReleasedNotification from '../components/notifications/subscription/pauseSchedule/SubscriptionPauseScheduleReleasedNotification';
import SubscriptionPauseScheduleUpdatedNotification from '../components/notifications/subscription/pauseSchedule/SubscriptionPauseScheduleUpdatedNotification';
import SubscriptionCanceledNotification from '../components/notifications/subscription/SubscriptionCanceledNotification';
import SubscriptionCreatedNotification from '../components/notifications/subscription/SubscriptionCreatedNotification';
import TaskDeadlineApproachingNotification from '../components/notifications/task/deadline/TaskDeadlineApproachingNotification';
import TaskDeadlineExceededNotification from '../components/notifications/task/deadline/TaskDeadlineExceededNotification';
import TaskDeadlineRemovedNotification from '../components/notifications/task/deadline/TaskDeadlineRemovedNotification';
import TaskDeadlineUpdatedNotification from '../components/notifications/task/deadline/TaskDeadlineUpdatedNotification';
import TaskParticipationCreatedNotification from '../components/notifications/task/participation/TaskParticipationCreatedNotification';
import TaskParticipationRemovedNotification from '../components/notifications/task/participation/TaskParticipationRemovedNotification';
import TaskPauseAppliedNotification from '../components/notifications/task/pause/TaskPauseAppliedNotification';
import TaskPauseResumedNotification from '../components/notifications/task/pause/TaskPauseResumedNotification';
import TaskPriorityElevatedNotification from '../components/notifications/task/priority/TaskPriorityElevatedNotification';
import TaskPriorityRemovedNotification from '../components/notifications/task/priority/TaskPriorityRemovedNotification';
import TaskQueueTransitionedNotification from '../components/notifications/task/queue/TaskQueueTransitionedNotification';
import TaskStateTransitionedNotification from '../components/notifications/task/state/TaskStateTransitionedNotification';
import TaskArchivedNotification from '../components/notifications/task/TaskArchivedNotification';
import TaskDeliveredNotification from '../components/notifications/task/TaskDeliveredNotification';
import TaskPublishedNotification from '../components/notifications/task/TaskPublishedNotification';
import TaskReopenedNotification from '../components/notifications/task/TaskReopenedNotification';
import TaskRestoredNotification from '../components/notifications/task/TaskRestoredNotification';
import TaskDeliverableCommentCreatedNotification from '../components/notifications/taskDeliverableComment/TaskDeliverableCommentCreatedNotification';
import TaskDeliverableCommentResolvedNotification from '../components/notifications/taskDeliverableComment/TaskDeliverableCommentResolvedNotification';
import TaskMessageCreatedNotification from '../components/notifications/taskMessage/TaskMessageCreatedNotification';
import TaskMessagePinnedNotification from '../components/notifications/taskMessage/TaskMessagePinnedNotification';
import UserClientVerifiedNotification from '../components/notifications/user/client/UserClientVerifiedNotification';
import UserNoteCreatedNotification from '../components/notifications/userNote/UserNoteCreatedNotification';

const notificationFactory: {
    [activityType in string]: (
        notification: NotificationResource,
        entity: NotificationListResource['entity'],
        authorizedUserId: ID,
        dismissNotification: (id: ID) => Promise<void>
    ) => React.ReactElement;
} = {
    'task.published': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskPublishedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskPublish={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.delivered': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskDeliveredNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.deadline.approaching': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskDeadlineApproachingNotification
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.deadline.exceeded': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskDeadlineExceededNotification
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.participation.created': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskParticipationCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.participation.removed': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskParticipationRemovedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.state.transitioned': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskStateTransitionedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.pause.applied': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskPauseAppliedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.pause.released': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskPauseResumedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.archived': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskArchivedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.restored': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskRestoredNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.reopened': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskReopenedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.queue.transitioned': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskQueueTransitionedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.deadline.set': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskDeadlineUpdatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.deadline.removed': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskDeadlineRemovedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.priority.elevated': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskPriorityElevatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task.priority.removed': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskPriorityRemovedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            task={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task_message.new': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskMessageCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskMessage={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task_message.pinned': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskMessagePinnedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskMessage={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task_deliverable_comment.new': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskDeliverableCommentCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskDeliverableComment={
                entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null
            }
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'task_deliverable_comment.resolved': (notification, entity, authorizedUserId, dismissNotification) => (
        <TaskDeliverableCommentResolvedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            taskDeliverableComment={
                entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null
            }
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'user_note.new': (notification, entity, authorizedUserId, dismissNotification) => (
        <UserNoteCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            userNote={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'user.client.verified': (notification, entity, authorizedUserId, dismissNotification) => (
        <UserClientVerifiedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            client={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'invoice.paid': (notification, entity, authorizedUserId, dismissNotification) => (
        <InvoicePaidNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            invoice={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.created': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionCreatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.canceled': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionCanceledNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.auto_renew.enabled': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionAutoRenewEnabledNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.pause.enabled': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionPauseEnabledNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.pause.disabled': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionPauseDisabledNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.pause_schedule.activated': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionPauseScheduleActivatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.pause_schedule.updated': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionPauseScheduleUpdatedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.pause_schedule.released': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionPauseScheduleReleasedNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
    'subscription.pause_schedule.canceled': (notification, entity, authorizedUserId, dismissNotification) => (
        <SubscriptionPauseScheduleCanceledNotification
            authorizedUserId={authorizedUserId}
            notification={notification}
            subscription={entity[notification.activity.entity_type]?.[notification.activity.entity_id] ?? null}
            dismiss={() => dismissNotification(notification.activity.id)}
        />
    ),
};

export default notificationFactory;
