import Flex from '@app/components/Flex';
import InputFormField from '@app/components/InputFormField';
import PasswordLockButton from '@app/components/PasswordLockButton';
import DevText from '@app/components/Text';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringRequiredValidator,
    getStringMinLengthValidator,
    getStringMaxLengthValidator,
    getStringEqualToStringValidator,
} from '@app/hooks/validation/functions';
import { Divider, Button, Intent } from '@blueprintjs/core';
import React, { HTMLAttributes, useState } from 'react';
import { updateUserPasswordAuth } from '../../store/effects';

const passwordValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const passwordConfirmationValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 8 }),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

export interface ChangePasswordFormProps {
    userId: ID;
    onCancel: () => void;
    onChangePasswordSuccess: () => void;
    onProcessingFlagChange: (isProcessing: boolean) => void;
}

export type Props = ChangePasswordFormProps & Omit<HTMLAttributes<HTMLFormElement>, 'onSubmit'>;

const ChangePasswordForm: React.FC<Props> = ({
    userId,
    onCancel,
    onChangePasswordSuccess,
    onProcessingFlagChange,
    ...props
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

    const nextPassword = useTextFormField({
        id: 'next-user-password',
        validators: passwordValidators,
        initialValue: '',
    });

    const nextPasswordConfirmation = useTextFormField({
        id: 'next-user-password-confirmation',
        validators: [
            ...passwordConfirmationValidators,
            getStringEqualToStringValidator({
                stringShouldBeEqualTo: nextPassword.value,
                stringShouldBeEqualToStringMessageFormatter: () => 'Should be equal to entered password',
            }),
        ],
        initialValue: '',
    });

    const form = useForm({
        fields: [nextPassword, nextPasswordConfirmation],
        apiCall: async () => {
            onProcessingFlagChange(true);

            try {
                const userRef = await updateUserPasswordAuth({
                    id: userId,
                    input: {
                        password: nextPassword.value,
                        is_forced: true,
                    },
                });

                onChangePasswordSuccess();

                return { response: true };
            } catch (e) {
                // TODO: handle error
                throw e;
            } finally {
                onProcessingFlagChange(false);
            }
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    return (
        <form onSubmit={form.handleFormSubmit} {...props}>
            <div className="mb-3">
                <InputFormField
                    field={nextPassword}
                    formGroupProps={{ label: 'New password' }}
                    inputProps={{
                        placeholder: 'Enter new password',
                        type: isPasswordVisible ? 'text' : 'password',
                        rightElement: (
                            <PasswordLockButton
                                isPasswordVisible={isPasswordVisible}
                                changePasswordVisibility={setIsPasswordVisible}
                            />
                        ),
                    }}
                />

                <InputFormField
                    field={nextPasswordConfirmation}
                    formGroupProps={{ label: 'Repeat new password' }}
                    inputProps={{
                        placeholder: 'Repeat new password',
                        type: isPasswordConfirmationVisible ? 'text' : 'password',
                        rightElement: (
                            <PasswordLockButton
                                isPasswordVisible={isPasswordConfirmationVisible}
                                changePasswordVisibility={setIsPasswordConfirmationVisible}
                            />
                        ),
                    }}
                />
            </div>

            <Divider className="mb-2" />

            <Flex align="center" justify="space-between">
                <DevText muted>Proceed with password change?</DevText>
                <div>
                    <Button minimal onClick={onCancel} className="mr-1" disabled={form.isSubmitting}>
                        No, cancel
                    </Button>
                    <Button
                        disabled={form.hasFieldErrors}
                        loading={form.isSubmitting}
                        intent={Intent.PRIMARY}
                        type="submit"
                    >
                        Yes, change password
                    </Button>
                </div>
            </Flex>
        </form>
    );
};

export default ChangePasswordForm;
