import { createApi } from 'effector';
import { $notifications } from './states';
import { NotificationListResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';

export const notificationsApi = createApi($notifications, {
    // Add at start
    addAtStart: (store, payload: { notifications: NotificationListResource }) => {
        if (!store) {
            // Store was NOT fetched yet
            return store;
        }

        const newNotifications = [...payload.notifications.list].reverse();

        return {
            ...store,
            entity: {
                task: {
                    ...(store.entity.task ?? {}),
                    ...(payload.notifications.entity.task ?? {}),
                },
                task_deliverable: {
                    ...(store.entity.task_deliverable ?? {}),
                    ...(payload.notifications.entity.task_deliverable ?? {}),
                },
                task_deliverable_comment: {
                    ...(store.entity.task_deliverable_comment ?? {}),
                    ...(payload.notifications.entity.task_deliverable_comment ?? {}),
                },
                task_message: {
                    ...(store.entity.task_message ?? {}),
                    ...(payload.notifications.entity.task_message ?? {}),
                },
                task_publish: {
                    ...(store.entity.task_publish ?? {}),
                    ...(payload.notifications.entity.task_publish ?? {}),
                },
                user_note: {
                    ...(store.entity.user_note ?? {}),
                    ...(payload.notifications.entity.user_note ?? {}),
                },
                user: {
                    ...(store.entity.user ?? {}),
                    ...(payload.notifications.entity.user ?? {}),
                },
                invoice: {
                    ...(store.entity.invoice ?? {}),
                    ...(payload.notifications.entity.invoice ?? {}),
                },
                subscription: {
                    ...(store.entity.subscription ?? {}),
                    ...(payload.notifications.entity.subscription ?? {}),
                },
            },
            list: [...newNotifications, ...store.list],
        };
    },

    // Add at tail after successful fetch
    addAtTailAfterSuccessfulFetch: (store, payload: { notifications: NotificationListResource }) => {
        if (!store) {
            // First time rendering
            return { ...payload.notifications };
        }

        return {
            ...store,
            cursor: payload.notifications.cursor,
            entity: {
                task: {
                    ...(store.entity.task ?? {}),
                    ...(payload.notifications.entity.task ?? {}),
                },
                task_deliverable: {
                    ...(store.entity.task_deliverable ?? {}),
                    ...(payload.notifications.entity.task_deliverable ?? {}),
                },
                task_deliverable_comment: {
                    ...(store.entity.task_deliverable_comment ?? {}),
                    ...(payload.notifications.entity.task_deliverable_comment ?? {}),
                },
                task_message: {
                    ...(store.entity.task_message ?? {}),
                    ...(payload.notifications.entity.task_message ?? {}),
                },
                task_publish: {
                    ...(store.entity.task_publish ?? {}),
                    ...(payload.notifications.entity.task_publish ?? {}),
                },
                user_note: {
                    ...(store.entity.user_note ?? {}),
                    ...(payload.notifications.entity.user_note ?? {}),
                },
                user: {
                    ...(store.entity.user ?? {}),
                    ...(payload.notifications.entity.user ?? {}),
                },
                invoice: {
                    ...(store.entity.invoice ?? {}),
                    ...(payload.notifications.entity.invoice ?? {}),
                },
                subscription: {
                    ...(store.entity.subscription ?? {}),
                    ...(payload.notifications.entity.subscription ?? {}),
                },
            },
            list: [...store.list, ...payload.notifications.list],
        };
    },

    dismiss: (store, payload: { id: ID }) => {
        if (!store) {
            // Store was NOT fetched yet
            return store;
        }

        return {
            ...store,
            list: store.list.filter((n) => n.activity.id !== payload.id),
        };
    },

    dismissAll: (store) => {
        if (!store) {
            // Store was NOT fetched yet
            return store;
        }

        return {
            cursor: {
                ...store.cursor,
                has_more: false,
            },
            entity: {
                invoice: {},
                subscription: {},
                task: {},
                task_deliverable: {},
                task_deliverable_comment: {},
                task_message: {},
                task_publish: {},
                user: {},
                user_note: {},
            },
            list: [],
        };
    },
});
