import { resetTaskClientFeedback, resetTaskClientFeedbackSummary } from './store/events';
import { TaskClientFeedbackFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/inputs';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { $coreUser } from '../../store/states';
import { amountOfTaskClientFeedbacksOnPage } from './consts';
import { fetchTaskClientFeedback, fetchTaskClientFeedbackSummary } from './store/effects';
import { $taskClientFeedback, $taskClientFeedbackSummary } from './store/states';
import TaskClientFeedback from '@containers/components/TaskClientFeedback';
import Flex from '@app/components/Flex';
import { Intent, Spinner } from '@blueprintjs/core';
import Pagination from '@app/components/Pagination';
import { usePageTitle, useScrollToTop } from '@app/hooks';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';

const CoreUserTaskFeedback: React.FC = () => {
    const { changeTitle } = usePageTitle('Member Client Feedback');
    const navigate = useNavigate();
    const { scrollToTop } = useScrollToTop();

    const coreUser = useStore($coreUser);
    const taskClientFeedback = useStore($taskClientFeedback);
    const taskClientFeedbackSummary = useStore($taskClientFeedbackSummary);
    const isFetchingTaskClientFeedback = useStore(fetchTaskClientFeedback.pending);

    const [page, setPage] = useState(1);

    const handleLoadPage = async (newPage: number) => {
        if (!coreUser) return;

        // Get page offset
        const pageOffset = (newPage - 1) * amountOfTaskClientFeedbacksOnPage;

        // Get task client feedback filter
        const filter: TaskClientFeedbackFilterInput = {
            participant_user_id: [coreUser.id],
        };

        try {
            // Fetch client feedback
            await fetchTaskClientFeedback({
                pagination: {
                    _pagination: { limit: amountOfTaskClientFeedbacksOnPage, offset: pageOffset },
                },
                filter,
            });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Request feedback for member with ID of ${coreUser.id} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to core users page
                navigate(Endpoints.TASK_CLIENT_FEEDBACK);
                return;
            }
        }

        try {
            // Fetch client summary
            await fetchTaskClientFeedbackSummary({ filter });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Request feedback summary for member with ID of ${coreUser.id} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to core users page
                navigate(Endpoints.TASK_CLIENT_FEEDBACK);
            }
        }

        // Scroll to top
        scrollToTop();
    };

    useEffect(() => {
        handleLoadPage(page);

        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (!coreUser) {
            return;
        }

        changeTitle(`Member Task Feedback - ${coreUser.first_name} ${coreUser.last_name}`);
    }, [coreUser?.first_name, coreUser?.last_name]);

    useEffect(() => {
        return () => {
            resetTaskClientFeedback();
            resetTaskClientFeedbackSummary();
        };
    }, []);

    if (!coreUser) {
        return null;
    }

    if (!taskClientFeedback || !taskClientFeedbackSummary) {
        return (
            <Flex direction="row" justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderPagination = () => {
        // Admins were not fetched yet
        if (taskClientFeedback === null) {
            return null;
        }

        // Check if offset paginator exist
        if (taskClientFeedback.paginator === null) {
            return;
        }

        // Only one page exist
        if (!taskClientFeedback.paginator.has_more && taskClientFeedback.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingTaskClientFeedback}
                    hasMore={taskClientFeedback.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfTaskClientFeedbacksOnPage}
                    totalItems={taskClientFeedback.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div>
            <TaskClientFeedback
                loading={isFetchingTaskClientFeedback}
                layout="list"
                taskClientFeedback={taskClientFeedback.items}
                taskClientFeedbackSummary={taskClientFeedbackSummary}
            />

            {/* Pagination */}
            {renderPagination()}
        </div>
    );
};

export default CoreUserTaskFeedback;
