import { breakpoints } from '@app/data/consts';
import styled from 'styled-components';

export const Wrapper = styled.div`
    height: 100vh;
    width: 255px;
    min-width: 255px;
    padding: 15px 8px;
    overflow-y: auto;
    background-color: #1c2127;

    @media (min-width: ${breakpoints.xl}px) {
        width: 300px;
        min-width: 300px;
        padding: 15px;
    }
`;
