import Avatar from '@app/components/Avatar';
import Heading from '@app/components/Heading';
import TaskStateTag from '@app/containers/components/TaskStateTag';
import { Endpoints } from '@app/data/consts';
import { Colors, Icon, Intent, Spinner, Tag, Tooltip } from '@blueprintjs/core';
import Flex from '@components/Flex';
import RouterLink from '@components/RouterLink';
import DevText from '@components/Text';
import { imageHashPreview } from '@data/consts';
import { $taskStateTransitionLog } from '@pages/Task/store/states';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskStateTransitionResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';

export interface StateTransitionLogProps {
    isCollapsed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & StateTransitionLogProps;

const StateTransitionLog: React.FC<Props> = ({ isCollapsed, ...props }) => {
    const taskStateTransitionLog = useStore($taskStateTransitionLog);

    if (taskStateTransitionLog === null) {
        return (
            <Flex direction="row" justify="center">
                <Spinner />
            </Flex>
        );
    }

    if (taskStateTransitionLog.items.length === 0) {
        return null;
    }

    const renderListItem = (
        isFirstItem: boolean,
        shouldRenderStateTransitionLogDateHeader: boolean,
        taskStateTransition: TaskStateTransitionResource
    ) => {
        const renderStateAppliedAtLabel = () => {
            return (
                <DevText inline muted className="mr-small">
                    {moment(taskStateTransition.applied_at).format('HH:mm')}
                </DevText>
            );
        };

        const renderStateTransitionUserInformation = () => {
            if (!taskStateTransition.user) {
                // Task state transition was initiated by platform not by user
                // TODO: add global config for bot information (name, image, etc.)
                return <DevText>System</DevText>;
            }

            // Create link to user page who initiated state transition
            let initiatorUserLink = Endpoints.CORE_USER_TASKS.replace(':coreUserId', `${taskStateTransition.user.id}`);
            if (taskStateTransition.user.type === UserType.CLIENT) {
                initiatorUserLink = Endpoints.CLIENT_TASKS.replace(':clientId', `${taskStateTransition.user.id}`);
            }

            // Get avatar src
            let avatarSrc: string | null = null;
            if (taskStateTransition.user.image_hash) {
                avatarSrc = imageHashPreview.userImage(taskStateTransition.user.image_hash, ImageHashPreviewSize.SM);
            }

            return (
                <>
                    <Avatar
                        className="ml-small mr-small"
                        width="25px"
                        height="25px"
                        src={avatarSrc}
                        alt={taskStateTransition.user.first_name}
                    />

                    <RouterLink color={Colors.WHITE} to={initiatorUserLink} fontWeight={600}>
                        {taskStateTransition.user.first_name} {taskStateTransition.user.last_name}
                    </RouterLink>
                </>
            );
        };

        const renderStateTransitionComment = () => {
            if (!taskStateTransition.comment) {
                // There is no comment for state transition
                return null;
            }

            return (
                <Tooltip className="ml-1" content={<DevText>{taskStateTransition.comment}</DevText>}>
                    <Icon style={{ cursor: 'pointer' }} color={Colors.GRAY2} icon="chat" />
                </Tooltip>
            );
        };

        const renderStateTransitionDateHeader = () => {
            if (!shouldRenderStateTransitionLogDateHeader) {
                return null;
            }

            const stateTransitionAppliedAtMoment = moment(taskStateTransition.applied_at);

            const renderDateSuffix = () => {
                const commonSuffixProps = {
                    inline: true,
                    muted: true,
                    className: 'ml-2',
                };
                const isStateTransitionDateToday = stateTransitionAppliedAtMoment.isSame(moment(), 'day');

                if (isStateTransitionDateToday) {
                    return <DevText {...commonSuffixProps}>Today</DevText>;
                }

                return (
                    <DevText {...commonSuffixProps}>
                        {TextFormatUtils.capitalize(stateTransitionAppliedAtMoment.fromNow())}
                    </DevText>
                );
            };

            return (
                <Heading className={['mb-1', isFirstItem ? '' : 'mt-3'].join(' ')} type="h5">
                    {stateTransitionAppliedAtMoment.format('D MMMM YYYY')}
                    {renderDateSuffix()}
                </Heading>
            );
        };

        return (
            <>
                {renderStateTransitionDateHeader()}

                <Flex direction="row" align="center">
                    {renderStateAppliedAtLabel()}
                    {renderStateTransitionUserInformation()}
                    <DevText inline className="ml-small mr-small">
                        changed status to
                    </DevText>
                    <TaskStateTag large={false} withCircle={false} state={taskStateTransition.state} />
                    {taskStateTransition.is_forced && (
                        <Tag minimal className="ml-1" icon="build" intent={Intent.DANGER}>
                            Forced
                        </Tag>
                    )}
                    {renderStateTransitionComment()}
                </Flex>
            </>
        );
    };

    const renderTaskStateTransitionLog = () => {
        const shouldRenderStateTransitionLogDateHeader = (currentState: TaskStateTransitionResource, index: number) => {
            if (index === 0) {
                return true;
            }

            const previousState = taskStateTransitionLog.items[index - 1];

            const previousStateAppliedAt = moment(previousState.applied_at);
            const currentStateAppliedAt = moment(currentState.applied_at);

            if (previousStateAppliedAt.isSame(currentStateAppliedAt, 'day')) {
                // Same day, no need to show new date header for state
                return false;
            }

            return true;
        };

        return (
            <div>
                {taskStateTransitionLog.items.map((currentState, index) => (
                    <div className="mb-1" key={`${currentState.applied_at}-${currentState.state}`}>
                        {renderListItem(
                            index === 0,
                            shouldRenderStateTransitionLogDateHeader(currentState, index),
                            currentState
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return <>{renderTaskStateTransitionLog()}</>;
};

export default StateTransitionLog;
