import Box from '@app/components/Box';
import { openModal } from '@app/containers/modals/store/events';
import { OpenTaskListData } from 'dy-frontend-shared/lib/data/types';
import { OpenTaskType } from 'dy-frontend-shared/lib/data/enums';
import { Button, Colors, Icon, Menu, MenuItem } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { Identifier, XYCoord } from 'dnd-core';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import React, { HTMLAttributes, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ChangeTaskQueueToActiveModal, {
    ChangeTaskQueueToActiveModalProps,
} from '../../modals/ChangeTaskQueueToActiveModal';
import ChangeTaskQueueToBacklogModal, {
    ChangeTaskQueueToBacklogModalProps,
} from '../../modals/ChangeTaskQueueToBacklogModal';
import ConfirmTaskLockQueueAutomationModal, {
    ConfirmTaskLockQueueAutomationModalProps,
} from '../../modals/ConfirmTaskLockQueueAutomationModal';
import ConfirmTaskUnlockQueueAutomationModal, {
    ConfirmTaskUnlockQueueAutomationModalProps,
} from '../../modals/ConfirmTaskUnlockQueueAutomationModal';
import { clientOpenTaskListDataApi } from '../../store/apis';
import { changeTaskPositionWithinSameQueue } from '../../store/effects';
import { DragItem } from './types';
import OpenTaskCard from '@containers/components/OpenTaskCard';
import SwapTaskQueueModal, { SwapTaskQueueModalProps } from '../../modals/SwapTaskQueueModal';
import { TaskPublishType } from 'dy-frontend-http-repository/lib/data/enums';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';

export interface DraggableOpenTaskCardProps {
    draggingEffectProcessing: boolean;
    draggable: boolean;
    canBeMovedUp: boolean;
    canBeMovedDown: boolean;
    type: OpenTaskType;
    index: number;
    task: TaskResource;
    taskData: OpenTaskListData;
}

export type Props = DraggableOpenTaskCardProps & HTMLAttributes<HTMLDivElement>;

const DraggableOpenTaskCard: React.FC<Props> = ({
    draggingEffectProcessing,
    draggable,
    canBeMovedUp,
    canBeMovedDown,
    type,
    index,
    task,
    taskData,
    style,
    ...props
}) => {
    const permissions = useStore($permissions);

    const ref = useRef<HTMLDivElement | null>(null);

    const [{ isDragging }, drag, preview] = useDrag({
        type: type,
        canDrag: draggable && !draggingEffectProcessing,
        item: () => {
            return { taskId: task.id, index, type };
        },
        collect: (monitor) => {
            return { isDragging: monitor.isDragging() };
        },
    });

    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>(
        {
            accept: type,
            canDrop: () => draggable && !draggingEffectProcessing,
            hover: (item, monitor) => {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;

                // Don't replace items with themselves
                if (dragIndex === hoverIndex) {
                    return;
                }

                // Determine rectangle on screen
                const hoverBoundingRect = ref.current?.getBoundingClientRect();

                // Get vertical middle
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

                // Determine mouse position
                const clientOffset = monitor.getClientOffset();

                // Get pixels to the top
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%

                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }

                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }

                // Time to actually perform the action
                clientOpenTaskListDataApi.swapTasks({ type, dragIndex, hoverIndex });

                // Note: we're mutating the monitor item here!
                // Generally it's better to avoid mutations,
                // but it's good here for the sake of performance
                // to avoid expensive index searches.
                item.index = hoverIndex;
            },
            drop: () => {
                console.log('DROPPED');
                const handleChangeTaskPositionWithinSameQueue = async () => {
                    // Get task by type
                    const tasks = Object.values(taskData.tasks[type]);

                    // Find index of the task
                    const taskIndex = tasks.findIndex((t) => t.id === task.id);
                    if (taskIndex === -1) {
                        console.log('task was not found');
                        return;
                    }

                    // Get previous element relative to foundTask
                    const afterTask = tasks[taskIndex - 1] ? tasks[taskIndex - 1] : null;

                    // Get after task id
                    const afterTaskId = afterTask ? afterTask.id : null;

                    try {
                        const taskRef = await changeTaskPositionWithinSameQueue({
                            taskId: task.id,
                            input: { after_task_id: afterTaskId },
                        });

                        // clientOpenTaskListDataApi.changeTaskPositionWithinSameQueue({
                        //     type,
                        //     afterTaskId: afterTaskId,
                        //     taskId: task.id,
                        // });
                    } catch (e) {
                        // TODO: handle error
                        console.error(e);
                    }
                };

                handleChangeTaskPositionWithinSameQueue();
            },
            collect: (monitor) => {
                return {
                    handlerId: monitor.getHandlerId(),
                };
            },
        },
        [taskData, draggingEffectProcessing]
    );

    const renderManageQueueAutomationButton = () => {
        const handleLockTaskQueueAutomation = () => {
            openModal<ConfirmTaskLockQueueAutomationModalProps>({
                ModalComponent: ConfirmTaskLockQueueAutomationModal,
                data: {
                    taskId: task.id,
                    onLockTaskQueueAutomation: () =>
                        clientOpenTaskListDataApi.lockQueueAutomation({ taskId: task.id, type }),
                },
            });
        };

        const handleUnlockTaskQueueAutomation = () => {
            openModal<ConfirmTaskUnlockQueueAutomationModalProps>({
                ModalComponent: ConfirmTaskUnlockQueueAutomationModal,
                data: {
                    taskId: task.id,
                    onUnlockTaskQueueAutomation: () =>
                        clientOpenTaskListDataApi.unlockQueueAutomation({ taskId: task.id, type }),
                },
            });
        };

        if (task.is_queue_automation_locked) {
            // Locked
            return (
                <Button small onClick={handleUnlockTaskQueueAutomation}>
                    Unlock
                </Button>
            );
        } else {
            // NOT locked
            return (
                <Button small onClick={handleLockTaskQueueAutomation}>
                    Lock
                </Button>
            );
        }
    };

    const renderChangeToActiveTaskQueueButton = () => {
        const handleChangeToActiveTaskQueue = async () => {
            openModal<ChangeTaskQueueToActiveModalProps>({
                ModalComponent: ChangeTaskQueueToActiveModal,
                data: {
                    taskId: task.id,
                    onTaskQueueToActive: (input) =>
                        clientOpenTaskListDataApi.changeTaskQueue({
                            type: type,
                            taskId: task.id,
                            input,
                        }),
                },
            });
        };

        return (
            <Button small onClick={handleChangeToActiveTaskQueue}>
                Move to active
            </Button>
        );
    };

    const renderChangeToBacklogTaskQueueButton = () => {
        const handleChangeToBacklogTaskQueue = async () => {
            openModal<ChangeTaskQueueToBacklogModalProps>({
                ModalComponent: ChangeTaskQueueToBacklogModal,
                data: {
                    taskId: task.id,
                    onTaskQueueToBacklog: (input) =>
                        clientOpenTaskListDataApi.changeTaskQueue({
                            type: type,
                            taskId: task.id,
                            input,
                        }),
                },
            });
        };

        return (
            <Button small onClick={handleChangeToBacklogTaskQueue}>
                Move to backlog
            </Button>
        );
    };

    const renderChangeTaskQueue = () => {
        switch (type) {
            case OpenTaskType.ACTIVE:
                return renderChangeToBacklogTaskQueueButton();
            case OpenTaskType.BACKLOG:
                return renderChangeToActiveTaskQueueButton();
            default:
                return null;
        }
    };

    const renderSwapTaskButton = () => {
        if (type === OpenTaskType.PAUSED) {
            // Task is paused
            return null;
        }

        if (task.publish.type !== TaskPublishType.QUOTA) {
            // Task is NOT published via quota
            return null;
        }

        const handleSwapTask = () => {
            openModal<SwapTaskQueueModalProps>({
                ModalComponent: SwapTaskQueueModal,
                data: {
                    task,
                },
            });
        };

        return (
            <Button small className="mr-1" onClick={handleSwapTask}>
                Swap
            </Button>
        );
    };

    const renderDragTile = () => {
        if (!draggable) {
            return null;
        }

        return (
            <Box className="mb-2" cursor="move" width="100%" backgroundColor={Colors.BLUE2} ref={drag}>
                <Flex fullWidth justify="center" align="center">
                    <Icon icon="drag-handle-horizontal" size={20} />
                </Flex>
            </Box>
        );
    };

    const renderOpenTaskMenuControls = () => {
        const isQueueChangeAllowed = permissions.isRoot.task || permissions.has(TaskPermission.QUEUE_TRANSITION);
        const isQueueSwapAllowed = permissions.isRoot.task || permissions.has(TaskPermission.QUEUE_SWAP);
        const isQueueLockManageAllowed = permissions.isRoot.task || permissions.has(TaskPermission.QUEUE_LOCK_MANAGE);

        if (!isQueueSwapAllowed && !isQueueSwapAllowed && !isQueueLockManageAllowed) {
            return null;
        }

        const renderSwapTaskMenuItem = () => {
            if (!isQueueSwapAllowed) {
                return null;
            }

            if (type === OpenTaskType.PAUSED) {
                // Task is paused
                return null;
            }

            if (task.publish.type !== TaskPublishType.QUOTA) {
                // Task is NOT published via quota
                return null;
            }

            // Get amount of active tasks published via quota
            const amountOfActiveTasksPublishedViaQuota = Object.values(taskData.tasks[OpenTaskType.ACTIVE]).reduce(
                (accumulator, task) => {
                    if (task.publish && task.publish.type === TaskPublishType.QUOTA) {
                        return accumulator + 1;
                    }

                    return accumulator;
                },
                0
            );

            // Get amount of backlog tasks published via quota
            const amountOfBacklogTasksPublishedViaQuota = Object.values(taskData.tasks[OpenTaskType.BACKLOG]).reduce(
                (accumulator, task) => {
                    if (task.publish && task.publish.type === TaskPublishType.QUOTA) {
                        return accumulator + 1;
                    }

                    return accumulator;
                },
                0
            );

            // Check if opposite queue has tasks published via quota
            let isOppositeQueueAmountOfTasksPublishedViaQuotaZero = false;
            switch (type) {
                case OpenTaskType.ACTIVE:
                    isOppositeQueueAmountOfTasksPublishedViaQuotaZero = amountOfBacklogTasksPublishedViaQuota === 0;
                    break;
                case OpenTaskType.BACKLOG:
                    isOppositeQueueAmountOfTasksPublishedViaQuotaZero = amountOfActiveTasksPublishedViaQuota === 0;
                    break;
            }

            if (isOppositeQueueAmountOfTasksPublishedViaQuotaZero) {
                return null;
            }

            const handleSwapTask = () => {
                openModal<SwapTaskQueueModalProps>({
                    ModalComponent: SwapTaskQueueModal,
                    data: {
                        task,
                    },
                });
            };

            return <MenuItem icon="swap-horizontal" text="Swap with other request" onClick={handleSwapTask} />;
        };

        const renderChangeTaskQueueMenuItem = () => {
            if (!isQueueChangeAllowed) {
                return null;
            }

            const renderChangeToActiveTaskQueueMenuItem = () => {
                const handleChangeToActiveTaskQueue = async () => {
                    openModal<ChangeTaskQueueToActiveModalProps>({
                        ModalComponent: ChangeTaskQueueToActiveModal,
                        data: {
                            taskId: task.id,
                            onTaskQueueToActive: (input) =>
                                clientOpenTaskListDataApi.changeTaskQueue({
                                    type: type,
                                    taskId: task.id,
                                    input,
                                }),
                        },
                    });
                };

                return (
                    <MenuItem
                        icon="unarchive"
                        text='Move to "Active" requests'
                        onClick={handleChangeToActiveTaskQueue}
                    />
                );
            };

            const renderChangeToBacklogTaskQueueMenuItem = () => {
                const handleChangeToBacklogTaskQueue = async () => {
                    openModal<ChangeTaskQueueToBacklogModalProps>({
                        ModalComponent: ChangeTaskQueueToBacklogModal,
                        data: {
                            taskId: task.id,
                            onTaskQueueToBacklog: (input) =>
                                clientOpenTaskListDataApi.changeTaskQueue({
                                    type: type,
                                    taskId: task.id,
                                    input,
                                }),
                        },
                    });
                };

                return (
                    <MenuItem
                        icon="archive"
                        text='Move to "Backlog" requests'
                        onClick={handleChangeToBacklogTaskQueue}
                    />
                );
            };

            switch (type) {
                case OpenTaskType.ACTIVE:
                    return renderChangeToBacklogTaskQueueMenuItem();
                case OpenTaskType.BACKLOG:
                    return renderChangeToActiveTaskQueueMenuItem();
                default:
                    return null;
            }
        };

        const renderManageQueueAutomationMenuItem = () => {
            if (!isQueueLockManageAllowed) {
                return null;
            }

            const handleLockTaskQueueAutomation = () => {
                openModal<ConfirmTaskLockQueueAutomationModalProps>({
                    ModalComponent: ConfirmTaskLockQueueAutomationModal,
                    data: {
                        taskId: task.id,
                        onLockTaskQueueAutomation: () =>
                            clientOpenTaskListDataApi.lockQueueAutomation({ taskId: task.id, type }),
                    },
                });
            };

            const handleUnlockTaskQueueAutomation = () => {
                openModal<ConfirmTaskUnlockQueueAutomationModalProps>({
                    ModalComponent: ConfirmTaskUnlockQueueAutomationModal,
                    data: {
                        taskId: task.id,
                        onUnlockTaskQueueAutomation: () =>
                            clientOpenTaskListDataApi.unlockQueueAutomation({ taskId: task.id, type }),
                    },
                });
            };

            if (task.is_queue_automation_locked) {
                // Locked
                return (
                    <MenuItem
                        icon="unlock"
                        text="Enable request automatic movement"
                        onClick={handleUnlockTaskQueueAutomation}
                    />
                );
            } else {
                // NOT locked
                return (
                    <MenuItem
                        icon="lock"
                        text="Disable request automatic movement"
                        onClick={handleLockTaskQueueAutomation}
                    />
                );
            }
        };

        const changeQueueMenuItemContent = renderChangeTaskQueueMenuItem();
        const swapMenuItemContent = renderSwapTaskMenuItem();
        const manageQueueAutomationItemContent = renderManageQueueAutomationMenuItem();
        if (!changeQueueMenuItemContent && !swapMenuItemContent && !manageQueueAutomationItemContent) {
            return null;
        }

        return (
            <Menu>
                {changeQueueMenuItemContent}
                {swapMenuItemContent}
                {manageQueueAutomationItemContent}
            </Menu>
        );
    };

    drop(ref);
    preview(ref);

    const opacity = isDragging ? 0 : 1;

    return (
        <OpenTaskCard
            hideClientInformation
            ref={ref}
            data-handler-id={handlerId}
            style={{ opacity }}
            task={task}
            taskData={taskData}
            headerRenderer={renderDragTile}
            controlMenuRenderer={renderOpenTaskMenuControls}
            {...props}
        />
    );
};

export default DraggableOpenTaskCard;
