import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import Circle from '@components/Circle';
import { taskStateInformation } from '@data/consts';

export interface RevisionRequiredProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & RevisionRequiredProps;

const RevisionRequired: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    const [isInRevisionAllowed, setIsInRevisionAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsInRevisionAllowed(permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_IN_REVISION));
    }, [task, permissions, me]);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    // TODO: Render helper actions

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    const renderInRevisionTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isInRevisionAllowed) {
            return null;
        }

        return (
            <Button
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.IN_REVISION}
                icon="outdated"
                intent={Intent.PRIMARY}
                onClick={() => handleTransitionToState(TaskState.IN_REVISION)}
            >
                Start another revision
            </Button>
        );
    };

    const renderClientReviewTransitionButton = () => {
        // TODO: task state actions / helper actions permissions

        return (
            <Button
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.CLIENT_REVIEW}
                onClick={() => handleTransitionToState(TaskState.CLIENT_REVIEW)}
            >
                Send back for client review
            </Button>
        );
    };

    const information = taskStateInformation[TaskState.REVISION_REQUIRED];

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Circle size="22px" className="mr-1" color={information.color} />
                    <Heading type="h4">{information.title}</Heading>
                </Flex>

                <Flex>
                    <div className="mr-1">{renderInRevisionTransitionButton()}</div>
                    <div>{renderClientReviewTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Additional revisions were requested for latest provided deliverables.</DevText>
            <DevText muted>Please, review latest messages from client</DevText>
        </Card>
    );
};

export default RevisionRequired;
