import CancelSubscriptionModal, { CancelSubscriptionModalProps } from '@app/containers/modals/CancelSubscriptionModal';
import PauseSubscriptionModal, { PauseSubscriptionModalProps } from '@app/containers/modals/PauseSubscriptionModal';
import { $permissions } from '@app/containers/store/states';
import { Endpoints } from '@app/data/consts';
import { Button, Colors, Icon, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import SubscriptionStateTags from '@containers/components/SubscriptionStateTags';
import {
    checkIfPauseSubscriptionActionAllowed,
    checkIfSubscriptionCancellationPending,
    checkIfSubscriptionPaused,
} from '@data/functions/subscription';
import { openModal } from '@modals/store/events';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { StripeUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';
import { fetchSubscription } from '../../store/effects';
import { SubscriptionPermission } from 'dy-frontend-permissions/lib/permission';
import checkIfCancelSubscriptionActionAllowed from '@app/data/functions/subscription/actions/checkIfCancelSubscriptionActionAllowed';

export interface SubscriptionHeaderProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionHeaderProps;

const SubscriptionHeader: React.FC<Props> = ({ subscription, ...props }) => {
    const permissions = useStore($permissions);

    const renderSubscriptionLifeDateInterval = () => {
        const subscriptionStartedAtFormatted = moment(subscription.started_at).format('D MMM, YYYY');
        const isSubscriptionEnded = subscription.ended_at;

        if (!isSubscriptionEnded) {
            // Subscription is NOT ended (Could be still paused or NOT cancelled yet)
            return (
                <DevText muted className="ml-1">
                    Started: {subscriptionStartedAtFormatted}
                </DevText>
            );
        }

        // Subscription is ended
        const subscriptionEndedAtFormatted = moment(subscription.ended_at).format('D MMM, YYYY');
        return (
            <Flex className="ml-1" direction="row" align="center">
                <DevText muted>Started: {subscriptionStartedAtFormatted}</DevText>
                <Icon size={10} color={Colors.GRAY2} className="ml-1 mr-1" icon="chevron-right" />
                <DevText muted>Ended: {subscriptionEndedAtFormatted}</DevText>
            </Flex>
        );
    };

    const renderViewSubscriptionInStripeButton = () => {
        return (
            <Button
                onClick={() =>
                    window.open(
                        StripeUtils.getSubscriptionStripeURL(
                            subscription.external_id,
                            process.env.STRIPE_BASE_INFORMATION_URL ?? Endpoints.TASKS
                        )
                    )
                }
            >
                View in Stripe
            </Button>
        );
    };

    const renderMoreButton = () => {
        // TODO: add permission check for pause ability whenever implemented on backend
        const isPauseAllowed =
            !checkIfSubscriptionPaused(subscription) &&
            checkIfPauseSubscriptionActionAllowed(subscription) &&
            (permissions.isRoot.subscription ||
                permissions.has(SubscriptionPermission.PAUSE_APPLY) ||
                permissions.has(SubscriptionPermission.PAUSE_UPDATE));
        const isCancelAllowed =
            !subscription.cancellation.is_canceled &&
            checkIfCancelSubscriptionActionAllowed(subscription) &&
            (permissions.isRoot.subscription || permissions.has(SubscriptionPermission.AUTO_RENEW_DISABLE));

        if (!isPauseAllowed && !isCancelAllowed) {
            // No actions allowed, do not show "more" button
            return null;
        }

        const handlePauseSubscription = () => {
            openModal<PauseSubscriptionModalProps>({
                ModalComponent: PauseSubscriptionModal,
                data: {
                    subscriptionId: subscription.id,
                    refetchOnSuccess: () => fetchSubscription(subscription.id),
                },
            });
        };

        const handleCancelSubscription = () => {
            openModal<CancelSubscriptionModalProps>({
                ModalComponent: CancelSubscriptionModal,
                data: {
                    subscriptionId: subscription.id,
                    refetchOnSuccess: () => fetchSubscription(subscription.id),
                },
            });
        };

        const renderPauseSubscriptionMenuItem = () => {
            if (!isPauseAllowed) {
                return null;
            }

            return <MenuItem icon="pause" text="Pause" onClick={handlePauseSubscription} />;
        };

        const renderCancelSubscriptionMenuItem = () => {
            if (!isCancelAllowed) {
                return null;
            }

            return <MenuItem icon="delete" text="Cancel" onClick={handleCancelSubscription} />;
        };

        const renderMoreButtonMenu = () => {
            return (
                <Menu>
                    {renderPauseSubscriptionMenuItem()}
                    {renderCancelSubscriptionMenuItem()}
                </Menu>
            );
        };

        return (
            <Popover content={renderMoreButtonMenu()}>
                <Button className="ml-1" minimal icon="more" />
            </Popover>
        );
    };

    return (
        <div {...props}>
            <Flex className="mb-1" direction="row" align="center" justify="space-between">
                <Heading type="h2">Subscription #{subscription.id}</Heading>

                <Flex align="center">
                    {renderViewSubscriptionInStripeButton()}
                    {renderMoreButton()}
                </Flex>
            </Flex>

            <Flex direction="row" align="center" flexWrap="wrap">
                <SubscriptionStateTags subscription={subscription} />
                {renderSubscriptionLifeDateInterval()}
            </Flex>
        </div>
    );
};

export default SubscriptionHeader;
