import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { $taskMessageTemplateCategories } from '@pages/TaskMessageTemplates/store/states';
import { fetchTaskMessageTemplateCategories } from '@pages/TaskMessageTemplates/store/effects';
import { resetTaskMessageTemplateCategories } from '@pages/TaskMessageTemplates/store/events';
import Grid from '@components/Grid';
import Flex from '@components/Flex';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Aside from './components/Aside';
import Content from './components/Content';
import { Spinner } from '@blueprintjs/core';
import Heading from '@components/Heading';
import { usePageTitle } from '@app/hooks';

const TaskMessageTemplates: React.FC = () => {

    usePageTitle('Request Message Templates');

    const taskMessageTemplateCategories = useStore($taskMessageTemplateCategories);

    useEffect(() => {
        fetchTaskMessageTemplateCategories().catch((e) => {
            // TODO: handle error
            console.error(e);
        });
    }, []);

    useEffect(() => {
        return () => {
            resetTaskMessageTemplateCategories();
        };
    }, []);

    // Task message template categories were NOT fetched yet
    if (!taskMessageTemplateCategories) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        <FixedWidthPageContainer>
            <Heading className="mb-2" type="h3">
                Request message templates
            </Heading>

            <Grid container>
                <Grid lg={3} xs={12}>
                    <Aside className="mb-2" />
                </Grid>
                <Grid lg={9} xs={12}>
                    <Content />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default TaskMessageTemplates;
