import moment from 'moment';
import { createApi } from 'effector';
import { TaskPublishType, TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { SetTaskDeadlineInput, UpdateTaskInformationInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import {
    BrandProfileResource,
    FileAttachmentResource,
    PickableTaskParticipantUserResource,
    TaskParticipationResource,
    TaskStateTransitionResource,
} from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { $task, $taskAddons, $taskDeliverablesTree, $taskStateTransitionLog } from './states';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TaskDeliverablesParsedInformation } from '../types';
import { TaskDeliverablesTreeUtils } from '../valueObjects';
import { UpdateTaskDeliverableInformationInput } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/inputs';

// API to manage task state locally
export const taskApi = createApi($task, {
    update: (store, payload: UpdateTaskInformationInput) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            ...payload,
        };
    },

    updateBrandProfile: (
        store,
        payload: {
            brandProfile: BrandProfileResource | null;
        }
    ) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            brand_profile: payload.brandProfile,
        };
    },

    addFiles: (store, payload: FileAttachmentResource[]) => {
        if (!store) {
            // Task was NOT fetched yet

            return store;
        }

        return {
            ...store,
            files: [...payload, ...store.files],
        };
    },

    removeFile: (store, payload: { fileId: ID }) => {
        if (!store) {
            return store;
        }

        return {
            ...store,
            files: store.files.filter((asset) => asset.file.id !== payload.fileId),
        };
    },

    // TODO: Rename to 'archive'
    remove: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            archived_at: moment().utc().format(),
        };
    },

    restore: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        // Check: is task published
        const isDraft = store.queue === TaskQueue.DRAFT || store.publish?.type === null;

        // Check: is delivered
        const isDelivered = store.state === TaskState.DELIVERED;

        // Change: will be moved to the backlog?
        const isBacklogMovementChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and not in backlog state already
            store.queue !== TaskQueue.BACKLOG;

        // Change: will be locked in backlog
        const isBacklogAutomationLockChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and published through `quota`
            store.publish?.type === TaskPublishType.QUOTA;

        return {
            ...store,
            archived_at: null,
            queue: isBacklogMovementChange ? TaskQueue.BACKLOG : store.queue,
            // TODO: Add "automation lock" flag when implemented (if required by `isBacklogAutomationLockChange`)
        };
    },

    pause: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            paused_at: moment().utc().format(),
            // TODO: Add pause user
        };
    },

    resume: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        // Check: is task published
        const isDraft = store.queue === TaskQueue.DRAFT || store.publish?.type === null;

        // Check: is delivered
        const isDelivered = store.state === TaskState.DELIVERED;

        // Change: will be moved to the backlog?
        const isBacklogMovementChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and not in backlog state already
            store.queue !== TaskQueue.BACKLOG;

        // Change: will be locked in backlog
        const isBacklogAutomationLockChange =
            // If not draft or delivered...
            !isDraft &&
            !isDelivered &&
            // ... and published through `quota`
            store.publish?.type === TaskPublishType.QUOTA;

        return {
            ...store,
            paused_at: null,
            queue: isBacklogMovementChange ? TaskQueue.BACKLOG : store.queue,
            // TODO: Add "automation lock" flag when implemented (if required by `isBacklogAutomationLockChange`)
        };
    },

    reopen: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            finalized_at: null,
            state: TaskState.IN_REVISION,
            queue: TaskQueue.BACKLOG,
        };
    },

    transitionState: (store, payload: { state: TaskState }) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            state: payload.state,
        };
    },

    removeParticipationByUserId: (store, payload: { userId: ID }) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            participants: store.participants.filter((x) => x.user.id !== payload.userId),
        };
    },

    addParticipation: (store, payload: { input: PickableTaskParticipantUserResource[] }) => {
        if (!store) {
            // Task
            return store;
        }

        const momentNowUtc = moment().utc().format();
        const taskParticipants: TaskParticipationResource[] = payload.input.map((participant) => ({
            assigned_at: momentNowUtc,
            user: participant,
        }));

        return {
            ...store,
            participants: [...taskParticipants, ...store.participants],
        };
    },

    setDeadline: (store, payload: SetTaskDeadlineInput) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            deadline_timezone_name: payload.deadline_timezone_name,
            deadline_at: payload.deadline_at,
        };
    },

    removeDeadline: (store) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            deadline_at: null,
        };
    },

    prioritize: (store) => {
        if (!store) {
            return null;
        }

        return {
            ...store,
            is_priority_elevated: true,
        };
    },

    removePrioritization: (store) => {
        if (!store) {
            return null;
        }

        return {
            ...store,
            is_priority_elevated: false,
        };
    },

    updateComplexityMultiplier: (store, payload: number) => {
        if (!store) {
            return null;
        }

        return {
            ...store,
            complexity_multiplier: payload,
        };
    },
});

// API to manage task addons state locally
export const taskAddonsApi = createApi($taskAddons, {
    restore: (store, payload: { taskAddonId: ID }) => {
        if (!store) {
            // Task addons were NOT initialized/fetched yet
            return store;
        }

        // Find task addon index
        const foundTaskAddonIndex = store.items.findIndex((addon) => addon.id === payload.taskAddonId);

        if (foundTaskAddonIndex === -1) {
            // Addon is NOT found nothing to restore
            return store;
        }

        // Copy task addons
        const taskAddons = [...store.items];

        // Get addon at index
        const foundTaskAddon = taskAddons[foundTaskAddonIndex];

        // Update task addon
        foundTaskAddon.archived_at = null;

        return { ...store, items: taskAddons };
    },

    remove: (store, payload: { taskAddonId: ID }) => {
        if (!store) {
            // Task addons were NOT initialized/fetched yet
            return store;
        }

        // Find task addon index
        const foundTaskAddonIndex = store.items.findIndex((addon) => addon.id === payload.taskAddonId);

        if (foundTaskAddonIndex === -1) {
            // Addon is NOT found nothing to restore
            return store;
        }

        // Copy task addons
        const taskAddons = [...store.items];

        // Get addon at index
        const foundTaskAddon = taskAddons[foundTaskAddonIndex];

        // Get current date
        const momentNowUtc = moment().utc().format();

        // Update task addon
        foundTaskAddon.archived_at = momentNowUtc;

        return { ...store, items: taskAddons };
    },
});

// API to manage task state locally
export const taskStateTransitionLogApi = createApi($taskStateTransitionLog, {
    update: (store, payload: TaskStateTransitionResource[]) => {
        if (!store) {
            // Task was NOT fetched yet
            return store;
        }

        return {
            ...store,
            items: [...payload, ...store.items],
        };
    },
});

// API to manage task deliverable comments state locally
export const taskDeliverablesTreeApi = createApi($taskDeliverablesTree, {
    insert: (store, payload: { taskDeliverables: TaskDeliverableResource[] }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (payload.taskDeliverables.length === 0) {
            // No task deliverables
            return taskDeliverablesInformation;
        }

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        for (let i = 0; i < payload.taskDeliverables.length; i++) {
            const deliverable = { ...payload.taskDeliverables[i] };
            const deliverablePath = deliverable.path;

            // Deliverable insertion
            taskDeliverablesInformation.deliverables = TaskDeliverablesTreeUtils.insertTaskDeliverable({
                deliverable,
                deliverablePath: deliverablePath,
                deliverablesMap: taskDeliverablesInformation.deliverables,
                skipInsertion: deliverable.archived_at !== null,
            });

            // Directory insertion
            if (deliverablePath !== '/' && deliverablePath.length > 1) {
                // Path is NOT "/" and there are characters exists after slash "/directory/mycooldeliverable"

                // Insert directory
                taskDeliverablesInformation.directories = TaskDeliverablesTreeUtils.insertDirectory({
                    directoryPath: deliverablePath,
                    directoriesMap: taskDeliverablesInformation.directories,
                    skipInsertion: deliverable.archived_at !== null,
                });
            }
        }

        return taskDeliverablesInformation;
    },

    update: (store, payload: { taskDeliverableId: ID; path: string; input: UpdateTaskDeliverableInformationInput }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        const taskDeliverables = taskDeliverablesInformation.deliverables[payload.path];
        if (!taskDeliverables) {
            // Task deliverables do NOT exist for this path
            return;
        }

        const foundTaskDeliverable = taskDeliverables[payload.taskDeliverableId];
        if (!foundTaskDeliverable) {
            // Task deliverable is NOT found
            return;
        }

        foundTaskDeliverable.title = payload.input.title ?? foundTaskDeliverable.title;
        foundTaskDeliverable.url = payload.input.url ?? foundTaskDeliverable.url;
        foundTaskDeliverable.text_content = payload.input.text_content ?? foundTaskDeliverable.text_content;

        return taskDeliverablesInformation;
    },

    createDirectory: (store, payload: { directoryPath: string; directoryName: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        // Get full directory path with name
        const directoryPath = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.directoryPath,
            directoryName: payload.directoryName,
        });

        // Insert directory
        taskDeliverablesInformation.directories = TaskDeliverablesTreeUtils.insertDirectory({
            directoryPath: directoryPath,
            directoriesMap: taskDeliverablesInformation.directories,
        });

        return taskDeliverablesInformation;
    },

    renameDirectory: (
        store,
        payload: { commonDirectoryPath: string; oldDirectoryName: string; newDirectoryName: string }
    ) => {
        if (!store) {
            return store;
        }

        // Check which task deliverable paths start with oldDirectoryPath/oldDirectoryName and rename it to oldDirectoryPath/newDirectoryName
        const deliverables = { ...store.deliverables };
        const directories = { ...store.directories };

        // Get old path with directory name
        const oldPathWithDirectoryName = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.commonDirectoryPath,
            directoryName: payload.oldDirectoryName,
        });

        // Get new path with directory name
        const newPathWithDirectoryName = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.commonDirectoryPath,
            directoryName: payload.newDirectoryName,
        });

        // Update deliverables object
        for (const deliverablePath in deliverables) {
            try {
                const renamedPath = TaskDeliverablesTreeUtils.getRenamedPath({
                    currentPath: deliverablePath,
                    oldPath: oldPathWithDirectoryName,
                    newPath: newPathWithDirectoryName,
                });

                if (renamedPath) {
                    // Current path should be renamed

                    // Update path field for deliverables
                    const deliverablesMapCopy = { ...deliverables[deliverablePath] };
                    for (const deliverableId in deliverablesMapCopy) {
                        deliverablesMapCopy[deliverableId].path = renamedPath;
                    }

                    // Update deliverables object
                    deliverables[renamedPath] = deliverablesMapCopy;
                    delete deliverables[deliverablePath];
                }
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        }

        // Update directories object
        for (const directoryPath in directories) {
            if (directoryPath === payload.commonDirectoryPath) {
                // Directory path found, need to rename directory

                // Delete old directory name
                delete directories[directoryPath][payload.oldDirectoryName];

                // Add new directory name
                directories[directoryPath][payload.newDirectoryName] = true;

                continue;
            }

            try {
                const renamedPath = TaskDeliverablesTreeUtils.getRenamedPath({
                    currentPath: directoryPath,
                    oldPath: oldPathWithDirectoryName,
                    newPath: newPathWithDirectoryName,
                });

                if (renamedPath) {
                    // Copy directory name map from the old directory path to the new directory path
                    directories[renamedPath] = { ...directories[directoryPath] };

                    // Delete old directory path map
                    delete directories[directoryPath];
                }
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        }

        return {
            deliverables,
            directories,
        };
    },

    removeTaskDeliverable: (store, payload: { taskDeliverableId: ID; path: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        const taskDeliverables = taskDeliverablesInformation.deliverables[payload.path];
        if (!taskDeliverables) {
            // Task deliverables do NOT exist for this path
            return;
        }

        const foundTaskDeliverable = taskDeliverables[payload.taskDeliverableId];
        if (!foundTaskDeliverable) {
            // Task deliverable is NOT found
            return;
        }

        delete taskDeliverables[payload.taskDeliverableId];

        return taskDeliverablesInformation;
    },

    removeDirectory: (store, payload: { directoryPath: string; directoryName: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        for (const path in taskDeliverablesInformation.directories) {
            taskDeliverablesInformation.directories = TaskDeliverablesTreeUtils.removeDirectory({
                path,
                directoriesMap: taskDeliverablesInformation.directories,
                directoryName: payload.directoryName,
                directoryPath: payload.directoryPath,
            });
        }

        return taskDeliverablesInformation;
    },

    approveTaskDeliverable: (store, payload: { taskDeliverableId: ID; path: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        const taskDeliverables = taskDeliverablesInformation.deliverables[payload.path];
        if (!taskDeliverables) {
            // Task deliverables do NOT exist for this path
            return;
        }

        const foundTaskDeliverable = taskDeliverables[payload.taskDeliverableId];
        if (!foundTaskDeliverable) {
            // Task deliverable is NOT found
            return;
        }

        const momentNowUtc = moment().utc().format();
        foundTaskDeliverable.approved_at = momentNowUtc;

        return taskDeliverablesInformation;
    },

    approveTaskDeliverables: (store, payload: { taskDeliverablesIds: ID[] }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        for (const path in taskDeliverablesInformation.deliverables) {
            const momentNowUtc = moment().utc().format();
            Object.values(taskDeliverablesInformation.deliverables[path]).forEach((deliverable) => {
                if (payload.taskDeliverablesIds.includes(deliverable.id)) {
                    // Need to be approved
                    deliverable.approved_at = momentNowUtc;
                }
            });
        }

        return taskDeliverablesInformation;
    },

    approveDirectory: (store, payload: { directoryPath: string; directoryName: string }) => {
        const taskDeliverablesInformation: TaskDeliverablesParsedInformation = {
            deliverables: {},
            directories: {},
        };

        if (store) {
            // Task deliverables were already initialized before
            taskDeliverablesInformation.deliverables = { ...store.deliverables };
            taskDeliverablesInformation.directories = { ...store.directories };
        }

        const fullDirectoryPathWithNameToRemove = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: payload.directoryPath,
            directoryName: payload.directoryName,
        });

        for (const deliverablePath in taskDeliverablesInformation.deliverables) {
            const shouldDeliverablesBeApproved = TaskDeliverablesTreeUtils.isSubPathPartOfPath({
                path: deliverablePath,
                subPath: fullDirectoryPathWithNameToRemove,
            });

            if (shouldDeliverablesBeApproved) {
                // Deliverables should be removed

                for (const deliverableId in taskDeliverablesInformation.deliverables[deliverablePath]) {
                    const deliverable = taskDeliverablesInformation.deliverables[deliverablePath][deliverableId];
                    if (deliverable.approved_at === null) {
                        const momentNowUtc = moment().utc().format();
                        taskDeliverablesInformation.deliverables[deliverablePath][deliverableId].approved_at =
                            momentNowUtc;
                    }
                }
            }
        }

        return taskDeliverablesInformation;
    },
});
