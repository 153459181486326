import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { fetchStaticData } from '@app/containers/pages/CoreUsers/store/effects';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { $isQueryHydrationFinished } from '@app/containers/pages/CoreUsers/store/states';
import { platformCoreUserFiltersInformation } from '@app/containers/pages/CoreUsers/consts';
import { PlatformCoreUserFilterType } from '@app/containers/pages/CoreUsers/enums';
import { setPlatformCoreUserFilterData } from '@app/containers/pages/CoreUsers/store/events';
import { PlatformCoreUserFilterData } from '@app/containers/pages/CoreUsers/types';
import Circle from '@app/components/Circle';
import Flex from '@app/components/Flex';

export interface CoreUserTeamsPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformCoreUserFilterData: PlatformCoreUserFilterData;
    onRemove: () => void;
}

export type Props = CoreUserTeamsPlatformFilterProps;

const CoreUserTeamsPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformCoreUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isPlanInitialized, setIsPlansInitialized] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState<PlatformCoreUserFilterData['teams']>([]);
    const [foundTeams, setFoundTeams] = useState<PlatformCoreUserFilterData['teams']>([]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            fetchStaticData({ team: '1' }).then((staticData) => {
                setFoundTeams(staticData.team);
                setSelectedTeams(
                    staticData.team.filter((sp) => platformCoreUserFilterData.teams.find((p) => p.id === sp.id))
                );
                setTimeout(() => setIsPlansInitialized(true), 0);
            });
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformCoreUserFilterData.teams.length === 0) {
            setSelectedTeams([]);
        }
    }, [platformCoreUserFilterData.teams]);

    const getFilterTagLabel = () => {
        if (selectedTeams.length === 0) return '';

        return selectedTeams
            .map((p, index) => {
                if (index === selectedTeams.length - 1) {
                    // Last element
                    return `${p.title}`;
                }

                return `${p.title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedTeams.length > 0) {
            // At least 1 team selected
            return 'Team:';
        }

        return 'Team';
    };

    const handleRemoveFilter = () => {
        if (selectedTeams.length !== 0) {
            // At least 1 team was selected

            setSelectedTeams([]);

            if (platformCoreUserFilterData.teams.length !== 0) {
                // Reset team to empty array
                setPlatformCoreUserFilterData({ ...platformCoreUserFilterData, teams: [] });
            }

            setIsOpen(false);
        }

        if (!platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_TEAMS].isPinned) {
            // Platform team filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (team: PlatformCoreUserFilterData['teams'][number]) => {
        if (!!selectedTeams.find((selectedTeam) => selectedTeam.id === team.id)) {
            // Plan is already checked
            setSelectedTeams(selectedTeams.filter((selectedTeam) => selectedTeam.id !== team.id));
        } else {
            // Plan is NOT checked yet
            setSelectedTeams([...selectedTeams, team]);
        }
    };

    const handleApplyFilter = () => {
        if (selectedTeams.length !== platformCoreUserFilterData.teams.length) {
            // If task teams array length didn't change since opening the filter then nothing apply to
            setPlatformCoreUserFilterData({ ...platformCoreUserFilterData, teams: selectedTeams });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_TEAMS].isPinned) {
            return;
        }

        if (selectedTeams.length === 0) {
            onRemove();
        }
    };

    const renderRow = (team: PlatformCoreUserFilterData['teams'][number]) => {
        return (
            <li className="mb-1" key={team.id}>
                <Flex justify="space-between" align="center">
                    <Checkbox
                        checked={!!selectedTeams.find((selectedTeam) => selectedTeam.id === team.id)}
                        className="mr-2"
                        label={team.title}
                        onClick={() => handleCheckboxClick(team)}
                    />

                    <Circle size="11px" color={team.color} />
                </Flex>
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformCoreUserFilterData['teams'][number]>
            isOpen={isOpen}
            loading={!isPlanInitialized}
            removable={selectedTeams.length > 0}
            title="Team"
            position={Position.BOTTOM_LEFT}
            minWidth={320}
            maxListHeight={400}
            list={foundTeams}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_TEAMS].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default CoreUserTeamsPlatformFilter;
