import { useMemo } from 'react';
import { useStore } from 'effector-react';
import { QuotaType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPublishOutputQueue } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskCategoryResource } from 'dy-frontend-http-repository/lib/modules/TaskCategory/resources';
import {
    PublishTaskPublishCreditInput,
    PublishTaskPublishManualInput,
    PublishTaskPublishPaymentInput,
    PublishTaskPublishQuotaInput,
} from 'dy-frontend-http-repository/lib/modules/TaskPublish/inputs';
import { PaymentMethodResource } from 'dy-frontend-http-repository/lib/modules/PaymentAccount/resources';
import { TaskPublishChoiceType } from './enums';
import { usePayment } from '@app/hooks';
import { $clientComputedComputedLicence, $clientQuotaUsage, $taskCategory } from '../../store/states';
import {
    attemptToFinalizePublish,
    publishTaskViaManual,
    publishTaskViaPayment,
    publishTaskViaQuota,
    publishViaCredit,
} from '../../store/effects';
import { CreditWalletResource } from 'dy-frontend-http-repository/lib/modules/CreditWallet/resources';
import { $permissions } from '@app/containers/store/states';
import { $clientPaymentAccount } from '@app/containers/pages/Task/store/states';
import { ComputedLicence, QuotaUsageContainer } from 'dy-frontend-shared/lib/data/valueObjects';
import { TaskPublishPermission } from 'dy-frontend-permissions/lib/permission';

export interface UseTaskPublishProps {
    taskId: ID;
    creditWallet?: CreditWalletResource | null;
    shouldCheckTaskPublishChoices?: boolean;
}

export type Props = UseTaskPublishProps;

const useTaskPublish = ({ taskId, shouldCheckTaskPublishChoices = true, creditWallet = null }: Props) => {
    const permissions = useStore($permissions);

    const { processPayment } = usePayment<
        typeof attemptToFinalizePublish,
        Awaited<ReturnType<typeof attemptToFinalizePublish>>
    >({
        processPayment: attemptToFinalizePublish,
    });

    const computedLicence = useStore($clientComputedComputedLicence);
    const quotaUsage = useStore($clientQuotaUsage);
    const taskCategory = useStore($taskCategory);
    const clientPaymentAccount = useStore($clientPaymentAccount);

    const isRootAccess = permissions.has(TaskPublishPermission.ROOT);

    const handlePublishTaskViaPayment = async (input: PublishTaskPublishPaymentInput) => {
        try {
            const taskPublishRef = await publishTaskViaPayment(input);
            await processPayment(taskPublishRef.id);
        } catch (e) {
            // TODO: handle error
            console.error(e);
            throw e;
        }
    };

    const handlePublishActiveTaskViaQuota = async (
        input: Omit<PublishTaskPublishQuotaInput, 'task_id' | 'output_queue'> = {}
    ) => {
        try {
            const taskPublishRef = await publishTaskViaQuota({
                ...input,
                task_id: taskId,
                output_queue: TaskPublishOutputQueue.ACTIVE,
            });

            await processPayment(taskPublishRef.id);
        } catch (e) {
            // TODO: handle error
            console.error(e);
            throw e;
        }
    };

    const handlePublishBacklogTaskViaQuota = async (
        input: Omit<PublishTaskPublishQuotaInput, 'task_id' | 'output_queue'> = {}
    ) => {
        try {
            const taskPublishRef = await publishTaskViaQuota({
                ...input,
                task_id: taskId,
                output_queue: TaskPublishOutputQueue.BACKLOG,
            });

            await processPayment(taskPublishRef.id);
        } catch (e) {
            // TODO: handle error
            console.error(e);
            throw e;
        }
    };

    const handlePublishTaskViaCredit = async (input: PublishTaskPublishCreditInput) => {
        try {
            const taskPublishRef = await publishViaCredit(input);
            await processPayment(taskPublishRef.id);
        } catch (e) {
            // TODO: handle error
            console.error(e);
            throw e;
        }
    };

    const handlePublishTaskViaManual = async (input: PublishTaskPublishManualInput) => {
        try {
            const taskPublishRef = await publishTaskViaManual(input);
            await processPayment(taskPublishRef.id);
        } catch (e) {
            // TODO: handle error
            console.error(e);
            throw e;
        }
    };

    const taskPublishChoices = useMemo(() => {
        // Rule: is provided category supported by computed licence
        const isTaskCategoryCoveredByComputedLicence = (
            taskCategory: TaskCategoryResource,
            computedLicence: ComputedLicence
        ): boolean => {
            // Check: force excluded
            const isForceExcluded = computedLicence.excludedTaskCategoryList.includes(taskCategory.id);
            if (isForceExcluded) {
                return false;
            }

            // Check: force included
            const isForceIncluded = computedLicence.includedTaskCategoryList.includes(taskCategory.id);
            if (isForceIncluded) {
                return true;
            }

            // Check: target task category's groups intersect with included ones
            const allowedTaskCategoryGroupIdList = computedLicence.getTaskCategoryGroupIdList();
            const isTaskCategoryGroupAllowed = taskCategory.groups.some((g) =>
                allowedTaskCategoryGroupIdList.includes(g.id)
            );
            if (isTaskCategoryGroupAllowed) {
                return true;
            }

            // All checks are failed
            return false;
        };

        // Rule: is provided quota usage is maxed out
        const isQuotaUsageMaxedOut = (
            quotaType: QuotaType.USER_ACTIVE_TASK | QuotaType.USER_BACKLOG_TASK,
            quotaUsage: QuotaUsageContainer,
            computedLicence: ComputedLicence
        ) => {
            // Resolve usage
            const usage = quotaUsage.getValue(quotaType);

            // Resolve quota value
            const allowed = computedLicence.getQuotaValue(quotaType);

            // Is usage maxed out?
            return usage >= allowed;
        };

        const isTaskPublishableViaCredits = (taskCategory: TaskCategoryResource) => {
            if (!taskCategory.is_credit_publish_supported) {
                // Task category do NOT support credit publish
                return false;
            }

            if (!creditWallet) {
                // Credit wallet was NOT passed or NOT fetched successfully
                return false;
            }

            if (creditWallet.is_locked) {
                // Credit wallet transactions are NOT allowed
                return false;
            }

            if (creditWallet.balance < taskCategory.credit_amount) {
                // Credit wallet do NOT have enough credits to cover task category
                return false;
            }

            return true;
        };

        const isTaskPublishableViaPayment = (
            taskCategory: TaskCategoryResource,
            paymentMethods: PaymentMethodResource[]
        ) => {
            if (!taskCategory.is_payment_publish_supported) {
                return false;
            }

            if (paymentMethods.length > 0) {
                return true;
            }

            return false;
        };

        // User choices of the way to publish task
        const taskPublishChoices: TaskPublishChoiceType[] = [];

        if (!shouldCheckTaskPublishChoices) {
            return taskPublishChoices;
        }

        // 1. Ability to publish manually
        const isManualPublishAllowed = isRootAccess || permissions.has(TaskPublishPermission.CREATE_MANUAL);
        if (isManualPublishAllowed) {
            // Manual task publish allowed
            taskPublishChoices.push(TaskPublishChoiceType.MANUAL);
        }

        const isNonManualAllowed = isRootAccess || permissions.has(TaskPublishPermission.CREATE);
        if (isNonManualAllowed && taskCategory && taskCategory.is_enabled) {
            // 2. Ability to publish via quota
            if (quotaUsage && computedLicence) {
                if (isTaskCategoryCoveredByComputedLicence(taskCategory, computedLicence)) {
                    // Active task quota is not maxed out - allow to publish to active
                    if (!isQuotaUsageMaxedOut(QuotaType.USER_ACTIVE_TASK, quotaUsage, computedLicence)) {
                        taskPublishChoices.push(TaskPublishChoiceType.ACTIVE_TASK);
                    }

                    // Backlog task quota is not maxed out - allow to publish to active
                    if (!isQuotaUsageMaxedOut(QuotaType.USER_BACKLOG_TASK, quotaUsage, computedLicence)) {
                        taskPublishChoices.push(TaskPublishChoiceType.BACKLOG_TASK);
                    }
                }
            }

            // 3. Ability to publish via credits check
            if (isTaskPublishableViaCredits(taskCategory)) {
                taskPublishChoices.push(TaskPublishChoiceType.CREDIT);
            }

            // 4. Ability to publish via payment check
            if (
                clientPaymentAccount &&
                isTaskPublishableViaPayment(taskCategory, clientPaymentAccount.payment_methods)
            ) {
                // At least 1 payment method exist, user can publish task via payment
                taskPublishChoices.push(TaskPublishChoiceType.PAYMENT);
            }
        }

        return taskPublishChoices;
    }, [computedLicence, quotaUsage, clientPaymentAccount, taskCategory, shouldCheckTaskPublishChoices, taskId]);

    return {
        taskPublishChoices,
        handlePublishActiveTaskViaQuota,
        handlePublishBacklogTaskViaQuota,
        handlePublishTaskViaPayment,
        handlePublishTaskViaCredit,
        handlePublishTaskViaManual,
    };
};

export default useTaskPublish;
