import { LocalStorage } from '@app/data/enums';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

export interface TimeoutProps {
    // Key for timeout (Going to be stored in localStorage)
    timeoutKey: string | LocalStorage;

    // Determine how much time timeout will work (In seconds)
    timeoutSeconds: number;

    // Determine how often label "N seconds" will be updating (In seconds)
    labelUpdateTimeoutSeconds: number;
}

const useTimeout = ({ timeoutKey, timeoutSeconds, labelUpdateTimeoutSeconds }: TimeoutProps) => {
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const [isTimeoutPresent, setIsTimeoutPresent] = useState(false);
    const [timeoutPresenceLeftInSeconds, setTimeoutPresenceLeftInSeconds] = useState<null | number>(null);

    const getTimeoutStartAt = () => {
        return localStorage.getItem(timeoutKey);
    };

    const checkIfTimeoutPresent = (timeoutStartAt: string) => {
        // Get moment date whenever timeout should be finished
        const timeoutFinishAt = moment(timeoutStartAt).add(timeoutSeconds, 'seconds');

        // Check if timeout finished
        const isTimeoutPresent = moment().isBefore(timeoutFinishAt);

        return isTimeoutPresent;
    };

    useEffect(() => {
        // Get timeout timestamp from local storage
        const timeoutStartAt = getTimeoutStartAt();
        if (!timeoutStartAt) {
            // Timeout timestamp is not found
            return;
        }

        // Initialize timeout presence flag
        setIsTimeoutPresent(checkIfTimeoutPresent(timeoutStartAt));

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, [timeoutKey]);

    useEffect(() => {
        if (!isTimeoutPresent) {
            // Timeout is NOT present
            return;
        }

        // Get timeout start at date
        const timeoutStartAt = getTimeoutStartAt();
        if (!timeoutStartAt) {
            // Timeout is NOT present on local storage
            return;
        }

        // Update timeout presence left in seconds
        const intervalDelay = labelUpdateTimeoutSeconds * 1000;
        intervalRef.current = setInterval(() => {
            const isTimeoutPresent = checkIfTimeoutPresent(timeoutStartAt);
            if (isTimeoutPresent) {
                // Timeout is still present
                const endAt = moment(timeoutStartAt).add(timeoutSeconds, 'seconds');
                const duration = moment.duration(endAt.diff(moment()));
                setTimeoutPresenceLeftInSeconds(Math.trunc(duration.asSeconds()));
            } else {
                // Timeout is NOT present anymore
                handleEndTimeout();
            }
        }, intervalDelay);
    }, [isTimeoutPresent]);

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    const handleStartTimeout = () => {
        handleEndTimeout();

        // Set timeout start at date in local storage
        localStorage.setItem(timeoutKey, moment().format());

        // Set timeout presence flag to "true"
        setIsTimeoutPresent(true);
    };

    const handleEndTimeout = () => {
        setIsTimeoutPresent(false);
        setTimeoutPresenceLeftInSeconds(null);
        if (intervalRef.current) clearInterval(intervalRef.current);
        intervalRef.current = null;
        localStorage.removeItem(timeoutKey);
    };

    return {
        isTimeoutPresent,
        timeoutPresenceLeftInSeconds,
        handleStartTimeout,
        handleEndTimeout,
    };
};

export default useTimeout;
