import TeamTag from '@app/containers/components/TeamTag';
import { usePageTitle, useScrollToTop } from '@app/hooks';
import { Button, Colors, Icon, Intent, MenuItem, Spinner } from '@blueprintjs/core';
import Avatar from '@components/Avatar';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Pagination from '@components/Pagination';
import RouterLink from '@components/RouterLink';
import Table from '@components/Table';
import TableCell from '@components/TableCell';
import DevText from '@components/Text';
import { Endpoints, imageHashPreview, userRoleInformation } from '@data/consts';
import { SessionStorage } from '@data/enums';
import { openModal } from '@modals/store/events';
import { CoreUserFilterInput } from 'dy-frontend-http-repository/lib/modules/CoreUser/inputs';
import { CoreUserListItemResource } from 'dy-frontend-http-repository/lib/modules/CoreUser/resources';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import qs from 'qs';
import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PlatformCoreUserFilter from './components/PlatformCoreUserFilter';
import { amountOfCoreUsersOnPage, initialPlatformCoreUserFilterData } from './consts';
import CreateCoreUserModal from './modals/CreateCoreUserModal';
import { fetchCoreUsers, hydrateQueryParameters } from './store/effects';
import {
    resetCoreUsers,
    resetIsQueryHydrationFinished,
    resetPage,
    resetPlatformCoreUserFilterData,
    setCoreUserSortData,
    setIsQueryHydrationFinished,
    setPage,
    setPlatformCoreUserFilterData,
} from './store/events';
import {
    $coreUsers,
    $coreUserSortData,
    $isQueryHydrationFinished,
    $page,
    $platformCoreUserFilterData,
} from './store/states';
import { CoreUserFilterQueryParameters, PlatformCoreUserFilterData } from './types';
import { PlatformCoreUserFilter as PlatformCoreUserFilterValueObject } from './valueObjects';
import { $permissions } from '@containers/store/states';
import { CoreUserPermission } from 'dy-frontend-permissions/lib/permission';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType, SortDirection } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse, SortData } from 'dy-frontend-http-repository/lib/data/types';
import { CoreUserSortField } from './enums';
import Select from '@app/components/Select';
import coreUserSortItemsInformation from './consts/coreUserSortItemsInformation';
import { ItemRendererProps } from '@blueprintjs/select';
import moment from 'moment';

const CoreUsers: React.FC = () => {
    usePageTitle('Members');
    const { scrollToTop } = useScrollToTop();

    const permissions = useStore($permissions);

    const navigate = useNavigate();
    const location = useLocation();
    const prevPage = useRef<number>(1);

    const page = useStore($page);
    const coreUsers = useStore($coreUsers);
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformCoreUserFilterData = useStore($platformCoreUserFilterData);
    const isFetchingCoreUsers = useStore(fetchCoreUsers.pending);
    const coreUserSortData = useStore($coreUserSortData);

    const handleLoadPage = async (
        newPage: number,
        sortData: SortData<CoreUserSortField>,
        filter?: CoreUserFilterInput
    ) => {
        try {
            const pageOffset = (newPage - 1) * amountOfCoreUsersOnPage;

            await fetchCoreUsers({
                pagination: {
                    _pagination: { limit: amountOfCoreUsersOnPage, offset: pageOffset },
                    _sort: sortData,
                },
                filter: {
                    is_shutdown: '0',
                    ...filter,
                },
            });

            prevPage.current = newPage;
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Members were not found`,
                    intent: Intent.DANGER,
                });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        }
    };

    // Initial load, parse query string If exists and apply filter, pagination, order and range
    useEffect(() => {
        let urlQueryParameters = location.search.slice(1);

        if (urlQueryParameters.length === 0) {
            // Filter
            const filterQueryParameters = sessionStorage.getItem(SessionStorage.CORE_USER_FILTER);
            if (filterQueryParameters && filterQueryParameters.length > 0) {
                urlQueryParameters = filterQueryParameters;
            }

            // Sort parameters
            const sortParameters = sessionStorage.getItem(SessionStorage.CORE_USER_SORTING);
            if (sortParameters) {
                if (urlQueryParameters.length > 0) {
                    urlQueryParameters += `&${sortParameters}`;
                } else {
                    urlQueryParameters = sortParameters;
                }
            }
        }

        // Get initial sort data
        const allQueryParameters = qs.parse(urlQueryParameters);
        let initialSortData: SortData<CoreUserSortField> = {
            field: CoreUserSortField.NAME,
            direction: SortDirection.ASC,
        };
        if (allQueryParameters.field) {
            initialSortData.field = allQueryParameters.field as CoreUserSortField;
            initialSortData.direction = allQueryParameters.direction as SortDirection;
        }

        const handleHydrateQueryParameters = async () => {
            // Get query params, get hydration out of it and create initial filter data
            const queryParameters: CoreUserFilterQueryParameters = allQueryParameters;
            const hydrationInput = PlatformCoreUserFilterValueObject.queryToHydrationInput(queryParameters);
            const platformCoreUserFilterData: PlatformCoreUserFilterData = {
                query: '',
                isArchived: queryParameters.isArchived !== undefined ? Boolean(queryParameters.isArchived) : null,
                isVerified: queryParameters.isVerified !== undefined ? Boolean(queryParameters.isVerified) : null,
                teams: [],
                roles: [],
            };

            try {
                // Hydrate & update filter data
                const { team } = await hydrateQueryParameters(hydrationInput);
                platformCoreUserFilterData.teams = team ?? [];
            } finally {
                // Update page, filter data and update hydration completion flag
                const page = queryParameters.page ? parseInt(queryParameters.page) : 1;
                prevPage.current = page;
                setPage(page);
                setCoreUserSortData(initialSortData);
                setPlatformCoreUserFilterData(platformCoreUserFilterData);
                setIsQueryHydrationFinished(true);
            }
        };

        handleHydrateQueryParameters();

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            if (page !== 1 && page === prevPage.current) {
                // Filter updated, so we need to go to first page
                setPage(1);
                return;
            }

            // platform filter -> API filter input
            const coreUsersFilterInput =
                PlatformCoreUserFilterValueObject.platformFilterToAPIFilterInput(platformCoreUserFilterData);

            // API filter input -> query params
            const coreUserFilterQueryParametersString = qs.stringify(
                PlatformCoreUserFilterValueObject.platformFilterToQueryParameters(page, platformCoreUserFilterData)
            );

            // Get sort data query parameters
            const coreUserSortDataQueryParametersString = qs.stringify(coreUserSortData);

            // Update session storage & URL
            sessionStorage.setItem(SessionStorage.CORE_USER_FILTER, coreUserFilterQueryParametersString);
            sessionStorage.setItem(SessionStorage.CORE_USER_SORTING, coreUserSortDataQueryParametersString);
            navigate(
                `${Endpoints.CORE_USERS}?${coreUserFilterQueryParametersString}&${coreUserSortDataQueryParametersString}`,
                {
                    replace: true,
                }
            );

            // Load page & scroll to top to see first items in the list
            handleLoadPage(page, coreUserSortData, coreUsersFilterInput);
            scrollToTop();
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished, page, platformCoreUserFilterData, coreUserSortData]);

    useEffect(() => {
        return () => {
            resetCoreUsers();
            resetPage();
            resetIsQueryHydrationFinished();
            resetPlatformCoreUserFilterData();
        };
    }, []);

    if (!coreUsers) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderCoreUserSortingSelect = () => {
        const currentLabel =
            coreUserSortItemsInformation[`${coreUserSortData.field}${coreUserSortData.direction}`] ??
            `${coreUserSortData.field} - ${coreUserSortData.direction}`;

        const renderItem = (item: SortData<CoreUserSortField>, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive =
                item.direction === coreUserSortData.direction && item.field === coreUserSortData.field;
            const label =
                coreUserSortItemsInformation[`${item.field}${item.direction}`] ?? `${item.field} - ${item.direction}`;
            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={`${item.field}${item.direction}`}
                    text={label}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: SortData<CoreUserSortField>) => {
            setCoreUserSortData(item);
        };

        const getItems = (): SortData<CoreUserSortField>[] => {
            return [
                {
                    field: CoreUserSortField.NAME,
                    direction: SortDirection.ASC,
                },
                {
                    field: CoreUserSortField.NAME,
                    direction: SortDirection.DESC,
                },
                {
                    field: CoreUserSortField.LATEST_ACTIVITY_AT,
                    direction: SortDirection.DESC,
                },
                {
                    field: CoreUserSortField.LATEST_ACTIVITY_AT,
                    direction: SortDirection.ASC,
                },
                {
                    field: CoreUserSortField.VERIFIED_AT,
                    direction: SortDirection.DESC,
                },
                {
                    field: CoreUserSortField.VERIFIED_AT,
                    direction: SortDirection.ASC,
                },
            ];
        };

        return (
            <Select<SortData<CoreUserSortField>>
                items={getItems()}
                itemRenderer={renderItem}
                onItemSelect={handleItemSelect}
                popoverProps={{
                    matchTargetWidth: true,
                    usePortal: false,
                    className: 'ml-auto',
                }}
                selectButtonProps={{
                    rightIcon: 'double-caret-vertical',
                    icon: 'sort',
                    text: currentLabel,
                    style: { width: '250px' },
                }}
            />
        );
    };

    const renderHeader = () => {
        const renderAddButton = () => {
            if (!permissions.isRoot.coreUser && !permissions.has(CoreUserPermission.CREATE)) {
                return null;
            }

            return (
                <Button
                    className="ml-1"
                    icon="add"
                    intent={Intent.PRIMARY}
                    onClick={() => openModal({ ModalComponent: CreateCoreUserModal })}
                >
                    Add member
                </Button>
            );
        };

        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h3">Members</Heading>

                <Flex direction="row" align="center">
                    {renderCoreUserSortingSelect()}
                    {renderAddButton()}
                </Flex>
            </Flex>
        );
    };

    const renderCoreUsersTable = () => {
        if (coreUsers.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No members were found
                        </Heading>
                    }
                />
            );
        }

        const renderTableHeader = () => {
            return (
                <thead>
                    <tr>
                        <th>Member</th>
                        <th>Team</th>
                        <th>Email</th>
                        <th>Latest clients</th>
                        <th>Latest activity</th>
                    </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            const renderRow = (coreUser: CoreUserListItemResource) => {
                // Render Team table cell
                const renderTeamTableCell = () => {
                    // Check if user attached to any team
                    if (coreUser.team_participation.length === 0) {
                        return (
                            <TableCell verticalAlign="middle">
                                <DevText muted>No team</DevText>
                            </TableCell>
                        );
                    }

                    return (
                        <TableCell verticalAlign="middle">
                            {coreUser.team_participation.map((teamParticipation) => (
                                <TeamTag
                                    className="mb-small mr-small"
                                    id={teamParticipation.team.id}
                                    name={teamParticipation.team.title}
                                    color={teamParticipation.team.color}
                                >
                                    {teamParticipation.team.title}
                                </TeamTag>
                            ))}
                        </TableCell>
                    );
                };

                const renderCompanyInfo = () => {
                    let companyInformation = userRoleInformation[coreUser.role].label;

                    if (coreUser.company_position.trim().length > 0) {
                        companyInformation += `, ${coreUser.company_position}`;
                    }

                    if (companyInformation.length === 0) {
                        return null;
                    }

                    return <DevText muted>{companyInformation}</DevText>;
                };

                // Get avatar src
                let avatarSrc: string | null = null;
                if (coreUser.image_hash) {
                    avatarSrc = imageHashPreview.userImage(coreUser.image_hash, ImageHashPreviewSize.SM);
                }

                return (
                    <tr key={coreUser.id}>
                        {/* User */}
                        <TableCell verticalAlign="middle">
                            <Flex direction="row" align="center">
                                <Avatar
                                    className="mr-1"
                                    alt={coreUser.first_name}
                                    width="42px"
                                    height="42px"
                                    src={avatarSrc}
                                />
                                <Flex direction="column" justify="center">
                                    <Flex direction="column">
                                        <RouterLink
                                            color={Colors.WHITE}
                                            to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', coreUser.id)}
                                        >
                                            {coreUser.first_name} {coreUser.last_name}
                                        </RouterLink>

                                        {renderCompanyInfo()}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </TableCell>

                        {/* Teams */}
                        {renderTeamTableCell()}

                        {/* Email */}
                        <TableCell verticalAlign="middle">
                            <DevText>{coreUser.email}</DevText>
                        </TableCell>

                        {/* TODO: Latest clients */}
                        <TableCell verticalAlign="middle">
                            <DevText muted>No data</DevText>
                        </TableCell>

                        <TableCell verticalAlign="middle">
                            {moment(coreUser.metadata.latest_task_activity_at).format('D MMM YYYY, HH:mm')}
                        </TableCell>
                    </tr>
                );
            };

            return <tbody>{coreUsers.items.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={isFetchingCoreUsers}>
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderPagination = () => {
        // Admins were not fetched yet
        if (coreUsers === null) {
            return null;
        }

        // Check if offset paginator exist
        if (coreUsers.paginator === null) {
            return;
        }

        // Only one page exist
        if (!coreUsers.paginator.has_more && coreUsers.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingCoreUsers}
                    hasMore={coreUsers.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfCoreUsersOnPage}
                    totalItems={coreUsers.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <FixedWidthPageContainer>
            {/* Header */}
            {renderHeader()}

            {/* Filter */}
            <PlatformCoreUserFilter className="mb-2" />

            {/* Core users table */}
            {renderCoreUsersTable()}

            {/* Pagination */}
            {renderPagination()}
        </FixedWidthPageContainer>
    );
};

export default CoreUsers;
