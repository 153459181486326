import { ButtonProps, Button } from '@blueprintjs/core';
import React, { useCallback } from 'react';
import { useDropzone, FileWithPath } from 'react-dropzone';

export interface FileUploadButtonProps {
    multiple?: boolean;
    extensionsToAccept?: string[];
    onAttachFiles: (files: FileWithPath[]) => any;
}

export type Props = ButtonProps & FileUploadButtonProps;

const FileUploadButton: React.FC<Props> = ({
    loading = false,
    disabled = false,
    multiple = false,
    onAttachFiles,
    extensionsToAccept = [],
    children,
    className,
    onClick,
    ...buttonProps
}) => {
    const onDrop = useCallback((acceptedFiles: File[]) => onAttachFiles(acceptedFiles), [onAttachFiles]);

    const { getRootProps, getInputProps, open } = useDropzone({
        disabled,
        multiple,
        noClick: true,
        accept: extensionsToAccept.length > 0 ? { accept: extensionsToAccept } : undefined,
        onDrop,
    });

    return (
        <div className={className ?? ''} {...getRootProps()}>
            <input style={{ display: 'none' }} {...getInputProps()} />
            <Button
                disabled={disabled || loading}
                loading={loading}
                onClick={(e) => {
                    open();
                    onClick?.(e);
                }}
                {...buttonProps}
            >
                {children}
            </Button>
        </div>
    );
};

export default FileUploadButton;
