import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { $task } from '@app/containers/pages/Task/store/states';
import { Card, Icon, IconName, Intent } from '@blueprintjs/core';
import { TaskFeedbackCriteria } from 'dy-frontend-shared/lib/data/enums';
import { taskFeedbackInformation } from 'dy-frontend-shared/lib/data/consts';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';

export type Props = HTMLAttributes<HTMLDivElement>;

const TaskFeedbackInformation: React.FC<Props> = (props) => {
    const task = useStore($task);

    if (!task) {
        return null;
    }

    if (task.state !== TaskState.DELIVERED) {
        // Task is NOT delivered
        return null;
    }

    // Get task feedback
    const feedback = task.client_feedback;
    if (!feedback) {
        // Client did NOT provide any feedback
        return null;
    }

    const renderTaskFeedbackCriteriaTile = (criteria: TaskFeedbackCriteria, icon: IconName) => {
        const isFeedbackPositive = taskFeedbackInformation[criteria].positiveFeedbackScore === feedback[criteria];

        return (
            <Flex direction="row" justify="space-between">
                <Flex direction="row" align="center">
                    <Icon className="mr-1" icon={icon} />
                    <DevText>{taskFeedbackInformation[criteria].label}</DevText>
                </Flex>

                <Icon
                    intent={isFeedbackPositive ? Intent.SUCCESS : Intent.DANGER}
                    icon={isFeedbackPositive ? 'thumbs-up' : 'thumbs-down'}
                />
            </Flex>
        );
    };

    const renderTaskFeedbackComment = () => {
        if (feedback.comment.trim().length === 0) {
            // Do not show empty comment
            return null;
        }

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    Comment
                </DevText>
                <DevText running>{feedback.comment}</DevText>
            </div>
        );
    };

    return (
        <Card {...props}>
            <Heading className="mb-2" type="h4">
                Client feedback
            </Heading>

            <div>
                <div className="mb-2">{renderTaskFeedbackCriteriaTile(TaskFeedbackCriteria.CREATIVITY, 'star')}</div>
                <div className="mb-2">{renderTaskFeedbackCriteriaTile(TaskFeedbackCriteria.SPEED, 'dashboard')}</div>
                <div className="mb-2">{renderTaskFeedbackCriteriaTile(TaskFeedbackCriteria.QUALITY, 'build')}</div>
                <div>{renderTaskFeedbackCriteriaTile(TaskFeedbackCriteria.COMMUNICATION, 'chat')}</div>
            </div>

            {renderTaskFeedbackComment()}
        </Card>
    );
};

export default TaskFeedbackInformation;
