import { Button, Card, Classes, Intent, Menu, MenuItem, Popover, Tag } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { $client } from '../../store/states';
import {
    endVirtualLicence,
    fetchLicences,
    fetchVirtualLicences,
    resumeVirtualLicence,
    suspendVirtualLicence,
} from './store/effects';
import { $licences, $virtualLicences } from './store/states';
import Box from '@components/Box';
import Heading from '@app/components/Heading';
import { VirtualLicenceResource } from 'dy-frontend-http-repository/lib/modules/VirtualLicence/resources';
import { resetLicences, resetTaskCategories, resetTaskCategoryGroups, resetVirtualLicences } from './store/events';
import Flex from '@app/components/Flex';
import { openModal } from '@app/containers/modals/store/events';
import SetVirtualLicenceQuotaListModal, {
    SetVirtualLicenceQuotaListModalProps,
} from './modals/SetVirtualLicenceQuotaListModal';
import DevText from '@app/components/Text';
import VirtualLicenceQuotaList from './components/VirtualLicenceQuotaList';
import SetVirtualLicenceTaskCategoryListModal, {
    SetVirtualLicenceTaskCategoryListModalProps,
} from './modals/SetVirtualLicenceTaskCategoryListModal';
import SetVirtualLicenceTaskCategoryGroupListModal, {
    SetVirtualLicenceTaskCategoryGroupListModalProps,
} from './modals/SetVirtualLicenceTaskCategoryGroupListModal';
import VirtualLicenceTaskCategoryGroupList from './components/VirtualLicenceTaskCategoryGroupList';
import VirtualLicenceTaskCategoryList from './components/VirtualLicenceTaskCategoryList';
import CreateVirtualLicenceModal, { CreateVirtualLicenceModalProps } from './modals/CreateVirtualLicenceModal';
import { usePageTitle } from '@app/hooks';
import LicencesList from './components/LicencesList';
import CreateLicenceModal, { CreateLicenceModalProps } from './modals/CreateLicenceModal';
import { virtualLicencesApi } from './store/apis';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { $permissions } from '@containers/store/states';
import { VirtualLicencePermission } from 'dy-frontend-permissions/lib/permission';
import { useNavigate } from 'react-router-dom';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { Endpoints } from '@app/data/consts';

const ClientLicences: React.FC = () => {
    const permissions = useStore($permissions);

    const { changeTitle } = usePageTitle('Client Licences');
    const navigate = useNavigate();

    const client = useStore($client);

    const virtualLicences = useStore($virtualLicences);
    const licences = useStore($licences);

    const [resumingVirtualLicenceId, setResumingVirtualLicenceId] = useState<ID | null>(null);
    const [suspendingVirtualLicenceId, setSuspendingVirtualLicenceId] = useState<ID | null>(null);
    const [endingVirtualLicenceId, setEndingVirtualLicenceId] = useState<ID | null>(null);

    useEffect(() => {
        if (!client) {
            return;
        }

        // Fetch virtual licences
        fetchVirtualLicences({ user_id: client.id }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Customizations for client with ID of ${client.id} were not found`,
                    intent: Intent.DANGER,
                });

                // Go to client tasks page
                navigate(Endpoints.CLIENT_TASKS.replace(':clientId', client.id));
            }
        });

        // Fetch licences
        fetchLicences({ user_id: client.id, is_shutdown: '0' }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Licences for client with ID of ${client.id} were not found`,
                    intent: Intent.DANGER,
                });

                // Go to client tasks page
                navigate(Endpoints.CLIENT_TASKS.replace(':clientId', client.id));
            }
        });
    }, [client?.id]);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Licences - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    useEffect(() => {
        return () => {
            resetVirtualLicences();
            resetTaskCategoryGroups();
            resetTaskCategories();
            resetLicences();
        };
    }, []);

    if (!client) {
        return null;
    }

    const renderSkeleton = () => {
        return <Box className={Classes.SKELETON} height="100px" />;
    };

    const renderVirtualLicences = () => {
        if (!permissions.isEnabled.virtualLicence) {
            return null;
        }

        if (!virtualLicences) {
            // Virtual licences is NOT fetched yet
            return renderSkeleton();
        }

        const isAttachmentManageAllowed =
            permissions.isRoot.virtualLicence || permissions.has(VirtualLicencePermission.ATTACHMENT_MANAGE);
        const isSuspendManageAllowed =
            permissions.isRoot.virtualLicence || permissions.has(VirtualLicencePermission.SUSPEND_MANAGE);
        const isEndAllowed = permissions.isRoot.virtualLicence || permissions.has(VirtualLicencePermission.END);

        const handleSetVirtualLicenceQuotaListModal = (virtualLicence: VirtualLicenceResource) => {
            openModal<SetVirtualLicenceQuotaListModalProps>({
                ModalComponent: SetVirtualLicenceQuotaListModal,
                data: {
                    virtualLicenceId: virtualLicence.id,
                    initialAttachmentList: virtualLicence.quotas,
                },
            });
        };

        const handleSetVirtualLicenceTaskCategoryListModal = (virtualLicence: VirtualLicenceResource) => {
            openModal<SetVirtualLicenceTaskCategoryListModalProps>({
                ModalComponent: SetVirtualLicenceTaskCategoryListModal,
                data: {
                    virtualLicenceId: virtualLicence.id,
                    initialAttachmentList: virtualLicence.task_categories,
                },
            });
        };

        const handleSetVirtualLicenceTaskCategoryGroupListModal = (virtualLicence: VirtualLicenceResource) => {
            openModal<SetVirtualLicenceTaskCategoryGroupListModalProps>({
                ModalComponent: SetVirtualLicenceTaskCategoryGroupListModal,
                data: {
                    virtualLicenceId: virtualLicence.id,
                    initialAttachmentList: virtualLicence.task_category_groups,
                },
            });
        };

        const handleSuspendVirtualLicence = async (virtualLicence: VirtualLicenceResource) => {
            setResumingVirtualLicenceId(virtualLicence.id);

            try {
                await suspendVirtualLicence(virtualLicence.id);
                virtualLicencesApi.suspend(virtualLicence.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setResumingVirtualLicenceId(null);
            }
        };

        const handleResumeVirtualLicence = async (virtualLicence: VirtualLicenceResource) => {
            setSuspendingVirtualLicenceId(virtualLicence.id);

            try {
                await resumeVirtualLicence(virtualLicence.id);
                virtualLicencesApi.resume(virtualLicence.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setSuspendingVirtualLicenceId(null);
            }
        };

        const handleEndVirtualLicence = async (virtualLicence: VirtualLicenceResource) => {
            setEndingVirtualLicenceId(virtualLicence.id);

            try {
                await endVirtualLicence(virtualLicence.id);
                virtualLicencesApi.end(virtualLicence.id);
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setEndingVirtualLicenceId(null);
            }
        };

        const renderSuspendVirtualLicenceMenuItem = (virtualLicence: VirtualLicenceResource) => {
            if (virtualLicence.suspended_at !== null) {
                // Virtual licence is suspended
                return null;
            }

            // Get suspending flag
            const isSuspending =
                suspendingVirtualLicenceId !== null && suspendingVirtualLicenceId === virtualLicence.id;

            return (
                <ConfirmationPopover
                    fill
                    title="Are you sure you want to suspend customization?"
                    description="When confirmed, customization will be suspended"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => handleSuspendVirtualLicence(virtualLicence)}
                        >
                            Yes, suspend
                        </Button>,
                    ]}
                >
                    <MenuItem
                        disabled={isSuspending}
                        shouldDismissPopover={false}
                        icon="pause"
                        text="Suspend customization"
                    />
                </ConfirmationPopover>
            );
        };

        const renderResumeVirtualLicenceMenuItem = (virtualLicence: VirtualLicenceResource) => {
            if (virtualLicence.suspended_at === null) {
                // Virtual licence is NOT suspended
                return null;
            }

            // Get resuming flag
            const isResuming = resumingVirtualLicenceId !== null && resumingVirtualLicenceId === virtualLicence.id;

            return (
                <ConfirmationPopover
                    fill
                    title="Are you sure you want to resume customization?"
                    description="When confirmed, customization will be resumed"
                    actions={[
                        <Button
                            intent={Intent.PRIMARY}
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => handleResumeVirtualLicence(virtualLicence)}
                        >
                            Yes, resume
                        </Button>,
                    ]}
                >
                    <MenuItem
                        disabled={isResuming}
                        shouldDismissPopover={false}
                        icon="play"
                        text="Resume customization"
                    />
                </ConfirmationPopover>
            );
        };

        const renderEndVirtualLicenceMenuItem = (virtualLicence: VirtualLicenceResource) => {
            if (virtualLicence.end_at !== null) {
                // Virtual licence is already ended
                return null;
            }

            // Get resuming flag
            const isEnding = endingVirtualLicenceId !== null && endingVirtualLicenceId === virtualLicence.id;

            return (
                <ConfirmationPopover
                    fill
                    title="Are you sure you want to remove customization?"
                    description="When confirmed, customization will be removed"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={() => handleEndVirtualLicence(virtualLicence)}
                        >
                            Yes, remove
                        </Button>,
                    ]}
                >
                    <MenuItem
                        disabled={isEnding}
                        shouldDismissPopover={false}
                        text="Remove customization"
                        intent={Intent.DANGER}
                    />
                </ConfirmationPopover>
            );
        };

        const renderVirtualLicenceMoreControlsButton = (virtualLicence: VirtualLicenceResource) => {
            if (!isAttachmentManageAllowed && !isSuspendManageAllowed && !isEndAllowed) {
                return null;
            }

            const renderMenu = () => {
                return (
                    <Menu>
                        {isAttachmentManageAllowed && (
                            <MenuItem
                                text="Update quota list / limitations"
                                onClick={() => handleSetVirtualLicenceQuotaListModal(virtualLicence)}
                            />
                        )}
                        {isAttachmentManageAllowed && (
                            <MenuItem
                                text="Update included request category group rules"
                                onClick={() => handleSetVirtualLicenceTaskCategoryGroupListModal(virtualLicence)}
                            />
                        )}
                        {isAttachmentManageAllowed && (
                            <MenuItem
                                text="Update specific request category rules"
                                onClick={() => handleSetVirtualLicenceTaskCategoryListModal(virtualLicence)}
                            />
                        )}
                        {isSuspendManageAllowed && renderSuspendVirtualLicenceMenuItem(virtualLicence)}
                        {isSuspendManageAllowed && renderResumeVirtualLicenceMenuItem(virtualLicence)}
                        {isEndAllowed && renderEndVirtualLicenceMenuItem(virtualLicence)}
                    </Menu>
                );
            };

            return (
                <Popover content={renderMenu()}>
                    <Button icon="more" />
                </Popover>
            );
        };

        const renderVirtualLicenceDescription = (virtualLicence: VirtualLicenceResource) => {
            if (virtualLicence.description.trim().length === 0) {
                return null;
            }

            return <DevText muted>{virtualLicence.description}</DevText>;
        };

        const renderVirtualLicenceSuspendedTag = (virtualLicence: VirtualLicenceResource) => {
            if (virtualLicence.suspended_at === null) {
                // Virtual licence is NOT suspended
                return null;
            }

            return (
                <Tag className="mr-1" icon="pause" intent={Intent.WARNING}>
                    Paused
                </Tag>
            );
        };

        const renderVirtualLicence = (virtualLicence: VirtualLicenceResource) => {
            return (
                <Card>
                    <div className="mb-2">
                        <Flex direction="row" justify="space-between" align="center" className="mb-small">
                            <Flex direction="row" align="center">
                                {renderVirtualLicenceSuspendedTag(virtualLicence)}{' '}
                                <Heading type="h4">Customization</Heading>
                            </Flex>

                            {renderVirtualLicenceMoreControlsButton(virtualLicence)}
                        </Flex>
                        {renderVirtualLicenceDescription(virtualLicence)}
                    </div>

                    {/* Quota list */}
                    <VirtualLicenceQuotaList className="mb-2" quotas={virtualLicence.quotas} />

                    {/* Task category groups */}
                    <VirtualLicenceTaskCategoryGroupList
                        className="mb-2"
                        taskCategoryGroupAttachments={virtualLicence.task_category_groups}
                    />

                    {/* Task categories */}
                    <VirtualLicenceTaskCategoryList taskCategoryAttachments={virtualLicence.task_categories} />
                </Card>
            );
        };

        const renderVirtualLicenceList = () => {
            if (virtualLicences.items.length === 0) {
                // Client do NOT have any virtual licences
                return <DevText muted>No user customizations are created</DevText>;
            }

            return (
                <div>
                    {virtualLicences.items.map((virtualLicence, index) => (
                        <div key={virtualLicence.id} className={index === 0 ? '' : 'mt-1'}>
                            {renderVirtualLicence(virtualLicence)}
                        </div>
                    ))}
                </div>
            );
        };

        const renderCreateVirtualLicenceButton = () => {
            if (!permissions.isRoot.virtualLicence && !permissions.has(VirtualLicencePermission.CREATE)) {
                return null;
            }

            const handleCreateVirtualLicence = () => {
                openModal<CreateVirtualLicenceModalProps>({
                    ModalComponent: CreateVirtualLicenceModal,
                    data: { userId: client.id },
                });
            };

            return (
                <Button icon="add" intent={Intent.PRIMARY} onClick={handleCreateVirtualLicence}>
                    Create
                </Button>
            );
        };

        return (
            <div>
                <Flex className="mb-2" direction="row" align="center" justify="space-between">
                    <Heading type="h3">User specific customizations</Heading>
                    {renderCreateVirtualLicenceButton()}
                </Flex>

                {renderVirtualLicenceList()}
            </div>
        );
    };

    const renderLicences = () => {
        if (!licences) {
            // Licences is NOT fetched yet
            return renderSkeleton();
        }

        const handleCreateLicence = () => {
            openModal<CreateLicenceModalProps>({
                ModalComponent: CreateLicenceModal,
                data: {
                    userId: client.id,
                },
            });
        };

        const renderCreateLicenceButton = () => {
            // TODO: Auth
            const isAllowed = false;
            if (!isAllowed) {
                return null;
            }

            return (
                <Button icon="add" intent={Intent.PRIMARY} onClick={handleCreateLicence}>
                    Create
                </Button>
            );
        };

        return (
            <div>
                <Flex className="mb-2" direction="row" align="center" justify="space-between">
                    <Heading type="h3">Plans and features</Heading>
                    {renderCreateLicenceButton()}
                </Flex>

                <LicencesList licences={licences.items} />
            </div>
        );
    };

    return (
        <div>
            <div className="mb-4">{renderLicences()}</div>
            <div>{renderVirtualLicences()}</div>
        </div>
    );
};

export default ClientLicences;
