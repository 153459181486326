import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import { NotificationResource, InvoiceResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints } from '@app/data/consts';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import Link from '@app/components/Link';
import NotificationTemplate from '../../../NotificationTemplate';

export interface InvoicePaidNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    invoice: InvoiceResource | null;
    dismiss: () => Promise<void>;
}

type Props = InvoicePaidNotificationProps;

const InvoicePaidNotification: React.FC<Props> = ({ notification, invoice, dismiss }) => {
    const renderContent = () => {
        if (!invoice) {
            // This is bad, invoice is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Invoice was not found</DevText>;
        }

        return (
            <div>
                <DevText running>
                    Invoice for{' '}
                    {PriceUtils.formatPrice({
                        price: invoice.total,
                        currency: invoice.currency,
                        shouldDisplayCents: true,
                    })}{' '}
                    was paid by{' '}
                    <RouterLink to={Endpoints.CLIENT_BILLING.replace(':clientId', invoice.user.id)}>
                        {invoice.user.first_name} {invoice.user.last_name}
                    </RouterLink>
                    .
                </DevText>
                {invoice.hosted_url && (
                    <Link color={Colors.BLUE5} onClick={() => invoice.hosted_url && window.open(invoice.hosted_url)}>
                        Click here to view info
                    </Link>
                )}
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="Invoice paid"
            icon="credit-card"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default InvoicePaidNotification;
