import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { Button, Card, Classes, Colors, Icon, Intent, Tag } from '@blueprintjs/core';
import { LicenceOrigin } from 'dy-frontend-http-repository/lib/data/enums';
import { LicenceResource } from 'dy-frontend-http-repository/lib/modules/Licence/resources';
import React, { HTMLAttributes, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { openModal } from '@app/containers/modals/store/events';
import UpdateLicencePlanModal, { UpdateLicencePlanModalProps } from '../../modals/UpdateLicencePlanModal';
import RouterLink from '@app/components/RouterLink';
import { shutdownLicence } from '../../store/effects';
import { licencesApi } from '../../store/apis';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { useStore } from 'effector-react';
import { $permissions } from '@containers/store/states';

export interface LicencesListProps {
    licences: LicenceResource[];
}

type Props = HTMLAttributes<HTMLDivElement> & LicencesListProps;

const LicencesList: React.FC<Props> = ({ licences, ...props }) => {
    const permissions = useStore($permissions);

    const navigate = useNavigate();

    const [removingLicenceId, setRemovingLicenceId] = useState<ID | null>(null);
    const [suspendingLicenceId, setSuspendingLicenceId] = useState<ID | null>(null);

    if (licences.length === 0) {
        // Client do NOT have any licences
        return <DevText muted>No plans and features are present at the moment</DevText>;
    }

    const handleUpdateLicencePlan = (licenceId: ID) => {
        openModal<UpdateLicencePlanModalProps>({
            ModalComponent: UpdateLicencePlanModal,
            data: { licenceId },
        });
    };

    const handleSuspendLicence = async (licenceId: ID) => {
        setSuspendingLicenceId(licenceId);

        try {
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingLicenceId(null);
        }
    };

    const handleRemoveLicence = async (licenceId: ID) => {
        setRemovingLicenceId(licenceId);

        try {
            await shutdownLicence(licenceId);
            licencesApi.shutdown(licenceId);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingLicenceId(null);
        }
    };

    const renderLicenceQuantityLabel = (quantity: number) => {
        if (quantity <= 1) {
            return null;
        }

        return (
            <Heading muted type="h5">
                x{quantity}
            </Heading>
        );
    };

    const renderLicencePlanSection = (licence: LicenceResource) => {
        return (
            <Flex direction="row">
                <Icon className="mr-1" icon="box" size={19} color={licence.plan.color} />
                <RouterLink color={Colors.WHITE} to={Endpoints.PLAN.replace(':planId', licence.plan.id)}>
                    <Heading type="h5" className="mr-1">
                        {licence.plan.title}
                    </Heading>
                </RouterLink>
                {renderLicenceQuantityLabel(licence.quantity)}
            </Flex>
        );
    };

    const renderSuspendedInformation = (licence: LicenceResource) => {
        if (!licence.suspended_at) {
            // Licence is active
            return null;
        }

        return (
            <Flex direction="row" align="center">
                <Tag large={false} icon="pause" className="mr-1" intent={Intent.WARNING}>
                    Paused
                </Tag>

                <DevText muted>
                    Suspended {moment(licence.suspended_at).format('D MMM, YYYY')} (
                    {moment(licence.suspended_at).fromNow()})
                </DevText>
            </Flex>
        );
    };

    const renderActiveInformation = (licence: LicenceResource) => {
        if (licence.suspended_at) {
            // Licence is suspended
            return null;
        }

        return (
            <Flex direction="row" align="center">
                <Tag minimal large={false} icon="play" className="mr-1" intent={Intent.SUCCESS}>
                    Active
                </Tag>

                <DevText muted>Since {moment(licence.allocated_at).format('D MMM, YYYY')}</DevText>
            </Flex>
        );
    };

    const renderFooterSection = (licence: LicenceResource) => {
        return (
            <Flex direction="row">
                {renderSuspendedInformation(licence)}
                {renderActiveInformation(licence)}
            </Flex>
        );
    };

    const renderViewLicenceSubscriptionModal = (subscriptionId: ID) => {
        if (
            !permissions.isEnabled.paymentAccount
            || !permissions.isEnabled.subscription
        ) {
            return null;
        }

        return (
            <Button
                minimal
                icon="eye-open"
                onClick={() => navigate(Endpoints.SUBSCRIPTION.replace(':subscriptionId', subscriptionId))}
            >
                View subscription
            </Button>
        );
    };

    const renderUpdateLicencePlanButton = (licenceId: ID) => {
        return (
            <Button minimal icon="refresh" onClick={() => handleUpdateLicencePlan(licenceId)}>
                Change plan
            </Button>
        );
    };

    const renderSuspendLicenceButton = (licenceId: ID) => {
        const isSuspendingLicence = licenceId === suspendingLicenceId;

        return (
            <ConfirmationPopover
                title="Are you sure you want to suspend licence?"
                description="When confirmed, licence will be suspended"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleSuspendLicence(licenceId)}
                    >
                        Yes, suspend
                    </Button>,
                ]}
            >
                <Button minimal loading={isSuspendingLicence} disabled={isSuspendingLicence} icon="pause">
                    Suspend
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderRemoveLicenceButton = (licenceId: ID) => {
        const isRemoving = removingLicenceId !== null && removingLicenceId === licenceId;
        return (
            <ConfirmationPopover
                title="Are you sure you want to remove licence?"
                description="When confirmed, licence will be removed"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleRemoveLicence(licenceId)}
                    >
                        Yes, remove
                    </Button>,
                ]}
            >
                <Button loading={isRemoving} disabled={isRemoving} minimal icon="trash" intent={Intent.DANGER}>
                    Remove
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderManualLicence = (licence: LicenceResource) => {
        return (
            <Card>
                <Flex direction="row" justify="space-between">
                    <div>
                        <div className="mb-1">{renderLicencePlanSection(licence)}</div>
                        <div>{renderFooterSection(licence)}</div>
                    </div>

                    <Flex direction="row" align="center">
                        <div className="mr-1">{renderUpdateLicencePlanButton(licence.id)}</div>
                        <div className="mr-1">{renderSuspendLicenceButton(licence.id)}</div>
                        <div>{renderRemoveLicenceButton(licence.id)}</div>
                    </Flex>
                </Flex>
            </Card>
        );
    };

    const renderSubscriptionLicence = (licence: LicenceResource) => {
        return (
            <Card>
                <Flex direction="row" justify="space-between">
                    <div>
                        <div className="mb-1">{renderLicencePlanSection(licence)}</div>
                        <div>{renderFooterSection(licence)}</div>
                    </div>

                    <Flex direction="row" align="center">
                        {licence.subscription && renderViewLicenceSubscriptionModal(licence.subscription.id)}
                    </Flex>
                </Flex>
            </Card>
        );
    };

    const renderLicence = (licence: LicenceResource) => {
        switch (licence.origin) {
            case LicenceOrigin.MANUAL:
                return renderManualLicence(licence);
            case LicenceOrigin.SUBSCRIPTION:
                return renderSubscriptionLicence(licence);
        }
    };

    const licenceOriginSortOrder = [LicenceOrigin.MANUAL, LicenceOrigin.SUBSCRIPTION];

    return (
        <div {...props}>
            {licences
                .sort((a, b) => licenceOriginSortOrder.indexOf(a.origin) - licenceOriginSortOrder.indexOf(b.origin))
                .map((licence, index) => (
                    <div key={licence.id} className={index === 0 ? '' : 'mt-1'}>
                        {renderLicence(licence)}
                    </div>
                ))}
        </div>
    );
};

export default LicencesList;
