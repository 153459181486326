import { openModal } from '@app/containers/modals/store/events';
import TaskDeliverableViewerModal, {
    TaskDeliverableViewerModalProps,
} from '@app/containers/pages/Task/modals/TaskDeliverableViewerModal';
import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { $authorizedUser } from '@containers/store/states';
import { $task } from '@pages/Task/store/states';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import TaskDeliverableListViewListItem from '../../../../components/TaskDeliverableListViewListItem';
import { useApproveTaskDeliverable, useRemoveTaskDeliverable } from '../../hooks';
import UpdateTaskDeliverableInformationModal, {
    UpdateTaskDeliverableInformationModalProps,
} from '../../pages/TaskDeliverablesAll/modals/UpdateTaskDeliverableInformationModal';

export interface TaskDeliverableListViewProps {
    taskDeliverables: TaskDeliverableResource[];
    isApproveAllowed?: boolean;
    isArchiveAllowed?: boolean;
    isRestoreAllowed?: boolean;
    isUpdateAllowed?: boolean;
}

export type Props = TaskDeliverableListViewProps & HTMLAttributes<HTMLDivElement>;

const TaskDeliverableListView: React.FC<Props> = ({
    taskDeliverables,
    isApproveAllowed = false,
    isArchiveAllowed = false,
    isRestoreAllowed = false,
    isUpdateAllowed = false,
    ...props
}) => {
    const { removeTaskDeliverable } = useRemoveTaskDeliverable();
    const { approveTaskDeliverable } = useApproveTaskDeliverable();

    const task = useStore($task);
    const me = useStore($authorizedUser);

    const [openedMenuId, setOpenedMenuId] = useState<ID | null>(null);
    const [approvingDeliverableId, setApprovingDeliverableId] = useState<ID | null>(null);
    const [removingDeliverableId, setRemovingDeliverableId] = useState<ID | null>(null);

    const [isAuthorizedUserParticipant, setIsAuthorizedUserParticipant] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        setIsAuthorizedUserParticipant(task.participants.find((p) => p.user.id === me.user.id) !== undefined);
    }, [task, me]);

    // TODO: may be add different tables for different task deliverable types

    if (!task) {
        return null;
    }

    const handleCloseMenu = () => {
        setOpenedMenuId(null);
    };

    const handleToggleMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, taskDeliverableId: ID) => {
        e.stopPropagation();

        if (openedMenuId) {
            // Close menu
            handleCloseMenu();
        } else {
            // Open menu
            setOpenedMenuId(taskDeliverableId);
        }
    };

    const handleRemoveTaskDeliverable = async (taskDeliverable: TaskDeliverableResource) => {
        setRemovingDeliverableId(taskDeliverable.id);

        try {
            await removeTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingDeliverableId(null);
        }
    };

    const handleApproveTaskDeliverable = async (taskDeliverable: TaskDeliverableResource) => {
        setApprovingDeliverableId(taskDeliverable.id);

        try {
            await approveTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setApprovingDeliverableId(null);
        }
    };

    const handleOpenTaskDeliverableViewerModal = (taskDeliverableId: ID) => {
        openModal<TaskDeliverableViewerModalProps>({
            ModalComponent: TaskDeliverableViewerModal,
            data: {
                taskId: task.id,
                taskDeliverableId,
                taskDeliverables: taskDeliverables,
                isAssignedToTask: isAuthorizedUserParticipant,
            },
        });
    };

    const renderTaskDeliverableMenu = (taskDeliverable: TaskDeliverableResource) => {
        if (!isApproveAllowed && !isArchiveAllowed && !isUpdateAllowed) {
            return null;
        }

        const renderUpdateTaskDeliverableInformationMenuItem = () => {
            if (!isUpdateAllowed) {
                return null;
            }

            return (
                <MenuItem
                    text="Update"
                    icon="edit"
                    onClick={() =>
                        openModal<UpdateTaskDeliverableInformationModalProps>({
                            ModalComponent: UpdateTaskDeliverableInformationModal,
                            data: {
                                taskDeliverableId: taskDeliverable.id,
                                taskId: task.id,
                                textContent: taskDeliverable.text_content,
                                title: taskDeliverable.title,
                                type: taskDeliverable.type,
                                url: taskDeliverable.url,
                                path: taskDeliverable.path,
                            },
                        })
                    }
                />
            );
        };

        const renderRemoveTaskDeliverableMenuItem = () => {
            if (!isArchiveAllowed) {
                return null;
            }

            const isRemovingDeliverable = removingDeliverableId === taskDeliverable.id;

            return (
                <MenuItem
                    disabled={isRemovingDeliverable}
                    text="Remove"
                    icon="trash"
                    intent={Intent.DANGER}
                    onClick={() => handleRemoveTaskDeliverable(taskDeliverable)}
                />
            );
        };

        const renderApproveTaskDeliverableMenuItem = () => {
            if (!isApproveAllowed) {
                return null;
            }

            const isApprovingDeliverable = approvingDeliverableId === taskDeliverable.id;

            return (
                <MenuItem
                    disabled={isApprovingDeliverable}
                    intent={Intent.SUCCESS}
                    text="Approve"
                    icon="confirm"
                    onClick={() => handleApproveTaskDeliverable(taskDeliverable)}
                />
            );
        };

        return (
            <Menu>
                {renderApproveTaskDeliverableMenuItem()}
                {renderUpdateTaskDeliverableInformationMenuItem()}
                {renderRemoveTaskDeliverableMenuItem()}
            </Menu>
        );
    };

    const renderTaskDeliverableMenuButton = (taskDeliverable: TaskDeliverableResource) => {
        const isMenuOpened = openedMenuId === taskDeliverable.id;
        const isMoreButtonDisabled =
            (approvingDeliverableId !== null && approvingDeliverableId === taskDeliverable.id) ||
            (removingDeliverableId !== null && removingDeliverableId === taskDeliverable.id);

        const content = renderTaskDeliverableMenu(taskDeliverable);
        if (!content) {
            return null;
        }

        return (
            <Popover isOpen={isMenuOpened} content={content} onClose={handleCloseMenu}>
                <Button
                    minimal
                    loading={isMoreButtonDisabled}
                    icon="more"
                    onClick={(e) => handleToggleMenu(e, taskDeliverable.id)}
                />
            </Popover>
        );
    };

    const renderList = () => {
        return taskDeliverables.map((taskDeliverable, index) => (
            <TaskDeliverableListViewListItem
                createdAt={taskDeliverable.created_at}
                className={index === 0 ? '' : 'mt-1'}
                key={`${taskDeliverable.id}-${taskDeliverable.created_at}`}
                taskDeliverable={taskDeliverable}
                actions={renderTaskDeliverableMenuButton(taskDeliverable)}
                onOpenViewer={() => handleOpenTaskDeliverableViewerModal(taskDeliverable.id)}
            />
        ));
    };

    return <div {...props}>{renderList()}</div>;
};

export default TaskDeliverableListView;
