import Grid from '@app/components/Grid';
import DevText from '@app/components/Text';
import { $taskDeliverablesTree } from '@app/containers/pages/Task/store/states';
import { useForm, useRichTextFormField, useTextFormField } from '@app/hooks';
import {
    getRichStringRequiredValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, Elevation, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import RichEditorFormField from '@components/RichEditorFormField';
import { ModalProps } from '@modals/types';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useCreateTextTaskDeliverable } from '../../../../hooks';
import { $currentPathLocation } from '../../../../store/states';
import { googleEmbedTypesConfiguration } from '../../../../valueObjects/TaskDeliverableGoogleEmbed/consts';
import { GoogleEmbedType } from '../../../../valueObjects/TaskDeliverableGoogleEmbed/enums';
import { IconCard } from './styled';
import Image from '@components/Image';
import { TimeUtils } from 'dy-frontend-shared/lib/utils';
import { TaskDeliverableGoogleEmbed } from '../../../../valueObjects';
import { FormField } from '@app/hooks/validation/types';
import { updateTaskDeliverableInformation } from '../../../../store/effects';
import { UpdateTaskDeliverableInformationInput } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/inputs';
import { taskDeliverablesTreeApi } from '@app/containers/pages/Task/store/apis';

export interface UpdateTaskDeliverableInformationModalProps {
    taskDeliverableId: ID;
    taskId: ID;
    type: TaskDeliverableType;
    title: string;
    url: string | null;
    textContent: string | null;
    path: string;
}

type Props = ModalProps<UpdateTaskDeliverableInformationModalProps>;

const taskDeliverableTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];
const taskDeliverableContentValidators = [getRichStringRequiredValidator()];

const UpdateTaskDeliverableInformationModal: React.FC<Props> = ({ closeModal, data }) => {
    const taskDeliverableTree = useStore($taskDeliverablesTree);

    const taskDeliverableTitle = useTextFormField({
        id: 'task-deliverable-title',
        validators: taskDeliverableTitleValidators,
        initialValue: data?.title ?? '',
    });

    const taskDeliverableContent = useRichTextFormField({
        id: 'task-deliverable-content',
        validators: taskDeliverableContentValidators,
        initialValue: data?.textContent ?? '',
    });

    // Google embed
    const [selectedGoogleEmbedType, setSelectedGoogleEmbedType] = useState<GoogleEmbedType | null>(
        new TaskDeliverableGoogleEmbed(data?.url ?? '').getType()
    );
    const [link, setLink] = useState(data?.url ?? '');
    const [isLinkValid, setIsLinkValid] = useState(true);

    const getFormFields = () => {
        const defaultFields: FormField[] = [taskDeliverableTitle];
        if (data?.type === TaskDeliverableType.TEXT) {
            defaultFields.push(taskDeliverableContent);
        }
        return defaultFields;
    };

    const form = useForm({
        fields: getFormFields(),
        apiCall: async () => {
            if (!data) {
                return { response: false };
            }

            // Prepare input
            const input: UpdateTaskDeliverableInformationInput = {
                title: taskDeliverableTitle.value,
                text_content: null,
                url: null,
            };

            switch (data.type) {
                case TaskDeliverableType.EMBED:
                    input.url = link;
                    break;
                case TaskDeliverableType.TEXT:
                    input.text_content = taskDeliverableContent.value;
                    break;
            }

            try {
                await updateTaskDeliverableInformation({
                    id: data.taskDeliverableId,
                    input,
                });

                taskDeliverablesTreeApi.update({ input, path: data.path, taskDeliverableId: data.taskDeliverableId });

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    useEffect(() => {
        if (!selectedGoogleEmbedType || data?.type !== TaskDeliverableType.EMBED) {
            return;
        }

        const validateLink = TimeUtils.debounce(() => {
            const isLinkValid = new TaskDeliverableGoogleEmbed(link).isTypeOf(selectedGoogleEmbedType);
            setIsLinkValid(isLinkValid);
        });

        validateLink();
    }, [link, selectedGoogleEmbedType]);

    if (!data) {
        closeModal?.();
        return null;
    }

    if (!taskDeliverableTree) {
        return null;
    }

    const renderTextContentInput = () => {
        if (data.type !== TaskDeliverableType.TEXT) {
            return null;
        }

        return (
            <RichEditorFormField
                field={taskDeliverableContent}
                formGroupProps={{ label: 'Content' }}
                richEditorProps={{ placeholder: 'Enter content' }}
            />
        );
    };

    const renderGoogleEmbedBlock = () => {
        if (data.type !== TaskDeliverableType.EMBED) {
            return null;
        }

        const renderGoogleEmbedTypeIcons = () => {
            return (
                <Grid className="mb-2" container>
                    {Object.entries(googleEmbedTypesConfiguration).map(([key, { title, icon }]) => {
                        const googleEmbedType = key as unknown as GoogleEmbedType;

                        return (
                            <Grid lg={3}>
                                <IconCard
                                    compact
                                    interactive={googleEmbedType !== selectedGoogleEmbedType}
                                    elevation={Elevation.ONE}
                                    $active={googleEmbedType === selectedGoogleEmbedType}
                                    key={googleEmbedType}
                                    onClick={() => setSelectedGoogleEmbedType(googleEmbedType)}
                                >
                                    <Flex direction="column" className="mb-small" align="center" justify="center">
                                        <Image
                                            className="mb-1"
                                            width="100px"
                                            height="100px"
                                            objectFit="contain"
                                            src={icon}
                                        />

                                        <DevText>{title}</DevText>
                                    </Flex>
                                </IconCard>
                            </Grid>
                        );
                    })}
                </Grid>
            );
        };

        const renderLinkInput = () => {
            if (selectedGoogleEmbedType === null) {
                return null;
            }

            return (
                <FormGroup label="Link">
                    <InputGroup
                        intent={isLinkValid ? Intent.NONE : Intent.DANGER}
                        placeholder="Enter link"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                    />
                </FormGroup>
            );
        };

        const renderGoogleEmbedInstructions = () => {
            if (selectedGoogleEmbedType === null) {
                return null;
            }

            const configuration = googleEmbedTypesConfiguration[selectedGoogleEmbedType];

            return (
                <div>
                    <DevText className="mb-1">How to embed your {configuration.title}</DevText>
                    {configuration.instructions.map((i, index) => (
                        <DevText className={index === 0 ? '' : 'mt-small'}>
                            {index + 1}. {i}
                        </DevText>
                    ))}
                </div>
            );
        };

        return (
            <>
                {renderGoogleEmbedTypeIcons()}
                {renderLinkInput()}
                {renderGoogleEmbedInstructions()}
            </>
        );
    };

    const renderSubmitButton = () => {
        let isValid = false;
        switch (data.type) {
            case TaskDeliverableType.FILE:
            case TaskDeliverableType.TEXT:
                isValid = !form.hasFieldErrors;
                break;
            case TaskDeliverableType.EMBED:
                isValid = isLinkValid && !taskDeliverableTitle.error;
        }

        return (
            <Button disabled={form.hasFieldErrors} loading={form.isSubmitting} type="submit" intent={Intent.PRIMARY}>
                Update
            </Button>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update deliverable information</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <InputFormField
                        field={taskDeliverableTitle}
                        formGroupProps={{ label: 'Title' }}
                        inputProps={{ placeholder: 'Enter title' }}
                    />

                    {renderTextContentInput()}
                    {renderGoogleEmbedBlock()}

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        {renderSubmitButton()}
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateTaskDeliverableInformationModal;
