import React, { HTMLAttributes, ReactNode } from 'react';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import FilePreview from '@app/components/FilePreview';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { Colors, Icon, Intent, Tooltip } from '@blueprintjs/core';
import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import Box from '@app/components/Box';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import moment from 'moment';
import { fileHashPreview } from '@app/data/consts';
import { FileHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/FileHashPreview/enums';
import Circle from '@app/components/Circle';

export interface TaskMessageDeliverableAttachmentProps {
    taskDeliverable: TaskDeliverableResource;
    actions?: ReactNode;
}

type Props = HTMLAttributes<HTMLDivElement> & TaskMessageDeliverableAttachmentProps;

const TaskMessageDeliverableAttachment: React.FC<Props> = ({ taskDeliverable, actions, ...props }) => {
    const renderPreview = () => {
        if (taskDeliverable.file) {
            // Task deliverable has file attached to it

            let previewUrl: string | null = null;
            if (taskDeliverable.file.preview_path) {
                previewUrl = fileHashPreview.getPreviewUrl(taskDeliverable.file.preview_path, FileHashPreviewSize.SM);
            }

            return (
                <FilePreview
                    useOnlySrcPresenceCheck
                    width="100%"
                    height="100%"
                    minWidth="100%"
                    minHeight="100%"
                    objectFit="cover"
                    borderTopLeftRadius="8px"
                    borderBottomLeftRadius="8px"
                    style={{ cursor: 'pointer' }}
                    extension={taskDeliverable.file.extension}
                    src={previewUrl}
                />
            );
        }

        // Get icon
        let icon: JSX.Element = <></>;
        switch (taskDeliverable.type) {
            case TaskDeliverableType.BLANK:
                icon = <Icon icon="circle" size={42} />;
                break;
            case TaskDeliverableType.TEXT:
                icon = <Icon icon="text-highlight" size={42} />;
                break;
            case TaskDeliverableType.EMBED:
                icon = <Icon icon="insert" size={42} />;
                break;
            default:
                icon = <Icon icon="error" size={42} />;
        }

        return (
            <Box width="56px" height="56px" minHeight="56px" minWidth="56px" cursor="pointer">
                <Flex fullHeight fullWidth align="center" justify="center">
                    {icon}
                </Flex>
            </Box>
        );
    };

    const renderCommentsCounter = () => {
        const metadata = taskDeliverable.metadata;

        if (metadata.comment_count_total === 0) {
            return <DevText muted>No comments</DevText>;
        }

        if (metadata.comment_count_unresolved === 0) {
            // No unresolved comments
            return (
                <DevText muted>
                    <Icon className="mr-small" icon="chat" />
                    {metadata.comment_count_total} total
                </DevText>
            );
        } else {
            return (
                <Tooltip content={`${metadata.comment_count_total} total`}>
                    <DevText>
                        <Icon className="mr-small" icon="chat" intent={Intent.PRIMARY} />
                        {metadata.comment_count_unresolved} unresolved
                    </DevText>
                </Tooltip>
            );
        }
    };

    return (
        <Box borderRadius="8px" backgroundColor={Colors.DARK_GRAY1} {...props}>
            <Flex align="center">
                <Box width="64px" height="64px" minWidth="64px" minHeight="64px">
                    <Flex fullHeight align="center" justify="center">
                        {renderPreview()}
                    </Flex>
                </Box>

                <Box width="100%" padding="8px 16px">
                    <Flex align="center" justify="space-between">
                        <Flex className="mr-2" direction="column">
                            {/* File name */}
                            <Tooltip className="mb-1" content={taskDeliverable.title}>
                                <DevText style={{ wordBreak: 'break-all' }}>
                                    {TextFormatUtils.truncate(taskDeliverable.title, 30)}
                                </DevText>
                            </Tooltip>

                            {/* File size*/}
                            <Flex direction="row" align="center">
                                <DevText muted className="mr-1">
                                    {moment(taskDeliverable.approved_at).format('D MMM, YYYY')}
                                </DevText>
                                <Circle color={Colors.GRAY2} size="6px" className="mr-1" />
                                {renderCommentsCounter()}
                            </Flex>
                        </Flex>

                        {actions && <Flex align="center">{actions}</Flex>}
                    </Flex>
                </Box>
            </Flex>
        </Box>
    );
};

export default TaskMessageDeliverableAttachment;
