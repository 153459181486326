import { useTextFormField } from '@app/hooks';
import { getStringRequiredValidator, getStringEmailValidator } from '@app/hooks/validation/functions';
import { ValidatorFunction } from '@app/hooks/validation/types';
import { FormGroup, InputGroup, Intent, Spinner } from '@blueprintjs/core';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import React, { HTMLAttributes, useEffect, useState } from 'react';

export interface EmailResolverInputProps {
    onChange: (email: string) => void;
    onInvalidateEmail: () => void;
    onFetchProgress: (isFetching: boolean) => void;
    validators?: ValidatorFunction<string>[];
}
export type Props = Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> & EmailResolverInputProps;

const emailValidators = [getStringRequiredValidator(), getStringEmailValidator()];

const EmailResolverInput: React.FC<Props> = ({
    onChange,
    onFetchProgress,
    onInvalidateEmail,
    validators = emailValidators,
    ...props
}) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [isEmailAvailable, setIsEmailAvailable] = useState<boolean | null>(null);

    const email = useTextFormField({
        id: 'email',
        validators: emailValidators,
        initialValue: '',
    });

    const handleCheckEmailAvailability = async () => {
        setIsFetching(true);
        onFetchProgress(true);

        try {
            const emailAvailability = await repository.email().checkAvailability({ email: email.value });
            return emailAvailability.is_available;
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsFetching(false);
            onFetchProgress(false);
        }
    };

    useEffect(() => {
        if (!isInitialized) {
            // Not initialized yet
            setIsInitialized(true);
            return;
        }

        // Invalidate email & reset email availability flag since email value changed
        onInvalidateEmail();
        setIsEmailAvailable(null);

        const timeout = setTimeout(async () => {
            if (!!email.error) {
                // Error is present
                return;
            }

            const isEmailAvailable = await handleCheckEmailAvailability();
            if (isEmailAvailable) {
                setIsEmailAvailable(true);
                onChange(email.value);
            } else {
                setIsEmailAvailable(false);
            }
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [email.value, email.error]);

    const renderRightElement = () => {
        if (isFetching) {
            return <Spinner size={16} />;
        }

        return <></>;
    };

    const getFormGroupData = () => {
        let intent: Intent = Intent.NONE;
        let helperText = '';

        if (!!email.error) {
            // Error present
            intent = Intent.DANGER;
            helperText = email.error;
        }

        if (isEmailAvailable) {
            // Available email present
            intent = Intent.SUCCESS;
            helperText = `Email ${email.value} is available`;
        } else if (isEmailAvailable !== null) {
            intent = Intent.DANGER;
            helperText = `Email ${email.value} is already taken`;
        }

        return {
            intent,
            helperText,
        };
    };

    const formGroupData = getFormGroupData();

    return (
        <FormGroup label="Email" intent={formGroupData.intent} helperText={formGroupData.helperText} {...props}>
            <InputGroup
                fill
                readOnly={isFetching}
                placeholder="Enter email"
                id={email.id}
                intent={!!email.error ? Intent.DANGER : Intent.NONE}
                value={email.value}
                rightElement={renderRightElement()}
                onChange={email.handleChange}
                onBlur={email.handleBlur}
            ></InputGroup>
        </FormGroup>
    );
};

export default EmailResolverInput;
