import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Classes, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { useNavigate } from 'react-router-dom';
import { Endpoints, taskStateInformation } from '@app/data/consts';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import Circle from '@components/Circle';

export interface ClientReviewProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & ClientReviewProps;

const ClientReview: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    const [isRevisionRequiredAllowed, setIsRevisionRequiredAllowed] = useState(false);
    const [isPreDeliveredAllowed, setIsPreDeliveredAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsRevisionRequiredAllowed(
            permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_REVISION_REQUIRED)
        );
        setIsPreDeliveredAllowed(
            permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_PRE_DELIVERED)
        );
    }, [task, permissions, me]);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    // TODO: Render helper actions

    const renderRevisionRequiredTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isRevisionRequiredAllowed) {
            return null;
        }

        return (
            <Button
                icon="outdated"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.IN_REVISION}
                onClick={() => handleTransitionToState(TaskState.IN_REVISION)}
            >
                Start another revision
            </Button>
        );
    };

    const renderPreDeliveredTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isPreDeliveredAllowed) {
            return null;
        }

        return (
            <ConfirmationPopover
                title="Are you sure you want to approve this request?"
                description="Please be aware that you are approving request on behalf of the client"
                actions={[
                    <Button
                        intent={Intent.PRIMARY}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleTransitionToState(TaskState.PRE_DELIVERED)}
                    >
                        Yes, approve request
                    </Button>,
                ]}
            >
                <Button
                    icon="endorsed"
                    disabled={transitioningTo !== null}
                    loading={transitioningTo === TaskState.PRE_DELIVERED}
                >
                    Approve request
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderOpenTaskDeliverables = () => {
        // TODO: task state actions / helper actions permissions

        return (
            <Button
                icon="unarchive"
                disabled={transitioningTo !== null}
                onClick={() => navigate(Endpoints.TASK_DELIVERABLES_ALL.replace(':taskId', task.id))}
            >
                View deliverables
            </Button>
        );
    };

    const information = taskStateInformation[TaskState.CLIENT_REVIEW];

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Circle size="22px" className="mr-1" color={information.color} />
                    <Heading type="h4">{information.title}</Heading>
                </Flex>

                <Flex direction="row">
                    <div className="mr-1">{renderRevisionRequiredTransitionButton()}</div>
                    <div>{renderPreDeliveredTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>
                Deliverables have been sent to the client for review. Please standby for any feedback that the client
                might provide.
            </DevText>
            <DevText muted>You can manually request another revision or approve request on client's behalf</DevText>

            {/* Helper actions */}
            <Flex className="mt-2" direction="row">
                <div>{renderOpenTaskDeliverables()}</div>
            </Flex>
        </Card>
    );
};

export default ClientReview;
