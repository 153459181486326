import DevText from '@app/components/Text';
import { Icon, Intent, Spinner } from '@blueprintjs/core';
import Flex, { Props as FlexProps } from '@components/Flex';
import { ModalProps } from '@modals/types';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import TaskDeliverableComments from './components/TaskDeliverableComments';
import TaskDeliverableFooter from './components/TaskDeliverableFooter';
import TaskDeliverableViewerManager from './components/TaskDeliverableViewerManager';
import { pointMarkerByTaskDeliverableCommentIdMapApi, taskDeliverableCommentsApi } from './store/apis';
import { fetchTaskDeliverableComments } from './store/effects';
import {
    resetHighlightedTaskDeliverableCommentId,
    resetPointMarker,
    resetPointMarkerByTaskDeliverableCommentIdMap,
    resetQueuedVideoTimelinePositionMS,
    resetRootTaskDeliverableCommentsByIdMap,
    resetSelectedTaskDeliverable,
    resetTaskDeliverableComments,
    resetTimeMarker,
    setSelectedTaskDeliverable,
} from './store/events';
import { $rootTaskDeliverableCommentsByIdMap, $selectedTaskDeliverable } from './store/states';
import { Card, DismissModalPanel, ModalContent, StyledOverlay } from './styled';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskDeliverableCommentPermission } from 'dy-frontend-permissions/lib/permission';
import { useBreakpoint } from '@app/hooks';
import { Breakpoint } from '@app/data/enums';

export interface TaskDeliverableViewerModalProps {
    taskId: ID;
    taskDeliverableId: ID;
    taskDeliverables: TaskDeliverableResource[];
    isAssignedToTask?: boolean;
    onViewerClose?: () => void;
}

type Props = ModalProps<TaskDeliverableViewerModalProps>;

const TaskDeliverableViewerModal: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const { breakpoint } = useBreakpoint();

    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);
    const rootTaskDeliverableCommentsByIdMap = useStore($rootTaskDeliverableCommentsByIdMap);

    const [isCommentModuleAllowed, setIsCommentModuleAllowed] = useState(false);
    const [isPublicCommentComposeAllowed, setIsPublicComposeAllowed] = useState(false);
    const [isInternalCommentComposeAllowed, setIsInternalComposeAllowed] = useState(false);

    const isBelowExtraLargeScreen = breakpoint !== null && breakpoint !== Breakpoint.XL;

    useEffect(() => {
        if (!data || !me) {
            return;
        }

        // Determine if assigned to task
        const isAssigned = !!data?.isAssignedToTask;

        // Module access
        if (!permissions.isEnabled.taskDeliverableComment && !permissions.isRoot.taskDeliverableComment) {
            setIsCommentModuleAllowed(false);
            setIsPublicComposeAllowed(false);
            setIsInternalComposeAllowed(false);
            return;
        } else {
            setIsCommentModuleAllowed(true);
        }

        // Root permissions
        if (permissions.isRoot.taskDeliverableComment) {
            setIsCommentModuleAllowed(true);
            setIsPublicComposeAllowed(true);
            setIsInternalComposeAllowed(true);
            return;
        }

        // Permission: public comment compose
        setIsPublicComposeAllowed(
            permissions.has(TaskDeliverableCommentPermission.CREATE) ||
                (isAssigned && permissions.has(TaskDeliverableCommentPermission.CREATE_MOD_TASK_ASSIGNED))
        );

        // Permission: internal comment compose
        setIsInternalComposeAllowed(
            permissions.has(TaskDeliverableCommentPermission.CREATE_INTERNAL) ||
                (isAssigned && permissions.has(TaskDeliverableCommentPermission.CREATE_INTERNAL_MOD_TASK_ASSIGNED))
        );
    }, [data, permissions, me]);

    const handleCloseViewer = () => {
        if (data && data.onViewerClose) {
            data.onViewerClose();
        }

        if (closeModal) {
            closeModal();
        }
    };

    const handleInitializeTaskDeliverableComments = async (taskDeliverableId: ID) => {
        try {
            const taskDeliverableComments = await fetchTaskDeliverableComments({
                task_deliverable_id: taskDeliverableId,
            });
            taskDeliverableCommentsApi.initialize({ taskDeliverableComments: taskDeliverableComments.items });
            pointMarkerByTaskDeliverableCommentIdMapApi.initialize({
                taskDeliverableComments: taskDeliverableComments.items,
            });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Deliverable comments for deliverable with ID of ${taskDeliverableId} were not found`,
                    intent: Intent.DANGER,
                });

                // Close modal
                handleCloseViewer();
            }
        }
    };

    useEffect(() => {
        const handleEscapeKeyPressed = (e: KeyboardEvent) => {
            if (e.code === 'Escape') {
                handleCloseViewer();
            }
        };

        document.addEventListener('keydown', handleEscapeKeyPressed);

        return () => {
            document.removeEventListener('keydown', handleEscapeKeyPressed);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!data) {
            handleCloseViewer();

            return;
        }

        if (data.taskDeliverables.length === 0) {
            // Zero task deliverables were passed to task deliverable viewer modal

            handleCloseViewer();

            // TODO: handle error
            console.error('Zero task deliverables were passed to task deliverable viewer modal');
            return;
        }

        const taskDeliverable = data.taskDeliverables.find(
            (taskDeliverable) => taskDeliverable.id === data.taskDeliverableId
        );

        if (taskDeliverable) {
            // Task deliverable with id of data.taskDeliverableId was found

            // Set selected task deliverables
            setSelectedTaskDeliverable(taskDeliverable);
        } else {
            // Task deliverable with id of data.taskDeliverableId was NOT found

            // TODO: handle error
            console.error(`Task deliverable with id of ${data.taskDeliverableId} was NOT found`);

            // Set selected task deliverable
            setSelectedTaskDeliverable(data.taskDeliverables[0]);
        }

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!selectedTaskDeliverable || !isCommentModuleAllowed) {
            return;
        }

        resetRootTaskDeliverableCommentsByIdMap();
        resetPointMarkerByTaskDeliverableCommentIdMap();

        // Fetch task deliverable comments
        handleInitializeTaskDeliverableComments(selectedTaskDeliverable.id);
    }, [selectedTaskDeliverable?.id, isCommentModuleAllowed]);

    useEffect(() => {
        return () => {
            resetSelectedTaskDeliverable();
            resetTaskDeliverableComments();
            resetRootTaskDeliverableCommentsByIdMap();
            resetTimeMarker();
            resetPointMarker();
            resetPointMarkerByTaskDeliverableCommentIdMap();
            resetHighlightedTaskDeliverableCommentId();
            resetQueuedVideoTimelinePositionMS();
        };
    }, []);

    if (!data) {
        handleCloseViewer();

        return null;
    }

    if (data.taskDeliverables.length === 0) {
        handleCloseViewer();

        return null;
    }

    const renderModalContent = () => {
        if (rootTaskDeliverableCommentsByIdMap === null || selectedTaskDeliverable === null) {
            // Task deliverable comments were NOT fetched yet OR task deliverable was NOT selected yet

            return (
                <Card>
                    <Flex fullHeight fullWidth align="center" justify="center">
                        <Spinner />
                    </Flex>
                </Card>
            );
        }

        // Create common flex positioner props
        const commonFlexPositionerProps: FlexProps = {
            fullWidth: true,
            fullHeight: true,
            flexGrow: 1,
            style: { margin: 0 },
            className: 'row',
        };

        // Auth
        const isCommentAccessAllowed = permissions.isEnabled.taskDeliverableComment;
        if (isCommentAccessAllowed) {
            return (
                <Card onClick={(e) => e.stopPropagation()}>
                    <Flex {...commonFlexPositionerProps}>
                        <Flex fullHeight direction="column" style={{ width: isBelowExtraLargeScreen ? '70%' : '80%' }}>
                            <TaskDeliverableViewerManager />
                            <TaskDeliverableFooter taskDeliverables={data.taskDeliverables} />
                        </Flex>

                        <Flex fullHeight direction="column" style={{ width: isBelowExtraLargeScreen ? '30%' : '20%' }}>
                            <TaskDeliverableComments
                                isPublicCommentComposeAllowed={isPublicCommentComposeAllowed}
                                isInternalCommentComposeAllowed={isInternalCommentComposeAllowed}
                            />
                        </Flex>
                    </Flex>
                </Card>
            );
        } else {
            return (
                <Card onClick={(e) => e.stopPropagation()}>
                    <Flex {...commonFlexPositionerProps}>
                        <Flex fullHeight direction="column" style={{ width: '100%' }}>
                            <TaskDeliverableViewerManager />
                            <TaskDeliverableFooter taskDeliverables={data.taskDeliverables} />
                        </Flex>
                    </Flex>
                </Card>
            );
        }
    };

    const renderDismissModalPanel = () => {
        return (
            <DismissModalPanel onClick={handleCloseViewer}>
                <Flex fullHeight fullWidth align="center" justify="center">
                    <Icon className="mr-1" icon="chevron-up" />
                    <DevText>Click here to close</DevText>
                    <Icon className="ml-1" icon="chevron-up" />
                </Flex>
            </DismissModalPanel>
        );
    };

    return (
        <StyledOverlay isOpen noPadding height="100%" onClose={handleCloseViewer}>
            <ModalContent>
                {renderDismissModalPanel()}
                {renderModalContent()}
            </ModalContent>
        </StyledOverlay>
    );
};

export default TaskDeliverableViewerModal;
