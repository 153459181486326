import { Callout, Icon, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import DevText from '@components/Text';
import { TaskDeliverableCommentResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverableComment/resources';
import { useStore } from 'effector-react';
import React, { createRef, HTMLAttributes, useEffect, useRef, useState } from 'react';
import { fetchTaskDeliverableComments } from '../../store/effects';
import { setHighlightedTaskDeliverableCommentId } from '../../store/events';
import {
    $highlightedTaskDeliverableCommentId,
    $pointMarkerByTaskDeliverableCommentIdMap,
    $rootTaskDeliverableCommentsByIdMap,
    $selectedTaskDeliverable,
} from '../../store/states';
import TaskDeliverableComment from './components/TaskDeliverableComment';
import UpsertTaskDeliverableCommentForm from './components/UpsertTaskDeliverableCommentForm';
import { Card } from './styled';
import { $permissions } from '@containers/store/states';

export interface TaskDeliverableCommentsProps {
    isPublicCommentComposeAllowed?: boolean;
    isInternalCommentComposeAllowed?: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & TaskDeliverableCommentsProps;

const TaskDeliverableComments: React.FC<Props> = ({
    isPublicCommentComposeAllowed = false,
    isInternalCommentComposeAllowed = false,
    ...props
}) => {
    const permissions = useStore($permissions);

    const taskDeliverableCommentsRef = useRef<{ [taskDeliverableCommentId in ID]: React.RefObject<HTMLDivElement> }>(
        {}
    );

    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);
    const rootTaskDeliverableCommentsByIdMap = useStore($rootTaskDeliverableCommentsByIdMap);
    const pointMarkerByTaskDeliverableCommentIdMap = useStore($pointMarkerByTaskDeliverableCommentIdMap);
    const highlightedTaskDeliverableCommentId = useStore($highlightedTaskDeliverableCommentId);
    const isFetchingTaskDeliverableComments = useStore(fetchTaskDeliverableComments.pending);

    const [replyCommentId, setReplyCommentId] = useState<ID | null>(null);
    const [editingCommentId, setEditingCommentId] = useState<ID | null>(null);

    useEffect(() => {
        if (!rootTaskDeliverableCommentsByIdMap) {
            return;
        }

        taskDeliverableCommentsRef.current = Object.values(rootTaskDeliverableCommentsByIdMap).reduce(
            (acc, taskDeliverableComment) => ({ ...acc, [taskDeliverableComment.id]: createRef() }),
            {}
        );
    }, [rootTaskDeliverableCommentsByIdMap]);

    useEffect(() => {
        if (!highlightedTaskDeliverableCommentId) {
            return;
        }

        const highlightedTaskDeliverableCommentRef = Object.entries(taskDeliverableCommentsRef.current).find(
            ([taskDeliverableCommentId]) => taskDeliverableCommentId === highlightedTaskDeliverableCommentId
        )?.[1];

        if (highlightedTaskDeliverableCommentRef && highlightedTaskDeliverableCommentRef.current) {
            highlightedTaskDeliverableCommentRef.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });

            // Clear highlighted task deliverable comment flag
            const highlightedTimeAmountMS = 2000;
            const timeoutId = setTimeout(() => {
                setHighlightedTaskDeliverableCommentId(null);
            }, highlightedTimeAmountMS);

            return () => {
                clearTimeout(timeoutId);
            };
        } else {
            setHighlightedTaskDeliverableCommentId(null);
        }
    }, [highlightedTaskDeliverableCommentId]);

    if (!rootTaskDeliverableCommentsByIdMap || isFetchingTaskDeliverableComments) {
        return (
            <Flex fullHeight fullWidth align="center" justify="center">
                <Spinner />
            </Flex>
        );
    }

    const handleSetEditingCommentId = (editingCommentId: ID) => {
        setEditingCommentId(editingCommentId);
        setReplyCommentId(null);
    };

    const handleSetReplyCommentId = (replyCommentId: ID) => {
        setReplyCommentId(replyCommentId);
        setEditingCommentId(null);
    };

    const renderComments = () => {
        const taskDeliverableComments = Object.values(rootTaskDeliverableCommentsByIdMap);

        if (!rootTaskDeliverableCommentsByIdMap || taskDeliverableComments.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="chat" size={50} />}
                    title={<DevText align="center">Task deliverable do not contain any comments yet</DevText>}
                />
            );
        }

        const renderReplyForm = (rootComment: TaskDeliverableCommentResource) => {
            if (replyCommentId !== rootComment.id) {
                return null;
            }

            if (!isPublicCommentComposeAllowed && !isInternalCommentComposeAllowed) {
                return null;
            }

            return (
                <UpsertTaskDeliverableCommentForm
                    className="mt-1 mb-1"
                    parentTaskDeliverableComment={rootComment}
                    comment={null}
                    onCloseForm={() => setReplyCommentId(null)}
                    isPublicCommentAllowed={isPublicCommentComposeAllowed}
                    isInternalCommentAllowed={isInternalCommentComposeAllowed}
                />
            );
        };

        const renderEditingForm = (
            parentTaskDeliverableComment: TaskDeliverableCommentResource | null,
            comment: TaskDeliverableCommentResource
        ) => {
            if (editingCommentId !== comment.id) {
                return null;
            }

            if (!isPublicCommentComposeAllowed && !isInternalCommentComposeAllowed) {
                return null;
            }

            return (
                <UpsertTaskDeliverableCommentForm
                    className="mt-1 mb-1"
                    parentTaskDeliverableComment={parentTaskDeliverableComment}
                    comment={comment}
                    onSubmittingComplete={() => setEditingCommentId(null)}
                    onCloseForm={() => setEditingCommentId(null)}
                    isPublicCommentAllowed={isPublicCommentComposeAllowed}
                    isInternalCommentAllowed={isInternalCommentComposeAllowed}
                />
            );
        };

        const renderRootComment = (rootComment: TaskDeliverableCommentResource) => {
            let isReplyAllowed = false;
            if (isPublicCommentComposeAllowed || isInternalCommentComposeAllowed) {
                isReplyAllowed = rootComment.is_internal ? isInternalCommentComposeAllowed : true;
            }

            return (
                <>
                    <TaskDeliverableComment
                        isReplyAllowed={isReplyAllowed}
                        isHighlighted={highlightedTaskDeliverableCommentId === rootComment.id}
                        key={rootComment.id}
                        comment={rootComment}
                        ref={taskDeliverableCommentsRef.current[rootComment.id]}
                        markersMap={pointMarkerByTaskDeliverableCommentIdMap}
                        onReply={() => handleSetReplyCommentId(rootComment.id)}
                        onEdit={() => handleSetEditingCommentId(rootComment.id)}
                        onSuccessfulArchive={() => {
                            if (replyCommentId === rootComment.id) {
                                setReplyCommentId(null);
                            }

                            if (editingCommentId === rootComment.id) {
                                setEditingCommentId(null);
                            }
                        }}
                    />
                    {renderEditingForm(null, rootComment)}
                </>
            );
        };

        const renderRootCommentChildren = (rootComment: TaskDeliverableCommentResource) => {
            if (rootComment.children.length === 0) {
                // Root comment has no children comments

                return null;
            }

            if (rootComment.archived_at !== null) {
                // Root comment is archived

                return null;
            }

            const renderChildComment = (childComment: TaskDeliverableCommentResource) => {
                return (
                    <>
                        <TaskDeliverableComment
                            isChildComment
                            key={childComment.id}
                            comment={childComment}
                            markersMap={pointMarkerByTaskDeliverableCommentIdMap}
                            onEdit={() => handleSetEditingCommentId(childComment.id)}
                            onSuccessfulArchive={() => {
                                if (replyCommentId === childComment.parent_task_deliverable_comment_id) {
                                    setReplyCommentId(null);
                                }

                                if (editingCommentId === childComment.parent_task_deliverable_comment_id) {
                                    setEditingCommentId(null);
                                }
                            }}
                        />
                        {renderEditingForm(rootComment, childComment)}
                    </>
                );
            };

            return rootComment.children.map(renderChildComment);
        };

        return taskDeliverableComments.map((comment) => {
            return (
                <>
                    {renderRootComment(comment)}
                    {renderRootCommentChildren(comment)}
                    {renderReplyForm(comment)}
                </>
            );
        });
    };

    const renderCreateForm = () => {
        if (!isPublicCommentComposeAllowed && !isInternalCommentComposeAllowed) {
            return null;
        }

        return (
            <UpsertTaskDeliverableCommentForm
                className="mb-2"
                parentTaskDeliverableComment={null}
                comment={null}
                isPublicCommentAllowed={isPublicCommentComposeAllowed}
                isInternalCommentAllowed={isInternalCommentComposeAllowed}
            />
        );
    };

    return (
        <Card id="task-deliverable-comments" className="custom-thin-scroll">
            {selectedTaskDeliverable && selectedTaskDeliverable.approved_at === null && (
                <Callout compact icon="warning-sign" className="mb-2" intent={Intent.WARNING}>
                    Not approved yet
                </Callout>
            )}

            {renderCreateForm()}

            {renderComments()}
        </Card>
    );
};

export default TaskDeliverableComments;
