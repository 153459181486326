import { PlatformCoreUserFilterData } from '../types';

const initialPlatformCoreUserFilterData: PlatformCoreUserFilterData = {
    query: '',
    teams: [],
    roles: [],
    isArchived: null,
    isVerified: null,
};

export default initialPlatformCoreUserFilterData;
