import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { PlatformTaskFilterData } from '@pages/Tasks/types';
import { platformFiltersInformation } from '@pages/Tasks/consts';
import { PlatformTaskFilterType } from '@pages/Tasks/enums';
import { $isQueryHydrationFinished } from '@pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@pages/Tasks/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';
import { fetchStaticData } from '@app/containers/pages/Tasks/store/effects';

export interface TaskCategoriesPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskCategoriesPlatformFilterProps;

const TaskCategoriesPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isTaskCategoriesInitialized, setIsTaskCategoriesInitialized] = useState(false);
    const [selectedTaskCategories, setSelectedTaskCategories] = useState<PlatformTaskFilterData['taskCategories']>([]);
    const [foundTaskCategories, setFoundTaskCategories] = useState<PlatformTaskFilterData['taskCategories']>([]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            fetchStaticData({ task_category: '1' }).then((staticData) => {
                setFoundTaskCategories(staticData.task_category);
                setSelectedTaskCategories(
                    staticData.task_category.filter((taskCategory) =>
                        platformTaskFilterData.taskCategories.find((c) => c.id === taskCategory.id)
                    )
                );
                setTimeout(() => setIsTaskCategoriesInitialized(true), 0);
            });
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.taskCategories.length === 0) {
            setSelectedTaskCategories([]);
        }
    }, [platformTaskFilterData.taskCategories]);

    const getFilterTagLabel = () => {
        if (selectedTaskCategories.length === 0) return '';

        return selectedTaskCategories
            .map((taskCategory, index) => {
                if (index === selectedTaskCategories.length - 1) {
                    // Last element
                    return `${taskCategory.title}`;
                }

                return `${taskCategory.title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedTaskCategories.length > 0) {
            // At least 1 task category selected
            return 'Category:';
        }

        return 'Category';
    };

    const handleRemoveFilter = () => {
        if (selectedTaskCategories.length !== 0) {
            // At least 1 task category was selected

            setSelectedTaskCategories([]);

            if (platformTaskFilterData.taskCategories.length !== 0) {
                // Reset task category to empty array
                setPlatformTaskFilterData({ ...platformTaskFilterData, taskCategories: [] });
            }

            setIsOpen(false);
        }

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_CATEGORIES].isPinned) {
            // Platform task category filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (taskCategory: PlatformTaskFilterData['taskCategories'][number]) => {
        if (!!selectedTaskCategories.find((selectedTaskCategory) => selectedTaskCategory.id === taskCategory.id)) {
            // Task category is already checked
            setSelectedTaskCategories(
                selectedTaskCategories.filter((selectedTaskCategory) => selectedTaskCategory.id !== taskCategory.id)
            );
        } else {
            // Task category is NOT checked yet
            setSelectedTaskCategories([...selectedTaskCategories, taskCategory]);
        }
    };

    const handleApplyFilter = () => {
        if (selectedTaskCategories.length !== platformTaskFilterData.taskCategories.length) {
            // If task categories array length didn't change since opening the filter then nothing apply to
            setPlatformTaskFilterData({ ...platformTaskFilterData, taskCategories: selectedTaskCategories });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_CATEGORIES].isPinned) {
            return;
        }

        if (selectedTaskCategories.length === 0) {
            onRemove();
        }
    };

    const renderRow = (taskCategory: PlatformTaskFilterData['taskCategories'][number]) => {
        return (
            <li className="mb-1" key={taskCategory.id}>
                <Checkbox
                    checked={
                        !!selectedTaskCategories.find(
                            (selectedTaskCategory) => selectedTaskCategory.id === taskCategory.id
                        )
                    }
                    className="mr-2"
                    label={taskCategory.title}
                    onClick={() => handleCheckboxClick(taskCategory)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformTaskFilterData['taskCategories'][number]>
            isOpen={isOpen}
            loading={!isTaskCategoriesInitialized}
            removable={selectedTaskCategories.length > 0}
            title="Category"
            position={Position.BOTTOM_LEFT}
            minWidth={320}
            maxListHeight={400}
            list={foundTaskCategories}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_CATEGORIES].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskCategoriesPlatformFilter;
