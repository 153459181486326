import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { $task, $taskDeliverables, $taskDeliverablesTree } from './store/states';
import {
    fetchTask,
    fetchTaskAddons,
    fetchTaskComplexity,
    fetchTaskDeliverables,
    fetchTaskStateTransitionLog,
} from './store/effects';
import {
    resetClientPaymentAccount,
    resetTask,
    resetTaskAddons,
    resetTaskComplexity,
    resetTaskDeliverables,
    resetTaskDeliverablesTree,
    resetTaskStateTransitionLog,
    setIsTaskDeliverablesTreeInitialized,
} from './store/events';
import { Endpoints } from '@data/consts';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Grid from '@components/Grid';
import Flex from '@components/Flex';
import Header from './components/Header';
import Aside from './components/Aside';
import Tabs from './components/Tabs';
import Information from './components/Information';
import { BreadcrumbProps, Intent, Spinner } from '@blueprintjs/core';
import TaskFlowControls from '@pages/Task/components/TaskFlowControls';
import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { usePageTitle } from '@app/hooks';
import { $permissions } from '@containers/store/states';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { taskDeliverablesTreeApi } from './store/apis';

const Task: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.task) {
        // TODO: Redirect to dashboard
    }

    const { changeTitle } = usePageTitle('Request');

    const navigate = useNavigate();
    const { taskId } = useParams() as { taskId: ID };

    const task = useStore($task);
    const taskDeliverables = useStore($taskDeliverables);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    useEffect(() => {
        if (!task) {
            return;
        }

        const breadcrumbItems: BreadcrumbProps[] = [
            {
                text: 'Requests',
                onClick: () => navigate(Endpoints.TASKS),
            },
            { text: task.title },
        ];

        setPageBreadcrumbs(breadcrumbItems);
    }, [task?.id]);

    useEffect(() => {
        fetchTask(taskId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({ message: `Request with ID of ${taskId} was not found`, intent: Intent.DANGER });

                // Go to tasks page
                navigate(Endpoints.TASKS);
            }
        });
    }, [taskId]);

    useEffect(() => {
        if (!permissions.isEnabled.taskDeliverable) {
            return;
        }

        if (task && task.id === taskId && !taskDeliverables) {
            fetchTaskDeliverables({ task_id: task.id }).catch((e) => {
                // Log
                console.error(e);

                const response = e.response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Deliverables for request with ID of ${taskId} were not found`,
                        intent: Intent.DANGER,
                    });

                    // Go to task information page
                    navigate(Endpoints.TASKS);
                }
            });
        }

        // eslint-disable-next-line
    }, [task?.id, taskDeliverables]);

    useEffect(() => {
        fetchTaskStateTransitionLog(taskId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Request state log list for task with ID of ${taskId} was not found`,
                    intent: Intent.DANGER,
                });
            }
        });
    }, [taskId]);

    useEffect(() => {
        fetchTaskComplexity({ task_id: [taskId] }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Request complexity for task with ID of ${taskId} was not found`,
                    intent: Intent.DANGER,
                });
            }
        });
    }, [taskId]);

    useEffect(() => {
        fetchTaskAddons({ task_id: taskId }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Request addons for task with ID of ${taskId} was not found`,
                    intent: Intent.DANGER,
                });
            }
        });
    }, [taskId]);

    useEffect(() => {
        if (!task) {
            return;
        }

        changeTitle(`Request - ${task.title}`);
    }, [task?.title]);

    useEffect(() => {
        return () => {
            resetTask();
            resetTaskComplexity();
            resetTaskStateTransitionLog();
            resetTaskAddons();
            resetClientPaymentAccount();
            resetPageBreadcrumbs();
            resetTaskDeliverables();
            resetTaskDeliverablesTree();
            setIsTaskDeliverablesTreeInitialized(false);
        };
    }, [taskId]);

    useEffect(() => {
        if (!taskDeliverablesTree) {
            // Task deliverables tree was NOT initialized yet

            if (taskDeliverables) {
                // Task deliverables were fetched

                // Initial insertion of task deliverables data
                taskDeliverablesTreeApi.insert({ taskDeliverables: taskDeliverables.items });

                // Set initialized flag to true
                setIsTaskDeliverablesTreeInitialized(true);
            }
        }

        // eslint-disable-next-line
    }, [taskDeliverables]);

    if (!task || !taskDeliverables || !taskDeliverablesTree) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    return (
        <FixedWidthPageContainer>
            <Header className="mb-2" />

            <div className="mb-2">
                <TaskFlowControls />
            </div>

            <Grid container>
                <Grid lg={3} xs={12}>
                    <Aside className="mb-2" />
                </Grid>
                <Grid lg={9} xs={12}>
                    <Tabs className="mb-2" />
                    <Information className="mb-2" />
                    <Outlet />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default Task;
