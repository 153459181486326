import { PlatformClientUserFilterData } from '../types';

const initialPlatformClientUserFilterData: PlatformClientUserFilterData = {
    query: '',
    plans: [],
    isArchived: null,
    isVerified: null,
};

export default initialPlatformClientUserFilterData;
