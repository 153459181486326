import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task, $taskDeliverablesTree } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { useNavigate } from 'react-router-dom';
import { Endpoints, taskStateInformation } from '@app/data/consts';
import { openModal } from '@app/containers/modals/store/events';
import UnapprovedTaskDeliverablePresenceSanityCheckModal, {
    UnapprovedTaskDeliverablePresenceSanityCheckModalProps,
} from '@app/containers/pages/Task/modals/sanityCheck/UnapprovedTaskDeliverablePresenceSanityCheckModal';
import { TaskDeliverablesTreeUtils } from '@app/containers/pages/Task/valueObjects';
import { approveTaskDeliverablesBatch } from '@app/containers/pages/Task/store/effects';
import { taskDeliverablesTreeApi } from '@app/containers/pages/Task/store/apis';
import Circle from '@components/Circle';

export interface PreDeliveredProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & PreDeliveredProps;

const PreDelivered: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { handleTransitionTaskState } = useTaskStateTransition();

    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);
    const [isDeliveredAllowed, setIsDeliveredAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsDeliveredAllowed(permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_DELIVERED));
    }, [task, permissions, me]);

    if (!me || !task || !taskDeliverablesTree) {
        return null;
    }

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    const handleApproveTaskDeliverablesAndTransitionToState = async (
        taskDeliverablesIds: ID[],
        transitionToState: TaskState
    ) => {
        try {
            await approveTaskDeliverablesBatch({
                task_deliverable_ids: taskDeliverablesIds,
            });
            taskDeliverablesTreeApi.approveTaskDeliverables({ taskDeliverablesIds });
            handleTransitionToState(transitionToState);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderDeliveredTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isDeliveredAllowed) {
            return null;
        }

        const handleDeliveredTransition = () => {
            const transitionToState = TaskState.DELIVERED;

            // Get array of deliverables
            const deliverables = TaskDeliverablesTreeUtils.getTaskDeliverablesArray({
                deliverablesMap: taskDeliverablesTree.deliverables,
            });

            if (deliverables.length === 0) {
                // No deliverables
                handleTransitionToState(transitionToState);
            } else {
                const unapprovedDeliverableIds: ID[] = [];
                deliverables.forEach((deliverable) => {
                    if (deliverable.approved_at === null) {
                        // Not approved
                        unapprovedDeliverableIds.push(deliverable.id);
                    }
                });

                if (unapprovedDeliverableIds.length > 0) {
                    // There are unapproved deliverables
                    openModal<UnapprovedTaskDeliverablePresenceSanityCheckModalProps>({
                        ModalComponent: UnapprovedTaskDeliverablePresenceSanityCheckModal,
                        data: {
                            approveTaskDeliverablesAndTransition: () =>
                                handleApproveTaskDeliverablesAndTransitionToState(
                                    unapprovedDeliverableIds,
                                    transitionToState
                                ),
                            unapprovedDeliverablesCount: unapprovedDeliverableIds.length,
                        },
                    });
                } else {
                    // All deliverables approved
                    handleTransitionToState(transitionToState);
                }
            }
        };

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="saved"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.DELIVERED}
                onClick={handleDeliveredTransition}
            >
                Mark as delivered
            </Button>
        );
    };

    const renderInRevisionTransitionButton = () => {
        // TODO: task state actions / helper actions permissions

        return (
            <Button
                icon="outdated"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.IN_REVISION}
                onClick={() => handleTransitionToState(TaskState.IN_REVISION)}
            >
                Start another revision
            </Button>
        );
    };

    const renderOpenTaskDeliverables = () => {
        // TODO: task state actions / helper actions permissions

        return (
            <Button
                icon="unarchive"
                disabled={transitioningTo !== null}
                onClick={() => navigate(Endpoints.TASK_DELIVERABLES_ALL.replace(':taskId', task.id))}
            >
                Upload final deliverables & source files
            </Button>
        );
    };

    const information = taskStateInformation[TaskState.PRE_DELIVERED];

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Circle size="22px" className="mr-1" color={information.color} />
                    <Heading type="h4">{information.title}</Heading>
                </Flex>

                <Flex direction="row">
                    <div className="mr-1">{renderDeliveredTransitionButton()}</div>
                    <div>{renderInRevisionTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Congratulations! The client approved provided deliverables.</DevText>
            <DevText muted>
                Please upload the final deliverables and make sure you deliver the source files if requested.
            </DevText>

            {/* Helper actions */}
            <Flex className="mt-2" direction="row">
                <div>{renderOpenTaskDeliverables()}</div>
            </Flex>
        </Card>
    );
};

export default PreDelivered;
