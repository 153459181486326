import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import NonIdealState from '@app/components/NonIdealState';
import DevText from '@app/components/Text';
import { Button, Icon } from '@blueprintjs/core';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React from 'react';
import { $selectedTaskDeliverable } from '../../store/states';
import TaskDeliverableGoogleDriveViewer from './components/TaskDeliverableGoogleDriveViewer';
import TaskDeliverableImageViewer from './components/TaskDeliverableImageViewer';
import TaskDeliverablePdfViewer from './components/TaskDeliverablePdfViewer';
import TaskDeliverableTextViewer from './components/TaskDeliverableTextViewer';
import TaskDeliverableVideoViewer from './components/TaskDeliverableVideoViewer';
import {
    EmbedGoogleDriveViewerWrapper,
    ImageViewerWrapper,
    PdfViewerWrapper,
    TextViewerWrapper,
    VideoViewerWrapper,
    Wrapper,
} from './styled';

const TaskDeliverableViewerManager: React.FC = () => {
    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);

    if (!selectedTaskDeliverable) {
        return null;
    }

    const renderFileViewer = () => {
        if (!selectedTaskDeliverable.file) {
            console.error('Task deliverable is of type file, but file is NOT provided');
            return null;
        }

        if (FileUtils.isImage(selectedTaskDeliverable.file.extension)) {
            return (
                <ImageViewerWrapper>
                    <TaskDeliverableImageViewer />
                </ImageViewerWrapper>
            );
        }

        if (FileUtils.isVideo(selectedTaskDeliverable.file.extension)) {
            return (
                <VideoViewerWrapper>
                    <TaskDeliverableVideoViewer />
                </VideoViewerWrapper>
            );
        }

        if (FileUtils.isPdf(selectedTaskDeliverable.file.extension)) {
            return (
                <PdfViewerWrapper>
                    <TaskDeliverablePdfViewer />
                </PdfViewerWrapper>
            );
        }

        // TODO: fallback to FilePreview + download button in order to view content
        return (
            <Flex fullWidth fullHeight align="center" justify="center">
                <NonIdealState
                    className="mt-2"
                    icon={<Icon className="mb-2" icon="box" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            {selectedTaskDeliverable.file.original_name}
                            {!!selectedTaskDeliverable.file.extension && `.${selectedTaskDeliverable.file.extension}`}
                        </Heading>
                    }
                    description={
                        <DevText muted>Deliverable could not be viewed, you can download it on your computer</DevText>
                    }
                    action={
                        <Button
                            className="mt-2"
                            icon="import"
                            onClick={() => window.open(selectedTaskDeliverable.file?.url)}
                        >
                            Download
                        </Button>
                    }
                />
            </Flex>
        );
    };

    const renderEmbedViewer = () => {
        return (
            <EmbedGoogleDriveViewerWrapper>
                <TaskDeliverableGoogleDriveViewer />
            </EmbedGoogleDriveViewerWrapper>
        );
    };

    const renderTextViewer = () => {
        return (
            <TextViewerWrapper>
                <TaskDeliverableTextViewer />
            </TextViewerWrapper>
        );
    };

    const renderViewer = () => {
        switch (selectedTaskDeliverable.type) {
            case TaskDeliverableType.FILE:
                return renderFileViewer();
            case TaskDeliverableType.EMBED:
                return renderEmbedViewer();
            case TaskDeliverableType.TEXT:
                return renderTextViewer();
            default:
                // No viewer for this type

                // TODO: fallback to FilePreview + download button in order to view content
                return null;
        }
    };

    return <Wrapper className="custom-thin-scroll">{renderViewer()}</Wrapper>;
};

export default TaskDeliverableViewerManager;
