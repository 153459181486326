enum SessionStorage {
    CORE_USER_FILTER = 'dy_internal/filter/core-user',
    CORE_USER_SORTING = 'dy_internal/sorting/core-user',
    CLIENT_USER_FILTER = 'dy_internal/filter/client-user',
    CLIENT_USER_SORTING = 'dy_internal/sorting/client-user',
    TASK_FILTER = 'dy_internal/filter/task',
    TASK_SORTING = 'dy_internal/sorting/task',
    TASK_CLIENT_FEEDBACK_FILTER = 'dy_internal/filter/task-client-feedback',
    TASK_KANBAN_FILTER = 'dy_internal/filter/task-kanban',
}

export default SessionStorage;
