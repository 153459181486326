import React, { HTMLAttributes } from 'react';
import { Endpoints, imageHashPreview } from '@app/data/consts';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import { Card, Classes, Colors, Divider, Icon, IconName, Tooltip } from '@blueprintjs/core';
import {
    ScoreSummaryCountResource,
    TaskClientFeedbackSummaryResource,
    TaskParticipationResource,
    TeamResource,
    TaskClientFeedbackResource,
    AuthorUserResource,
} from 'dy-frontend-http-repository/lib/modules/TaskClientFeedback/resources';
import DevText from '@app/components/Text';
import RatioTile from '@app/components/RatioTile';
import { RatioIndexData } from '@app/components/RatioTile/types';
import Grid from '@app/components/Grid';
import TableCell from '@app/components/TableCell';
import Table from '@app/components/Table';
import NonIdealState from '@app/components/NonIdealState';
import RouterLink from '@app/components/RouterLink';
import moment from 'moment';
import { taskFeedbackInformation } from 'dy-frontend-shared/lib/data/consts';
import { TaskFeedbackCriteria } from 'dy-frontend-shared/lib/data/enums';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import AvatarStack, { AvatarStackProps } from '@app/components/AvatarStack';
import Box from '@app/components/Box';
import TeamTag from '../TeamTag';
import ParticipantListInformationProvider, {
    ParticipantListInformationProviderProps,
} from '@app/containers/providers/ParticipantListInformationProvider';

export interface TaskClientFeedbackProps {
    layout?: 'table' | 'list';
    loading?: boolean;
    loadingSummary?: boolean;
    taskClientFeedback: TaskClientFeedbackResource[];
    taskClientFeedbackSummary: TaskClientFeedbackSummaryResource;
}
export type Props = HTMLAttributes<HTMLDivElement> & TaskClientFeedbackProps;

const TaskClientFeedback: React.FC<Props> = ({
    layout = 'table',
    loading,
    loadingSummary,
    taskClientFeedback,
    taskClientFeedbackSummary,
    ...props
}) => {
    const renderTaskClientFeedbackSummary = () => {
        const getRatioTileConfig = (
            scoreSummaryCount: ScoreSummaryCountResource
        ): { [key in string]: RatioIndexData } => {
            // Get positive ratio index data
            const positiveRatioIndexData: RatioIndexData = {
                color: Colors.GREEN3,
                label: 'Positive',
                value: scoreSummaryCount.positive,
            };

            // Get neutral ratio index data
            const neutralRatioIndexData: RatioIndexData = {
                color: Colors.GRAY5,
                label: 'Neutral',
                value: scoreSummaryCount.neutral,
            };

            // Get negative ratio index data
            const negativeRatioIndexData: RatioIndexData = {
                color: Colors.RED3,
                label: 'Negative',
                value: scoreSummaryCount.negative,
            };

            // Get absent ratio index data
            const absentRatioIndexData: RatioIndexData = {
                color: Colors.GRAY1,
                label: 'Absent',
                value: scoreSummaryCount.absent,
            };

            return {
                positive: positiveRatioIndexData,
                neutral: neutralRatioIndexData,
                negative: negativeRatioIndexData,
                absent: absentRatioIndexData,
            };
        };

        const renderScoreIndex = (title: string, count: number) => {
            return (
                <Flex direction="column">
                    <Heading className="mb-small" type="h3">
                        {count}
                    </Heading>
                    <DevText small>{title}</DevText>
                </Flex>
            );
        };

        const renderScoreSummaryCountCard = (
            title: string,
            icon: IconName,
            scoreSummaryCount: ScoreSummaryCountResource
        ) => {
            // Get total score
            const totalScore =
                scoreSummaryCount.positive +
                scoreSummaryCount.neutral +
                scoreSummaryCount.negative +
                scoreSummaryCount.absent;

            // TODO: move ".toFixed(2).replace(/[.,]0+$/, '')" to utils, this code works like that "5.00" will show as "5", but "25.256457457", will be "25.25", same for "65.55", it'll be "65.55"
            // Get positive feedback percentage
            let positiveFeedbackPercentage = (scoreSummaryCount.positive / totalScore) * 100;
            if (isNaN(positiveFeedbackPercentage)) {
                positiveFeedbackPercentage = 0;
            }

            // Get skeleton class name
            const skeletonClassName = loadingSummary ? Classes.SKELETON : '';

            return (
                <Card>
                    <Flex className="mb-2" direction="row" justify="space-between">
                        <DevText muted>{title}</DevText>
                        <DevText muted>
                            <Icon icon={icon} />
                        </DevText>
                    </Flex>

                    <Heading className={`mb-1 ${skeletonClassName}`} type="h1">
                        {Math.round(positiveFeedbackPercentage)}%
                    </Heading>

                    <RatioTile className={`mb-1 ${skeletonClassName}`} config={getRatioTileConfig(scoreSummaryCount)} />

                    <Flex direction="row">
                        <div className={`mr-3 ${skeletonClassName}`}>
                            {renderScoreIndex('Positive', scoreSummaryCount.positive)}
                        </div>

                        <div className={skeletonClassName}>
                            {renderScoreIndex('Negative', scoreSummaryCount.negative)}
                        </div>
                    </Flex>
                </Card>
            );
        };

        return (
            <Grid container>
                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard('Creativity', 'clean', taskClientFeedbackSummary.score_creativity)}
                </Grid>

                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard('Quality', 'star', taskClientFeedbackSummary.score_quality)}
                </Grid>

                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard('Speed', 'dashboard', taskClientFeedbackSummary.score_speed)}
                </Grid>

                <Grid className="mb-2" lg={3} sm={6} xs={12}>
                    {renderScoreSummaryCountCard(
                        'Communication',
                        'chat',
                        taskClientFeedbackSummary.score_communication
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderScoreLabel = (feedbackCriteria: TaskFeedbackCriteria, value: number) => {
        const info = taskFeedbackInformation[feedbackCriteria];

        if (info.positiveFeedbackScore === value) {
            // Positive
            return <DevText color={Colors.GREEN3}>Positive</DevText>;
        }

        if (info.negativeFeedbackScore === value) {
            // Negative
            return <DevText color={Colors.RED3}>Negative</DevText>;
        }

        return <DevText muted>No data</DevText>;
    };

    const renderParticipantTeams = (participants: TaskParticipationResource[]) => {
        if (participants.length === 0) {
            // Participant user is NOT part of any team
            return null;
        }

        // Get team map
        const teamMap: { [teamId in ID]: TeamResource } = {};
        participants.forEach(({ user: { team_participation } }) =>
            team_participation.forEach((p) => (teamMap[p.team.id] = p.team))
        );

        // Get teams
        const teams = Object.values(teamMap);

        return teams.map((team) => <TeamTag key={team.id} id={team.id} name={team.title} color={team.color} />);
    };

    const renderTaskParticipants = (participants: TaskParticipationResource[]) => {
        if (participants.length === 0) {
            return <DevText muted>No participants</DevText>;
        }

        // Get participant information for information provider
        const participantListInformation: ParticipantListInformationProviderProps['participants'] = participants.map(
            (p) => ({
                userId: p.user.id,
                imageHash: p.user.image_hash,
                name: `${p.user.first_name} ${p.user.last_name}`,
                role: p.user.role,
                teams: p.user.team_participation.map((tp) => ({
                    id: tp.team.id,
                    name: tp.team.title,
                    color: tp.team.color,
                })),
            })
        );

        // Get avatar  stack  information
        const avatarStackInformation: AvatarStackProps['avatars'] = participants.map((p) => ({
            id: p.user.id,
            name: `${p.user.first_name} ${p.user.last_name}`,
            src: p.user.image_hash ? imageHashPreview.userImage(p.user.image_hash, ImageHashPreviewSize.SM) : null,
            borderColor: p.user.team_participation.length > 0 ? p.user.team_participation[0].team.color : undefined,
        }));

        return (
            <ParticipantListInformationProvider participants={participantListInformation}>
                <AvatarStack tight withCounter maxAmountOfAvatarsToShow={3} avatars={avatarStackInformation} />
            </ParticipantListInformationProvider>
        );
    };

    const renderCommentIcon = (comment: string) => {
        if (comment.trim().length === 0) {
            return null;
        }

        return (
            <Tooltip
                content={<Box maxWidth="300px">{comment}</Box>}
                renderTarget={(props) => (
                    <Box cursor="pointer" style={{ display: 'inline-block' }} {...props}>
                        <DevText muted inline>
                            <Icon icon="chat" />
                        </DevText>
                    </Box>
                )}
            />
        );
    };

    const renderAuthorInformation = (user: AuthorUserResource) => {
        return (
            <div>
                <RouterLink to={Endpoints.CLIENT_TASKS.replace(':clientId', user.id)}>
                    {user.first_name} {user.last_name}
                </RouterLink>
                {user.company_name.length > 0 && <DevText muted>{user.company_name}</DevText>}
            </div>
        );
    };

    const renderTaskClientFeedbackTable = () => {
        if (layout !== 'table') {
            return null;
        }

        if (taskClientFeedback.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client feedback was found
                        </Heading>
                    }
                />
            );
        }

        const renderTableHeader = () => {
            return (
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Client</th>
                        <th>
                            <Icon className="mr-small" icon="clean" />
                            Creativity
                        </th>
                        <th>
                            <Icon className="mr-small" icon="star" />
                            Quality
                        </th>
                        <th>
                            <Icon className="mr-small" icon="dashboard" />
                            Speed
                        </th>
                        <th>
                            <Icon className="mr-small" icon="chat" />
                            Communication
                        </th>
                        <th>Participants</th>
                    </tr>
                </thead>
            );
        };

        const renderTableBody = () => {
            const renderRow = (feedback: TaskClientFeedbackResource) => {
                return (
                    <tr key={feedback.id}>
                        <TableCell>
                            <RouterLink
                                className="mr-1"
                                color={Colors.WHITE}
                                to={Endpoints.TASK_INFORMATION.replace(':taskId', feedback.task.id)}
                            >
                                {feedback.task.title}
                            </RouterLink>
                            {renderCommentIcon(feedback.comment)}
                        </TableCell>
                        <TableCell>
                            <DevText muted className="mb-small">
                                {moment(feedback.updated_at).format('D MMM YYYY')}
                            </DevText>
                            {renderAuthorInformation(feedback.author_user)}
                        </TableCell>
                        <TableCell>
                            {renderScoreLabel(TaskFeedbackCriteria.CREATIVITY, feedback.score_creativity)}
                        </TableCell>
                        <TableCell>{renderScoreLabel(TaskFeedbackCriteria.QUALITY, feedback.score_quality)}</TableCell>
                        <TableCell>{renderScoreLabel(TaskFeedbackCriteria.SPEED, feedback.score_speed)}</TableCell>
                        <TableCell>
                            {renderScoreLabel(TaskFeedbackCriteria.COMMUNICATION, feedback.score_communication)}
                        </TableCell>
                        <TableCell>
                            <div className="mb-1">{renderParticipantTeams(feedback.task.participants)}</div>
                            {renderTaskParticipants(feedback.task.participants)}
                        </TableCell>
                    </tr>
                );
            };

            return <tbody>{taskClientFeedback.map(renderRow)}</tbody>;
        };

        return (
            <Table striped loading={loading}>
                {renderTableHeader()}
                {renderTableBody()}
            </Table>
        );
    };

    const renderTaskClientFeedbackList = () => {
        if (layout !== 'list') {
            return null;
        }

        if (taskClientFeedback.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client feedback was found
                        </Heading>
                    }
                />
            );
        }

        const renderScore = (feedback: TaskClientFeedbackResource) => {
            return (
                <Table>
                    <thead>
                        <tr>
                            <th>
                                <Icon className="mr-small" icon="clean" />
                                Creativity
                            </th>
                            <th>
                                <Icon className="mr-small" icon="star" />
                                Quality
                            </th>
                            <th>
                                <Icon className="mr-small" icon="dashboard" />
                                Speed
                            </th>
                            <th>
                                <Icon className="mr-small" icon="chat" />
                                Communication
                            </th>
                            <th>Participants</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <TableCell>
                                {renderScoreLabel(TaskFeedbackCriteria.CREATIVITY, feedback.score_creativity)}
                            </TableCell>
                            <TableCell>
                                {renderScoreLabel(TaskFeedbackCriteria.QUALITY, feedback.score_quality)}
                            </TableCell>
                            <TableCell>{renderScoreLabel(TaskFeedbackCriteria.SPEED, feedback.score_speed)}</TableCell>
                            <TableCell>
                                {renderScoreLabel(TaskFeedbackCriteria.COMMUNICATION, feedback.score_communication)}
                            </TableCell>
                            <TableCell>
                                <div className="mb-small">{renderParticipantTeams(feedback.task.participants)}</div>
                                {renderTaskParticipants(feedback.task.participants)}
                            </TableCell>
                        </tr>
                    </tbody>
                </Table>
            );
        };

        const renderFeedback = (feedback: TaskClientFeedbackResource) => {
            return (
                <Card>
                    <Flex className="mb-small" direction="row" justify="space-between">
                        <DevText>
                            <DevText inline className="mr-small">
                                Feedback from
                            </DevText>

                            <RouterLink to={Endpoints.CLIENT_TASKS.replace(':clientId', feedback.author_user.id)}>
                                {feedback.author_user.first_name} {feedback.author_user.last_name}
                            </RouterLink>

                            <DevText inline className="ml-small mr-small">
                                for request
                            </DevText>

                            <RouterLink
                                className="mr-1"
                                to={Endpoints.TASK_INFORMATION.replace(':taskId', feedback.task.id)}
                            >
                                {feedback.task.title}
                            </RouterLink>
                        </DevText>

                        <DevText muted>{moment(feedback.updated_at).format('D MMM, YYYY HH:mm')}</DevText>
                    </Flex>

                    {feedback.comment.trim().length > 0 && (
                        <DevText running>
                            <Icon className="mr-small" icon="chat" />
                            {feedback.comment}
                        </DevText>
                    )}

                    <Divider className="mt-2 mb-3" />

                    {renderScore(feedback)}
                </Card>
            );
        };

        return taskClientFeedback.map((feedback, index) => (
            <div key={feedback.id} className={index === 0 ? '' : 'mt-2'}>
                {renderFeedback(feedback)}
            </div>
        ));
    };

    return (
        <div {...props}>
            {/* Task client feedback summary */}
            <div className="mb-1">{renderTaskClientFeedbackSummary()}</div>
            {/* Task client feedback */}
            {renderTaskClientFeedbackTable()}
            {renderTaskClientFeedbackList()}
        </div>
    );
};

export default TaskClientFeedback;
