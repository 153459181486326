import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import Circle from '@app/components/Circle';
import Flex from '@app/components/Flex';
import { PlatformTaskFilterData } from '@app/containers/pages/Tasks/types';
import { $isQueryHydrationFinished } from '@app/containers/pages/Tasks/store/states';
import { fetchStaticData } from '@app/containers/pages/Tasks/store/effects';
import { setPlatformTaskFilterData } from '@app/containers/pages/Tasks/store/events';
import PlatformListFilter from '@app/components/PlatformListFilter';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import { platformFiltersInformation } from '@app/containers/pages/Tasks/consts';
import { PlatformTaskFilterType } from '@app/containers/pages/Tasks/enums';

export interface TaskTeamsPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskTeamsPlatformFilterProps;

const TaskTeamsPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformTaskFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isPlanInitialized, setIsPlansInitialized] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState<PlatformTaskFilterData['teams']>([]);
    const [foundTeams, setFoundTeams] = useState<PlatformTaskFilterData['teams']>([]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            fetchStaticData({ team: '1' }).then((staticData) => {
                setFoundTeams(staticData.team);
                setSelectedTeams(
                    staticData.team.filter((sp) => platformTaskFilterData.teams.find((p) => p.id === sp.id))
                );
                setTimeout(() => setIsPlansInitialized(true), 0);
            });
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.teams.length === 0) {
            setSelectedTeams([]);
        }
    }, [platformTaskFilterData.teams]);

    const getFilterTagLabel = () => {
        if (selectedTeams.length === 0) return '';

        return selectedTeams
            .map((p, index) => {
                if (index === selectedTeams.length - 1) {
                    // Last element
                    return `${p.title}`;
                }

                return `${p.title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedTeams.length > 0) {
            // At least 1 team selected
            return 'Team:';
        }

        return 'Team';
    };

    const handleRemoveFilter = () => {
        if (selectedTeams.length !== 0) {
            // At least 1 team was selected

            setSelectedTeams([]);

            if (platformTaskFilterData.teams.length !== 0) {
                // Reset team to empty array
                setPlatformTaskFilterData({ ...platformTaskFilterData, teams: [] });
            }

            setIsOpen(false);
        }

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_TEAMS].isPinned) {
            // Platform team filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (team: PlatformTaskFilterData['teams'][number]) => {
        if (!!selectedTeams.find((selectedTeam) => selectedTeam.id === team.id)) {
            // Plan is already checked
            setSelectedTeams(selectedTeams.filter((selectedTeam) => selectedTeam.id !== team.id));
        } else {
            // Plan is NOT checked yet
            setSelectedTeams([...selectedTeams, team]);
        }
    };

    const handleApplyFilter = () => {
        if (selectedTeams.length !== platformTaskFilterData.teams.length) {
            // If task teams array length didn't change since opening the filter then nothing apply to
            setPlatformTaskFilterData({ ...platformTaskFilterData, teams: selectedTeams });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_TEAMS].isPinned) {
            return;
        }

        if (selectedTeams.length === 0) {
            onRemove();
        }
    };

    const renderRow = (team: PlatformTaskFilterData['teams'][number]) => {
        return (
            <li className="mb-1" key={team.id}>
                <Flex justify="space-between" align="center">
                    <Checkbox
                        checked={!!selectedTeams.find((selectedTeam) => selectedTeam.id === team.id)}
                        className="mr-2"
                        label={team.title}
                        onClick={() => handleCheckboxClick(team)}
                    />

                    <Circle size="11px" color={team.color} />
                </Flex>
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformTaskFilterData['teams'][number]>
            isOpen={isOpen}
            loading={!isPlanInitialized}
            removable={selectedTeams.length > 0}
            title="Team"
            position={Position.BOTTOM_LEFT}
            minWidth={320}
            maxListHeight={400}
            list={foundTeams}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_TEAMS].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskTeamsPlatformFilter;
