import NonIdealState from '@app/components/NonIdealState';
import TaskDeliverableListViewListItem from '@app/containers/pages/Task/components/TaskDeliverableListViewListItem';
import { rootTaskDeliverablesPathLabel } from '@app/containers/pages/Task/consts';
import { Endpoints } from '@app/data/consts';
import { ToastUtils } from '@app/data/utils';
import {
    Breadcrumb,
    BreadcrumbProps,
    Breadcrumbs,
    Button,
    Card,
    Colors,
    Divider,
    Elevation,
    Icon,
    Intent,
    Spinner,
} from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { taskDeliverablesTreeApi } from './store/apis';
import { fetchApprovedTaskDeliverables } from './store/effects';
import { resetApprovedTaskDeliverables, resetTaskDeliverablesTree } from './store/events';
import { $approvedTaskDeliverables, $taskDeliverablesTree } from './store/states';

type SelectedTaskDeliverableMap = { [taskDeliverableId in ID]: TaskDeliverableResource };
export interface AddTaskDeliverableAttachmentModalProps {
    taskId: ID;
    selectedTaskDeliverableIdList: ID[];
    onAttach: (map: { [taskDeliverableId in ID]: TaskDeliverableResource }) => void;
}

type Props = ModalProps<AddTaskDeliverableAttachmentModalProps>;

const AddTaskDeliverableAttachmentModal: React.FC<Props> = ({ closeModal, data }) => {
    const navigate = useNavigate();

    const approvedTaskDeliverables = useStore($approvedTaskDeliverables);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    const [selectedTaskDeliverableMap, setSelectedTaskDeliverableMap] = useState<SelectedTaskDeliverableMap>({});

    useEffect(() => {
        if (!data) {
            return;
        }

        // Fetch approved task deliverables
        fetchApprovedTaskDeliverables({ task_id: data.taskId, is_approved: '1' }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Deliverables for request with ID of ${data.taskId} were not found`,
                    intent: Intent.DANGER,
                });

                // Close modal
                closeModal?.();
            }
        });

        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!data || !approvedTaskDeliverables || taskDeliverablesTree) {
            return;
        }

        // Initialize selected deliverables
        const map: SelectedTaskDeliverableMap = {};
        approvedTaskDeliverables.items.forEach((deliverable) => {
            if (data.selectedTaskDeliverableIdList.includes(deliverable.id)) {
                map[deliverable.id] = deliverable;
            }
        });
        setSelectedTaskDeliverableMap(map);

        // Initialize task deliverables map
        taskDeliverablesTreeApi.insert({ taskDeliverables: approvedTaskDeliverables.items });

        // eslint-disable-next-line
    }, [approvedTaskDeliverables]);

    useEffect(() => {
        return () => {
            resetApprovedTaskDeliverables();
            resetTaskDeliverablesTree();
        };
    }, []);

    if (!data) {
        closeModal?.();
        return null;
    }

    const handleGoToDeliverables = () => {
        navigate(Endpoints.TASK_DELIVERABLES_ALL.replace(':taskId', data.taskId));
        closeModal?.();
    };

    const handleAttachTaskDeliverables = () => {
        data.onAttach(selectedTaskDeliverableMap);
        closeModal?.();
    };

    const handleSelectTaskDeliverable = (taskDeliverable: TaskDeliverableResource) => {
        if (selectedTaskDeliverableMap[taskDeliverable.id]) {
            // Already selected
            setSelectedTaskDeliverableMap((map) => {
                const copyMap = { ...map };
                delete copyMap[taskDeliverable.id];
                return copyMap;
            });
        } else {
            // Not selected
            setSelectedTaskDeliverableMap((map) => ({ [taskDeliverable.id]: taskDeliverable, ...map }));
        }
    };

    const renderDirectoryBlock = (path: string, taskDeliverables: TaskDeliverableResource[]) => {
        // Collect breadcrumbs
        const breadcrumbItems: BreadcrumbProps[] = [];
        breadcrumbItems.push({ text: rootTaskDeliverablesPathLabel });
        if (path !== '/') {
            const parsedPath = path.split('/');
            for (let i = 1; i < parsedPath.length; i++) {
                breadcrumbItems.push({
                    text: parsedPath[i],
                });
            }
        }

        return (
            <div key={path} className="mb-2">
                <Flex className="mb-1" direction="row" align="center" justify="space-between">
                    <Breadcrumbs
                        items={breadcrumbItems}
                        currentBreadcrumbRenderer={({ text, ...restProps }: BreadcrumbProps) => (
                            <Breadcrumb {...restProps}>{text}</Breadcrumb>
                        )}
                    />
                </Flex>

                <div>
                    {taskDeliverables.map((deliverable, index) => (
                        <TaskDeliverableListViewListItem
                            approvementTagVisible={false}
                            createdAt={deliverable.approved_at ?? ''}
                            className={index === 0 ? '' : 'mt-1'}
                            taskDeliverable={deliverable}
                            style={{
                                cursor: 'pointer',
                                boxShadow: !!selectedTaskDeliverableMap[deliverable.id]
                                    ? `0 0 0 2px ${Colors.BLUE2}`
                                    : undefined,
                            }}
                            onClick={() => handleSelectTaskDeliverable(deliverable)}
                        />
                    ))}
                </div>
            </div>
        );
    };

    const renderDeliverables = () => {
        if (!approvedTaskDeliverables || !taskDeliverablesTree) {
            // Loading
            return (
                <Flex align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (Object.values(taskDeliverablesTree.deliverables).length === 0) {
            // No deliverables
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-2">
                            No approved deliverables found
                        </Heading>
                    }
                    action={
                        <Button intent={Intent.PRIMARY} onClick={handleGoToDeliverables}>
                            Go to deliverables
                        </Button>
                    }
                />
            );
        }

        const directoryBlocks: JSX.Element[] = [];
        for (const path in taskDeliverablesTree.deliverables) {
            // Get task deliverables for path
            const taskDeliverablesForPath = Object.values(taskDeliverablesTree.deliverables[path]);
            directoryBlocks.push(renderDirectoryBlock(path, taskDeliverablesForPath));
        }

        return <div className="mt-2">{directoryBlocks}</div>;
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '700px', maxWidth: '100%' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Attach deliverables</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                {renderDeliverables()}

                <Flex className="mt-2" justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>

                    <Button icon="confirm" intent={Intent.PRIMARY} onClick={handleAttachTaskDeliverables}>
                        Confirm
                    </Button>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default AddTaskDeliverableAttachmentModal;
