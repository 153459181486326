import { taskDeliverablesTreeApi } from '@pages/Task/store/apis';
import { approveTaskDeliverablesBatch } from '../../../../store/effects';

const useApproveTaskDeliverable = () => {
    const handleApproveTaskDeliverable = async ({
        taskDeliverableId,
        path,
    }: {
        taskDeliverableId: ID;
        path: string;
    }) => {
        try {
            // Approve task deliverable by ID
            await approveTaskDeliverablesBatch({
                task_deliverable_ids: [taskDeliverableId],
            });

            taskDeliverablesTreeApi.approveTaskDeliverable({
                taskDeliverableId: taskDeliverableId,
                path,
            });
        } catch (e) {
            throw e;
        }
    };

    return { approveTaskDeliverable: handleApproveTaskDeliverable };
};

export default useApproveTaskDeliverable;
