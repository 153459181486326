import { SortDirection } from 'dy-frontend-http-repository/lib/data/enums';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { SortData } from 'dy-frontend-http-repository/lib/data/types';
import { TaskListItemResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { initialPlatformTaskFilterData } from '../consts';
import { TaskSortField } from '../enums';
import { PlatformTaskFilterData } from '../types';
import domain from './domain';
import {
    resetTasks,
    resetPlatformTaskFilterData,
    resetIsQueryHydrationFinished,
    resetPage,
    resetTaskSortData,
} from './events';

// Tasks
export const $tasks = domain.createStore<CollectionResource<TaskListItemResource, {}> | null>(null).reset(resetTasks);

/*
  Filter accepts:
  - query: string;                                                      // Search input (string)
  - state: TaskState[];                                                 // Static filter (Task state)
  - participant_user_id: ID[];                                          // Dynamic filter (FilterTaskParticipationResource[])
  - owner_user_id: ID[];                                                // Dynamic filter (ClientUserResource[])
  - workspace_id: ID[];                                                 // Dynamic filter (WorkspaceResource[])
  - task_category_id: ID[];                                             // Dynamic filter (TaskCategoryResource[])
  - publish_type: TaskPublishType[];                                    // Static filter (TaskPublishType[])
  - queue: TaskQueue[];                                                 // Static filter (TaskQueue[])
  - deadline_at: {gte?: string; lte?: string;};                         // ???: do we combine it to Date filter ???
  - is_archived: "1" | "0";                                             // ???: do we combine it to Date filter ???
*/

// Page
export const $page = domain.createStore<number>(1).reset(resetPage);

// Initialization flag for hydration finished
export const $isQueryHydrationFinished = domain.createStore<boolean>(false).reset(resetIsQueryHydrationFinished);

// Platform task filter data
export const $platformTaskFilterData = domain
    .createStore<PlatformTaskFilterData>(initialPlatformTaskFilterData)
    .reset(resetPlatformTaskFilterData);

// Sort data
export const $taskSortData = domain
    .createStore<SortData<TaskSortField>>({ field: TaskSortField.PUBLISH_AT, direction: SortDirection.DESC })
    .reset(resetTaskSortData);
