import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import domain from './domain';

// Set task deliverables tree initialized flag
export const setIsTaskDeliverablesTreeInitialized = domain.createEvent<boolean>('setIsTaskDeliverablesTreeInitialized');

// Set task deliverables tree initialized flag
export const setApprovedTaskDeliverables = domain.createEvent<TaskDeliverableResource[]>('setApprovedTaskDeliverables');

// Reset task
export const resetTask = domain.createEvent('resetTask');

// Reset task state transition log
export const resetTaskStateTransitionLog = domain.createEvent('resetTaskStateTransitionLog');

// Reset task pickable participant users
export const resetTaskPickableParticipantUsers = domain.createEvent('resetTaskPickableParticipantUsers');

// Reset teams
export const resetTeams = domain.createEvent('resetTeams');

// Reset task complexity
export const resetTaskComplexity = domain.createEvent('resetTaskComplexity');

// Reset task addons
export const resetTaskAddons = domain.createEvent('resetTaskAddons');

// Reset client payment account
export const resetClientPaymentAccount = domain.createEvent('resetClientPaymentAccount');

// Reset task deliverables
export const resetTaskDeliverables = domain.createEvent('resetTaskDeliverables');

// Reset approved task deliverables
export const resetApprovedTaskDeliverables = domain.createEvent('resetApprovedTaskDeliverables');

// Reset task deliverables tree
export const resetTaskDeliverablesTree = domain.createEvent('resetTaskDeliverablesTree');
