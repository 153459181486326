import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import { NotificationResource, UserResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints } from '@app/data/consts';
import NotificationTemplate from '../../../../NotificationTemplate';

export interface UserClientVerifiedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    client: UserResource | null;
    dismiss: () => Promise<void>;
}

type Props = UserClientVerifiedNotificationProps;

const UserClientVerifiedNotification: React.FC<Props> = ({ notification, client, dismiss }) => {
    const renderContent = () => {
        if (!client) {
            // This is bad, client is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Client was not found</DevText>;
        }

        return (
            <div>
                <DevText running>
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.CLIENT_TASKS.replace(':clientId', client.id)}
                    >
                        {client.first_name} {client.last_name}
                    </RouterLink>{' '}
                    signed up as DotYeti client
                </DevText>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="New client"
            icon="user"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default UserClientVerifiedNotification;
