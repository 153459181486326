import repository from 'dy-frontend-http-repository/lib/repository';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CoreUserListItemResource, CoreUserResource } from 'dy-frontend-http-repository/lib/modules/CoreUser/resources';
import { CoreUserFilterInput, CreateCoreUserInput } from 'dy-frontend-http-repository/lib/modules/CoreUser/inputs';
import { CoreUserRef } from 'dy-frontend-http-repository/lib/modules/CoreUser/refs';
import domain from './domain';
import {
    HydrateFilterDataFilterInput,
    StaticDataFilterInput,
    TeamFilterDataFilterInput,
} from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { HydratedFilterResource, StaticFilterDataResource, TeamResource } from 'dy-frontend-http-repository/lib/modules/Filter/resources';

// Fetch list of core users
export const fetchCoreUsers = domain.createEffect<
    { pagination: OffsetPaginationInput; filter?: CoreUserFilterInput },
    CollectionResource<CoreUserListItemResource, {}>
>({
    name: 'fetchCoreUsers',
    handler: async ({ pagination, filter }) => {
        return await repository.coreUser().get(pagination, filter);
    },
});

// Fetch client
export const fetchCoreUser = domain.createEffect<ID, CoreUserResource>({
    name: 'fetchCoreUser',
    handler: async (coreUserId) => {
        return await repository.coreUser().getById(coreUserId);
    },
});

// Create new core user
export const createCoreUser = domain.createEffect<CreateCoreUserInput, CoreUserRef>({
    name: 'createCoreUser',
    handler: async (input) => {
        return await repository.coreUser().create(input);
    },
});

// Hydrate query parameters
export const hydrateQueryParameters = domain.createEffect<HydrateFilterDataFilterInput, HydratedFilterResource>({
    name: 'hydrateQueryParameters',
    handler: async (input) => {
        return await repository.filter().hydrateData(input);
    },
});

// Fetch plan data
export const fetchStaticData = domain.createEffect<
    StaticDataFilterInput,
    StaticFilterDataResource
>({
    name: 'fetchStaticData',
    handler: async (filter) => {
        return await repository.filter().getStaticData(filter);
    },
});
