import { breakpoints } from '@app/data/consts';
import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';

export const NotificationsWrapper = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100vh;
    background: ${Colors.DARK_GRAY1};
`;

export const Header = styled.div`
    flex-shrink: 0;

    padding: 20px 16px 0px 16px;

    @media (min-width: ${breakpoints.xl}px) {
        padding: 32px 16px 0px 20px;
    }
`;

export const List = styled.div`
    padding-left: 16px;
    padding-right: 20px;
    overflow-y: auto;

    padding: 0px 16px 0px 16px;

    @media (min-width: ${breakpoints.xl}px) {
        padding: 0px 16px 0px 20px;
    }
`;

export const Footer = styled.div`
    flex-shrink: 0;

    padding: 0px 16px 20px 16px;

    @media (min-width: ${breakpoints.xl}px) {
        padding: 0px 16px 32px 20px;
    }
`;
