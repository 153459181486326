import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent, Tooltip } from '@blueprintjs/core';
import { openModal } from '@app/containers/modals/store/events';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import PublishTaskModal, { PublishTaskModalProps } from '../../../../modals/PublishTaskModal';
import { TaskPublishPermission } from 'dy-frontend-permissions/lib/permission';
import ArchiveTaskModal, { ArchiveTaskModalProps } from '@app/containers/pages/Task/modals/ArchiveTaskModal';
import Circle from '@components/Circle';
import { taskStateInformation } from '@data/consts';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';

export type Props = HTMLAttributes<HTMLDivElement>;

const Draft: React.FC<Props> = (props) => {
    const me = useStore($authorizedUser);
    const task = useStore($task);
    const permissions = useStore($permissions);

    if (!me) {
        return null;
    }

    if (!task) {
        // Task is NOT fetched yet
        return null;
    }

    const renderRemoveTaskButton = () => {
        // TODO: task state actions / helper actions permissions

        return (
            <Tooltip content="Archive request">
                <Button
                    minimal
                    icon="trash"
                    onClick={() =>
                        openModal<ArchiveTaskModalProps>({
                            ModalComponent: ArchiveTaskModal,
                            data: { taskId: task.id },
                        })
                    }
                />
            </Tooltip>
        );
    };

    const renderTaskPublishFlowButton = () => {
        const isRoot = permissions.has(TaskPublishPermission.ROOT);

        // Ensure enabled or has root permissions
        if (!(permissions.has(TaskPublishPermission._ENABLED) || isRoot)) {
            return null;
        }

        // Ensure has permission or root
        if (!(permissions.has(TaskPublishPermission.CREATE) || isRoot)) {
            return null;
        }

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="send-to-graph"
                onClick={() =>
                    openModal<PublishTaskModalProps>({
                        ModalComponent: PublishTaskModal,
                        data: { taskId: task.id },
                    })
                }
            >
                Publish request
            </Button>
        );
    };

    const information = taskStateInformation[TaskState.DRAFT];

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Circle size="22px" className="mr-1" color={information.color} />
                    <Heading type="h4">{information.title}</Heading>
                </Flex>

                <Flex direction="row">
                    <div className="mr-1">{renderTaskPublishFlowButton()}</div>
                    <div>{renderRemoveTaskButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Task is not yet published</DevText>
        </Card>
    );
};

export default Draft;
