import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import {
    initialPlatformCoreUserFilterData,
    platformCoreUserFiltersInformation,
} from '@app/containers/pages/CoreUsers/consts';
import { PlatformCoreUserFilterType } from '@app/containers/pages/CoreUsers/enums';
import { setPlatformCoreUserFilterData } from '@app/containers/pages/CoreUsers/store/events';
import { PlatformCoreUserFilterData } from '@app/containers/pages/CoreUsers/types';
import { Radio, Position } from '@blueprintjs/core';
import { $isQueryHydrationFinished } from '@app/containers/pages/CoreUsers/store/states';

export interface CoreUserVerifiedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformCoreUserFilterData: PlatformCoreUserFilterData;
    onRemove: () => void;
}

export type Props = CoreUserVerifiedPlatformFilterProps;

const CoreUserVerifiedPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformCoreUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isOnlyVerifiedVisible, setIsOnlyVerifiedVisible] = useState<NonNullable<
        PlatformCoreUserFilterData['isVerified']
    > | null>(platformCoreUserFilterData.isVerified);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsOnlyVerifiedVisible(platformCoreUserFilterData.isVerified);
        }
        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformCoreUserFilterData.isVerified === null) {
            setIsOnlyVerifiedVisible(null);
        }
    }, [platformCoreUserFilterData.isVerified]);

    const getFilterTagLabel = () => {
        if (isOnlyVerifiedVisible === null) {
            return '';
        }

        if (isOnlyVerifiedVisible) {
            return 'Only verified accounts';
        } else {
            return 'Only unverified accounts';
        }
    };

    const getFilterTagTitle = () => {
        if (isOnlyVerifiedVisible === null) {
            return 'Verified';
        }

        return 'Verified:';
    };

    const handleRemoveFilter = () => {
        setIsOnlyVerifiedVisible(null);
        setPlatformCoreUserFilterData({
            ...platformCoreUserFilterData,
            isVerified: initialPlatformCoreUserFilterData.isVerified,
        });
        setIsOpen(false);
        if (!platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_VERIFIED].isPinned) {
            // Platform core user verified filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformCoreUserFilterData.isVerified !== isOnlyVerifiedVisible) {
            setPlatformCoreUserFilterData({ ...platformCoreUserFilterData, isVerified: isOnlyVerifiedVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_VERIFIED].isPinned) {
            return;
        }

        if (isOnlyVerifiedVisible === null) {
            onRemove();
        }
    };

    const renderRow = (key: number) => {
        return (
            <li key={key}>
                <Radio
                    className="mb-1"
                    checked={isOnlyVerifiedVisible !== null && isOnlyVerifiedVisible}
                    label="Only verified accounts"
                    onChange={() => setIsOnlyVerifiedVisible(true)}
                />

                <Radio
                    checked={isOnlyVerifiedVisible !== null && !isOnlyVerifiedVisible}
                    label="Only unverified accounts"
                    onChange={() => setIsOnlyVerifiedVisible(false)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Verified"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_VERIFIED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default CoreUserVerifiedPlatformFilter;
