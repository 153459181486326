import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Card, Colors } from '@blueprintjs/core';
import React from 'react';
import {
    NotificationResource,
    SubscriptionResource,
    SubscriptionCanceledActivityPayloadResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints, subscriptionCancelReasonInformation, subscriptionCancelTypeInformation } from '@app/data/consts';
import moment from 'moment';
import NotificationTemplate from '../../../NotificationTemplate';

export interface SubscriptionCanceledNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    subscription: SubscriptionResource | null;
    dismiss: () => Promise<void>;
}

type Props = SubscriptionCanceledNotificationProps;

const SubscriptionCanceledNotification: React.FC<Props> = ({ notification, subscription, dismiss }) => {
    const renderContent = () => {
        if (!subscription) {
            // This is bad, subscription is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Subscription was not found</DevText>;
        }

        const payload: SubscriptionCanceledActivityPayloadResource | null = notification.activity.payload;
        if (!payload) {
            // This is bad, there should be payload, but for some reason it is not there
            return <DevText color={Colors.RED2}>Payload was not found</DevText>;
        }

        const renderCancelDateLabel = () => {
            if (payload.pending_cancel_at === null) {
                return (
                    <DevText className="mb-small">
                        <DevText inline muted>
                            Canceled at:
                        </DevText>{' '}
                        {moment(payload.canceled_at).format('D MMM YYYY, HH:mm')}
                    </DevText>
                );
            }

            return (
                <DevText className="mb-small">
                    <DevText inline muted>
                        Will be canceled at:
                    </DevText>{' '}
                    {moment(payload.pending_cancel_at).format('D MMM YYYY, HH:mm')}
                </DevText>
            );
        };

        const renderHeader = () => {
            if (payload.pending_cancel_at === null) {
                return (
                    <DevText running className="mb-1">
                        <RouterLink to={Endpoints.SUBSCRIPTION.replace(':subscriptionId', subscription.id)}>
                            Subscription
                        </RouterLink>{' '}
                        of{' '}
                        <RouterLink to={Endpoints.CLIENT_BILLING.replace(':clientId', subscription.user.id)}>
                            {subscription.user.first_name} {subscription.user.last_name}
                        </RouterLink>{' '}
                        has been cancelled
                    </DevText>
                );
            }

            return (
                <DevText running className="mb-1">
                    <RouterLink to={Endpoints.SUBSCRIPTION.replace(':subscriptionId', subscription.id)}>
                        Subscription
                    </RouterLink>{' '}
                    of{' '}
                    <RouterLink to={Endpoints.CLIENT_BILLING.replace(':clientId', subscription.user.id)}>
                        {subscription.user.first_name} {subscription.user.last_name}
                    </RouterLink>{' '}
                    is scheduled to cancel
                </DevText>
            );
        };

        return (
            <div>
                {renderHeader()}

                <Card compact>
                    {renderCancelDateLabel()}

                    <DevText className="mb-small">
                        <DevText inline muted>
                            Type:
                        </DevText>{' '}
                        {subscriptionCancelTypeInformation[payload.type]}
                    </DevText>

                    <DevText className="mb-small">
                        <DevText inline muted>
                            Reason:
                        </DevText>{' '}
                        {subscriptionCancelReasonInformation[payload.reason]}
                    </DevText>

                    {payload.comment.trim().length > 0 && (
                        <DevText>
                            <DevText inline muted>
                                Comment:
                            </DevText>{' '}
                            {payload.comment.split('&&').map((field) => (
                                <DevText className="mb-small" key={field} style={{ wordBreak: 'break-all' }}>
                                    {field.trim()}
                                </DevText>
                            ))}
                        </DevText>
                    )}
                </Card>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="Subscription canceled"
            icon="cross-circle"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default SubscriptionCanceledNotification;
