import React, { HTMLAttributes } from 'react';
import { Colors, Icon, IconName } from '@blueprintjs/core';
import DevText from '@components/Text';

export interface CommonInformationBlockProps {
    title: string;
    text: string | number | React.ReactNode;
    icon?: IconName;
}

export type Props = CommonInformationBlockProps & HTMLAttributes<HTMLDivElement>;

const CommonInformationBlock: React.FC<Props> = ({ title, text, icon, ...props }) => {
    let textContent = <DevText>{text}</DevText>;

    if (!text) {
        // TODO: fontWeight={600}
        textContent = <DevText muted>No data</DevText>;
    }

    return (
        <div {...props}>
            <DevText muted className="mb-small">
                {icon && <Icon className="mr-1" icon={icon} size={16} color={Colors.GRAY2} />}
                {title}
            </DevText>
            {textContent}
        </div>
    );
};

export default CommonInformationBlock;
