import { SortDirection } from 'dy-frontend-http-repository/lib/data/enums';
import { CoreUserSortField } from '../enums';

const coreUserSortItemsInformation: { [key in string]: string } = {
    [`${CoreUserSortField.LATEST_ACTIVITY_AT}${SortDirection.DESC}`]: 'Latest activity - recent first',
    [`${CoreUserSortField.LATEST_ACTIVITY_AT}${SortDirection.ASC}`]: 'Latest activity - oldest first',
    [`${CoreUserSortField.NAME}${SortDirection.ASC}`]: 'Name - A-Z',
    [`${CoreUserSortField.NAME}${SortDirection.DESC}`]: 'Name - Z-A',
    [`${CoreUserSortField.VERIFIED_AT}${SortDirection.DESC}`]: 'Verification date - recent first',
    [`${CoreUserSortField.VERIFIED_AT}${SortDirection.ASC}`]: 'Verification date - oldest first',
};

export default coreUserSortItemsInformation;
