import { IconName } from '@blueprintjs/core';
import { PlatformClientUserFilterType } from '../enums';

const platformClientUserFiltersInformation: {
    [type in PlatformClientUserFilterType]: {
        title: string;
        isPinned: boolean;
        icon: IconName;
    };
} = {
    [PlatformClientUserFilterType.CLIENT_PLANS]: {
        title: 'Plan',
        icon: 'properties',
        isPinned: true,
    },

    [PlatformClientUserFilterType.CLIENT_VERIFIED]: {
        title: 'Verified',
        icon: 'endorsed',
        isPinned: true,
    },

    [PlatformClientUserFilterType.CLIENT_ARCHIVED]: {
        title: 'Archived',
        icon: 'remove',
        isPinned: true,
    },
};

export default platformClientUserFiltersInformation;
