import { Button, Intent, Popover, PopoverProps } from '@blueprintjs/core';
import Flex from '@components/Flex';
import React, { ReactNode } from 'react';
import { Content, List } from './styled';
import DevText from '@components/Text';

export interface PlatformListFilterProps<T> {
    loading?: boolean;
    removable?: boolean;
    title: string;
    maxListHeight?: number;
    minWidth?: number;
    skeletonList?: number[];
    list: T[];
    itemRenderer: (item: T, index: number) => JSX.Element;
    skeletonItemRenderer?: (id: number) => JSX.Element;
    onApply: () => void;
    onRemove: () => void;
}

export type Props<T> = PlatformListFilterProps<T> & Omit<PopoverProps, 'content'> & { children: ReactNode };

function PlatformListFilter<T>({
    loading = false,
    removable = true,
    title,
    minWidth,
    maxListHeight,
    skeletonList = [],
    list,
    itemRenderer,
    skeletonItemRenderer,
    onRemove,
    onApply,
    onClose,

    children,
    ...props
}: Props<T>) {
    const handleClosePopover = (e: React.SyntheticEvent<HTMLElement, Event>) => {
        onApply();
        onClose && onClose(e);
    };

    const renderList = () => {
        if (loading) {
            return (
                <List className="custom-thin-scroll" $maxListHeight={maxListHeight}>
                    {skeletonList.map((id) => skeletonItemRenderer?.(id))}
                </List>
            );
        } else {
            return (
                <List className="custom-thin-scroll" $maxListHeight={maxListHeight}>
                    {list.map((item, index) => itemRenderer(item, index))}
                </List>
            );
        }
    };

    const renderPopoverContent = () => {
        const buttonVisibility = removable ? 'visible' : 'hidden';

        return (
            <Content $minWidth={minWidth}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <DevText muted>{title}</DevText>

                    <Button
                        minimal
                        icon="trash"
                        intent={Intent.DANGER}
                        style={{ visibility: buttonVisibility }}
                        onClick={onRemove}
                    />
                </Flex>

                {renderList()}
            </Content>
        );
    };

    return (
        <Popover enforceFocus={false} content={renderPopoverContent()} onClose={handleClosePopover} {...props}>
            {children}
        </Popover>
    );
}

export default PlatformListFilter;
