import Grid from '@app/components/Grid';
import InputFormField from '@app/components/InputFormField';
import TextAreaFormField from '@app/components/TextAreaFormField';
import { planSubscriptionSetupModeInformation } from '@app/data/consts';
import { useColorPickerFormField, useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import { getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Button, Card, Checkbox, Divider, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { PlanSubscriptionSetupMode } from 'dy-frontend-http-repository/lib/data/enums';
import React, { useState } from 'react';
import { updatePlan } from '../../store/effects';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import SelectFormField from '@app/components/SelectFormField';
import { UpdatePlanInformationInput } from 'dy-frontend-http-repository/lib/modules/Plan/inputs';
import { planApi } from '../../store/apis';
import ColorTilePickerFormField from '@app/components/ColorTilePickerFormField';

export interface UpdatePlanModalProps {
    plan: PlanResource;
}

type Props = ModalProps<UpdatePlanModalProps>;

const planTitleValidators = [getStringRequiredValidator()];
const planSummaryValidators = [getStringRequiredValidator()];
const planColorValidators = [getStringRequiredValidator()];
const planDescriptionFeaturesValidators = [getStringRequiredValidator()];
const planSubscriptionSetupModeValidators = [getStringRequiredValidator()];

const UpdatePlanModal: React.FC<Props> = ({ closeModal, data }) => {
    const planColor = useColorPickerFormField({
        id: 'color',
        validators: planColorValidators,
        initialValue: data?.plan.color ?? '',
    });

    const planTitle = useTextFormField({
        id: 'plan-title',
        validators: planTitleValidators,
        initialValue: data?.plan.title ?? '',
    });

    const planSummary = useTextFormField({
        id: 'plan-summary',
        validators: planSummaryValidators,
        initialValue: data?.plan.summary ?? '',
    });

    const planDescriptionFeatures = useTextFormField({
        id: 'plan-summary',
        validators: planDescriptionFeaturesValidators,
        initialValue: data?.plan.description_feature_list ? data.plan.description_feature_list.join('\n') : '',
    });

    const planSubscriptionSetupMode = useCustomSelectFormField<string | null>({
        id: 'plan-subscription-setup-mode',
        validators: planSubscriptionSetupModeValidators,
        initialValue: data?.plan.subscription_setup_mode ?? null,
        formatValue: (value) => value ?? '',
    });

    const [isPlanLegacy, setIsPlanLegacy] = useState(data ? data.plan.is_legacy : false);

    const form = useForm({
        fields: [planColor, planTitle, planSummary, planSubscriptionSetupMode],

        apiCall: async () => {
            if (!data) {
                throw new Error('Modal data.plan information was not passed to modal');
            }

            try {
                // Create input
                const updatePlanInput: UpdatePlanInformationInput = {
                    title: planTitle.value,
                    summary: planSummary.value,
                    color: planColor.value,
                    is_legacy: isPlanLegacy,
                    subscription_setup_mode: planSubscriptionSetupMode.value,
                    description_feature_list: planDescriptionFeatures.value
                        .split('\n')
                        .filter((feature) => feature.trim().length > 0),
                };

                // Update plan
                await updatePlan({
                    planId: data.plan.id,
                    input: updatePlanInput,
                });

                // Update plan state
                planApi.update({ input: updatePlanInput });

                // Close modal
                closeModal?.();

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update Plan</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container>
                        <Grid lg={9}>
                            {/* Title */}
                            <InputFormField
                                field={planTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter title' }}
                            />
                        </Grid>

                        <Grid lg={3}>
                            {/* Color */}
                            <ColorTilePickerFormField field={planColor} formGroupProps={{ label: 'Color' }} />
                        </Grid>

                        <Grid lg={12}>
                            {/* Summary */}
                            <TextAreaFormField
                                field={planSummary}
                                formGroupProps={{ label: 'Summary' }}
                                textAreaProps={{ placeholder: 'Enter summary', rows: 3 }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            {/* Plan subscription setup mode type */}
                            <SelectFormField
                                field={planSubscriptionSetupMode}
                                items={Object.values(PlanSubscriptionSetupMode)}
                                formGroupProps={{ label: 'Subscription setup mode' }}
                                selectProps={{ selectButtonProps: { placeholder: 'Select setup mode' } }}
                                itemToReadableFormatter={(item) => planSubscriptionSetupModeInformation[item]}
                            />
                        </Grid>

                        <Grid lg={12}>
                            {/* Features */}
                            <TextAreaFormField
                                field={planDescriptionFeatures}
                                formGroupProps={{
                                    label: 'Features',
                                    helperText:
                                        'Each feature should go to new line. IMPORTANT: Those features will be visible to the client.',
                                }}
                                textAreaProps={{ placeholder: 'Enter comment', rows: 7 }}
                            />
                        </Grid>

                        <Grid lg={6}>
                            {/* Legacy flag */}
                            <Checkbox
                                checked={isPlanLegacy}
                                label="Is legacy"
                                onChange={() => setIsPlanLegacy((prevValue) => !prevValue)}
                            />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Update
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdatePlanModal;
