import moment from 'moment';
import { createApi } from 'effector';
import { TeamParticipationRole } from 'dy-frontend-http-repository/lib/data/enums';
import { PickableTeamParticipantUserResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { TeamParticipationResource } from 'dy-frontend-http-repository/lib/modules/Team/resources';
import { $team } from './states';

// API to manage team state locally
export const teamApi = createApi($team, {
    // Updates team information
    updateTeamInformation: (
        store,
        payload: {
            title: string;
            summary: string;
            color: string;
            country: string;
            timezone_name: string;
            working_hours_start: string;
            working_hours_end: string;
        }
    ) => {
        // Skip if team not loaded
        if (!store) {
            return store;
        }

        return {
            ...store,
            title: payload.title,
            summary: payload.summary,
            color: payload.color,
            country: payload.country,
            timezone_name: payload.timezone_name,
            working_hours_start: payload.working_hours_start,
            working_hours_end: payload.working_hours_end,
        };
    },

    // Marks team as archived
    archiveTeam: (store, payload: { archivedAt: string }) => {
        // Skip if team not loaded
        if (!store) {
            return store;
        }

        return {
            ...store,
            archived_at: payload.archivedAt,
        };
    },

    // Removes archive status from team
    restoreTeam: (store) => {
        // Skip if team not loaded
        if (!store) {
            return store;
        }

        return {
            ...store,
            archived_at: null,
        };
    },

    // Removes participation by user ID
    removeParticipationByUserId: (
        store,
        payload: {
            userId: ID;
        }
    ) => {
        // Skip if team not loaded
        if (!store) {
            return store;
        }

        return {
            ...store,
            participants: store.participants.filter((x) => x.user.id !== payload.userId),
        };
    },

    // Update participation by user ID
    updateParticipationByUserId: (
        store,
        payload: {
            userId: ID;
            role: TeamParticipationRole;
        }
    ) => {
        // Skip if team not loaded
        if (!store) {
            return store;
        }

        return {
            ...store,
            participants: store.participants.map((x) => {
                if (x.user.id !== payload.userId) {
                    return x;
                }

                return {
                    ...x,
                    role: payload.role,
                };
            }),
        };
    },

    // Add new participation
    addParticipation: (store, payload: { pickableTeamParticipantUsers: PickableTeamParticipantUserResource[] }) => {
        // Skip if team not loaded
        if (!store) {
            return store;
        }

        const momentNowUtc = moment().utc().format();
        const newParticipation: TeamParticipationResource[] = payload.pickableTeamParticipantUsers.map(
            (pickableParticipantUser) => ({
                role: TeamParticipationRole.MEMBER,
                assigned_at: momentNowUtc,
                user: {
                    id: pickableParticipantUser.id,
                    first_name: pickableParticipantUser.first_name,
                    last_name: pickableParticipantUser.last_name,
                    image_hash: pickableParticipantUser.image_hash,
                    company_position: pickableParticipantUser.company_position,
                    role: pickableParticipantUser.role,
                    metadata: {
                        ...pickableParticipantUser.metadata,
                    },
                },
            })
        );

        return {
            ...store,
            participants: [...store.participants, ...newParticipation],
        };
    },
});
