import React from 'react';
import { ButtonProps } from '@blueprintjs/core';
import { SelectProps as BlueprintSelectProps } from '@blueprintjs/select';
import { SelectButton, StyledSelect } from './styled';
import Flex from '../Flex';
import DevText from '@components/Text';

export interface SelectProps {
    selectButtonProps: Pick<ButtonProps, 'text' | 'icon' | 'rightIcon' | 'fill' | 'style'> & {
        placeholder?: string;
        label?: string | null;
    };
}

export type Props<T> = BlueprintSelectProps<T> & SelectProps;

function Select<T>({
    selectButtonProps: { placeholder, text, label, ...selectButtonProps },
    popoverProps,
    disabled,
    ...props
}: Props<T>) {
    const extendedPopoverProps: BlueprintSelectProps<T>['popoverProps'] = {
        matchTargetWidth: true,
        className: 'custom-thin-scroll',
        ...popoverProps,
    };

    const extendedMenuProps: BlueprintSelectProps<T>['menuProps'] = {
        className: 'custom-thin-scroll',
    };

    if (popoverProps) {
        // Additional popover props were passed
        if (popoverProps.popoverClassName) {
            // Additional popover props contain class name property

            extendedPopoverProps['popoverClassName'] = `custom-thin-scroll ${popoverProps.className}`;
        }
    }

    const renderSelectButton = () => {
        if (placeholder && !text) {
            return (
                <SelectButton disabled={disabled} alignText="left" {...selectButtonProps}>
                    <DevText muted>{placeholder}</DevText>
                </SelectButton>
            );
        }

        return (
            <SelectButton disabled={disabled} alignText="left" {...selectButtonProps}>
                <Flex align="center" justify="space-between">
                    <DevText inline>{text}</DevText>

                    {label && (
                        <DevText inline muted>
                            {label}
                        </DevText>
                    )}
                </Flex>
            </SelectButton>
        );
    };

    return (
        /* @ts-ignore */
        <StyledSelect<T>
            disabled={disabled}
            filterable={false}
            popoverProps={extendedPopoverProps}
            menuProps={extendedMenuProps}
            {...props}
        >
            {renderSelectButton()}
        </StyledSelect>
    );
}

export default Select;
