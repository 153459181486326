import React, { useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { usePageTitle } from '@app/hooks';
import { taskStateColumns } from './consts';
import { $taskKanbanBoard, $taskKanbanBoardFormatted } from './store/states';
import { fetchTaskKanbanBoard } from './store/effects';
import { taskKanbanBoardFormattedApi } from './store/apis';
import PageContainer from '@components/PageContainer';
import Flex from '@components/Flex';
import NonIdealState from '@components/NonIdealState';
import TaskKanbanColumn from './components/TaskKanbanColumn';
import { ScrollContainerWrapper, StyledScrollContainer } from './styled';
import { Button, ButtonGroup, Colors, Divider, Icon, Spinner } from '@blueprintjs/core';
import Heading from '@components/Heading';
import { TaskQueue, UserRole } from 'dy-frontend-http-repository/lib/data/enums';
import { taskStateInformation } from '@app/data/consts';
import { TaskKanbanFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskKanban/inputs';
import { $authorizedUser } from '@app/containers/store/states';
import { TaskKanbanFilterType } from './enums';
import { LocalStorage, SessionStorage } from '@app/data/enums';
import DevText from '@app/components/Text';
import useTimeout from '@app/hooks/useTimeout';

const myTeamRoles = [UserRole.CORE_PROJECT_MANAGER, UserRole.CORE_ART_DIRECTOR, UserRole.CORE_ASSISTANT_ART_DIRECTOR];

const TaskKanbanBoard: React.FC = () => {
    // TODO: check that all pages use usePageTitle hook
    usePageTitle('Kanban Board');
    const { isTimeoutPresent, handleStartTimeout } = useTimeout({
        timeoutKey: LocalStorage.REFRESH_TASK_KANBAN_BOARD,
        timeoutSeconds: 10,
        labelUpdateTimeoutSeconds: 1,
    });

    const me = useStore($authorizedUser);
    const taskKanbanBoard = useStore($taskKanbanBoard);
    const taskKanbanBoardFormatted = useStore($taskKanbanBoardFormatted);
    const isTaskKanbanBoardLoading = useStore(fetchTaskKanbanBoard.pending);

    const [filterInput, setFilterInput] = useState<TaskKanbanFilterInput>({});

    const handleRefetchTaskKanban = async () => {
        try {
            const taskKanbanBoard = await fetchTaskKanbanBoard(filterInput);
            taskKanbanBoardFormattedApi.initialize({ taskKanbanBoard });
            handleStartTimeout();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };
    const handleFetchTaskKanban = async (filter: TaskKanbanFilterInput) => {
        try {
            const taskKanbanBoard = await fetchTaskKanbanBoard(filter);
            taskKanbanBoardFormattedApi.initialize({ taskKanbanBoard });

            setFilterInput(filter);
            let filterType: TaskKanbanFilterType = TaskKanbanFilterType.ALL;
            if (filter.team_id !== undefined && filter.team_id.length > 0) {
                filterType = TaskKanbanFilterType.MY_TEAMS;
            } else if (filter.participant_user_id !== undefined) {
                filterType = TaskKanbanFilterType.MY_TASKS;
            }

            sessionStorage.setItem(SessionStorage.TASK_KANBAN_FILTER, filterType);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    useEffect(() => {
        if (taskKanbanBoard) {
            // Get last applied filter type
            const lastFilterType: TaskKanbanFilterType | null = sessionStorage.getItem(
                SessionStorage.TASK_KANBAN_FILTER
            ) as TaskKanbanFilterType | null;

            if (lastFilterType && me) {
                const lastFilterInput: TaskKanbanFilterInput = {};
                switch (lastFilterType) {
                    case TaskKanbanFilterType.MY_TASKS:
                        lastFilterInput.participant_user_id = me.user.id;
                        break;
                    case TaskKanbanFilterType.MY_TEAMS:
                        lastFilterInput.team_id = me.team.map((t) => t.id);
                }

                setFilterInput(lastFilterInput);
            }
        } else {
            // Initialize input
            const input: TaskKanbanFilterInput = {};
            const isMyTeamFilterAvailable = me && myTeamRoles.includes(me.user.role) && me.team.length > 0;
            if (isMyTeamFilterAvailable) {
                input.team_id = me?.team.map((t) => t.id);
            }

            setFilterInput(input);
            handleFetchTaskKanban(input);
        }
    }, []);

    if (!me) {
        return null;
    }

    const handleSelectAllFilter = () => {
        handleFetchTaskKanban({});
    };

    const handleSelectMyTaskFilter = () => {
        handleFetchTaskKanban({ participant_user_id: me.user.id });
    };

    const handleSelectMyTeamFilter = () => {
        if (me.team.length === 0) {
            return;
        }

        handleFetchTaskKanban({ team_id: me.team.map((t) => t.id) });
    };

    const renderKanbanFilter = () => {
        if (me?.user.role === UserRole.CORE_DESIGNER) {
            // Do not show filters for designer
            return null;
        }

        const isAllFilterActive =
            (filterInput.team_id === undefined || filterInput.team_id.length === 0) &&
            filterInput.participant_user_id === undefined;
        const isMyTasksFilterActive =
            filterInput.participant_user_id !== undefined &&
            (filterInput.team_id === undefined || filterInput.team_id.length === 0);
        const isMyTeamFilterActive =
            me.team.length > 0 && filterInput.team_id !== undefined && filterInput.team_id.length > 0;

        const isMyTeamFilterAvailable = me && myTeamRoles.includes(me.user.role) && me.team.length > 0;

        return (
            <Flex className="mb-3" direction="row" align="center" style={{ padding: '4px' }}>
                <DevText muted className="mr-1">
                    Show
                </DevText>

                <ButtonGroup>
                    <Button
                        disabled={isTaskKanbanBoardLoading}
                        active={isAllFilterActive}
                        onClick={handleSelectAllFilter}
                    >
                        All
                    </Button>
                    <Button
                        disabled={isTaskKanbanBoardLoading}
                        active={isMyTasksFilterActive}
                        onClick={handleSelectMyTaskFilter}
                    >
                        Assigned to me
                    </Button>
                    {isMyTeamFilterAvailable && (
                        <Button
                            disabled={isTaskKanbanBoardLoading}
                            active={isMyTeamFilterActive}
                            onClick={handleSelectMyTeamFilter}
                        >
                            Assigned to my team
                        </Button>
                    )}
                </ButtonGroup>
            </Flex>
        );
    };

    const renderKanbanBoard = () => {
        // Task kanban board was NOT fetched yet
        if (!taskKanbanBoard || !taskKanbanBoardFormatted) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Task kanban board was fetched, but where are no tasks there
        if (taskKanbanBoard.tasks.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading className="mb-1" type="h4">
                            No requests were found
                        </Heading>
                    }
                />
            );
        }

        const renderPausedTasksColumn = () => {
            return (
                <TaskKanbanColumn
                    title="Paused"
                    icon={<Icon className="mr-1" icon="pause" />}
                    color={Colors.ORANGE3}
                    taskKanbanBoardFormatted={taskKanbanBoardFormatted}
                    tasks={Object.values(taskKanbanBoardFormatted.tasks.paused)}
                />
            );
        };

        const renderBacklogTasksColumn = () => {
            return (
                <TaskKanbanColumn
                    title="Backlog"
                    icon={<Icon className="mr-1" icon="remove-row-bottom" />}
                    color={Colors.BLACK}
                    taskKanbanBoardFormatted={taskKanbanBoardFormatted}
                    tasks={Object.values(taskKanbanBoardFormatted.tasks[TaskQueue.BACKLOG])}
                />
            );
        };

        const renderTaskStateColumns = () => {
            return taskStateColumns.map((taskState) => {
                const { title, color } = taskStateInformation[taskState];

                return (
                    <TaskKanbanColumn
                        key={taskState}
                        title={title}
                        color={color}
                        taskKanbanBoardFormatted={taskKanbanBoardFormatted}
                        tasks={Object.values(taskKanbanBoardFormatted.tasks[taskState])}
                    />
                );
            });
        };

        const renderTaskStateColumnDivider = () => {
            return (
                <Flex fullHeight className="ml-3 mr-4" direction="row" align="stretch">
                    <Divider />
                </Flex>
            );
        };

        return (
            <ScrollContainerWrapper style={{ opacity: isTaskKanbanBoardLoading ? 0.5 : 1 }}>
                <StyledScrollContainer hideScrollbars={false} vertical={false} className="custom-thin-scroll">
                    {renderPausedTasksColumn()}
                    {renderBacklogTasksColumn()}
                    {renderTaskStateColumnDivider()}
                    {renderTaskStateColumns()}
                </StyledScrollContainer>
            </ScrollContainerWrapper>
        );
    };

    return (
        <PageContainer className="custom-thin-scroll">
            <Flex className="mb-2" direction="row">
                <Heading type="h3">Kanban board</Heading>
                <Button
                    minimal
                    loading={isTaskKanbanBoardLoading}
                    disabled={!taskKanbanBoard || isTaskKanbanBoardLoading || isTimeoutPresent}
                    className="ml-small"
                    icon="refresh"
                    onClick={handleRefetchTaskKanban}
                />
            </Flex>
            {renderKanbanFilter()}
            {renderKanbanBoard()}
        </PageContainer>
    );
};

export default TaskKanbanBoard;
