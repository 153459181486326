import React from 'react';
import { Card, Colors } from '@blueprintjs/core';
import DevText from '@app/components/Text';
import RouterLink from '@app/components/RouterLink';
import { Endpoints } from '@app/data/consts';
import {
    NotificationResource,
    TaskDeliverableCommentResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import RichText from '@app/components/RichText';
import { UserUtils } from '@containers/components/Notifications/utils';
import NotificationTemplate from '../../../NotificationTemplate';

export interface TaskDeliverableCommentCreatedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    taskDeliverableComment: TaskDeliverableCommentResource | null;
    dismiss: () => Promise<void>;
}

export type Props = TaskDeliverableCommentCreatedNotificationProps;

const TaskDeliverableCommentCreatedNotification: React.FC<Props> = ({
    authorizedUserId,
    notification,
    taskDeliverableComment,
    dismiss,
}) => {
    const renderContent = () => {
        if (!taskDeliverableComment) {
            // This is bad, task deliverable comment is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Comment was not found</DevText>;
        }

        const getTaskDeliverablesUrl = () => {
            return `${Endpoints.TASK_DELIVERABLES.replace(
                ':taskId',
                taskDeliverableComment.task_deliverable.task.id
            )}?path=${taskDeliverableComment.task_deliverable.path}&taskDeliverableId=${
                taskDeliverableComment.task_deliverable.id
            }`;
        };

        return (
            <div>
                <DevText running className="mb-1">
                    {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)} added deliverable
                    comment for{' '}
                    <RouterLink style={{ display: 'inline' }} to={getTaskDeliverablesUrl()}>
                        {taskDeliverableComment.task_deliverable.task.title}
                    </RouterLink>
                </DevText>

                <Card compact>
                    <RichText value={taskDeliverableComment.content} />
                </Card>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="New deliverable comment"
            icon="issue-new"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default TaskDeliverableCommentCreatedNotification;
