import { Card, Colors } from '@blueprintjs/core';
import styled, { css } from 'styled-components';
import { TaskMessageProps } from '.';

export const StyledCard = styled(Card)<{
    $alignment: TaskMessageProps['alignment'];
    $isInternal: boolean;
}>`
    padding: 0;

    ${({ $isInternal }) =>
        $isInternal &&
        css`
            background: ${Colors.BLUE1} !important;
        `}

    ${({ $alignment }) => {
        switch ($alignment) {
            case 'left':
                return css`
                    margin-left: auto;
                    max-width: 90%;
                    border-radius: 18px 18px 0;
                `;
            case 'right':
                return css`
                    margin-right: auto;
                    max-width: 90%;
                    border-radius: 18px 18px 18px 0;
                `;
        }
    }}
`;

export const TaskMessageHeader = styled.div`
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;

    padding: 10px 16px;
    background: ${Colors.BLUE2};
`;

export const TaskMessageContent = styled.div<{ $headerVisible: boolean }>`
    padding: 12px 16px;

    ${({ $headerVisible }) =>
        $headerVisible
            ? css`
                  border-bottom-left-radius: inherit;
                  border-bottom-right-radius: inherit;
              `
            : css`
                  border-radius: inherit;
              `}
`;
