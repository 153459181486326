import PriceResolverInput, { PriceResolverInputValue } from '@app/containers/components/PriceResolverInput';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Checkbox, Divider, FormGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import TextAreaFormField from '@components/TextAreaFormField';
import { ModalProps } from '@modals/types';
import { PriceType } from 'dy-frontend-http-repository/lib/modules/Price/enums';
import {
    CreateTaskCategoryAddonInput,
    UpdateTaskCategoryAddonInput,
} from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/inputs';
import { TaskCategoryAddonRef } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/refs';
import { TaskCategoryAddonResource } from 'dy-frontend-http-repository/lib/modules/TaskCategoryAddon/resources';
import React, { useState } from 'react';
import { taskCategoryAddonsApi } from '../../store/apis';
import { createTaskCategoryAddon, updateTaskCategoryAddon } from '../../store/effects';

export interface UpsertTaskCategoryAddonModalProps {
    taskCategoryId: ID;
    taskCategoryAddon?: TaskCategoryAddonResource;
}

type Props = ModalProps<UpsertTaskCategoryAddonModalProps>;

const categoryAddonTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 6 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const categoryAddonSummaryValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 1 }),
    getStringMaxLengthValidator({ maxStringLength: 255 }),
];
const categoryAddonExternalPriceIdValidators = [];

const UpsertTaskCategoryAddonModal: React.FC<Props> = ({ closeModal, data }) => {
    const addonTitle = useTextFormField({
        id: 'addon-title',
        initialValue: data?.taskCategoryAddon?.title ?? '',
        validators: categoryAddonTitleValidators,
    });

    const addonSummary = useTextFormField({
        id: 'addon-summary',
        initialValue: data?.taskCategoryAddon?.summary ?? '',
        validators: categoryAddonSummaryValidators,
    });

    const [paymentPublishPrice, setPaymentPublishPrice] = useState<PriceResolverInputValue | null>(
        data?.taskCategoryAddon?.price
            ? {
                  id: data.taskCategoryAddon.price.id,
                  externalId: data.taskCategoryAddon.price.external_id,
                  amount: data.taskCategoryAddon.price.unit_amount,
                  currency: data.taskCategoryAddon.price.currency,
                  type: PriceType.ONE_TIME,
                  recurringInterval: null,
                  recurringIntervalCount: null,
                  planId: null,
                  planTitle: null,
              }
            : null
    );

    const [isTaskCategoryAddonEnabled, setIsTaskCategoryAddonEnabled] = useState(
        data?.taskCategoryAddon?.is_enabled ?? true
    );

    const [isTaskCategoryAddonPaymentFlexible, setIsTaskCategoryAddonPaymentFlexible] = useState(
        data?.taskCategoryAddon?.is_payment_flexible ?? false
    );

    const isTaskCategoryAddonCreated = data?.taskCategoryAddon;

    const handleCreateTaskCategoryAddon = async (taskCategoryId: ID) => {
        // TODO: we do not give priceResource to api, so after addon created price "Not specified"
        const createTaskCategoryAddonInput: CreateTaskCategoryAddonInput = {
            task_category_id: taskCategoryId,
            title: addonTitle.value,
            summary: addonSummary.value,
            is_enabled: isTaskCategoryAddonEnabled,
            is_payment_flexible: isTaskCategoryAddonPaymentFlexible,
            price_id: null,
        };

        if (!isTaskCategoryAddonPaymentFlexible) {
            // Task category addon payment is NOT flexible

            createTaskCategoryAddonInput.price_id = paymentPublishPrice ? paymentPublishPrice.id : null;
        }

        // Create task category addon
        try {
            const taskCategoryAddonRef = await createTaskCategoryAddon(createTaskCategoryAddonInput);
            taskCategoryAddonsApi.create({
                taskCategoryAddonId: taskCategoryAddonRef.id,
                input: createTaskCategoryAddonInput,
                price: paymentPublishPrice
                    ? {
                          id: paymentPublishPrice.id,
                          currency: paymentPublishPrice.currency,
                          external_id: paymentPublishPrice.externalId,
                          is_active: true,
                          unit_amount: paymentPublishPrice.amount,
                      }
                    : null,
            });

            return { response: taskCategoryAddonRef };
        } catch (e) {
            throw e;
        }
    };

    const handleUpdateTaskCategoryAddon = async (taskCategoryAddon: TaskCategoryAddonResource) => {
        const updateTaskCategoryAddonInput: UpdateTaskCategoryAddonInput = {
            title: addonTitle.value,
            summary: addonSummary.value,
            is_enabled: isTaskCategoryAddonEnabled,
            is_payment_flexible: isTaskCategoryAddonPaymentFlexible,
            price_id: taskCategoryAddon.price?.id ?? null,
        };

        if (isTaskCategoryAddonPaymentFlexible) {
            // Addon payment is flexible
            updateTaskCategoryAddonInput.price_id = null;
        } else {
            // Addon payment is NOT flexible
            updateTaskCategoryAddonInput.price_id = paymentPublishPrice ? paymentPublishPrice.id : null;
        }

        // Update task category addon
        try {
            const taskCategoryAddonRef = await updateTaskCategoryAddon({
                taskCategoryAddonId: taskCategoryAddon.id,
                input: updateTaskCategoryAddonInput,
            });

            taskCategoryAddonsApi.update({
                taskCategoryAddonId: taskCategoryAddon.id,
                input: updateTaskCategoryAddonInput,
                price: paymentPublishPrice
                    ? {
                          id: paymentPublishPrice.id,
                          currency: paymentPublishPrice.currency,
                          external_id: paymentPublishPrice.externalId,
                          is_active: true,
                          unit_amount: paymentPublishPrice.amount,
                      }
                    : null,
            });

            return { response: taskCategoryAddonRef };
        } catch (e) {
            throw e;
        }
    };

    const form = useForm<TaskCategoryAddonRef>({
        fields: [addonTitle, addonSummary],
        apiCall: async () => {
            try {
                if (data!.taskCategoryAddon) {
                    // Update task category addon
                    return await handleUpdateTaskCategoryAddon(data!.taskCategoryAddon);
                } else {
                    // Create task category addon
                    return await handleCreateTaskCategoryAddon(data!.taskCategoryId);
                }
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderEnableTaskCategoryInput = () => {
        return (
            <Grid lg={12}>
                {/* Is task category enabled */}
                <FormGroup>
                    <Checkbox
                        label="Is Addon Enabled"
                        checked={isTaskCategoryAddonEnabled}
                        onChange={(e) => {
                            setIsTaskCategoryAddonEnabled((prevValue) => !prevValue);
                        }}
                    />
                </FormGroup>
            </Grid>
        );
    };

    const renderTaskCategoryAddonPriceInputsBlock = () => {
        let externalPriceInput: JSX.Element | null = null;

        if (!isTaskCategoryAddonPaymentFlexible) {
            externalPriceInput = (
                <FormGroup fill label="Payment publish price">
                    <PriceResolverInput
                        value={paymentPublishPrice}
                        onChange={setPaymentPublishPrice}
                        onViolationListRequested={(resolved) => {
                            // Ensure one-time
                            if (resolved.type !== PriceType.ONE_TIME) {
                                return ['Must be one-time payment price'];
                            }

                            // TODO: Other violations
                            return [];
                        }}
                    />
                </FormGroup>
            );
        }

        return (
            <>
                <Grid lg={12}>
                    {/* Is task category enabled */}
                    <FormGroup>
                        <Checkbox
                            label="Is Payment Flexible"
                            checked={isTaskCategoryAddonPaymentFlexible}
                            onChange={(e) => {
                                setIsTaskCategoryAddonPaymentFlexible((prevValue) => !prevValue);
                            }}
                        />
                    </FormGroup>
                </Grid>

                {externalPriceInput}
            </>
        );
    };

    const submitButtonText = isTaskCategoryAddonCreated ? 'Update' : 'Create';
    const modalTitle = isTaskCategoryAddonCreated ? 'Update task category addon' : 'Create task category addon';

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">{modalTitle}</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container className="mb-2">
                        <Grid lg={12}>
                            {/* Title */}
                            <InputFormField
                                field={addonTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter title' }}
                            />
                        </Grid>

                        <Grid lg={12}>
                            {/* Summary */}
                            <TextAreaFormField
                                field={addonSummary}
                                formGroupProps={{ label: 'Summary' }}
                                textAreaProps={{ placeholder: 'Enter summary' }}
                            />
                        </Grid>

                        {/* Is task category enabled */}
                        {renderEnableTaskCategoryInput()}

                        {/* Is flexible checkbox & External price ID input */}
                        {renderTaskCategoryAddonPriceInputsBlock()}
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            {submitButtonText}
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpsertTaskCategoryAddonModal;
