import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 70px;
    min-height: 70px;
    padding: 8px 14px;
    background: ${Colors.DARK_GRAY2} !important;
`;
