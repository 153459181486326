import { Colors, Tooltip } from '@blueprintjs/core';
import React, { forwardRef, HTMLAttributes } from 'react';
import { MarkerPositioner, Marker } from './styled';

export interface PointMarkerProps {
    container: DOMRect | null;
    x: number;
    y: number;
    label?: string;
    content?: string;
    color?: string;
}

export type Props = HTMLAttributes<HTMLDivElement> & PointMarkerProps;

const PointMarker = forwardRef<HTMLDivElement, Props>(
    ({ container, content, x, y, label, color = Colors.BLUE3, ...props }, ref) => {
        if (!container) {
            return null;
        }

        const top = y * container.height;
        const left = x * container.width;
        const markerLabel = label ?? '';

        if (content) {
            return (
                <MarkerPositioner key={`${top}${left}`} $top={top} $left={left} ref={ref} {...props}>
                    <Tooltip content={content}>
                        <Marker $color={color}>{markerLabel}</Marker>
                    </Tooltip>
                </MarkerPositioner>
            );
        } else {
            return (
                <MarkerPositioner key={`${top}${left}`} $top={top} $left={left} ref={ref} {...props}>
                    <Marker $color={color}>{markerLabel}</Marker>
                </MarkerPositioner>
            );
        }
    }
);

export default PointMarker;
