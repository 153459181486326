import { SortDirection } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskSortField } from '../enums';

const taskSortItemsInformation: { [key in string]: string } = {
    [`${TaskSortField.DEADLINE_AT}${SortDirection.DESC}`]: 'Deadline - expiring first',
    [`${TaskSortField.DEADLINE_AT}${SortDirection.ASC}`]: 'Deadline - far first',
    [`${TaskSortField.LATEST_ACTIVITY_AT}${SortDirection.DESC}`]: 'Latest activity - newest first',
    [`${TaskSortField.LATEST_ACTIVITY_AT}${SortDirection.ASC}`]: 'Latest activity - recent first',
    [`${TaskSortField.PRIORITY_ELEVATION}${SortDirection.ASC}`]: 'Priority - regular first',
    [`${TaskSortField.PRIORITY_ELEVATION}${SortDirection.DESC}`]: 'Priority - high first',
    [`${TaskSortField.PUBLISH_AT}${SortDirection.DESC}`]: 'Publish date - newest first',
    [`${TaskSortField.PUBLISH_AT}${SortDirection.ASC}`]: 'Publish date - oldest first',
    [`${TaskSortField.STATE}${SortDirection.ASC}`]: 'Status - early stage first',
    [`${TaskSortField.STATE}${SortDirection.DESC}`]: 'Status - late stage first',
};

export default taskSortItemsInformation;
