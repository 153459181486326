import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task, $taskDeliverables, $taskDeliverablesTree } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Classes, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { useNavigate } from 'react-router-dom';
import { Endpoints, taskStateInformation } from '@app/data/consts';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import TaskDeliverablePresenceSanityCheckModal, {
    TaskDeliverablePresenceSanityCheckModalProps,
} from '@app/containers/pages/Task/modals/sanityCheck/TaskDeliverablePresenceSanityCheckModal';
import { TaskDeliverablesTreeUtils } from '@app/containers/pages/Task/valueObjects';
import { openModal } from '@app/containers/modals/store/events';
import UnapprovedTaskDeliverablePresenceSanityCheckModal, {
    UnapprovedTaskDeliverablePresenceSanityCheckModalProps,
} from '@app/containers/pages/Task/modals/sanityCheck/UnapprovedTaskDeliverablePresenceSanityCheckModal';
import { approveTaskDeliverablesBatch } from '@app/containers/pages/Task/store/effects';
import { taskDeliverablesTreeApi } from '@app/containers/pages/Task/store/apis';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import Circle from '@components/Circle';

export interface InRevisionProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & InRevisionProps;

const InRevision: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { handleTransitionTaskState } = useTaskStateTransition();

    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);
    const [isInternalQaAllowed, setIsInternalQaAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsInternalQaAllowed(permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_INTERNAL_QA));
    }, [task, permissions, me]);

    if (!me || !task || !taskDeliverablesTree) {
        return null;
    }

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    const handleApproveTaskDeliverablesAndTransitionToState = async (
        taskDeliverablesIds: ID[],
        transitionToState: TaskState
    ) => {
        try {
            await approveTaskDeliverablesBatch({
                task_deliverable_ids: taskDeliverablesIds,
            });
            taskDeliverablesTreeApi.approveTaskDeliverables({ taskDeliverablesIds });
            handleTransitionToState(transitionToState);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderInternalQaTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isInternalQaAllowed) {
            return null;
        }

        const handleInternalQaTransition = () => {
            const transitionToState = TaskState.INTERNAL_QA;

            // Get array of deliverables
            const deliverables = TaskDeliverablesTreeUtils.getTaskDeliverablesArray({
                deliverablesMap: taskDeliverablesTree.deliverables,
            });

            if (deliverables.length === 0) {
                // No deliverables
                openModal<TaskDeliverablePresenceSanityCheckModalProps>({
                    ModalComponent: TaskDeliverablePresenceSanityCheckModal,
                    data: {
                        transitionState: () => handleTransitionToState(transitionToState),
                        transitionToState: transitionToState,
                    },
                });
            } else {
                handleTransitionToState(transitionToState);
            }
        };

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="geosearch"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.INTERNAL_QA}
                onClick={handleInternalQaTransition}
            >
                Ready for QA
            </Button>
        );
    };

    const renderOpenTaskDeliverables = () => {
        // TODO: task state actions / helper actions permissions

        return (
            <Button
                icon="unarchive"
                disabled={transitioningTo !== null}
                onClick={() => navigate(Endpoints.TASK_DELIVERABLES_ALL.replace(':taskId', task.id))}
            >
                Provide deliverables
            </Button>
        );
    };

    const renderClientReviewTransitionButton = () => {
        // TODO: task state actions / helper actions permissions

        const handleClientReviewTransition = () => {
            const transitionToState = TaskState.CLIENT_REVIEW;

            // Get array of deliverables
            const deliverables = TaskDeliverablesTreeUtils.getTaskDeliverablesArray({
                deliverablesMap: taskDeliverablesTree.deliverables,
            });

            if (deliverables.length === 0) {
                // No deliverables
                openModal<TaskDeliverablePresenceSanityCheckModalProps>({
                    ModalComponent: TaskDeliverablePresenceSanityCheckModal,
                    data: {
                        transitionState: () => handleTransitionToState(transitionToState),
                        transitionToState: transitionToState,
                    },
                });
            } else {
                const unapprovedDeliverableIds: ID[] = [];
                deliverables.forEach((deliverable) => {
                    if (deliverable.approved_at === null) {
                        // Not approved
                        unapprovedDeliverableIds.push(deliverable.id);
                    }
                });

                if (unapprovedDeliverableIds.length > 0) {
                    // There are unapproved deliverables
                    openModal<UnapprovedTaskDeliverablePresenceSanityCheckModalProps>({
                        ModalComponent: UnapprovedTaskDeliverablePresenceSanityCheckModal,
                        data: {
                            approveTaskDeliverablesAndTransition: () =>
                                handleApproveTaskDeliverablesAndTransitionToState(
                                    unapprovedDeliverableIds,
                                    transitionToState
                                ),
                            unapprovedDeliverablesCount: unapprovedDeliverableIds.length,
                        },
                    });
                } else {
                    // All deliverables approved
                    handleTransitionToState(transitionToState);
                }
            }
        };

        return (
            <ConfirmationPopover
                title="Are you sure you want to skip the QA phase?"
                description="I am aware that by skipping the QA phase the manager won't get to see the assets and I will take full responsibility for the quality of the delivered assets"
                actions={[
                    <Button
                        intent={Intent.PRIMARY}
                        className={Classes.POPOVER_DISMISS}
                        onClick={handleClientReviewTransition}
                    >
                        Yes, deliver to client
                    </Button>,
                ]}
            >
                <Button disabled={transitioningTo !== null} loading={transitioningTo === TaskState.CLIENT_REVIEW}>
                    Deliver to client
                </Button>
            </ConfirmationPopover>
        );
    };

    const information = taskStateInformation[TaskState.IN_REVISION];

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Circle size="22px" className="mr-1" color={information.color} />
                    <Heading type="h4">{information.title}</Heading>
                </Flex>

                <Flex>
                    <div className="mr-1">{renderInternalQaTransitionButton()}</div>
                    <div>{renderClientReviewTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Work on another revision is in progress</DevText>

            {/* Helper actions */}
            <Flex className="mt-2" direction="row">
                <div>{renderOpenTaskDeliverables()}</div>
            </Flex>
        </Card>
    );
};

export default InRevision;
