import React, { HTMLAttributes, useEffect, useState } from 'react';
import moment from 'moment';
import { useStore } from 'effector-react';
import { openModal } from '@modals/store/events';
import { $task } from '@pages/Task/store/states';
import DeadlineTag from '@containers/components/TaskDeadlineTag';
import Flex from '@components/Flex';
import CommonInformationBlock from '@components/CommonInformationBlock';
import { Button, Card } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import SetTaskDeadlineModal, {
    SetTaskDeadlineModalProps,
} from '@app/containers/pages/Task/modals/SetTaskDeadlineModal';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { TimezoneUtils } from 'dy-frontend-shared/lib/utils';

export type Props = HTMLAttributes<HTMLDivElement>;

const DatesInformation: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);

    const task = useStore($task);

    const [isDeadlineManageAllowed, setIsDeadlineManageAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // Root permission set
        if (permissions.isRoot.task) {
            setIsDeadlineManageAllowed(true);
            return;
        }

        // Base permission
        if (permissions.has(TaskPermission.DEADLINE_MANAGE)) {
            setIsDeadlineManageAllowed(true);
            return;
        } else {
            const isAssigned = task.participants.find((p) => p.user.id === me.user.id) !== undefined;
            setIsDeadlineManageAllowed(isAssigned && permissions.has(TaskPermission.DEADLINE_MANAGE_MOD_ASSIGNED));
        }
    }, [task, permissions, me]);

    if (!task || !me) {
        return null;
    }

    const renderUpdateTaskDeadline = () => {
        if (task.state === TaskState.DELIVERED) {
            // Task has already been delivered, no point to set new deadline
            return null;
        }

        if (!isDeadlineManageAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<SetTaskDeadlineModalProps>({
                        ModalComponent: SetTaskDeadlineModal,
                        data: {
                            taskId: task.id,
                            workerTimezone: TimezoneUtils.getCurrentTimezoneName(),
                            clientTimezone: task.deadline_timezone_name,
                            deadlineTimezone: task.deadline_timezone_name,
                            deliveryDays: task.category.average_delivery_days,
                            deadlineValue: task.deadline_at,
                        },
                    })
                }
            />
        );
    };

    const renderHeader = () => {
        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Dates</Heading>

                {renderUpdateTaskDeadline()}
            </Flex>
        );
    };

    const renderDeadline = () => {
        const renderNoDeadlineLabel = () => {
            return <DevText muted>To be set</DevText>;
        };

        return (
            <div className="mt-2">
                <DevText muted className="mb-small">
                    Deadline
                </DevText>
                <DeadlineTag
                    deadlineAt={task.deadline_at}
                    finalizedAt={task.finalized_at}
                    noDeadlineRenderer={renderNoDeadlineLabel}
                />
            </div>
        );
    };

    const renderPublishAtDateInformation = () => {
        if (!task.publish) {
            // Task is NOT published yet
            return null;
        }

        return (
            <CommonInformationBlock
                className="mt-2"
                title="Published at"
                text={moment(task.publish.finalized_at).format('D MMM, YYYY')}
            />
        );
    };

    const renderFinalizedAtDateInformation = () => {
        if (task.finalized_at === null) {
            // Not finalized yet
            return null;
        }

        return (
            <CommonInformationBlock
                className="mt-2"
                title="Delivered at"
                text={moment(task.finalized_at).format('D MMM YYYY, HH:mm')}
            />
        );
    };

    return (
        <Card {...props}>
            {renderHeader()}
            {renderDeadline()}
            {renderPublishAtDateInformation()}
            {renderFinalizedAtDateInformation()}
        </Card>
    );
};

export default DatesInformation;
