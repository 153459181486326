import React from 'react';
import { Card, Colors } from '@blueprintjs/core';
import DevText from '@app/components/Text';
import RouterLink from '@app/components/RouterLink';
import { Endpoints } from '@app/data/consts';
import { NotificationResource, UserNoteResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import RichText from '@app/components/RichText';
import { UserUtils } from '@containers/components/Notifications/utils';
import NotificationTemplate from '../../../NotificationTemplate';
import { UserType } from 'dy-frontend-http-repository/lib/data/enums';

export interface UserNoteCreatedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    userNote: UserNoteResource | null;
    dismiss: () => Promise<void>;
}

export type Props = UserNoteCreatedNotificationProps;

const UserNoteCreatedNotification: React.FC<Props> = ({ authorizedUserId, notification, userNote, dismiss }) => {
    // Get note user type
    let noteUserType = 'unknown note user type';
    let noteUserLink: string | null = null;
    if (userNote) {
        // Get user for which note was created
        const user = userNote.user;

        switch (user.type) {
            case UserType.CLIENT:
                noteUserType = 'client';
                noteUserLink = Endpoints.CLIENT_NOTES.replace(':clientId', user.id);
                break;
            case UserType.CORE:
                noteUserType = 'member';
                noteUserLink = Endpoints.CORE_USER_TASKS.replace(':coreUserId', user.id);
                break;
            case UserType.WORKSPACE:
                noteUserType = 'workspace';
                break;
        }
    }

    const renderContent = () => {
        if (!userNote) {
            // This is bad, user note is NOT found, which should not happen
            return <DevText color={Colors.RED2}>User note was not found</DevText>;
        }

        const renderUserLink = () => {
            if (!noteUserLink) {
                return (
                    <DevText inline muted>
                        {userNote.user.first_name} {userNote.user.last_name}
                    </DevText>
                );
            }

            return (
                <RouterLink style={{ display: 'inline' }} to={noteUserLink}>
                    {userNote.user.first_name} {userNote.user.last_name}
                </RouterLink>
            );
        };

        return (
            <div>
                <DevText running className="mb-1">
                    {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)} added note for{' '}
                    {noteUserType} {renderUserLink()}
                </DevText>

                <Card compact>
                    <RichText value={userNote.content} />
                </Card>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title={`New note for ${noteUserType}`}
            icon="clipboard"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default UserNoteCreatedNotification;
