import { $taskKanbanBoard } from './states';
import { fetchTaskKanbanBoard } from './effects';
import moment from 'moment';

// Fetch kanban board
$taskKanbanBoard.on(fetchTaskKanbanBoard.doneData, (_, data) => {
    return {
        ...data,
        tasks: data.tasks.sort((a, b) =>
            moment.utc(b.state_latest_transition_at).diff(moment.utc(a.state_latest_transition_at))
        ),
    };
});
