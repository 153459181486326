import { IconName } from '@blueprintjs/core';
import { PlatformTaskClientFeedbackFilterType } from '../enums';

const platformFiltersInformation: {
    [type in PlatformTaskClientFeedbackFilterType]: {
        title: string;
        isPinned: boolean;
        icon: IconName;
    };
} = {
    [PlatformTaskClientFeedbackFilterType.NEGATIVE_SCORE]: {
        title: 'Publish type',
        icon: 'send-message',
        isPinned: true,
    },
    [PlatformTaskClientFeedbackFilterType.CLIENT_USERS]: {
        title: 'Client',
        icon: 'person',
        isPinned: true,
    },
    [PlatformTaskClientFeedbackFilterType.TASK_PARTICIPANTS]: {
        title: 'Participant',
        icon: 'inherited-group',
        isPinned: true,
    },
    [PlatformTaskClientFeedbackFilterType.TEAMS]: {
        title: 'Team',
        icon: 'inherited-group',
        isPinned: true,
    },
};

export default platformFiltersInformation;
