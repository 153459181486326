import styled, { css } from 'styled-components';
import { LinkProps } from './';
import { Colors } from '@blueprintjs/core';
import { UnitUtils } from 'dy-frontend-shared/lib/utils';

export const StyledLink = styled.a<{
    $underline: LinkProps['underline'];
    $showUnderlineOnHover: LinkProps['showUnderlineOnHover'];
    $fontSize: LinkProps['fontSize'];
    $fontWeight: LinkProps['fontWeight'];
    $lineHeight: LinkProps['lineHeight'];
    $inline: LinkProps['inline'];
    $color: LinkProps['color'];
}>`
    &&& {
        &:active,
        &:focus {
            outline: 0;
            border: none;
        }

        ${({ $underline, $inline, $lineHeight, $fontWeight }) => css`
            display: ${$inline ? 'inline-block' : 'block'};
            text-decoration: ${$underline ? 'underline' : 'none'};
            line-height: ${$lineHeight ?? 1.7};
            font-weight: ${$fontWeight ?? 400};

            &,
            &:hover {
                color: ${Colors.WHITE};
            }
        `}

        ${({ $underline, $showUnderlineOnHover }) =>
            $showUnderlineOnHover &&
            !$underline &&
            css`
                &:hover {
                    text-decoration: underline;
                }
            `}

        ${({ $fontSize }) =>
            $fontSize &&
            css`
                font-size: ${UnitUtils.pxToRem($fontSize, 14)};
            `}

        ${({ $color }) =>
            $color &&
            css`
                &,
                &:hover {
                    color: ${$color};
                }
            `}
    }
`;
