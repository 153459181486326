import { openModal } from '@app/containers/modals/store/events';
import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Grid from '@components/Grid';
import { $task } from '@pages/Task/store/states';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useApproveTaskDeliverable, useRemoveTaskDeliverable } from '../../hooks';
import UpdateTaskDeliverableInformationModal, {
    UpdateTaskDeliverableInformationModalProps,
} from '../../pages/TaskDeliverablesAll/modals/UpdateTaskDeliverableInformationModal';
import TaskDeliverableListGridViewListItem from '../TaskDeliverableListGridViewListItem';

export interface TaskDeliverableListGridViewProps {
    taskDeliverables: TaskDeliverableResource[];
    isUpdateAllowed?: boolean;
    isApproveAllowed?: boolean;
    isArchiveAllowed?: boolean;
    isRestoreAllowed?: boolean;
}

export type Props = TaskDeliverableListGridViewProps & HTMLAttributes<HTMLDivElement>;

const TaskDeliverableListGridView: React.FC<Props> = ({
    taskDeliverables,
    isUpdateAllowed = false,
    isApproveAllowed = false,
    isArchiveAllowed = false,
    isRestoreAllowed = false,
    ...props
}) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { removeTaskDeliverable } = useRemoveTaskDeliverable();
    const { approveTaskDeliverable } = useApproveTaskDeliverable();

    const task = useStore($task);

    const [openedMenuId, setOpenedMenuId] = useState<ID | null>(null);
    const [approvingDeliverableId, setApprovingDeliverableId] = useState<ID | null>(null);
    const [removingDeliverableId, setRemovingDeliverableId] = useState<ID | null>(null);

    // TODO: may be add different blocks for different task deliverable types, for task deliverables of type FILE consider adding extension block division

    if (!task) {
        return null;
    }

    const handleCloseMenu = () => {
        setOpenedMenuId(null);
    };

    const handleToggleMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>, taskDeliverableId: ID) => {
        e.stopPropagation();

        if (openedMenuId) {
            // Close menu
            handleCloseMenu();
        } else {
            // Open menu
            setOpenedMenuId(taskDeliverableId);
        }
    };

    const handleRemoveTaskDeliverable = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        taskDeliverable: TaskDeliverableResource
    ) => {
        e.stopPropagation();

        setRemovingDeliverableId(taskDeliverable.id);

        try {
            // Remove task deliverable
            await removeTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });

            // Close menu
            handleCloseMenu();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingDeliverableId(null);
        }
    };

    const handleApproveTaskDeliverable = async (
        e: React.MouseEvent<HTMLElement, MouseEvent>,
        taskDeliverable: TaskDeliverableResource
    ) => {
        e.stopPropagation();

        setApprovingDeliverableId(taskDeliverable.id);

        try {
            // Approve task deliverable
            await approveTaskDeliverable({
                path: taskDeliverable.path,
                taskDeliverableId: taskDeliverable.id,
            });

            // Close menu
            handleCloseMenu();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setApprovingDeliverableId(null);
        }
    };

    const handleOpenTaskDeliverableViewerModal = (taskDeliverableId: ID, path: string) => {
        setSearchParams({ path, taskDeliverableId });
    };

    const renderTaskDeliverableMenu = (taskDeliverable: TaskDeliverableResource) => {
        if (!isApproveAllowed && !isArchiveAllowed && !isUpdateAllowed) {
            return null;
        }

        const renderUpdateTaskDeliverableInformationMenuItem = () => {
            if (!isUpdateAllowed) {
                return null;
            }

            return (
                <MenuItem
                    text="Update"
                    icon="edit"
                    onClick={() =>
                        openModal<UpdateTaskDeliverableInformationModalProps>({
                            ModalComponent: UpdateTaskDeliverableInformationModal,
                            data: {
                                taskDeliverableId: taskDeliverable.id,
                                taskId: task.id,
                                textContent: taskDeliverable.text_content,
                                title: taskDeliverable.title,
                                type: taskDeliverable.type,
                                url: taskDeliverable.url,
                                path: taskDeliverable.path,
                            },
                        })
                    }
                />
            );
        };

        const renderRemoveTaskDeliverableMenuItem = () => {
            if (!isArchiveAllowed) {
                return null;
            }

            const isRemovingDeliverable = removingDeliverableId === taskDeliverable.id;

            return (
                <MenuItem
                    disabled={isRemovingDeliverable}
                    text="Remove"
                    icon="trash"
                    intent={Intent.DANGER}
                    onClick={(e) => handleRemoveTaskDeliverable(e, taskDeliverable)}
                />
            );
        };

        const renderApproveTaskDeliverableMenuItem = () => {
            if (!isApproveAllowed) {
                return null;
            }

            const isApprovingDeliverable = approvingDeliverableId === taskDeliverable.id;

            if (taskDeliverable.approved_at !== null) {
                return null;
            }

            return (
                <MenuItem
                    disabled={isApprovingDeliverable}
                    intent={Intent.SUCCESS}
                    text="Approve"
                    icon="confirm"
                    onClick={(e) => handleApproveTaskDeliverable(e, taskDeliverable)}
                />
            );
        };

        return (
            <Menu>
                {renderApproveTaskDeliverableMenuItem()}
                {renderUpdateTaskDeliverableInformationMenuItem()}
                {renderRemoveTaskDeliverableMenuItem()}
            </Menu>
        );
    };

    const renderTaskDeliverableMenuButton = (taskDeliverable: TaskDeliverableResource) => {
        const isMenuOpened = openedMenuId === taskDeliverable.id;
        const isMoreButtonDisabled =
            (approvingDeliverableId !== null && approvingDeliverableId === taskDeliverable.id) ||
            (removingDeliverableId !== null && removingDeliverableId === taskDeliverable.id);

        const content = renderTaskDeliverableMenu(taskDeliverable);
        if (!content) {
            return null;
        }

        return (
            <Popover isOpen={isMenuOpened} content={content} onClose={handleCloseMenu}>
                <Button
                    minimal
                    loading={isMoreButtonDisabled}
                    icon="more"
                    onClick={(e) => handleToggleMenu(e, taskDeliverable.id)}
                />
            </Popover>
        );
    };

    return (
        <Grid container {...props}>
            {taskDeliverables.map((taskDeliverable) => (
                <Grid key={`${taskDeliverable.id}-${taskDeliverable.created_at}`} className="mb-2" xs={12} lg={6}>
                    <TaskDeliverableListGridViewListItem
                        taskDeliverable={taskDeliverable}
                        onOpenViewer={() =>
                            handleOpenTaskDeliverableViewerModal(taskDeliverable.id, taskDeliverable.path)
                        }
                        actions={renderTaskDeliverableMenuButton(taskDeliverable)}
                    />
                </Grid>
            ))}
        </Grid>
    );
};

export default TaskDeliverableListGridView;
