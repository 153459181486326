enum LocalStorage {
    ACCESS_TOKEN = 'dy_internal/access_token',
    REFRESH_TOKEN = 'dy_internal/refresh_token',
    RECENT_WORKSPACES_DATA = 'dy_internal/recent_workspaces_data',
    RESEND_WORKSPACE_VERIFICATION_TIMEOUT = 'dy_internal/resend_workspace_verification_timeout',
    REFRESH_NOTIFICATIONS_TIMEOUT = 'dy_internal/refresh_notifications_timeout',
    REFRESH_TASK_KANBAN_BOARD = 'dy_internal/refresh_task_kanban_board',
    LAST_PRESENCE_PING_AT = 'dy_internal/last_presence_ping_at',
}

export default LocalStorage;
