import Box from '@app/components/Box';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { $task } from '@app/containers/pages/Task/store/states';
import { $authorizedUser } from '@app/containers/store/states';
import { ToastUtils } from '@app/data/utils';
import { Button, Collapse, Divider, Icon, Icon, Intent } from '@blueprintjs/core';
import { HTTPErrorType, UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { TaskMessageFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskMessage/inputs';
import { TaskMessageListItemResource } from 'dy-frontend-http-repository/lib/modules/TaskMessage/resources';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { pinnedTaskMessagesApi } from '../../store/apis';
import { fetchTaskMessages } from '../../store/effects';
import { $pinnedTaskMessages } from '../../store/states';
import BotMessage from '../BotMessage';
import TaskMessage from '../TaskMessage';
import { amountOfPinnedTaskMessagesOnPage } from './consts';

type Props = HTMLAttributes<HTMLDivElement>;

const PinnedTaskMessageList: React.FC<Props> = (props) => {
    const me = useStore($authorizedUser);
    const task = useStore($task);
    const pinnedTaskMessages = useStore($pinnedTaskMessages);

    const [isCollapseOpen, setIsCollapseOpen] = useState(false);
    const [isAuthorizedUserParticipant, setIsAuthorizedUserParticipant] = useState(false);

    const handleFetchPinnedTaskMessages = async () => {
        if (!task) return;

        // Get common filter
        const filter: TaskMessageFilterInput = {
            task_id: task.id,
            is_archived: '0',
            is_pinned: '1',
        };

        try {
            // Fetch pinned task messages
            const taskMessages = await fetchTaskMessages({
                pagination: {
                    _cursor: {
                        direction: 'desc',
                        limit: amountOfPinnedTaskMessagesOnPage,
                    },
                },
                filter,
            });

            pinnedTaskMessagesApi.initialize({ taskMessages });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Pinned task messages for task with ID of ${task.id} were not found`,
                    intent: Intent.DANGER,
                });
            }
        }
    };

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        setIsAuthorizedUserParticipant(task.participants.find((p) => p.user.id === me.user.id) !== undefined);
    }, [task, me]);

    useEffect(() => {
        handleFetchPinnedTaskMessages();
    }, [task]);

    if (!pinnedTaskMessages || pinnedTaskMessages.items.length === 0) {
        // Pinned messages were not fetched or there are no pinned messages
        return null;
    }

    const handleToggleCollapse = () => {
        setIsCollapseOpen((prevValue) => !prevValue);
    };

    const renderTaskMessage = (taskMessage: TaskMessageListItemResource, index: number) => {
        // Bot message
        if (!taskMessage.user) {
            // TODO: have to get resource as props

            return (
                <BotMessage
                    isAuthorizedUserParticipant={isAuthorizedUserParticipant}
                    className={index === 0 ? 'mt-4' : 'mt-2'}
                    taskMessage={taskMessage}
                />
            );
        }

        // "right" alignment for core users, "left" alignment for client users
        let alignment: 'right' | 'left' = 'right'; // TODO: add task message dependency on Component alignment type

        if (taskMessage.user.type === UserType.CLIENT) {
            alignment = 'left';
        }

        return (
            <TaskMessage
                canBeRemoved={false}
                isAuthorizedUserParticipant={isAuthorizedUserParticipant}
                isAuthorizedUserAuthor={me!.user.id === taskMessage.user.id}
                className={index === 0 ? 'mt-4' : 'mt-2'}
                alignment={alignment}
                taskMessage={taskMessage}
            />
        );
    };

    return (
        <div {...props}>
            <Divider className="mt-4 mb-2" />

            <Flex fullWidth direction="row" align="center" justify="space-between">
                <DevText muted>
                    <Icon className="ml-small mr-small" icon="pin" />
                    {pinnedTaskMessages.items.length > 1
                        ? `There are ${pinnedTaskMessages.items.length} pinned messages`
                        : `There is ${pinnedTaskMessages.items.length} pinned message`}
                </DevText>
                <Button minimal icon={isCollapseOpen ? 'chevron-up' : 'chevron-down'} onClick={handleToggleCollapse}>
                    {isCollapseOpen ? 'Click to hide pinned' : 'Click to show pinned'}
                </Button>
            </Flex>

            <Collapse isOpen={isCollapseOpen}>{pinnedTaskMessages.items.map(renderTaskMessage)}</Collapse>

            <Divider className={isCollapseOpen ? 'mb-4 mt-4' : 'mb-4 mt-2'} />
        </div>
    );
};

export default PinnedTaskMessageList;
