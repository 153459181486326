import { TaskDeliverablesTreeUtils } from '@app/containers/pages/Task/valueObjects';
import { TaskDeliverablesParsedInformation } from '../../../../types';
import { taskDeliverablesTreeApi } from '@pages/Task/store/apis';
import { approveTaskDeliverablesBatch } from '@app/containers/pages/Task/store/effects';

const useApproveDirectory = () => {
    const handleApproveDirectory = async ({
        taskDeliverablesTree,
        directoryPathToApprove,
        directoryNameToApprove,
    }: {
        taskDeliverablesTree: TaskDeliverablesParsedInformation;
        directoryPathToApprove: string;
        directoryNameToApprove: string;
    }) => {
        const fullDirectoryPathWithNameToRemove = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: directoryPathToApprove,
            directoryName: directoryNameToApprove,
        });

        let deliverableIdsToApprove: ID[] = [];

        for (const deliverablePath in taskDeliverablesTree.deliverables) {
            const shouldDeliverablesBeApproved = TaskDeliverablesTreeUtils.isSubPathPartOfPath({
                path: deliverablePath,
                subPath: fullDirectoryPathWithNameToRemove,
            });

            if (shouldDeliverablesBeApproved) {
                // Deliverables should be removed

                // Get not approved deliverables and deliverable path
                const notApprovedDeliverablesAtPath = Object.values(taskDeliverablesTree.deliverables[deliverablePath])
                    .filter((deliverable) => deliverable.approved_at === null)
                    .map((deliverable) => deliverable.id);

                // Update deliverable ids to approve array
                deliverableIdsToApprove = [...deliverableIdsToApprove, ...notApprovedDeliverablesAtPath];
            }
        }

        try {
            await approveTaskDeliverablesBatch({
                task_deliverable_ids: deliverableIdsToApprove,
            });

            taskDeliverablesTreeApi.approveDirectory({
                directoryName: directoryNameToApprove,
                directoryPath: directoryPathToApprove,
            });
        } catch (e) {
            throw e;
        }
    };

    return { approveDirectory: handleApproveDirectory };
};

export default useApproveDirectory;
