import { HydrateFilterDataFilterInput } from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { CoreUserFilterInput } from 'dy-frontend-http-repository/lib/modules/CoreUser/inputs';
import { PlatformCoreUserFilterType } from '../../enums';
import { PlatformCoreUserFilterData, CoreUserFilterQueryParameters } from '../../types';
import { platformCoreUserFiltersInformation } from '../../consts';
import { HydratedQueryParameters } from './types';
import { TaskFilterInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';

export default class PlatformCoreUserFilter {
    // query -> hydrate input
    static queryToHydrationInput = (queryParameters: CoreUserFilterQueryParameters) => {
        const hydrateQueryInput: HydrateFilterDataFilterInput = {};

        if (queryParameters.teamIds && queryParameters.teamIds.length > 0) {
            hydrateQueryInput.team_id = queryParameters.teamIds;
        }

        return hydrateQueryInput;
    };

    // hydrated -> platform filter
    static hydratedToPlatformFilter(hydratedQueryParameters: HydratedQueryParameters): PlatformCoreUserFilterData {
        // Create empty platform task filter data object
        const platformCoreUserFilterData: PlatformCoreUserFilterData = {
            query: '',
            isArchived: null,
            isVerified: null,
            teams: [],
            roles: [],
        };

        if (hydratedQueryParameters.isArchived !== null && hydratedQueryParameters.isArchived !== undefined) {
            //  Set archived flag
            platformCoreUserFilterData.isArchived = hydratedQueryParameters.isArchived;
        }

        if (hydratedQueryParameters.isVerified !== null && hydratedQueryParameters.isVerified !== undefined) {
            //  Set verified flag
            platformCoreUserFilterData.isVerified = hydratedQueryParameters.isVerified;
        }

        if (hydratedQueryParameters.teams) {
            // Set teams
            platformCoreUserFilterData.teams = hydratedQueryParameters.teams;
        }

        if (hydratedQueryParameters.roles) {
            // Set roles
            platformCoreUserFilterData.roles = hydratedQueryParameters.roles;
        }

        return platformCoreUserFilterData;
    }

    // platform filter -> API filter input
    static platformFilterToAPIFilterInput(platformFilterData: PlatformCoreUserFilterData): TaskFilterInput {
        const taskFilterInput: CoreUserFilterInput = {};

        if (platformFilterData.query) {
            // Set query
            taskFilterInput.query = platformFilterData.query;
        }

        if (platformFilterData.isArchived !== null) {
            // Set archived flag
            taskFilterInput.is_shutdown = platformFilterData.isArchived ? '1' : '0';
        }

        if (platformFilterData.isVerified !== null) {
            // Set archived flag
            taskFilterInput.is_verified = platformFilterData.isVerified ? '1' : '0';
        }

        if (platformFilterData.teams.length > 0) {
            // Set plans
            taskFilterInput.team_id = platformFilterData.teams.map((p) => p.id);
        }

        if (platformFilterData.roles.length > 0) {
            // Set roles
            taskFilterInput.role = platformFilterData.roles;
        }

        return taskFilterInput;
    }

    // platform filter -> query parameters
    static platformFilterToQueryParameters(
        page: number,
        platformFilter: PlatformCoreUserFilterData
    ): CoreUserFilterQueryParameters {
        const queryParameters: CoreUserFilterQueryParameters = {
            page: `${page}`,
        };

        if (platformFilter.isArchived !== null) {
            queryParameters.isArchived = platformFilter.isArchived ? 1 : 0;
        }

        if (platformFilter.isVerified !== null) {
            queryParameters.isVerified = platformFilter.isVerified ? 1 : 0;
        }

        if (platformFilter.teams && platformFilter.teams.length > 0) {
            queryParameters.teamIds = platformFilter.teams.map((t) => t.id);
        }

        if (platformFilter.roles && platformFilter.roles.length > 0) {
            queryParameters.roles = platformFilter.roles;
        }

        return queryParameters;
    }

    static getAppliedPlatformFilters(platformFilter: PlatformCoreUserFilterData): PlatformCoreUserFilterType[] {
        const appliedPlatformFilters: PlatformCoreUserFilterType[] = [];

        for (const filterType of Object.keys(platformCoreUserFiltersInformation) as PlatformCoreUserFilterType[]) {
            if (platformCoreUserFiltersInformation[filterType].isPinned) {
                // Filter is pinned by default
                appliedPlatformFilters.push(filterType);
                continue;
            }

            switch (filterType) {
                case PlatformCoreUserFilterType.CORE_USER_TEAMS:
                    if (platformFilter.teams.length > 0) {
                        appliedPlatformFilters.push(PlatformCoreUserFilterType.CORE_USER_TEAMS);
                    }
                    break;
                case PlatformCoreUserFilterType.CORE_USER_ROLES:
                    if (platformFilter.roles.length > 0) {
                        appliedPlatformFilters.push(PlatformCoreUserFilterType.CORE_USER_ROLES);
                    }
                    break;

                case PlatformCoreUserFilterType.CORE_USER_VERIFIED:
                    if (platformFilter.isVerified !== null) {
                        appliedPlatformFilters.push(PlatformCoreUserFilterType.CORE_USER_VERIFIED);
                    }
                    break;
                case PlatformCoreUserFilterType.CORE_USER_ARCHIVED:
                    if (platformFilter.isArchived !== null) {
                        appliedPlatformFilters.push(PlatformCoreUserFilterType.CORE_USER_ARCHIVED);
                    }
                    break;
            }
        }

        return appliedPlatformFilters;
    }
}
