import PriceResolverInput, { PriceResolverInputValue } from '@app/containers/components/PriceResolverInput';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import { Endpoints } from '@data/consts';
import { ModalProps } from '@modals/types';
import { PriceType } from 'dy-frontend-http-repository/lib/modules/Price/enums';
import { CreateTaskCategoryInput } from 'dy-frontend-http-repository/lib/modules/TaskCategory/inputs';
import { TaskCategoryRef } from 'dy-frontend-http-repository/lib/modules/TaskCategory/refs';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTaskCategory } from '../../store/effects';

type Props = ModalProps;

const categoryTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 1 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];

const CreateTaskCategoryModal: React.FC<Props> = ({ closeModal }) => {
    const navigate = useNavigate();

    const categoryTitle = useTextFormField({
        id: 'category-title',
        initialValue: '',
        validators: categoryTitleValidators,
    });

    const [paymentPublishPrice, setPaymentPublishPrice] = useState<PriceResolverInputValue | null>(null);

    const form = useForm<TaskCategoryRef>({
        fields: [categoryTitle],
        apiCall: async () => {
            try {
                // Create input
                const createTaskCategoryInput: CreateTaskCategoryInput = {
                    title: categoryTitle.value,
                    price_id: paymentPublishPrice ? paymentPublishPrice.id : null,
                    is_enabled: false,
                };

                // Create task category
                const taskCategoryRef = await createTaskCategory(createTaskCategoryInput);

                return { response: taskCategoryRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: ({ response: taskCategoryRef }) => {
            closeModal?.();
            navigate(Endpoints.TASK_CATEGORY.replace(':taskCategoryId', taskCategoryRef.id));
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create category</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    {/* Title */}
                    <InputFormField
                        field={categoryTitle}
                        formGroupProps={{ label: 'Title' }}
                        inputProps={{ placeholder: 'Enter title' }}
                    />

                    {/* External price ID */}
                    <FormGroup fill label="Payment publish price">
                        <PriceResolverInput
                            value={paymentPublishPrice}
                            onChange={setPaymentPublishPrice}
                            onViolationListRequested={(resolved) => {
                                // Ensure one-time
                                if (resolved.type !== PriceType.ONE_TIME) {
                                    return ['Must be one-time payment price'];
                                }

                                // TODO: Other violations
                                return [];
                            }}
                        />
                    </FormGroup>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateTaskCategoryModal;
