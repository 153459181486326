import { usePageTitle, useScrollToTop } from '@app/hooks';
import { Button, Classes, Icon, InputGroup, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import NonIdealState from '@components/NonIdealState';
import Pagination from '@components/Pagination';
import { openModal } from '@containers/modals/store/events';
import { Endpoints, imageHashPreview } from '@data/consts';
import { fetchClientBrandProfiles } from '@pages/Client/pages/ClientBrandProfiles/store/effects';
import { resetClientBrandProfiles } from '@pages/Client/pages/ClientBrandProfiles/store/events';
import { $clientBrandProfiles } from '@pages/Client/pages/ClientBrandProfiles/store/states';
import { $client, $computedLicence, $quotaUsage } from '@pages/Client/store/states';
import { BrandProfilesFilterInput } from 'dy-frontend-http-repository/lib/modules/BrandProfile/inputs';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BrandProfileListItem from './components/BrandProfileListItem';
import { amountOfBrandProfilesOnPage } from './consts';
import CreateBrandProfileModal, { CreateBrandProfileModalProps } from './modals/CreateBrandProfileModal';
import { $permissions } from '@containers/store/states';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';
import { HTTPErrorType, QuotaType } from 'dy-frontend-http-repository/lib/data/enums';
import DevText from '@app/components/Text';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const ClientBrandProfiles: React.FC = () => {
    const { scrollToTop } = useScrollToTop();
    const { changeTitle } = usePageTitle('Client Brand Profiles');
    const navigate = useNavigate();

    const permissions = useStore($permissions);

    const client = useStore($client);
    const clientBrandProfiles = useStore($clientBrandProfiles);
    const quotaUsage = useStore($quotaUsage);
    const computedLicence = useStore($computedLicence);
    const isFetchingClientBrandProfiles = useStore(fetchClientBrandProfiles.pending);

    const [page, setPage] = useState(1);

    const [search, setSearch] = useState('');
    const [isSearchInputTouched, setIsSearchInputTouched] = useState(false);

    const handleLoadPage = (newPage: number, search?: string) => {
        if (!client) return;

        // Common filters
        const filter: BrandProfilesFilterInput = {
            user_id: client.id,
            is_archived: '0',
        };

        // Check if search was provided
        if (search) {
            filter['query'] = search;
        }

        const pageOffset = (newPage - 1) * amountOfBrandProfilesOnPage;

        if (!permissions.isEnabled.brandProfile) {
            return;
        }

        fetchClientBrandProfiles({
            pagination: {
                _pagination: { limit: amountOfBrandProfilesOnPage, offset: pageOffset },
            },
            filter,
        }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Brand profiles for client with ID ${client.id} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to client tasks page
                navigate(Endpoints.CLIENT_TASKS.replace(':clientId', client.id));
            }
        });
    };

    useEffect(() => {
        handleLoadPage(page, search);

        // Scroll to top
        scrollToTop();

        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (!isSearchInputTouched) {
            return;
        }

        const timeoutId = setTimeout(() => handleLoadPage(page, search), 500);

        return () => {
            clearTimeout(timeoutId);
        };
        // eslint-disable-next-line
    }, [search]);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Brand Profiles - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    useEffect(() => {
        return () => {
            resetClientBrandProfiles();
        };
    }, []);

    if (!permissions.isEnabled.brandProfile) {
        return null;
    }

    if (!client) {
        return null;
    }

    const renderHeader = () => {
        const renderCreateBrandProfileButton = () => {
            const isAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.CREATE);
            if (!isAllowed) {
                return null;
            }

            return (
                <Button
                    icon="new-object"
                    intent={Intent.PRIMARY}
                    onClick={() =>
                        openModal<CreateBrandProfileModalProps>({
                            ModalComponent: CreateBrandProfileModal,
                            data: {
                                userId: client.id,
                            },
                        })
                    }
                >
                    Create brand profile
                </Button>
            );
        };

        const renderBacklogCounter = () => {
            const isLoading = quotaUsage === null || computedLicence === null;

            let currentBrandProfiles = 0;
            let totalBrandProfiles = 0;

            if (computedLicence && quotaUsage) {
                currentBrandProfiles =
                    quotaUsage.items.find((quota) => quota.quota_type === QuotaType.USER_BRAND_PROFILE)?.value ?? 0;
                totalBrandProfiles =
                    computedLicence.quotas.find((quota) => quota.type === QuotaType.USER_BRAND_PROFILE)?.value ?? 0;
            }

            return (
                <DevText muted className={isLoading ? Classes.SKELETON : ''}>
                    Brand profiles: {currentBrandProfiles} / {totalBrandProfiles}
                </DevText>
            );
        };

        return (
            <Flex className="mb-2" align="center" justify="space-between">
                <InputGroup
                    leftIcon="search"
                    placeholder="Search brand profile"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onFocus={() => setIsSearchInputTouched(true)}
                />

                <Flex className="ml-1" direction="row" align="center">
                    {renderBacklogCounter()}
                    <div className="ml-2">{renderCreateBrandProfileButton()}</div>
                </Flex>
            </Flex>
        );
    };

    const renderClientBrandProfilesList = () => {
        // Client brand profiles were NOT fetched yet
        if (!clientBrandProfiles) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Client brand profiles were fetched, but where are no brand profiles existed for this client
        if (clientBrandProfiles.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No client brand profiles were found
                        </Heading>
                    }
                />
            );
        }

        // TODO: add imageSrc whenever files will be implemented on backend
        return (
            <Grid container>
                {clientBrandProfiles.items.map((brandProfile) => {
                    // Get avatar src
                    let avatarSrc: string | null = null;
                    if (brandProfile.image_hash) {
                        avatarSrc = imageHashPreview.brandProfileImage(
                            brandProfile.image_hash,
                            ImageHashPreviewSize.MD
                        );
                    }

                    return (
                        <Grid className="mb-2" key={brandProfile.id} lg={4}>
                            <BrandProfileListItem
                                title={brandProfile.title}
                                createdAt={brandProfile.created_at}
                                archivedAt={brandProfile.archived_at}
                                imageSrc={avatarSrc}
                                onClick={() =>
                                    navigate(Endpoints.BRAND_PROFILE.replace(':brandProfileId', `${brandProfile.id}`))
                                }
                            />
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const renderPagination = () => {
        // Client brand profiles is not fetched yet
        if (clientBrandProfiles === null) {
            return null;
        }

        // Check if offset paginator exist
        if (clientBrandProfiles.paginator === null) {
            return;
        }

        // Only one page exist
        if (!clientBrandProfiles.paginator.has_more && clientBrandProfiles.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex justify="flex-end">
                <Pagination
                    fetching={isFetchingClientBrandProfiles}
                    hasMore={clientBrandProfiles.paginator.has_more}
                    className="mt-2"
                    page={page}
                    amountOfItemsOnPage={amountOfBrandProfilesOnPage}
                    totalItems={clientBrandProfiles.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div>
            {/* Header with search input and "create brand profile" button */}
            {renderHeader()}

            {/* Client brand profiles list */}
            {renderClientBrandProfilesList()}

            {/* Pagination */}
            {renderPagination()}
        </div>
    );
};

export default ClientBrandProfiles;
