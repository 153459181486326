import {
    Alignment,
    Breadcrumbs,
    Button,
    Classes,
    Drawer,
    DrawerSize,
    NavbarDivider,
    NavbarGroup,
    NavbarProps,
    Tooltip,
} from '@blueprintjs/core';
import { $authorizedUser, $pageBreadcrumbs } from '@containers/store/states';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { Navbar as StyledNavbar, UserInfoBlock } from './styled';
import DevText from '@components/Text';
import Avatar from '@components/Avatar';
import { Endpoints, imageHashPreview } from '@data/consts';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { useBreakpoint, useLogout } from '@app/hooks';
import RouterLink from '@app/components/RouterLink';
import { Breakpoint } from '@app/data/enums';
import Notifications from '@app/containers/components/Notifications';

type Props = NavbarProps;

const Navbar: React.FC<Props> = (props) => {
    const logout = useLogout();
    const { breakpoint } = useBreakpoint();

    const me = useStore($authorizedUser);
    const pageBreadcrumbs = useStore($pageBreadcrumbs);

    const [isNotificationsVisible, setIsNotificationsVisible] = useState(false);

    console.log('isNotificationsVisible: ', isNotificationsVisible);

    if (!me) {
        return null;
    }

    const handleToggleNotification = () => setIsNotificationsVisible((prevValue) => !prevValue);

    const renderAvatar = () => {
        // Get avatar src
        let avatarSrc: string | null = null;
        if (me?.user.image_hash) {
            avatarSrc = imageHashPreview.userImage(me.user.image_hash, ImageHashPreviewSize.SM);
        }
        return <Avatar alt={me.user.first_name} src={avatarSrc} />;
    };

    const renderPageBreadcrumbs = () => {
        if (!pageBreadcrumbs) {
            // No breadcrumbs for current page
            return null;
        }

        return (
            <NavbarGroup>
                <Breadcrumbs items={pageBreadcrumbs} />
            </NavbarGroup>
        );
    };

    const renderLogoutButton = () => {
        return (
            <Tooltip content="Logout">
                <Button minimal icon="power" onClick={logout} />
            </Tooltip>
        );
    };

    const renderNotificationsDrawer = () => {
        if (breakpoint && breakpoint === Breakpoint.XL) {
            // Do not allow toggle notifications on extra large screens, notifications will always be present in the case of such screen
            return null;
        }

        return (
            <div>
                <Button minimal icon="history" onClick={handleToggleNotification} />
                <Drawer
                    isOpen={isNotificationsVisible}
                    size={DrawerSize.SMALL}
                    onClose={() => setIsNotificationsVisible(false)}
                    transitionDuration={100}
                >
                    <div className={`${Classes.DRAWER_BODY} ${Classes.DARK}`}>
                        <Notifications isOpen={isNotificationsVisible} />
                    </div>
                </Drawer>
            </div>
        );
    };

    return (
        <StyledNavbar {...props}>
            {renderPageBreadcrumbs()}

            <NavbarGroup align={Alignment.RIGHT}>
                <UserInfoBlock className="mr-1">
                    <div className="mr-2">
                        <DevText align="end">
                            <RouterLink
                                color="inherit"
                                lineHeight={1}
                                to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', me.user.id)}
                            >
                                {me.user.first_name} {me.user.last_name}
                            </RouterLink>
                        </DevText>
                        <DevText muted align="end">
                            {me.user.email}
                        </DevText>
                    </div>
                    {renderAvatar()}
                </UserInfoBlock>

                {renderNotificationsDrawer()}

                <NavbarDivider />

                {renderLogoutButton()}
            </NavbarGroup>
        </StyledNavbar>
    );
};

export default Navbar;
