import { HydrateFilterDataFilterInput } from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { TaskFilterInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';
import { PlatformTaskFilterType, TaskDeadlineState } from '../../enums';
import { PlatformTaskFilterData, TaskFilterQueryParameters } from '../../types';
import { platformFiltersInformation } from '../../consts';
import { HydratedQueryParameters } from './types';
import moment from 'moment';
import TaskPlatformFilterData from '../../types/PlatformTaskFilterData';

export default class PlatformTaskFilter {
    // query -> hydrate input
    static queryToHydrationInput = (queryParameters: TaskFilterQueryParameters) => {
        const hydrateQueryInput: HydrateFilterDataFilterInput = {};

        // Set client user ids
        if (queryParameters.clientIds && queryParameters.clientIds.length > 0) {
            hydrateQueryInput.client_user_id = queryParameters.clientIds;
        }

        // Set participants ids
        if (queryParameters.participantIds && queryParameters.participantIds.length > 0) {
            hydrateQueryInput.core_user_id = queryParameters.participantIds;
        }

        // Set team ids
        if (queryParameters.teamIds && queryParameters.teamIds.length > 0) {
            hydrateQueryInput.team_id = queryParameters.teamIds;
        }

        // Set task categories
        if (queryParameters.taskCategoryIds && queryParameters.taskCategoryIds.length > 0) {
            hydrateQueryInput.task_category_id = queryParameters.taskCategoryIds;
        }

        // Set plan ids
        if (queryParameters.planIds && queryParameters.planIds.length > 0) {
            hydrateQueryInput.plan_id = queryParameters.planIds;
        }

        return hydrateQueryInput;
    };

    // hydrated -> platform filter
    static hydratedToPlatformFilter(hydratedQueryParameters: HydratedQueryParameters): PlatformTaskFilterData {
        // Create empty platform task filter data object
        const platformTaskFilterData: PlatformTaskFilterData = {
            query: '',
            clients: [],
            participants: [],
            teams: [],
            states: [],
            queue: null,
            taskCategories: [],
            isArchived: null,
            isPaused: null,
            publishTypes: [],
            plans: [],
            deadlineState: null,
        };

        if (hydratedQueryParameters.clients) {
            // Set clients
            platformTaskFilterData.clients = hydratedQueryParameters.clients;
        }

        if (hydratedQueryParameters.participants) {
            // Set participants
            platformTaskFilterData.participants = hydratedQueryParameters.participants;
        }

        if (hydratedQueryParameters.teams) {
            // Set teams
            platformTaskFilterData.teams = hydratedQueryParameters.teams;
        }

        if (hydratedQueryParameters.states) {
            // Set states
            platformTaskFilterData.states = hydratedQueryParameters.states;
        }

        if (hydratedQueryParameters.queue !== null && hydratedQueryParameters.queue !== undefined) {
            // Set queue
            platformTaskFilterData.queue = hydratedQueryParameters.queue;
        }

        if (hydratedQueryParameters.taskCategories) {
            // Set task categories
            platformTaskFilterData.taskCategories = hydratedQueryParameters.taskCategories;
        }

        if (hydratedQueryParameters.isArchived !== null && hydratedQueryParameters.isArchived !== undefined) {
            //  Set archived flag
            platformTaskFilterData.isArchived = hydratedQueryParameters.isArchived;
        }

        if (hydratedQueryParameters.isPaused !== null && hydratedQueryParameters.isPaused !== undefined) {
            //  Set paused flag
            platformTaskFilterData.isPaused = hydratedQueryParameters.isPaused;
        }

        if (hydratedQueryParameters.publishTypes) {
            // Set publish types
            platformTaskFilterData.publishTypes = hydratedQueryParameters.publishTypes;
        }

        if (hydratedQueryParameters.plans) {
            // Set plans
            platformTaskFilterData.plans = hydratedQueryParameters.plans;
        }

        if (hydratedQueryParameters.deadlineState !== null && hydratedQueryParameters.deadlineState !== undefined) {
            // Set deadline state
            platformTaskFilterData.deadlineState = hydratedQueryParameters.deadlineState;
        }

        return platformTaskFilterData;
    }

    // platform filter -> API filter input
    static platformFilterToAPIFilterInput(platformFilterData: PlatformTaskFilterData): TaskFilterInput {
        const taskFilterInput: TaskFilterInput = {};

        if (platformFilterData.query) {
            // Set query
            taskFilterInput.query = platformFilterData.query;
        }

        if (platformFilterData.clients.length > 0) {
            // Set clients
            taskFilterInput.owner_user_id = platformFilterData.clients.map((c) => c.id);
        }

        if (platformFilterData.participants.length > 0) {
            // Set participants
            taskFilterInput.participant_user_id = platformFilterData.participants.map((p) => p.id);
        }

        if (platformFilterData.teams.length > 0) {
            // Set teams
            taskFilterInput.team_id = platformFilterData.teams.map((t) => t.id);
        }

        if (platformFilterData.states.length > 0) {
            // Set states
            taskFilterInput.state = platformFilterData.states;
        }

        if (platformFilterData.queue !== null) {
            // Set queue
            taskFilterInput.queue = platformFilterData.queue;
        }

        if (platformFilterData.taskCategories.length > 0) {
            // Set task categories
            taskFilterInput.task_category_id = platformFilterData.taskCategories.map((t) => t.id);
        }

        if (platformFilterData.isArchived !== null) {
            // Set archived flag
            taskFilterInput.is_archived = platformFilterData.isArchived ? '1' : '0';
        }

        if (platformFilterData.isPaused !== null) {
            // Set paused flag
            taskFilterInput.is_paused = platformFilterData.isPaused ? '1' : '0';
        }

        if (platformFilterData.publishTypes !== null) {
            // Set publish types
            taskFilterInput.publish_type = platformFilterData.publishTypes;
        }

        if (platformFilterData.plans.length > 0) {
            // Set plans
            taskFilterInput.licence_plan_id = platformFilterData.plans.map((p) => p.id);
        }

        if (platformFilterData.deadlineState !== null) {
            // Set deadline
            switch (platformFilterData.deadlineState) {
                case TaskDeadlineState.EXCEEDED:
                    taskFilterInput.deadline_at = {
                        lte: moment().utc().unix(),
                    };
                    break;
                case TaskDeadlineState.APPROACHING:
                    taskFilterInput.deadline_at = {
                        lte: moment().utc().add(1, 'days').unix(),
                    };
                    break;
            }
        }

        return taskFilterInput;
    }

    // platform filter -> query parameters
    static platformFilterToQueryParameters(
        page: number,
        platformFilter: TaskPlatformFilterData
    ): TaskFilterQueryParameters {
        const queryParameters: TaskFilterQueryParameters = {
            page: `${page}`,
        };

        if (platformFilter.clients && platformFilter.clients.length > 0) {
            queryParameters.clientIds = platformFilter.clients.map((c) => c.id);
        }

        if (platformFilter.participants && platformFilter.participants.length > 0) {
            queryParameters.participantIds = platformFilter.participants.map((p) => p.id);
        }

        if (platformFilter.teams && platformFilter.teams.length > 0) {
            queryParameters.teamIds = platformFilter.teams.map((t) => t.id);
        }

        if (platformFilter.states && platformFilter.states.length > 0) {
            queryParameters.states = platformFilter.states;
        }

        if (platformFilter.queue !== null) {
            queryParameters.queue = platformFilter.queue;
        }

        if (platformFilter.taskCategories && platformFilter.taskCategories.length > 0) {
            queryParameters.taskCategoryIds = platformFilter.taskCategories.map((t) => t.id);
        }

        if (platformFilter.isArchived !== null) {
            queryParameters.isArchived = platformFilter.isArchived ? 1 : 0;
        }

        if (platformFilter.isPaused !== null) {
            queryParameters.isPaused = platformFilter.isPaused ? 1 : 0;
        }

        if (platformFilter.publishTypes && platformFilter.publishTypes.length > 0) {
            queryParameters.publishTypes = platformFilter.publishTypes;
        }

        if (platformFilter.plans && platformFilter.plans.length > 0) {
            queryParameters.planIds = platformFilter.plans.map((t) => t.id);
        }

        if (platformFilter.deadlineState && platformFilter.deadlineState.length > 0) {
            queryParameters.deadlineState = platformFilter.deadlineState;
        }

        return queryParameters;
    }

    static getAppliedPlatformFilters(platformFilter: PlatformTaskFilterData): PlatformTaskFilterType[] {
        const appliedPlatformFilters: PlatformTaskFilterType[] = [];

        console.log(Object.values(PlatformTaskFilterType));
        for (const filterType of Object.keys(platformFiltersInformation) as PlatformTaskFilterType[]) {
            if (platformFiltersInformation[filterType].isPinned) {
                // Filter is pinned by default
                appliedPlatformFilters.push(filterType);
                continue;
            }

            switch (filterType) {
                case PlatformTaskFilterType.TASK_STATES:
                    if (platformFilter.states.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_STATES);
                    }
                    break;

                case PlatformTaskFilterType.TASK_QUEUE:
                    if (platformFilter.queue !== null) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_QUEUE);
                    }
                    break;
                case PlatformTaskFilterType.CLIENT_USERS:
                    if (platformFilter.clients.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.CLIENT_USERS);
                    }
                    break;
                case PlatformTaskFilterType.TASK_PARTICIPANTS:
                    if (platformFilter.participants.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_PARTICIPANTS);
                    }
                    break;
                case PlatformTaskFilterType.TASK_TEAMS:
                    if (platformFilter.teams.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_TEAMS);
                    }
                    break;
                case PlatformTaskFilterType.TASK_DEADLINE:
                    if (platformFilter.deadlineState !== null) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_DEADLINE);
                    }
                    break;
                case PlatformTaskFilterType.TASK_CATEGORIES:
                    if (platformFilter.taskCategories.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_CATEGORIES);
                    }
                    break;
                case PlatformTaskFilterType.TASK_PUBLISH_TYPES:
                    if (platformFilter.publishTypes.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_PUBLISH_TYPES);
                    }
                    break;
                case PlatformTaskFilterType.TASK_PLANS:
                    if (platformFilter.plans.length > 0) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_PLANS);
                    }
                    break;
                case PlatformTaskFilterType.TASK_PAUSED:
                    if (platformFilter.isPaused !== null) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_PAUSED);
                    }
                    break;
                case PlatformTaskFilterType.TASK_ARCHIVED:
                    if (platformFilter.isArchived !== null) {
                        appliedPlatformFilters.push(PlatformTaskFilterType.TASK_ARCHIVED);
                    }
                    break;
            }
        }

        return appliedPlatformFilters;
    }
}
