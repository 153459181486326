import { SubscriptionMode } from 'dy-frontend-http-repository/lib/modules/Subscription/enums';
import { SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { checkIfSubscriptionTerminated, checkIfSubscriptionPaused, checkIfSubscriptionUpdateScheduled } from '../';

/**
 * Check if cancel subscription action allowed
 * @returns {boolean}  cancel subscription action allowed flag
 */
export default function checkIfCancelSubscriptionActionAllowed(subscription: SubscriptionResource) {
    if (subscription.mode === SubscriptionMode.MANUAL) {
        // Subscription mode is MANUAL
        return false;
    }

    if (checkIfSubscriptionTerminated(subscription)) {
        // Subscription is terminated
        return false;
    }

    if (checkIfSubscriptionPaused(subscription)) {
        // Subscription is paused
        return false;
    }

    if (checkIfSubscriptionUpdateScheduled(subscription)) {
        // Subscription update is scheduled
        return false;
    }

    return true;
}
