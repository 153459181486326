import CommonInformationBlock from '@app/components/CommonInformationBlock';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import OccupancyRate from '@app/containers/components/OccupancyRate';
import { openModal } from '@app/containers/modals/store/events';
import UpdateCoreUserOccupancySettingsModal, {
    UpdateCoreUserOccupancySettingsModalProps,
} from '@app/containers/pages/CoreUser/modals/UpdateCoreUserOccupancySettingsModal';
import { $coreUser, $coreUserOccupancy } from '@app/containers/pages/CoreUser/store/states';
import { Button, Card, Tooltip } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';

export type Props = HTMLAttributes<HTMLDivElement>;

const CoreUserOccupancyInformation: React.FC<Props> = ({ ...props }) => {
    const coreUser = useStore($coreUser);
    const coreUserOccupancy = useStore($coreUserOccupancy);

    if (!coreUserOccupancy || !coreUser) {
        return null;
    }

    const occupancy = coreUserOccupancy.items[0];

    if (!occupancy) {
        return null;
    }

    // const handleUpdateUserOccupancySettings = () => {
    //     openModal<UpdateCoreUserOccupancySettingsModalProps>({
    //         ModalComponent: UpdateCoreUserOccupancySettingsModal,
    //         data: { coreUserId: coreUser.id, maxTotalTaskComplexity: occupancy.max_total_task_complexity },
    //     });
    // };

    const renderUpdateUserOccupancySettingsButton = () => {
        // return <Button icon="edit" onClick={handleUpdateUserOccupancySettings} />;
        return null;
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" direction="row" justify="space-between" align="center">
                <Heading type="h4">Occupancy</Heading>
                {renderUpdateUserOccupancySettingsButton()}
            </Flex>

            <Tooltip
                fill
                content={
                    <div>
                        <DevText>Total complexity: {occupancy.total_task_complexity}</DevText>
                        <DevText>Average complexity: {occupancy.average_task_complexity}</DevText>
                        <DevText>Max total complexity: {occupancy.max_total_task_complexity}</DevText>
                    </div>
                }
            >
                <OccupancyRate interactive score={occupancy.score} />
            </Tooltip>
        </Card>
    );
};

export default CoreUserOccupancyInformation;
