import React from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@app/components/Text';
import { useNavigate } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { $task } from '../../../store/states';
import { useStore } from 'effector-react';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';

export interface UnapprovedTaskDeliverablePresenceSanityCheckModalProps {
    unapprovedDeliverablesCount: number;
    approveTaskDeliverablesAndTransition: () => any;
}

type Props = ModalProps<UnapprovedTaskDeliverablePresenceSanityCheckModalProps>;

const UnapprovedTaskDeliverablePresenceSanityCheckModal: React.FC<Props> = ({ closeModal, data }) => {
    const navigate = useNavigate();

    const task = useStore($task);

    const handleModalClose = () => {
        closeModal?.();
    };

    if (!data || !task) {
        handleModalClose();
        return null;
    }

    const handleApproveTaskDeliverablesAndTransition = () => {
        data.approveTaskDeliverablesAndTransition();
        handleModalClose();
    };

    const handleGoToTaskDeliverables = () => {
        navigate(Endpoints.TASK_DELIVERABLES_APPROVED.replace(':taskId', task.id));
        handleModalClose();
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Heading className="mb-1" type="h5">
                    ATTENTION!
                </Heading>
                <Heading className="mb-2" type="h6">
                    Currently there are {TextFormatUtils.getMultiple(data.unapprovedDeliverablesCount, 'deliverable')}{' '}
                    awaiting approval
                </Heading>

                <DevText className="mb-2">
                    When continuing deliverables will be automatically approved. Are you sure that you want
                    to continue?
                </DevText>

                <Flex direction="row" align="center" justify="space-between">
                    <div className="mr-1">
                        <Button
                            className="mr-1"
                            intent={Intent.DANGER}
                            onClick={handleApproveTaskDeliverablesAndTransition}
                        >
                            Yes, I am sure
                        </Button>
                        <Button intent={Intent.PRIMARY} onClick={handleModalClose}>
                            No, cancel
                        </Button>
                    </div>

                    <Button minimal onClick={handleGoToTaskDeliverables}>Go to deliverables</Button>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default UnapprovedTaskDeliverablePresenceSanityCheckModal;
