import { IconName } from '@blueprintjs/core';
import { PlatformTaskFilterType } from '../enums';

const platformFiltersInformation: {
    [type in PlatformTaskFilterType]: {
        title: string;
        isPinned: boolean;
        icon: IconName;
    };
} = {
    [PlatformTaskFilterType.TASK_STATES]: {
        title: 'Status',
        icon: 'confirm',
        isPinned: true,
    },

    [PlatformTaskFilterType.TASK_QUEUE]: {
        title: 'Queue',
        icon: 'remove-row-bottom',
        isPinned: true,
    },

    [PlatformTaskFilterType.CLIENT_USERS]: {
        title: 'Client',
        icon: 'person',
        isPinned: true,
    },

    [PlatformTaskFilterType.TASK_PARTICIPANTS]: {
        title: 'Participant',
        icon: 'inherited-group',
        isPinned: true,
    },

    [PlatformTaskFilterType.TASK_TEAMS]: {
        title: 'Team',
        icon: 'team',
        isPinned: true,
    },

    [PlatformTaskFilterType.TASK_DEADLINE]: {
        title: 'Deadline',
        icon: 'time',
        isPinned: true,
    },

    [PlatformTaskFilterType.TASK_CATEGORIES]: {
        title: 'Category',
        icon: 'properties',
        isPinned: false,
    },

    [PlatformTaskFilterType.TASK_PUBLISH_TYPES]: {
        title: 'Publish type',
        icon: 'send-message',
        isPinned: false,
    },

    [PlatformTaskFilterType.TASK_PLANS]: {
        title: 'Client plan',
        icon: 'box',
        isPinned: false,
    },

    [PlatformTaskFilterType.TASK_PAUSED]: {
        title: 'Paused',
        icon: 'pause',
        isPinned: false,
    },

    [PlatformTaskFilterType.TASK_ARCHIVED]: {
        title: 'Archived',
        icon: 'trash',
        isPinned: false,
    },
};

export default platformFiltersInformation;
