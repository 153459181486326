import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import moment from 'moment';
import { TaskDeliverablesMap, TaskDeliverablesDirectoriesMap } from '../../types';

export default class TaskDeliverablesTreeUtils {
    static isSubPathPartOfPath({ path, subPath }: { path: string; subPath: string }) {
        if (path.startsWith(subPath)) {
            // subPath is in the beginning

            const splittedPath = path.split(subPath);

            if (splittedPath.length === 0) {
                // subPath is not part of path
                return false;
            }

            if (splittedPath.length >= 2) {
                const additionalPartOfSplittedPath = splittedPath[1];

                if (additionalPartOfSplittedPath.length === 0) {
                    // Full match
                    return true;
                }

                // This need to avoid situations where path is /family, but there are paths like /family/photos and /family_reunion/photos where both will match path
                if (additionalPartOfSplittedPath.startsWith('/')) {
                    return true;
                } else {
                    return false;
                }
            }
        }

        // subPath is NOT in the beginning
        return false;
    }

    static getLastPathIndexToBeRenamed({ path, subPath }: { path: string; subPath: string }) {
        if (path === subPath) {
            // Full match
            return 0;
        }

        const splittedPath = path.split(subPath);
        if (splittedPath.length >= 2) {
            const additionalPartOfSplittedPath = splittedPath[1];
            return path.indexOf(additionalPartOfSplittedPath);
        }

        return -1;
    }

    static getRenamedPath({
        currentPath,
        oldPath,
        newPath,
    }: {
        currentPath: string;
        oldPath: string;
        newPath: string;
    }) {
        const shouldCurrentPathBeRenamed = TaskDeliverablesTreeUtils.isSubPathPartOfPath({
            path: currentPath,
            subPath: oldPath,
        });

        if (shouldCurrentPathBeRenamed) {
            const lastOldPathIndexToBeRenamed = TaskDeliverablesTreeUtils.getLastPathIndexToBeRenamed({
                path: currentPath,
                subPath: oldPath,
            });

            if (lastOldPathIndexToBeRenamed === -1) {
                throw new Error(
                    `Can't get substring index for old path: "${oldPath}" in current path: "${currentPath}"`
                );
            }

            if (lastOldPathIndexToBeRenamed === 0) {
                // Full match
                return newPath;
            }

            const additionalOldPathPart = currentPath.slice(lastOldPathIndexToBeRenamed);
            return `${newPath}${additionalOldPathPart}`;
        }

        return null;
    }

    static getFullDirectoryPath({
        directoryPath,
        directoryName,
    }: {
        directoryPath: string;
        directoryName: string;
    }): string {
        if (directoryPath === '/') {
            return `/${directoryName}`;
        }

        return `${directoryPath}/${directoryName}`;
    }

    static insertTaskDeliverable({
        deliverablesMap,
        deliverablePath,
        deliverable,
        skipInsertion = false,
    }: {
        deliverablesMap: TaskDeliverablesMap;
        deliverablePath: string;
        deliverable: TaskDeliverableResource;
        skipInsertion?: boolean;
    }) {
        // Copy directories map
        const copyTaskDeliverablesMap = { ...deliverablesMap };

        if (skipInsertion) {
            // Skip insertion if task deliverable is archived
            return copyTaskDeliverablesMap;
        }

        // Deliverable insertion
        if (copyTaskDeliverablesMap[deliverablePath]) {
            // Deliverable path key is already present in parsed deliverables object
            copyTaskDeliverablesMap[deliverablePath][deliverable.id] = deliverable;
        } else {
            // Deliverable path key is NOT present in parsed deliverables object
            copyTaskDeliverablesMap[deliverablePath] = {
                [deliverable.id]: deliverable,
            };
        }

        return copyTaskDeliverablesMap;
    }

    static insertDirectory({
        directoriesMap,
        directoryPath,
        skipInsertion = false,
    }: {
        directoriesMap: TaskDeliverablesDirectoriesMap;
        directoryPath: string;
        skipInsertion?: boolean;
    }): TaskDeliverablesDirectoriesMap {
        // Copy directories map
        const copyDirectoriesMap = { ...directoriesMap };

        if (skipInsertion) {
            // Skip insertion if task deliverable is archived
            return copyDirectoriesMap;
        }

        // Get current directory path
        let currentDirectoryPath = directoryPath;

        // Is all directories adjusted after inserting new directory
        let isDirectoriesMapUpdated = false;
        while (!isDirectoriesMapUpdated) {
            // Get directory name & path
            const { name: directoryName, path: pathWithoutDirectoryName } =
                TaskDeliverablesTreeUtils.getDirectoryNameWithPath({ path: currentDirectoryPath });

            // Set current directory path
            currentDirectoryPath = pathWithoutDirectoryName;
            if (currentDirectoryPath === '/') {
                isDirectoriesMapUpdated = true;
            }

            // Get directory names map for current directory path
            const directoryNamesMap = copyDirectoriesMap[currentDirectoryPath] ?? {};

            // Update directory names map for current directory path
            copyDirectoriesMap[currentDirectoryPath] = {
                ...directoryNamesMap,
                [directoryName]: true,
            };
        }

        return copyDirectoriesMap;
    }

    static removeDirectory({
        directoriesMap,
        directoryPath,
        directoryName,
        path,
    }: {
        directoriesMap: TaskDeliverablesDirectoriesMap;
        path: string;
        directoryPath: string;
        directoryName: string;
    }): TaskDeliverablesDirectoriesMap {
        const copyDirectoriesMap = { ...directoriesMap };

        if (path === directoryPath) {
            // Full match
            delete copyDirectoriesMap[path][directoryName];
            return copyDirectoriesMap;
        }

        const directoryPathWithName = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath,
            directoryName,
        });

        const shouldDirectoryBeRemoved = TaskDeliverablesTreeUtils.isSubPathPartOfPath({
            path,
            subPath: directoryPathWithName,
        });

        if (shouldDirectoryBeRemoved) {
            delete copyDirectoriesMap[path];
        }

        return copyDirectoriesMap;
    }

    static approveDirectory({
        deliverablesMap,
        directoryPath,
        directoryName,
        path,
    }: {
        deliverablesMap: TaskDeliverablesMap;
        path: string;
        directoryPath: string;
        directoryName: string;
    }): TaskDeliverablesMap {
        const copyTaskDeliverablesMap = { ...deliverablesMap };

        const fullDirectoryPathWithNameToRemove = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath,
            directoryName,
        });

        const shouldTaskDeliverableInDirectoryBeApproved = TaskDeliverablesTreeUtils.isSubPathPartOfPath({
            path,
            subPath: fullDirectoryPathWithNameToRemove,
        });

        if (shouldTaskDeliverableInDirectoryBeApproved) {
            const deliverablesMapAtPath = deliverablesMap[fullDirectoryPathWithNameToRemove];

            if (deliverablesMapAtPath) {
                // Deliverables exist at directory

                for (const deliverableId in deliverablesMapAtPath) {
                    // Get deliverable
                    const deliverable = deliverablesMapAtPath[deliverableId];

                    if (!deliverable.approved_at) {
                        // Approve only if not approved yet

                        const momentNowUtc = moment().utc().format();

                        // Approve
                        deliverable.approved_at = momentNowUtc;

                        // Update map with approved deliverable
                        deliverablesMapAtPath[deliverableId] = deliverable;
                    }
                }
            }
        }

        return copyTaskDeliverablesMap;
    }

    static getDirectoryNameWithPath({ path }: { path: string }) {
        // Get last slash index
        const lastPathSlashIndex = path.lastIndexOf('/');

        // Get directory name from path
        const directoryName = path.slice(lastPathSlashIndex + 1);

        // Get path without directory name
        let pathWithoutDirectoryName: string;
        if (lastPathSlashIndex === 0) {
            pathWithoutDirectoryName = '/';
        } else {
            pathWithoutDirectoryName = path.slice(0, lastPathSlashIndex);
        }

        return {
            name: directoryName,
            path: pathWithoutDirectoryName,
        };
    }

    static isPathPresent({ directories, path }: { directories: TaskDeliverablesDirectoriesMap; path: string }) {
        if (directories[path]) {
            // Path exists and it has no more directories inside
            return true;
        }

        const { name: directoryName, path: pathWithoutDirectoryName } =
            TaskDeliverablesTreeUtils.getDirectoryNameWithPath({ path });

        if (directories[pathWithoutDirectoryName] && directories[pathWithoutDirectoryName][directoryName]) {
            // Path exists and it has directories inside
            return true;
        }

        return false;
    }

    static getApprovedTaskDeliverables({ deliverablesMap }: { deliverablesMap: TaskDeliverablesMap }) {
        const approvedTaskDeliverables: TaskDeliverableResource[] = [];

        for (const path in deliverablesMap) {
            const deliverables = Object.values(deliverablesMap[path]);

            deliverables.forEach((d) => {
                if (d.approved_at !== null) {
                    // Approved
                    approvedTaskDeliverables.push({ ...d });
                }
            });
        }

        return approvedTaskDeliverables;
    }

    static getTaskDeliverablesArray({ deliverablesMap }: { deliverablesMap: TaskDeliverablesMap }) {
        const deliverablesArray: TaskDeliverableResource[] = [];

        for (const path in deliverablesMap) {
            const deliverables = Object.values(deliverablesMap[path]);
            deliverables.map((d) => deliverablesArray.push(d));
        }

        return deliverablesArray;
    }
}
