import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { $permissions } from '@app/containers/store/states';
import { usePageTitle } from '@app/hooks';
import {
    BreadcrumbProps,
    Button,
    Classes,
    Intent,
    Menu,
    MenuItem,
    Popover,
    Spinner,
    Tag,
    Tooltip,
} from '@blueprintjs/core';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import { Endpoints } from '@data/consts';
import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Aside from './components/Aside';
import BrandAssets from './components/BrandAssets';
import Colors from './components/Colors';
import Description from './components/Description';
import Fonts from './components/Fonts';
import { brandProfileApi } from './store/apis';
import { fetchBrandProfile, removeBrandProfile, restoreBrandProfile } from './store/effects';
import { resetBrandProfile, resetBrandProfileAssets } from './store/events';
import { $brandProfile } from './store/states';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { ToastUtils } from '@app/data/utils';

const BrandProfile: React.FC = () => {
    usePageTitle('Brand Profile');

    const navigate = useNavigate();
    const { brandProfileId } = useParams() as { brandProfileId: ID };

    const permissions = useStore($permissions);
    if (!permissions.isEnabled.brandProfile) {
        // TODO: Redirect to dashboard
    }

    const brandProfile = useStore($brandProfile);
    const isRemoving = useStore(removeBrandProfile.pending);
    const isRestoring = useStore(restoreBrandProfile.pending);

    useEffect(() => {
        if (!brandProfile) {
            return;
        }

        const breadcrumbItems: BreadcrumbProps[] = [
            {
                text: `${brandProfile.user.first_name} ${brandProfile.user.last_name}`,
                onClick: () => navigate(Endpoints.CLIENT_BRAND_PROFILES.replace(':clientId', brandProfile.user.id)),
            },
            { text: brandProfile.title },
        ];

        setPageBreadcrumbs(breadcrumbItems);
    }, [brandProfile?.id]);

    useEffect(() => {
        fetchBrandProfile(brandProfileId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Brand profile with ID of ${brandProfileId} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to clients page
                navigate(Endpoints.CLIENTS);
            }
        });
    }, [brandProfileId]);

    useEffect(() => {
        return () => {
            resetBrandProfile();
            resetBrandProfileAssets();
            resetPageBreadcrumbs();
        };
    }, []);
    if (!brandProfile) {
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderBrandProfileTitle = () => {
        const renderBrandProfileTags = () => {
            const tags: JSX.Element[] = [];

            if (brandProfile.archived_at) {
                tags.push(
                    <Tag key="archived-brand-profile-tag" intent={Intent.DANGER}>
                        Archived
                    </Tag>
                );
            }

            return tags;
        };

        return (
            <Flex direction="row">
                <Heading type="h3" className="mr-1">
                    {brandProfile.title}
                </Heading>
                {renderBrandProfileTags()}
            </Flex>
        );
    };

    const renderBrandProfileControls = () => {
        const isRemoveAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.ARCHIVE);
        const isRestoreAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.RESTORE);
        if (!isRemoveAllowed && !isRestoreAllowed) {
            return null;
        }

        const renderRemoveBrandProfileButton = () => {
            if (brandProfile.archived_at) {
                // Brand profile is already archived
                return null;
            }

            if (!isRemoveAllowed) {
                return null;
            }

            const handleRemoveBrandProfile = async () => {
                try {
                    await removeBrandProfile(brandProfile.id);
                    brandProfileApi.remove();
                } catch (e) {
                    // TODO: handle error
                }
            };

            return (
                <ConfirmationPopover
                    title="Are you sure want to remove brand profile?"
                    description="When confirmed, brand profile will be removed"
                    actions={[
                        <Button
                            intent={Intent.DANGER}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleRemoveBrandProfile}
                        >
                            Yes, remove
                        </Button>,
                    ]}
                >
                    <Button minimal loading={isRemoving} disabled={isRemoving} icon="trash" intent={Intent.DANGER}>
                        Remove brand profile
                    </Button>
                </ConfirmationPopover>
            );
        };

        const renderRestoreBrandProfileButton = () => {
            if (!brandProfile.archived_at) {
                // Brand profile is NOT archived
                return null;
            }

            if (!isRestoreAllowed) {
                return null;
            }

            const handleRestoreBrandProfile = async () => {
                try {
                    await restoreBrandProfile(brandProfile.id);
                    brandProfileApi.restore();
                } catch (e) {
                    // TODO: handle error
                }
            };

            return (
                <ConfirmationPopover
                    title="Are you sure want to restore brand profile?"
                    description="When confirmed, brand profile will be restored"
                    actions={[
                        <Button
                            intent={Intent.SUCCESS}
                            className={Classes.POPOVER_DISMISS}
                            onClick={handleRestoreBrandProfile}
                        >
                            Yes, restore
                        </Button>,
                    ]}
                >
                    <Button loading={isRestoring} disabled={isRestoring} outlined intent={Intent.SUCCESS}>
                        Restore brand profile
                    </Button>
                </ConfirmationPopover>
            );
        };

        return (
            <Flex direction="row">
                {renderRemoveBrandProfileButton()}
                {renderRestoreBrandProfileButton()}
            </Flex>
        );
    };

    const renderCopyInformation = () => {
        const handleCopy = (copyText: string) => {
            navigator.clipboard.writeText(copyText);
        };

        const renderMenu = () => {
            return (
                <Menu>
                    <MenuItem icon="binary-number" text="Copy brand ID" onClick={() => handleCopy(brandProfile.id)} />
                    <MenuItem
                        icon="link"
                        text="Copy brand URL"
                        onClick={() =>
                            handleCopy(
                                `${process.env.INTERNAL_PLATFORM_URL}${Endpoints.BRAND_PROFILE.replace(
                                    ':brandProfileId',
                                    brandProfile.id
                                )}`
                            )
                        }
                    />
                    <MenuItem icon="highlight" text="Copy brand title" onClick={() => handleCopy(brandProfile.title)} />
                </Menu>
            );
        };

        return (
            <Popover content={renderMenu()}>
                <Tooltip content="Click to copy">
                    <Button minimal icon="duplicate" />
                </Tooltip>
            </Popover>
        );
    };

    return (
        <FixedWidthPageContainer>
            <Flex className="mb-2" align="center" justify="space-between">
                <Flex direction="row" align="center">
                    {renderBrandProfileTitle()}
                    {renderCopyInformation()}
                </Flex>

                {renderBrandProfileControls()}
            </Flex>

            <Grid container>
                <Grid lg={3} xs={12}>
                    <Aside className="mb-2" />
                </Grid>
                <Grid lg={9} xs={12}>
                    <Grid container>
                        <Grid className="mb-2" lg={12}>
                            <Description />
                        </Grid>
                        <Grid className="mb-2" lg={4}>
                            <Fonts />
                        </Grid>
                        <Grid className="mb-2" lg={8}>
                            <Colors />
                        </Grid>
                        {permissions.isEnabled.brandProfileAsset && (
                            <Grid lg={12}>
                                <BrandAssets />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default BrandProfile;
