import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Checkbox, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import InputFormField from '@components/InputFormField';
import { $authorizedUser } from '@containers/store/states';
import { taskApi, taskStateTransitionLogApi } from '@pages/Task/store/apis';
import { QuotaType, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPublishOutputQueue } from 'dy-frontend-http-repository/lib/data/enums';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useState } from 'react';
import { PublishTaskStep } from '../../enums';
import { useTaskPublish } from '../../hooks';
import { changeStep } from '../../store/events';
import { $clientComputedComputedLicence, $clientQuotaUsage } from '../../store/states';
import { CommonStepProps } from '../../types';
import Heading from '@components/Heading';
import DevText from '@components/Text';

export type Props = CommonStepProps;

const manualTaskPublishCommentValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];

const PublishTaskViaQuotaStep: React.FC<Props> = ({ closeModal, data }) => {
    const { handlePublishActiveTaskViaQuota, handlePublishBacklogTaskViaQuota } = useTaskPublish({
        taskId: data.taskId,
        shouldCheckTaskPublishChoices: false,
    });

    const me = useStore($authorizedUser);
    const clientQuotaUsage = useStore($clientQuotaUsage);
    const clientComputedLicence = useStore($clientComputedComputedLicence);

    const [publishOutputQueue, setPublishOutputQueue] = useState<TaskPublishOutputQueue>(TaskPublishOutputQueue.ACTIVE);

    const quotaTaskPublishComment = useTextFormField({
        id: 'quota-task-publish-comment',
        validators: manualTaskPublishCommentValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [quotaTaskPublishComment],
        apiCall: async () => {
            try {
                let publishTaskFunction: () => Promise<unknown>;
                switch (publishOutputQueue) {
                    case TaskPublishOutputQueue.ACTIVE:
                        publishTaskFunction = () =>
                            handlePublishActiveTaskViaQuota({ comment: quotaTaskPublishComment.value });
                        break;
                    case TaskPublishOutputQueue.BACKLOG:
                        publishTaskFunction = () =>
                            handlePublishBacklogTaskViaQuota({ comment: quotaTaskPublishComment.value });
                        break;
                }

                // Publish task
                const momentNowUtc = moment().utc().format();
                const response = await publishTaskFunction!();
                taskApi.transitionState({ state: TaskState.PUBLISHED });
                taskStateTransitionLogApi.update([
                    {
                        comment: '',
                        applied_at: momentNowUtc,
                        is_forced: false,
                        state: TaskState.PUBLISHED,
                        user: {
                            first_name: me!.user.first_name,
                            id: me!.user.id,
                            last_name: me!.user.last_name,
                            image_hash: me!.user.image_hash,
                            type: me!.user.type,
                            role: me!.user.role
                        },
                    },
                ]);

                return { response };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!me) {
        return null;
    }

    const renderTaskCounters = () => {
        // Resolve quota for active/backlog tasks
        const activeTaskQuota = clientComputedLicence?.getQuotaValue(QuotaType.USER_ACTIVE_TASK) ?? 0;
        const backlogTaskQuota = clientComputedLicence?.getQuotaValue(QuotaType.USER_BACKLOG_TASK) ?? 0;

        // Resolve current usages for active/backlog
        const currentActiveTaskQuotaUsage = clientQuotaUsage?.getValue(QuotaType.USER_ACTIVE_TASK) ?? 0;
        const currentBacklogTaskQuotaUsage  = clientQuotaUsage?.getValue(QuotaType.USER_BACKLOG_TASK) ?? 0;

        return (
            <Flex className="mb-4">
                <Flex className="mr-4" direction="column">
                    <DevText muted className="mb-1">
                        Active requests
                    </DevText>

                    <DevText>
                        {currentActiveTaskQuotaUsage} / {activeTaskQuota}
                    </DevText>
                </Flex>

                <Flex direction="column">
                    <DevText muted className="mb-1">
                        Backlog requests
                    </DevText>

                    <DevText>
                        {currentBacklogTaskQuotaUsage} / {backlogTaskQuota}
                    </DevText>
                </Flex>
            </Flex>
        );
    };

    return (
        <div>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Publish using quota</Heading>
                <Button minimal icon="cross" onClick={closeModal} />
            </Flex>

            <Divider className="mb-2" />

            {renderTaskCounters()}

            <form onSubmit={form.handleFormSubmit}>
                {/* Comment*/}
                <InputFormField
                    field={quotaTaskPublishComment}
                    formGroupProps={{ label: 'Publish comment' }}
                    inputProps={{ placeholder: 'Enter publish comment' }}
                />

                {/* Publish output queue */}
                <Checkbox
                    checked={publishOutputQueue === TaskPublishOutputQueue.BACKLOG}
                    label='Publish to "Backlog"'
                    onChange={() =>
                        setPublishOutputQueue((prevValue) =>
                            prevValue === TaskPublishOutputQueue.ACTIVE
                                ? TaskPublishOutputQueue.BACKLOG
                                : TaskPublishOutputQueue.ACTIVE
                        )
                    }
                />
                <Flex justify="flex-end">
                    <Button className="mr-1" outlined onClick={() => changeStep(PublishTaskStep.PUBLISH_METHOD_SELECT)}>
                        Select other method
                    </Button>
                    <Button
                        disabled={form.hasFieldErrors}
                        loading={form.isSubmitting}
                        type="submit"
                        intent={Intent.PRIMARY}
                    >
                        Publish
                    </Button>
                </Flex>
            </form>
        </div>
    );
};

export default PublishTaskViaQuotaStep;
