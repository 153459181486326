import React, { HTMLAttributes, useEffect, useState } from 'react';
import Heading from '@components/Heading';
import { Button, Card, HTMLSelect } from '@blueprintjs/core';
import Flex from '@components/Flex';
import DevText from '@components/Text';
import { useStore } from 'effector-react';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { $userIdentity } from '@containers/components/UserSettings/store/states';
import { UserRole, UserType } from 'dy-frontend-http-repository/lib/data/enums';
import { userRoleInformation } from '@data/consts';
import repository from 'dy-frontend-http-repository/lib/repository';
import { userIdentityApi } from '@containers/components/UserSettings/store/apis';

export type Props = HTMLAttributes<HTMLDivElement>;

const RoleManagement: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const identity = useStore($userIdentity);

    const [role, setRole] = useState<UserRole>(UserRole.CORE_DESIGNER);

    // TODO: I KNOW THAT I DO NOT NEED TO WRITE SUCH CODE HERE BUT I JUST DON'T CARE ANYMORE
    const [isRoleUpdating, setIsRoleUpdating] = useState(false);
    const handleCoreUserRoleUpdate = (userId: ID, nextRole: UserRole) => {
        setIsRoleUpdating(true);
        repository
            .coreUser()
            .setRole(userId, { role: nextRole })
            .then(() => {
                setRole(nextRole);
                userIdentityApi.setRole({ role: nextRole });
            })
            .catch(console.error) // TODO: HANDLE AHAHHAHAHAH:)))))00
            .finally(() => setIsRoleUpdating(false));
    };

    useEffect(() => {
        if (!identity) {
            return;
        }

        setRole(identity.role);
    }, [identity]);

    if (!identity || !me) {
        return null;
    }

    // Not allowed to change role of workspace(heh) or client users
    if ([UserType.CLIENT, UserType.WORKSPACE].includes(identity.type)) {
        return null;
    }

    const renderUpdateRoleControl = () => {
        if (!identity) {
            return null;
        }

        // TODO: Auth check

        const isChangeRoleAllowed = [
            UserRole.CORE_ROOT,
            UserRole.CORE_ADMIN,
            UserRole.CORE_PLATFORM_DEV,
            UserRole.CORE_OPERATIONS_MANAGER,
        ].includes(me.user.role);

        // List of assignable roles
        const allowed = [
            UserRole.CORE_ADMIN,
            UserRole.CORE_OPERATIONS_MANAGER,
            UserRole.CORE_ACCOUNT_MANAGER,
            UserRole.CORE_HR,
            UserRole.CORE_PROJECT_MANAGER,
            UserRole.CORE_ART_DIRECTOR,
            UserRole.CORE_ASSISTANT_ART_DIRECTOR,
            UserRole.CORE_DESIGNER,
        ];

        return (
            <Flex align="center">
                <DevText muted className="mr-1">
                    Change to:
                </DevText>
                <HTMLSelect
                    disabled={!isChangeRoleAllowed}
                    value={role}
                    onChange={(e) => setRole(e.currentTarget.value as UserRole)}
                    className="mr-1"
                >
                    {allowed.map((r) => (
                        <option value={r}>{userRoleInformation[r].label}</option>
                    ))}
                </HTMLSelect>
                {isChangeRoleAllowed && role !== identity.role && (
                    <Button
                        disabled={role === identity.role}
                        loading={isRoleUpdating}
                        onClick={() => handleCoreUserRoleUpdate(identity.user_id, role)}
                    >
                        Change
                    </Button>
                )}
            </Flex>
        );
    };

    return (
        <div {...props}>
            <Heading type="h3" className="mb-1">
                User role
            </Heading>
            <Card compact>
                <Flex align="center" justify="space-between" className="mb-small">
                    <Heading type="h4">Role: {userRoleInformation[identity.role].label}</Heading>
                    {renderUpdateRoleControl()}
                </Flex>
                <DevText muted>Please note that changing user role might affect data user can access</DevText>
            </Card>
        </div>
    );
};

export default RoleManagement;
