import DevText from '@app/components/Text';
import { $taskDeliverablesTree } from '@app/containers/pages/Task/store/states';
import { Button, Card, Divider, Elevation, FormGroup, InputGroup, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import Image from '@components/Image';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { TaskDeliverableGoogleEmbed } from '@pages/Task/pages/TaskDeliverables/valueObjects';
import { googleEmbedTypesConfiguration } from '@pages/Task/pages/TaskDeliverables/valueObjects/TaskDeliverableGoogleEmbed/consts';
import { GoogleEmbedType } from '@pages/Task/pages/TaskDeliverables/valueObjects/TaskDeliverableGoogleEmbed/enums';
import { TextFormatUtils, TimeUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { useCreateEmbedTaskDeliverable } from '../../../../hooks';
import { $currentPathLocation } from '../../../../store/states';
import { IconCard } from './styled';

export interface CreateTaskDeliverableGoogleEmbedModalProps {
    taskId: ID;
}

type Props = ModalProps<CreateTaskDeliverableGoogleEmbedModalProps>;

const CreateTaskDeliverableGoogleEmbedModal: React.FC<Props> = ({ closeModal, data }) => {
    const { createEmbedTaskDeliverable } = useCreateEmbedTaskDeliverable();

    const currentPathLocation = useStore($currentPathLocation);
    const taskDeliverableTree = useStore($taskDeliverablesTree);

    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    const [selectedGoogleEmbedType, setSelectedGoogleEmbedType] = useState<GoogleEmbedType | null>(null);
    const [title, setTitle] = useState('');
    // TODO: add title validation
    const [link, setLink] = useState('');
    const [isLinkValid, setIsLinkValid] = useState(false);

    useEffect(() => {
        if (!selectedGoogleEmbedType) {
            return;
        }

        const validateLink = TimeUtils.debounce(() => {
            const isLinkValid = new TaskDeliverableGoogleEmbed(link).isTypeOf(selectedGoogleEmbedType);
            setIsLinkValid(isLinkValid);
        });

        validateLink();
    }, [link, selectedGoogleEmbedType]);

    if (!data) {
        closeModal?.();
        return null;
    }

    if (!taskDeliverableTree) {
        return null;
    }

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!isLinkValid) {
            return;
        }

        setIsFormSubmitting(true);

        try {
            // Create task deliverable embed
            await createEmbedTaskDeliverable({ taskId: data.taskId, path: currentPathLocation, title, url: link });

            // Close modal
            closeModal?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const renderGoogleEmbedTypeIcons = () => {
        // TODO: add name under icon to see what type of embed it is
        return (
            <Grid className="mb-2" container>
                {Object.entries(googleEmbedTypesConfiguration).map(([key, { title, icon }]) => {
                    const googleEmbedType = key as unknown as GoogleEmbedType;

                    return (
                        <Grid lg={3}>
                            <IconCard
                                compact
                                interactive={googleEmbedType !== selectedGoogleEmbedType}
                                elevation={Elevation.ONE}
                                $active={googleEmbedType === selectedGoogleEmbedType}
                                key={googleEmbedType}
                                onClick={() => setSelectedGoogleEmbedType(googleEmbedType)}
                            >
                                <Flex direction="column" className="mb-small" align="center" justify="center">
                                    <Image
                                        className="mb-1"
                                        width="100px"
                                        height="100px"
                                        objectFit="contain"
                                        src={icon}
                                    />

                                    <DevText>{title}</DevText>
                                </Flex>
                            </IconCard>
                        </Grid>
                    );
                })}
            </Grid>
        );
    };

    const renderLinkInput = () => {
        if (selectedGoogleEmbedType === null) {
            return null;
        }

        return (
            <FormGroup label="Link">
                <InputGroup
                    intent={isLinkValid ? Intent.NONE : Intent.DANGER}
                    placeholder="Enter link"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                />
            </FormGroup>
        );
    };

    const renderGoogleEmbedInstructions = () => {
        if (selectedGoogleEmbedType === null) {
            return null;
        }

        const configuration = googleEmbedTypesConfiguration[selectedGoogleEmbedType];

        return (
            <div>
                <DevText className="mb-1">How to embed your {configuration.title}</DevText>
                {configuration.instructions.map((i, index) => (
                    <DevText className={index === 0 ? '' : 'mt-small'}>
                        {index + 1}. {i}
                    </DevText>
                ))}
            </div>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create google embed deliverable</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={handleFormSubmit}>
                    <FormGroup label="Title">
                        <InputGroup
                            intent={title.trim().length === 0 ? Intent.DANGER : Intent.NONE}
                            placeholder="Enter title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />
                    </FormGroup>

                    {renderGoogleEmbedTypeIcons()}
                    {renderLinkInput()}
                    {renderGoogleEmbedInstructions()}

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            disabled={!isLinkValid || title.trim().length === 0}
                            loading={isFormSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateTaskDeliverableGoogleEmbedModal;
