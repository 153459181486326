import Box, { Props as BoxProps } from '@components/Box';
import FileExtensionIcon from '@components/FileExtensionIcon';
import FileUploadProgress from '@components/FileUploadProgress';
import Image, { ImageProps } from '@components/Image';
import React, { ReactNode } from 'react';
import { FileUtils } from 'dy-frontend-shared/lib/utils';

export interface FilePreviewProps {
    // If src presence, do not do any other check and use it as a src (used for previews), whenever turned off will check if extensions is image and can be shown if not, then file extension icon will be used
    useOnlySrcPresenceCheck?: boolean;

    // Flag should file viewer be shown whenever clicking file preview
    withViewer?: boolean;

    // Flag file preview loading
    loading?: boolean;

    // File extension
    extension: string;

    // File loading progress
    progress?: number;

    // File src
    src?: string | null;

    // File alt to show instead of file src if something goes wrong
    alt?: string;

    // Object fit like in an img
    objectFit?: ImageProps['objectFit'];

    // Actions
    actions?: ReactNode;
}

export type Props = FilePreviewProps & BoxProps;

const FilePreview: React.FC<Props> = ({
    useOnlySrcPresenceCheck = false,
    withViewer = false,
    loading = false,
    extension,
    progress = 0,
    src,
    alt = '',
    objectFit = 'contain',
    actions,
    ...props
}) => {
    const renderContent = () => {
        if (loading) {
            return <FileUploadProgress width="100%" height="100%" progress={progress < 1 ? progress : undefined} />;
        }

        if (useOnlySrcPresenceCheck && src) {
            return (
                <Image borderRadius="inherit" width="100%" height="100%" objectFit={objectFit} src={src} alt={alt} />
            );
        } else {
            const isImage = FileUtils.isImage(extension) && !!src;
            if (isImage) {
                return (
                    <Image
                        borderRadius="inherit"
                        width="100%"
                        height="100%"
                        objectFit={objectFit}
                        src={src}
                        alt={alt}
                    />
                );
            } else {
                return <FileExtensionIcon padding="5px" width="100%" height="100%" extension={extension} />;
            }
        }
    };

    return (
        <Box position="relative" {...props}>
            {renderContent()}
            {actions && (
                <Box position="absolute" top="0px" right="0px">
                    {actions}
                </Box>
            )}
        </Box>
    );
};

export default FilePreview;
