import { FileInformation } from '@app/hooks/useUpload/types';
import { FileResource } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/resources';
import { FileUtils } from 'dy-frontend-shared/lib/utils';

export default class FileInformationToBrandAssetFileResourceMapper {
    public static transform(fileInformation: FileInformation): FileResource | null {
        const file = fileInformation.file;
        const verifiedFileResource = fileInformation.verifiedFileResource;

        if (!verifiedFileResource) {
            return null;
        }

        return {
            id: verifiedFileResource.id,
            url: verifiedFileResource.url,
            preview_path: verifiedFileResource.preview_path,
            extension: FileUtils.getFileExtension(file.name),
            mime: file.type,
            original_name: file.name,
            size: file.size,
        };
    }
}
