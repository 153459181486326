import InputFormField from '@app/components/InputFormField';
import { useForm, useTextFormField } from '@app/hooks';
import {
    getStringDirectoryNameValidator,
    getStringMaxLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { useStore } from 'effector-react';
import React from 'react';
import { useCreateDirectory, useRenameDirectory } from '@pages/Task/pages/TaskDeliverables/hooks';
import { $taskDeliverablesTree } from '@app/containers/pages/Task/store/states';

export interface UpsertTaskDeliverableDirectoryModalProps {
    directoryPath: string;
    directoryName?: string;
}

type Props = ModalProps<UpsertTaskDeliverableDirectoryModalProps>;

const directoryNameValidators = [
    getStringRequiredValidator(),
    getStringDirectoryNameValidator(),
    getStringMaxLengthValidator({ maxStringLength: 50 }),
];

const UpsertTaskDeliverableDirectoryModal: React.FC<Props> = ({ closeModal, data }) => {
    const isCreatingDirectory = data?.directoryName === undefined;
    const modalTitle = isCreatingDirectory ? 'Create new directory' : 'Rename directory';
    const submitButtonText = isCreatingDirectory ? 'Create' : 'Rename';

    const { createDirectory } = useCreateDirectory();
    const { renameDirectory } = useRenameDirectory();

    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    const directoryName = useTextFormField({
        id: 'directory-name',
        validators: directoryNameValidators,
        initialValue: data?.directoryName ?? '',
    });

    const form = useForm<boolean>({
        fields: [directoryName],
        apiCall: async () => {
            try {
                if (isCreatingDirectory) {
                    // Create directory
                    createDirectory({
                        directoryPath: data!.directoryPath,
                        directoryName: directoryName.value,
                    });
                } else {
                    // Update directory name
                    await renameDirectory({
                        commonDirectoryPath: data.directoryPath,
                        newDirectoryName: directoryName.value,
                        oldDirectoryName: data.directoryName!,
                        taskDeliverablesTree: taskDeliverablesTree!,
                    });
                }

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!taskDeliverablesTree) {
        return null;
    }

    if (!data) {
        closeModal?.();
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">{modalTitle}</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <InputFormField
                        field={directoryName}
                        formGroupProps={{ label: 'Directory name' }}
                        inputProps={{ placeholder: 'Enter directory name' }}
                    />

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            {submitButtonText}
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpsertTaskDeliverableDirectoryModal;
