import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import NotificationTemplate from '../../../../NotificationTemplate';
import { NotificationResource, TaskResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints } from '@app/data/consts';

export interface TaskDeadlineUpdatedNotificationProps {
    notification: NotificationResource;
    task: TaskResource | null;
    dismiss: () => Promise<void>;
}

type Props = TaskDeadlineUpdatedNotificationProps;

const TaskDeadlineUpdatedNotification: React.FC<Props> = ({ notification, task, dismiss }) => {
    const renderContent = () => {
        if (!task) {
            // This is bad, task is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Request was not found</DevText>;
        }

        return (
            <div>
                <DevText running className="mb-1">
                    Deadline will be expired soon for{' '}
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.TASK_INFORMATION.replace(':taskId', task.id)}
                    >
                        {task.title}
                    </RouterLink>
                </DevText>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="Deadline approaching"
            icon="time"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default TaskDeadlineUpdatedNotification;
