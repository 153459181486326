import { checkValidators } from '@app/hooks/validation/functions';
import { ValidatorFunction } from '@app/hooks/validation/types';
import { Intent } from '@blueprintjs/core';
import axios, { Method } from 'axios';
import { FilePurpose } from 'dy-frontend-http-repository/lib/data/enums';
import {
    VerifiedFileResource,
    SignedUploadFormResource,
    UploadIntentResource,
} from 'dy-frontend-http-repository/lib/modules/File/resources';
import repository from 'dy-frontend-http-repository/lib/repository';
import { ToastUtils } from '@data/utils';
import { useEffect, useMemo, useState } from 'react';
import { FileInformationStatus } from './enums';
import { FileGroup, FileGroupsMap, FileInformation } from './types';

export interface UseUploadProps {
    clearFilesOnUploadSuccess?: boolean;
    validators?: ValidatorFunction<File>[];
    onUploadSuccess?: (files: FileInformation[]) => void;
}

export type Props = UseUploadProps;
/*
    fileGroupsMap = {
        "gkjg95jg9": {
            "gdfkjgkdfjgh": FileInformation,
            "bij9540jh904": FileInformation,
            "cdfkjgkdfjgh": FileInformation,
            "zigf540jh94c": FileInformation,
            "afgjgkdfzxc": FileInformation,
            "hij9540jhiii": FileInformation,
        },
        "g85485485": {
            "vcxvbtbtbtbb": FileInformation,
            "h934jdkacnfh": FileInformation,
            "lA94JFKDkgj4": FileInformation,
        }
    }

    Preparation file uploading process:
    1. Check that array File[] have files to upload
    2. If there are validators then need to check every file to each validator from the array of validators, and show error toast if any errors occur, valid files will be uploaded, not valid files will skip uploading
    3. Split files in the groups of 10, go over each of the group in the cycle and upload files one by one
    
    File group uploading process:
    1. Create key for file group from map
    2. Go over each file, create key for each of the file and add file information to the group with initial values: key, status: UPLOADING, progress: 0, file and resource (will be populated after file verification, for now just null) then update the file group map state
    3. Create signed upload intent for each file using API endpoint, file key can be used as a key for intent, so file information could be easily accessed later, if error occur while creating signed upload intent then update file information status to ERROR_CREATING_FILE_FORM_INTENT
    4. Upload files to S3, go over each created signed upload intent, get file key from intent and use it to get file information from file groups map and upload file to S3, on this step you can also add event to monitor progress and update file information in file groups map, to collect uploaded file resource id to file key map, which will be needed for file verification later on, if error occur while uploading file to S3, then update file information status to ERROR_UPLOADING_TO_S3
    5. Verify uploaded to S3 files, after verification get file resource and update each file information in file groups map from the group using id to file key map collected in step 4, change status for each file to VERIFIED, if error occur while verifying files, then update all states of the files from the group to ERROR_VERIFICATION
    6. Can return map where all groups merged to one group with the structure of key to file information map

    Other essential processes:
    1. useEffect checks if at least 1 file information has status of UPLOADING if so, then useEffect stop running otherwise it call onUploadSuccess callback and clear file group map if clearFilesOnUploadSuccess prop present and it's true after that isUploading flag is set to false and process of file uploading is finished

    Things which bothers me relative to file uploading:
    1. In TaskMessages we convert files which are uploaded FileInformation[] to AttachedFile[] which is used in RichEditor to showed attached files to rich editor and after successful creation/update of the task message with attached files we need to fetch newly created task messages to get updated task message resource, replace it in the local state and if ever be updated again, we'll need to convert task message files FileAttachmentResource[] to AttachedFile[]. To conclude we needed to convert FileInformation[] to AttachedFile[] and FileAttachmentResource[] to AttachedFile[] and this is only case with rich editor, there are probably other places where files should be converted to props for FileTilePreview where list of files is shown, etc., the problem with this is that there are so many different resources we have to convert sometimes to or from that it start looking like that this is too much code and can be simplified or structured better to not write too much code for each case where we need to convert FileInformation[] to some other type.
*/

const fileGroupSize = 10;
type FileResourceIdToFileInformationKeyMap = { [resourceId in ID]: string };

/**
 * Validate files and then return only valid files
 * @param files files to validate
 * @param validators validators to check over each file
 * @returns {string} unique key
 */
const validateAndGetFiles = async (files: File[], validators: ValidatorFunction<File>[]) => {
    if (validators.length === 0) {
        // No validators, all files are valid
        return files;
    }

    const validFiles: File[] = [];
    for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // Validate file
        const validationResult = await checkValidators(file, validators);
        if (validationResult === null) {
            // Valid
            validFiles.push(file);
        } else {
            // Show validation error message
            ToastUtils.showToast({ message: validationResult, intent: Intent.DANGER });
        }
    }

    return validFiles;
};

/**
 * Generate unique key
 * @returns {string} unique key
 */
const generateUniqueKey = () => {
    return Math.random().toString(16).slice(2);
};

/**
 * Create initial file group
 * @param files files
 * @returns {FileGroup} created file group
 */
const createInitialFileGroup = (files: File[]): FileGroup => {
    // Create initial file group
    const fileGroups: FileGroup = {};

    for (const file of files) {
        // Generate file information key
        const fileInformationKey = generateUniqueKey();

        // Create initial file information
        fileGroups[fileInformationKey] = {
            key: fileInformationKey,
            status: FileInformationStatus.UPLOADING,
            progress: 0,
            file,
            verifiedFileResource: null,
        };
    }

    return fileGroups;
};

/**
 * Create file storage form data which will be used while uploading to storage service
 * @param file file group key
 * @param form file group
 * @returns {FormData} form data
 */
const createStorageFormDataForFile = (file: File, form: SignedUploadFormResource): FormData => {
    const formData = new FormData();

    for (const key in form.data) {
        formData.append(key, form.data[key]);
    }

    formData.append('file', file);

    return formData;
};

const useUpload = ({ onUploadSuccess, clearFilesOnUploadSuccess = true, validators = [] }: Props) => {
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadingMultipleGroups, setIsUploadingMultipleGroups] = useState(false);
    const [fileGroupsMap, setFileGroupsMap] = useState<FileGroupsMap>({});

    console.log('fileGroupsMap: ', fileGroupsMap);

    // Get array of files information
    const fileInformationArray = useMemo(() => {
        if (Object.keys(fileGroupsMap).length === 0) return [];

        let informationArray: FileInformation[] = [];
        for (const fileGroupKey in fileGroupsMap) {
            const fileGroup = fileGroupsMap[fileGroupKey];
            informationArray = [...informationArray, ...Object.values(fileGroup)];
        }

        return informationArray;
    }, [fileGroupsMap]);

    useEffect(() => {
        if (Object.keys(fileGroupsMap).length === 0) {
            // There are no file groups
            return;
        }

        if (isUploadingMultipleGroups) {
            // Still processing multiple chunks
            return;
        }

        for (const fileGroupKey in fileGroupsMap) {
            const fileGroup = fileGroupsMap[fileGroupKey];
            if (
                Object.values(fileGroup).some(
                    (fileInformation) => fileInformation.status === FileInformationStatus.UPLOADING
                )
            ) {
                // At least 1 file is still uploading
                return;
            }
        }

        if (onUploadSuccess) {
            // Call files upload success callback
            onUploadSuccess([...fileInformationArray]);
        }

        if (clearFilesOnUploadSuccess) {
            // Clear file groups map
            setFileGroupsMap({});
        }

        // Set uploading flag to false
        setIsUploading(false);

        // eslint-disable-next-line
    }, [fileInformationArray, isUploadingMultipleGroups]);

    /**
     * Create and get signed upload intent for file group
     * @param purpose file group purpose
     * @param fileGroupKey file group key
     * @param fileGroup file group
     * @returns {Promise<UploadIntentResource[]>} upload intent resource array
     */
    const createAndGetSignedUploadIntentsForFileGroup = async (
        purpose: FilePurpose,
        fileGroupKey: string,
        fileGroup: FileGroup
    ): Promise<UploadIntentResource[]> => {
        let signedUploadIntents: UploadIntentResource[] = [];
        try {
            signedUploadIntents = await repository.file().createUploadIntent({
                purpose,
                files: Object.values(fileGroup).map((fileInformation) => ({
                    key: fileInformation.key,
                    content_length: fileInformation.file.size,
                    content_type: fileInformation.file.type,
                    original_name: fileInformation.file.name,
                })),
            });
        } catch (e) {
            setFileGroupsMap((prevFileGroupsMap) => {
                const fileGroup = { ...prevFileGroupsMap[fileGroupKey] };

                for (const fileInformation of Object.values(fileGroup)) {
                    fileInformation.status = FileInformationStatus.ERROR_CREATING_FILE_FORM_INTENT;
                }

                return {
                    ...prevFileGroupsMap,
                    [fileGroupKey]: fileGroup,
                };
            });

            throw new Error('Error while creating signed upload intents for file group');
        }

        return signedUploadIntents;
    };

    /**
     * Upload file group to storage service
     * @param fileGroupKey file group key
     * @param fileGroup file group
     * @param fileGroupUploadIntents file group uploaded intents
     * @returns {FileResourceIdToFileInformationKeyMap} file resource ID to file information key map
     */
    const uploadFileGroupToStorageService = async (
        fileGroupKey: string,
        fileGroup: FileGroup,
        fileGroupUploadIntents: UploadIntentResource[]
    ): Promise<FileResourceIdToFileInformationKeyMap> => {
        const fileResourceIdToFileInformationKeyMap: FileResourceIdToFileInformationKeyMap = {};

        for (const intent of fileGroupUploadIntents) {
            // Get file information key
            const fileInformationKey = intent.key;

            // Get file from file group by key
            const file = fileGroup[fileInformationKey].file;

            // Form
            const signedUploadForm = intent.form;
            const signedUploadFormData = createStorageFormDataForFile(file, signedUploadForm);

            try {
                await axios({
                    method: signedUploadForm.method as Method,
                    url: signedUploadForm.action,
                    data: signedUploadFormData,
                    headers: {
                        'Content-Type': signedUploadForm.enctype,
                    },
                    onUploadProgress: (event) => {
                        const { total, loaded } = event;

                        // Calculate & update progress value for file information
                        setFileGroupsMap((prevFileGroupsMap) => {
                            const fileGroup = { ...prevFileGroupsMap[fileGroupKey] };
                            const fileInformation = fileGroup[fileInformationKey];
                            fileInformation.progress = loaded / total;

                            return {
                                ...prevFileGroupsMap,
                                [fileGroupKey]: fileGroup,
                            };
                        });
                    },
                });

                fileResourceIdToFileInformationKeyMap[intent.file_id] = intent.key;
            } catch (e) {
                setFileGroupsMap((prevFileGroupsMap) => {
                    const fileGroup = { ...prevFileGroupsMap[fileGroupKey] };
                    const fileInformation = fileGroup[fileInformationKey];
                    fileInformation.status = FileInformationStatus.ERROR_FILE_UPLOADING_TO_S3;

                    return {
                        ...prevFileGroupsMap,
                        [fileGroupKey]: fileGroup,
                    };
                });
            }
        }

        return fileResourceIdToFileInformationKeyMap;
    };

    /**
     * Upload file group to storage service
     * @param fileGroupKey file group key
     * @param fileIds file ID array to verify
     * @returns {VerifiedFileResource[]} verified files resources
     */
    const verifyUploadedFileGroupFiles = async (
        fileGroupKey: string,
        fileIds: ID[]
    ): Promise<VerifiedFileResource[]> => {
        let verifiedFiles: VerifiedFileResource[] = [];

        try {
            verifiedFiles = await repository.file().verifySignedUpload({ file_ids: fileIds });
        } catch (e) {
            setFileGroupsMap((prevFileGroupsMap) => {
                const fileGroup = { ...prevFileGroupsMap[fileGroupKey] };

                for (const fileInformation of Object.values(fileGroup)) {
                    fileInformation.status = FileInformationStatus.ERROR_FILE_VERIFICATION;
                }

                return {
                    ...prevFileGroupsMap,
                    [fileGroupKey]: fileGroup,
                };
            });

            throw new Error('Error while verifying files');
        }

        return verifiedFiles;
    };

    /**
     * Upload single file group
     * @param purpose files purpose
     * @param files files which should be uploaded
     * @returns {VerifiedFileResource[]} verified files array
     */
    const handleUploadFileGroup = async (purpose: FilePurpose, files: File[]) => {
        // Generate file group key
        const fileGroupKey = generateUniqueKey();

        // Initialize file group
        const fileGroup: FileGroup = createInitialFileGroup(files);
        setFileGroupsMap((prevFileGroupsMap) => ({
            ...prevFileGroupsMap,
            [fileGroupKey]: fileGroup,
        }));

        // 1. Create file group files upload intents
        console.log('1. Create file group files upload intents');
        let fileGroupUploadedIntents: UploadIntentResource[] = [];
        try {
            fileGroupUploadedIntents = await createAndGetSignedUploadIntentsForFileGroup(
                purpose,
                fileGroupKey,
                fileGroup
            );
        } catch (e) {
            throw e;
        }

        // 2. Upload file group to storage service
        console.log('2. Upload file group to storage service');
        let fileResourceIdToFileInformationKeyMap: FileResourceIdToFileInformationKeyMap = {};
        try {
            fileResourceIdToFileInformationKeyMap = await uploadFileGroupToStorageService(
                fileGroupKey,
                fileGroup,
                fileGroupUploadedIntents
            );
        } catch (e) {
            throw e;
        }

        // 3. Verify uploaded files
        const fileIdsToVerify = Object.keys(fileResourceIdToFileInformationKeyMap);
        let verifiedFileResources: VerifiedFileResource[] = [];
        if (fileIdsToVerify.length > 0) {
            console.log('3. Verify uploaded files');
            try {
                verifiedFileResources = await verifyUploadedFileGroupFiles(fileGroupKey, fileIdsToVerify);
            } catch (e) {
                throw e;
            }

            // Add verified resource to file information in the group
            setFileGroupsMap((prevFileGroupsMap) => {
                const fileGroup = { ...prevFileGroupsMap[fileGroupKey] };

                for (const verifiedFileResource of verifiedFileResources) {
                    const fileInformationKey = fileResourceIdToFileInformationKeyMap[verifiedFileResource.id];
                    const fileInformation = fileGroup[fileInformationKey];

                    fileInformation.verifiedFileResource = verifiedFileResource;
                    fileInformation.status = FileInformationStatus.VERIFIED;
                }

                return {
                    ...prevFileGroupsMap,
                    [fileGroupKey]: fileGroup,
                };
            });
        }

        return verifiedFileResources;
    };

    /**
     * Upload files. Validate passed files, and split it into groups which are being uploaded one by one and returned as an array of uploaded file information array
     * @param purpose files purpose
     * @param files files which should be uploaded
     * @returns {VerifiedFileResource[]} verified files array
     */
    const handleUploadFileGroups = async (purpose: FilePurpose, files: FileList | File[]) => {
        if (files.length === 0) {
            // Files were not provided
            return [];
        }

        // Get valid files
        const validFiles: File[] = await validateAndGetFiles(Array.from(files), validators);

        setIsUploading(true);
        setIsUploadingMultipleGroups(validFiles.length > fileGroupSize);

        // Upload files groups
        let verifiedFileResources: VerifiedFileResource[] = [];
        for (let i = 0; i < validFiles.length; i += fileGroupSize) {
            // Get file group to upload
            const fileGroupToUpload = validFiles.slice(i, i + fileGroupSize);

            try {
                // Upload group
                const fileGroupVerifiedFileResources = await handleUploadFileGroup(purpose, fileGroupToUpload);

                // Update verified file resources
                verifiedFileResources = [...verifiedFileResources, ...fileGroupVerifiedFileResources];
            } catch (e) {
                throw e;
            }
        }

        setIsUploadingMultipleGroups(false);

        return verifiedFileResources;
    };

    return {
        files: fileInformationArray,
        isUploading,
        upload: handleUploadFileGroups,
        clearFiles: () => setFileGroupsMap({}),
    };
};

export default useUpload;
