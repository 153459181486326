import { useForm, useTextFormField } from '@app/hooks';
import { getStringRequiredValidator, getStringWebURLValidator } from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import InputFormField from '@components/InputFormField';
import richEditorPlugins from '@components/RichEditor/plugins/index';
import { DraftBlockType, DraftHandleValue, DraftInlineStyleType, Editor, EditorState, RichUtils } from 'draft-js';
import { RichTextFormat } from 'dy-frontend-shared/lib/data/valueObjects';
import React, { forwardRef, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { blockControls, inlineControls } from './consts';
import { DefaultControlType } from './enums';
import { AttachedFilesWrapper, EditorWrapper, Wrapper } from './styled';
import { AdditionalControl, AttachedFile } from './types';

export interface RichEditorProps {
    error?: boolean;
    showDefaultActions?: boolean;
    value: string;
    forceValue?: string | null;
    placeholder?: string;
    files?: AttachedFile[];
    additionalActions?: AdditionalControl[];
    primaryAction?: ReactNode;
    onChange: (data: string) => void;
    onFocus?: () => void;
    onBlur?: () => void;
    renderFileItem?: (file: AttachedFile) => JSX.Element;
    onForceValueSet?: () => void;
}

export type Props = { className?: string } & RichEditorProps;

const richEditorLinkValidators = [getStringRequiredValidator(), getStringWebURLValidator()];

const RichEditor: React.FC<Props> = forwardRef<Editor, Props>(
    (
        {
            error = false,
            showDefaultActions = true,
            className,
            value,
            forceValue,
            placeholder = '',
            files,
            additionalActions,
            primaryAction,
            onChange,
            onFocus,
            onBlur,
            renderFileItem,
            onForceValueSet,
        },
        ref
    ) => {
        const hasAnyAction = (additionalActions && additionalActions.length > 0) || showDefaultActions;

        const linkInputRef = useRef<null | HTMLInputElement>(null);

        const [isEditorInitialized, setIsEditorInitialized] = useState(false);
        const [editorState, setEditorState] = useState(new RichTextFormat(value, richEditorPlugins).getState());
        const [isEditorFocused, setIsEditorFocused] = useState(false);
        const [isLinkPopoverOpen, setIsLinkPopoverOpen] = useState(false);
        const link = useTextFormField({
            id: 'rich-editor-link',
            validators: richEditorLinkValidators,
            initialValue: '',
        });

        const selection = useMemo(() => editorState.getSelection(), [editorState]);
        const activeBlockType = useMemo(
            () => editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType(),
            [editorState, selection]
        );
        const activeInlineType = useMemo(() => editorState.getCurrentInlineStyle(), [editorState]);

        const confirmLinkForm = useForm<boolean>({
            fields: [link],
            apiCall: async () => {
                const contentState = editorState.getCurrentContent();

                const linkTrimmed = link.value.trim();
                const url = linkTrimmed.startsWith('http') ? linkTrimmed : `http://${linkTrimmed}`;

                const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url });
                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
                const newEditorState = EditorState.set(editorState, {
                    currentContent: contentStateWithEntity,
                });

                setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey));

                link.handleSetValue('');
                setIsLinkPopoverOpen(false);

                return { response: true };
            },
        });

        useEffect(() => {
            if (!isEditorInitialized) {
                return;
            }

            const timeoutId = setTimeout(() => {
                onChange(new RichTextFormat(editorState, richEditorPlugins).toString());
            }, 350);

            return () => {
                clearTimeout(timeoutId);
            };
        }, [editorState]);

        useEffect(() => {
            if (!isEditorInitialized) {
                return;
            }

            if (isEditorFocused) {
                onFocus?.();
            } else {
                onBlur?.();
            }
            // eslint-disable-next-line
        }, [isEditorFocused]);

        // Clear state if value was set to empty string/cleared
        useEffect(() => {
            if (!isEditorInitialized) {
                return;
            }

            if (value === '') {
                setEditorState(new RichTextFormat('', richEditorPlugins).getState());
            }
        }, [value]);

        useEffect(() => {
            if (forceValue !== undefined && forceValue !== null) {
                setEditorState(new RichTextFormat(forceValue, richEditorPlugins).getState());
                onForceValueSet?.();
            }
        }, [forceValue]);

        useEffect(() => {
            setIsEditorInitialized(true);
        }, []);

        const handleKeyCommand = (command: string): DraftHandleValue => {
            if (!showDefaultActions) return 'not-handled';

            const state = RichUtils.handleKeyCommand(editorState, command);

            if (state) {
                setEditorState(state);
                return 'handled';
            }

            return 'not-handled';
        };

        const toggleControl = (actionType: DefaultControlType, blockType: DraftBlockType | DraftInlineStyleType) => {
            if (!showDefaultActions) return;

            switch (actionType) {
                case DefaultControlType.BLOCK:
                    setEditorState((currentEditorState) => RichUtils.toggleBlockType(currentEditorState, blockType));
                    break;
                case DefaultControlType.INLINE:
                    setEditorState((currentEditorState) => RichUtils.toggleInlineStyle(currentEditorState, blockType));
                    break;
            }
        };

        const renderLinkAsAWordAction = () => {
            const promptForLink = (e) => {
                e.preventDefault();

                if (!selection.isCollapsed()) {
                    const contentState = editorState.getCurrentContent();
                    const startKey = editorState.getSelection().getStartKey();
                    const startOffset = editorState.getSelection().getStartOffset();
                    const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
                    const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

                    let url = '';
                    if (linkKey) {
                        const linkInstance = contentState.getEntity(linkKey);
                        url = linkInstance.getData().url;
                    }

                    link.handleSetValue(url);
                    setIsLinkPopoverOpen(true);
                } else {
                    // TODO: set tooltip
                }
            };

            return (
                <Popover
                    placement="bottom"
                    isOpen={isLinkPopoverOpen}
                    content={
                        <Card compact>
                            <InputFormField
                                field={link}
                                formGroupProps={{ label: 'Link' }}
                                inputProps={{ placeholder: 'Enter a link' }}
                            />

                            <Flex justify="flex-end">
                                <Button
                                    disabled={confirmLinkForm.hasFieldErrors}
                                    loading={confirmLinkForm.isSubmitting}
                                    type="submit"
                                    className="mr-1"
                                    intent={Intent.PRIMARY}
                                    onClick={(e) => confirmLinkForm.handleFormSubmit(e)}
                                >
                                    Submit
                                </Button>
                                <Button minimal onClick={() => setIsLinkPopoverOpen(false)}>
                                    Cancel
                                </Button>
                            </Flex>
                        </Card>
                    }
                    onClose={() => setIsLinkPopoverOpen(false)}
                    onOpened={() => {
                        if (linkInputRef.current !== null) {
                            setIsEditorFocused(false);
                            (linkInputRef.current as HTMLInputElement).focus();
                        }
                    }}
                >
                    <Button className="ml-small" minimal={!isLinkPopoverOpen} icon="link" onClick={promptForLink} />
                </Popover>
            );
        };

        const renderActions = () => {
            let defaultActions: ReactNode | null = null;
            const leftAdditionalActions: ReactNode[] = [];
            const rightAdditionalActions: ReactNode[] = [];

            if (!hasAnyAction) return null;

            // Default actions
            if (showDefaultActions) {
                defaultActions = (
                    <>
                        {/* Inline controls */}
                        {inlineControls.map(({ icon, style }, index) => (
                            <Button
                                className={index !== 0 ? 'ml-small' : ''}
                                minimal={!activeInlineType.has(style.toUpperCase())}
                                key={style}
                                icon={icon}
                                onMouseDown={(e) => {
                                    e.preventDefault();

                                    toggleControl(DefaultControlType.INLINE, style);
                                }}
                            />
                        ))}

                        {/* Text link control */}
                        {renderLinkAsAWordAction()}

                        {/* Block controls */}
                        {blockControls.map(({ icon, style }, index) => (
                            <Button
                                className={index !== 0 ? 'ml-small' : ''}
                                minimal={activeBlockType !== style}
                                key={style}
                                icon={icon}
                                onMouseDown={(e) => {
                                    e.preventDefault();

                                    toggleControl(DefaultControlType.BLOCK, style);
                                }}
                            />
                        ))}
                    </>
                );
            }

            // Additional actions
            if (additionalActions) {
                for (let i = 0; i < additionalActions.length; i++) {
                    let jsxComponent;

                    const { Component, alignment, withDivider = false, ...action } = additionalActions[i];

                    // Check if action is custom component
                    if (Component) {
                        // TODO: key
                        jsxComponent = Component;
                    } else {
                        const { label, icon, ...props } = action;

                        let jsxComponentClassName = 'ml-small';

                        if (alignment === 'left') {
                            jsxComponentClassName = 'mr-small';
                        }

                        jsxComponent = (
                            <Button key={`${label}-${icon}`} className={jsxComponentClassName} icon={icon} {...props}>
                                {label}
                            </Button>
                        );
                    }

                    if (alignment === 'right') {
                        rightAdditionalActions.push(jsxComponent);
                        // withDivider && rightAdditionalActions.push(<Divider fullWidth={false} />);
                    } else {
                        leftAdditionalActions.push(jsxComponent);
                        // withDivider && rightAdditionalActions.push(<Divider fullWidth={false} />);
                    }
                }
            }

            return (
                <>
                    <Flex className="rich-editor-controls" justify="space-between">
                        <Flex align="center">
                            {leftAdditionalActions.length > 0 && (
                                <>
                                    {leftAdditionalActions}
                                    {/* {showDefaultActions && <Divider fullWidth={false} />} */}
                                </>
                            )}
                            {defaultActions}
                            {rightAdditionalActions.length > 0 && (
                                <>
                                    {/* {showDefaultActions && <Divider fullWidth={false} />} */}
                                    {rightAdditionalActions}
                                </>
                            )}
                        </Flex>

                        {primaryAction && primaryAction}
                    </Flex>
                </>
            );
        };

        const renderFiles = () => {
            // Files or renderFileItem props were NOT passed
            if (!files || !renderFileItem) {
                return null;
            }

            // Files were passed, but it's and empty array
            if (files.length === 0) {
                return null;
            }

            return (
                <>
                    <Divider className="rich-editor-action-divider" />

                    <AttachedFilesWrapper>
                        <Grid container>
                            {files.map((file) => (
                                <Grid className="mt-2" key={file.key} lg={6} xs={12}>
                                    {renderFileItem(file)}
                                </Grid>
                            ))}
                        </Grid>
                    </AttachedFilesWrapper>
                </>
            );
        };

        return (
            <Wrapper className={className}>
                <EditorWrapper $error={error}>
                    <Editor
                        spellCheck
                        readOnly={false}
                        ref={ref}
                        editorState={editorState}
                        placeholder={activeInlineType.count() > 0 || activeBlockType !== 'unstyled' ? '' : placeholder}
                        handleKeyCommand={(command) => handleKeyCommand(command)}
                        onChange={setEditorState}
                        onFocus={() => setIsEditorFocused(true)}
                        onBlur={() => setIsEditorFocused(false)}
                    />

                    {/* Files */}
                    {renderFiles()}

                    {/* Actions */}
                    {renderActions()}
                </EditorWrapper>
            </Wrapper>
        );
    }
);

export default RichEditor;
