import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStore } from 'effector-react';
import { $selectedTaskDeliverable } from '@pages/Task/modals/TaskDeliverableViewerModal/store/states';
import PdfViewer from '@components/PdfViewer';
import { PdfWrapper, Positioner, Wrapper } from './styled';
import { useSetPointMarker } from '../../../../hooks';
import PointMarkerBlock from '../PointMarkerBlock';

const TaskDeliverablePdfViewer: React.FC = () => {
    const { handleSetPointMarker } = useSetPointMarker();
    const ref = useRef<HTMLDivElement | null>(null);

    const selectedTaskDeliverable = useStore($selectedTaskDeliverable);

    const [numPages, setNumPages] = useState<number | null>(null);
    const [renderedPagesCounter, setRenderedPagesCounter] = useState(0);
    const [isPdfLoaded, setIsPadLoaded] = useState(false);

    const pdfDocumentProps = useMemo(() => {
        return {
            onDocumentLoadSuccess: (numPages) => setNumPages(numPages),
            inputRef: ref,
        };
    }, [ref, setNumPages]);

    const pdfPageProps = useMemo(() => {
        return {
            onRenderSuccess: () => setRenderedPagesCounter((prevPagesCounter) => prevPagesCounter + 1),
        };
    }, [setRenderedPagesCounter]);

    useEffect(() => {
        if (isPdfLoaded) {
            // Already loaded
            return;
        }

        if (numPages !== null && renderedPagesCounter === numPages && ref.current) {
            setIsPadLoaded(true);
        }
    }, [numPages, renderedPagesCounter, ref.current]);

    if (!selectedTaskDeliverable || !selectedTaskDeliverable.file) {
        return null;
    }

    const handlePutMarker = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isPdfLoaded) {
            return;
        }
        handleSetPointMarker(event);
    };

    return (
        <Wrapper>
            <Positioner>
                <PdfWrapper ref={ref} onClick={handlePutMarker}>
                    <PdfViewer
                        src={selectedTaskDeliverable.file.url}
                        documentProps={pdfDocumentProps}
                        pageProps={pdfPageProps}
                    />

                    {isPdfLoaded && <PointMarkerBlock containerRef={ref} />}
                </PdfWrapper>
            </Positioner>
        </Wrapper>
    );
};

export default TaskDeliverablePdfViewer;
