import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import {
    NotificationResource,
    SubscriptionResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import NotificationTemplate from '../../../NotificationTemplate';
import { Endpoints } from '@app/data/consts';

export interface SubscriptionCreatedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    subscription: SubscriptionResource | null;
    dismiss: () => Promise<void>;
}

type Props = SubscriptionCreatedNotificationProps;

const SubscriptionCreatedNotification: React.FC<Props> = ({ notification, subscription, dismiss }) => {
    const renderContent = () => {
        if (!subscription) {
            // This is bad, subscription is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Subscription was not found</DevText>;
        }

        return (
            <div>
                <DevText running>
                    <RouterLink to={Endpoints.SUBSCRIPTION.replace(':subscriptionId', subscription.id)}>
                        New subscription
                    </RouterLink>{' '}
                    created for{' '}
                    <RouterLink to={Endpoints.CLIENT_BILLING.replace(':clientId', subscription.user.id)}>
                        {subscription.user.first_name} {subscription.user.last_name}
                    </RouterLink>
                </DevText>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="New subscription"
            icon="new-object"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default SubscriptionCreatedNotification;
