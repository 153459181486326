import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { PlatformTaskFilterData } from '@app/containers/pages/Tasks/types';
import { $isQueryHydrationFinished } from '@app/containers/pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@app/containers/pages/Tasks/store/events';
import { initialPlatformTaskFilterData, platformFiltersInformation } from '@app/containers/pages/Tasks/consts';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { Radio, Position } from '@blueprintjs/core';
import { PlatformTaskFilterType } from '@app/containers/pages/Tasks/enums';
import { TaskQueue } from 'dy-frontend-http-repository/lib/data/enums';

export interface TaskQueuePlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskQueuePlatformFilterProps;

const TaskQueuePlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [queue, setQueue] = useState<TaskQueue | null>(platformTaskFilterData.queue);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setQueue(platformTaskFilterData.queue);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.queue === null) {
            setQueue(null);
        }
    }, [platformTaskFilterData.queue]);

    const getFilterTagLabel = () => {
        switch (queue) {
            case TaskQueue.ACTIVE:
                return 'Active';
            case TaskQueue.BACKLOG:
                return 'Backlog';
            default:
                return '';
        }
    };

    const getFilterTagTitle = () => {
        if (queue === null) {
            return 'Queue';
        }

        return 'Queue:';
    };

    const handleRemoveFilter = () => {
        setQueue(null);
        setPlatformTaskFilterData({
            ...platformTaskFilterData,
            queue: initialPlatformTaskFilterData.queue,
        });
        setIsOpen(false);

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_QUEUE].isPinned) {
            // Platform task queue filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformTaskFilterData.queue !== queue) {
            setPlatformTaskFilterData({ ...platformTaskFilterData, queue });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_QUEUE].isPinned) {
            return;
        }

        if (queue === null) {
            onRemove();
        }
    };

    const renderRow = (key: number) => {
        return (
            <li key={key}>
                <Radio
                    checked={queue === TaskQueue.ACTIVE}
                    className="mb-1"
                    label="Active"
                    onChange={() => setQueue(TaskQueue.ACTIVE)}
                />

                <Radio
                    checked={queue === TaskQueue.BACKLOG}
                    label="Backlog"
                    onChange={() => setQueue(TaskQueue.BACKLOG)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Queue"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_QUEUE].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskQueuePlatformFilter;
