import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Classes, Colors, Intent, Menu, MenuItem, Popover, Tag, Tooltip } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { TaskQueue, TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { $task } from '../../store/states';
import StateTransitionLog from '@pages/Task/components/Header/components/StateTransitionLog';
import { openModal } from '@modals/store/events';
import ArchiveTaskModal, { ArchiveTaskModalProps } from '@pages/Task/modals/ArchiveTaskModal';
import PauseTaskModal, { PauseTaskModalProps } from '@pages/Task/modals/PauseTaskModal';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import DevText from '@app/components/Text';
import CopyTextButton from '@app/components/CopyTextButton';
import ChangeTaskStateForcefullyModal, {
    ChangeTaskStateForcefullyModalProps,
} from '../../modals/ChangeTaskStateForcefullyModal';
import { useLocation } from 'react-router-dom';
import Link from '@app/components/Link';
import { Endpoints } from '@app/data/consts';

export type Props = HTMLAttributes<HTMLDivElement>;

const Header: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const location = useLocation();

    const task = useStore($task);

    const [isStateLogVisible, setIsStateLogVisible] = useState(false);
    const [isPauseManageAllowed, setIsPauseManageAllowed] = useState(false);
    const [isArchiveAllowed, setIsArchiveAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }

        // Root permission set
        const isRoot = permissions.isRoot.task;
        if (isRoot) {
            setIsPauseManageAllowed(true);
            setIsArchiveAllowed(true);
            return;
        }

        // Base permission: archive
        setIsArchiveAllowed(permissions.has(TaskPermission.ARCHIVE));

        // Base permissions: pause manage
        if (permissions.has(TaskPermission.PAUSE_MANAGE)) {
            setIsPauseManageAllowed(true);
        } else {
            const isAssigned = task.participants.find((p) => p.user.id === me.user.id) !== undefined;
            setIsPauseManageAllowed(isAssigned && permissions.has(TaskPermission.PAUSE_MANAGE_MOD_ASSIGNED));
        }
    }, [task, permissions, me]);

    if (!task) {
        return null;
    }

    // Checks for actions
    const isPaused = task.paused_at !== null;
    const isDraft = task.state === TaskState.DRAFT;
    const isDelivered = task.state === TaskState.DELIVERED;
    const isArchived = task.archived_at !== null;
    const isBacklog = task.queue === TaskQueue.BACKLOG;

    const renderPriorityTag = () => {
        if (!task.is_priority_elevated) {
            return null;
        }

        return (
            <Tag minimal large className="mr-1" icon="generate" intent={Intent.DANGER}>
                High priority
            </Tag>
        );
    };

    const renderBacklogQueueTag = () => {
        if (task.queue !== TaskQueue.BACKLOG) {
            return null;
        }

        return (
            <Tag minimal large className="mr-1" icon="remove-row-bottom" style={{ flexShrink: 0 }}>
                Backlog
            </Tag>
        );
    };

    const renderStateLogToggleButton = () => {
        if (task.state === TaskState.DRAFT) {
            return null;
        }

        return (
            <Button
                minimal
                icon={isStateLogVisible ? 'chevron-up' : 'chevron-down'}
                onClick={() => setIsStateLogVisible(!isStateLogVisible)}
            >
                {isStateLogVisible ? 'Hide state log' : 'Show state log'}
            </Button>
        );
    };

    const renderStateLog = () => {
        if (!isStateLogVisible) {
            return null;
        }

        return (
            <Card>
                <StateTransitionLog isCollapsed={false} />
            </Card>
        );
    };

    const renderAdditionalControlMenu = () => {
        // List of action buttons
        const actions: React.ReactNode[] = [];

        // Action: force state change
        // TODO: add permission
        const isForceStateChangeSupported = !isPaused && !isArchived && !isDelivered && !isBacklog;
        if (isForceStateChangeSupported) {
            actions.push(
                <MenuItem
                    key="force-state-change"
                    icon="build"
                    text="Force request state"
                    onClick={() =>
                        openModal<ChangeTaskStateForcefullyModalProps>({
                            ModalComponent: ChangeTaskStateForcefullyModal,
                            data: { taskId: task.id, currentState: task.state },
                        })
                    }
                />
            );
        }

        // Action: pause
        const isPauseSupported = !isPaused && !isDraft && !isDelivered && !isArchived;
        if (isPauseSupported && isPauseManageAllowed) {
            actions.push(
                <MenuItem
                    key="task-act-pause"
                    icon="pause"
                    text="Pause"
                    intent={Intent.WARNING}
                    onClick={() =>
                        openModal<PauseTaskModalProps>({
                            ModalComponent: PauseTaskModal,
                            data: { taskId: task.id },
                        })
                    }
                />
            );
        }

        // Action: archive
        const isArchiveSupported = !isArchived && !isDelivered;
        if (isArchiveSupported && isArchiveAllowed) {
            actions.push(
                <MenuItem
                    key="task-act-archive"
                    icon="trash"
                    text="Archive"
                    intent={Intent.DANGER}
                    onClick={() =>
                        openModal<ArchiveTaskModalProps>({
                            ModalComponent: ArchiveTaskModal,
                            data: { taskId: task.id },
                        })
                    }
                />
            );
        }

        // Skip if no actions are allowed
        if (actions.length === 0) {
            return null;
        }

        return (
            <Popover content={<Menu>{actions}</Menu>}>
                <Button className="ml-1" icon="more" />
            </Popover>
        );
    };

    const renderCopyInformation = () => {
        const handleCopy = (copyText: string) => {
            navigator.clipboard.writeText(copyText);
        };

        const renderMenu = () => {
            return (
                <Menu>
                    <MenuItem icon="binary-number" text="Copy request ID" onClick={() => handleCopy(task.id)} />
                    <MenuItem
                        icon="link"
                        text="Copy request URL"
                        onClick={() =>
                            handleCopy(
                                `${process.env.INTERNAL_PLATFORM_URL}${Endpoints.TASK_INFORMATION.replace(
                                    ':taskId',
                                    task.id
                                )}`
                            )
                        }
                    />
                    <MenuItem icon="highlight" text="Copy request title" onClick={() => handleCopy(task.title)} />
                </Menu>
            );
        };

        return (
            <Popover content={renderMenu()}>
                <Tooltip content="Click to copy">
                    <Button minimal icon="duplicate" />
                </Tooltip>
            </Popover>
        );
    };

    return (
        <div {...props}>
            <Flex align="start" justify="space-between" className={isStateLogVisible ? 'mb-1' : ''}>
                <Flex align="center" flexWrap="wrap">
                    <Heading type="h3" className="mr-1">
                        {renderPriorityTag()}
                        {renderBacklogQueueTag()}
                        {task.title}
                    </Heading>
                    {renderCopyInformation()}
                </Flex>

                <Flex align="center">
                    {renderStateLogToggleButton()}
                    {renderAdditionalControlMenu()}
                </Flex>
            </Flex>
            {renderStateLog()}
        </div>
    );
};

export default Header;
