import { Breakpoint } from '../enums';

const breakpoints: { [breakpoint in Breakpoint]: number } = {
    [Breakpoint.XL]: 1920,
    [Breakpoint.LG]: 1280,
    [Breakpoint.MD]: 960,
    [Breakpoint.SM]: 600,
    [Breakpoint.XS]: 0,
};

export default breakpoints;
