enum PlatformTaskFilterType {
    TASK_STATES = 'TASK_STATES',
    TASK_QUEUE = 'TASK_QUEUE',
    CLIENT_USERS = 'CLIENT_USERS',
    TASK_PARTICIPANTS = 'TASK_PARTICIPANTS',
    TASK_TEAMS = 'TASK_TEAMS',
    TASK_DEADLINE = 'TASK_DEADLINE',
    TASK_CATEGORIES = 'TASK_CATEGORIES',
    TASK_PUBLISH_TYPES = 'TASK_PUBLISH_TYPES',
    TASK_PLANS = 'TASK_PLANS',
    TASK_PAUSED = 'TASK_PAUSED',
    TASK_ARCHIVED = 'TASK_ARCHIVED',
}

export default PlatformTaskFilterType;
