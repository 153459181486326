import { Colors } from '@blueprintjs/core';
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: grid;
    flex-grow: 1;
    align-items: center;

    overflow-y: auto;
    width: 100%;
    background: ${Colors.DARK_GRAY2};
`;

export const PdfViewerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`;

export const ImageViewerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
`;

export const VideoViewerWrapper = styled.div``;

export const EmbedGoogleDriveViewerWrapper = styled.div`
    align-self: flex-start;
    height: 100%;
`;

export const TextViewerWrapper = styled.div`
    align-self: flex-start;
    height: 100%;
`;
