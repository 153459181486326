import React, { useEffect, useState } from 'react';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { resetCreditWallet, resetCreditWalletTransactions } from './store/events';
import { $client } from '../../store/states';
import { useStore } from 'effector-react';
import { $creditWallet, $creditWalletTransactions } from './store/states';
import {
    fetchCreditWalletForUser,
    fetchCreditWalletForWorkspace,
    fetchCreditWalletTransactions,
} from './store/effects';
import {
    CreditWalletResource,
    CreditWalletTransactionResource,
} from 'dy-frontend-http-repository/lib/modules/CreditWallet/resources';
import { amountOfCreditWalletTransactionsOnPage } from './consts';
import Flex from '@app/components/Flex';
import { Spinner, Icon, Card, Callout, Intent, Colors, Button, Popover, MenuItem, Menu, Tag } from '@blueprintjs/core';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import NonIdealState from '@app/components/NonIdealState';
import { openModal } from '@app/containers/modals/store/events';
import AddCreditWalletCreditsModal, { AddCreditWalletCreditsModalProps } from './modals/AddCreditWalletCreditsModal';
import RemoveCreditWalletCreditsModal, {
    RemoveCreditWalletCreditsModalProps,
} from './modals/RemoveCreditWalletCreditsModal';
import Pagination from '@app/components/Pagination';
import moment from 'moment';
import { usePageTitle, useScrollToTop } from '@app/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { Endpoints } from '@app/data/consts';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';

const ClientCredits: React.FC = () => {
    const { changeTitle } = usePageTitle('Client Credits');
    const { clientId } = useParams() as { clientId: ID };
    const navigate = useNavigate();
    const { scrollToTop } = useScrollToTop();

    const client = useStore($client);
    const creditWallet = useStore($creditWallet);
    const creditWalletTransactions = useStore($creditWalletTransactions);
    const isFetchingCreditWalletTransactions = useStore(fetchCreditWalletTransactions.pending);

    const [page, setPage] = useState(1);

    const handleLoadCreditWalletTransactionsPage = (newPage: number, creditWallet: CreditWalletResource) => {
        if (!creditWallet) return;

        const pageOffset = (newPage - 1) * amountOfCreditWalletTransactionsOnPage;

        fetchCreditWalletTransactions({
            pagination: {
                _pagination: { limit: amountOfCreditWalletTransactionsOnPage, offset: pageOffset },
            },
            creditWalletId: creditWallet.id,
        }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Credit wallet for client with ID ${clientId} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to client tasks page
                navigate(Endpoints.CLIENT_TASKS.replace(':clientId', clientId));
            }
        });
    };

    useEffect(() => {
        // Initialize credit wallet & transactions

        if (!client) {
            return;
        }

        if (creditWallet && client.id === creditWallet.user_id) {
            return;
        }

        const handleInitialize = async () => {
            let creditWallet: CreditWalletResource | null = null;

            if (client.workspace) {
                // Workspace user
                try {
                    creditWallet = await fetchCreditWalletForWorkspace(client.workspace.id);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            } else {
                // DotYeti client

                try {
                    creditWallet = await fetchCreditWalletForUser(client.id);
                } catch (e) {
                    // TODO: handle error
                    console.error(e);
                }
            }
        };

        handleInitialize();
    }, [client]);

    useEffect(() => {
        if (!creditWallet) {
            return;
        }

        handleLoadCreditWalletTransactionsPage(page, creditWallet);

        // Scroll to top
        scrollToTop();

        // eslint-disable-next-line
    }, [page, creditWallet]);

    useEffect(() => {
        if (!client) {
            return;
        }

        changeTitle(`Client Credits - ${client.first_name} ${client.last_name}`);
    }, [client?.first_name, client?.last_name]);

    useEffect(() => {
        return () => {
            resetCreditWallet();
            resetCreditWalletTransactions();
        };
    }, []);

    if (!client) {
        return null;
    }

    if (!creditWallet) {
        // Credit wallet is NOT fetched yet
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const renderCreditWalletInformationCard = () => {
        const renderLockedInformation = () => {
            if (creditWallet.is_locked) {
                return (
                    <Callout intent={Intent.WARNING}>
                        Wallet is locked. Client can't use credits to pay for service.
                    </Callout>
                );
            }

            return (
                <DevText color={Colors.GREEN4}>Wallet is active. Client can use credits to pay for service.</DevText>
            );
        };
        return (
            <Card>
                <Heading className="mb-1" type="h4">
                    Balance: {TextFormatUtils.getMultiple(creditWallet.balance, 'credit')}
                </Heading>
                {renderLockedInformation()}
            </Card>
        );
    };

    const renderCreditWalletTransaction = (transaction: CreditWalletTransactionResource) => {
        const renderAmountHeader = () => {
            if (transaction.amount > 0) {
                // Addition
                return (
                    <Flex align="center">
                        <Icon className="mr-small" icon="plus" intent={Intent.SUCCESS} />
                        <Heading type="h4">{TextFormatUtils.getMultiple(transaction.amount, 'credit')}</Heading>
                    </Flex>
                );
            } else {
                // Withdrawal
                return (
                    <Flex align="center">
                        <Icon className="mr-small" icon="minus" intent={Intent.DANGER} />
                        <Heading type="h4">{TextFormatUtils.getMultiple(-transaction.amount, 'credit')}</Heading>
                    </Flex>
                );
            }
        };

        return (
            <Card key={transaction.id} className="mb-1">
                <Flex direction="row" justify="space-between">
                    <div>
                        <div className="mb-1">{renderAmountHeader()}</div>
                        <DevText muted>{transaction.summary}</DevText>
                    </div>

                    <DevText small muted>
                        {moment(transaction.at).format('D MMMM YYYY, HH:mm')}
                    </DevText>
                </Flex>
            </Card>
        );
    };

    const renderCreditWalletTransactions = () => {
        // Client invoices were NOT fetched yet
        if (!creditWalletTransactions) {
            return (
                <Flex justify="center">
                    <Spinner />
                </Flex>
            );
        }

        // Client invoices were fetched, but where are no invoices existed for this client
        if (creditWalletTransactions.items.length === 0) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            No transactions were found
                        </Heading>
                    }
                />
            );
        }

        return <div>{creditWalletTransactions.items.map(renderCreditWalletTransaction)}</div>;
    };

    const renderCreditWalletControlsButton = () => {
        const isBalancePositive = creditWallet.balance > 0;

        const renderMenu = () => {
            return (
                <Menu>
                    <MenuItem
                        icon="add"
                        text="Add credit(s)"
                        onClick={() =>
                            openModal<AddCreditWalletCreditsModalProps>({
                                ModalComponent: AddCreditWalletCreditsModal,
                                data: {
                                    balance: creditWallet.balance,
                                    creditWalletId: creditWallet.id,
                                    clientId: client?.id,
                                },
                            })
                        }
                    />

                    {isBalancePositive && (
                        <MenuItem
                            icon="remove"
                            text="Remove credit(s)"
                            intent={Intent.DANGER}
                            onClick={() =>
                                openModal<RemoveCreditWalletCreditsModalProps>({
                                    ModalComponent: RemoveCreditWalletCreditsModal,
                                    data: {
                                        balance: creditWallet.balance,
                                        creditWalletId: creditWallet.id,
                                        clientId: client?.id,
                                    },
                                })
                            }
                        />
                    )}
                </Menu>
            );
        };

        return (
            <Popover content={renderMenu()}>
                <Button icon="more" />
            </Popover>
        );
    };

    const renderPagination = () => {
        // Client brand profiles is not fetched yet
        if (creditWalletTransactions === null) {
            return null;
        }

        // Check if offset paginator exist
        if (creditWalletTransactions.paginator === null) {
            return;
        }

        // Only one page exist
        if (!creditWalletTransactions.paginator.has_more && creditWalletTransactions.paginator.offset === 0) {
            return null;
        }

        return (
            <Flex className="mt-2" justify="flex-end">
                <Pagination
                    fetching={isFetchingCreditWalletTransactions}
                    hasMore={creditWalletTransactions.paginator.has_more}
                    page={page}
                    amountOfItemsOnPage={amountOfCreditWalletTransactionsOnPage}
                    totalItems={creditWalletTransactions.paginator.total}
                    onPageChange={(newPage) => setPage(newPage)}
                />
            </Flex>
        );
    };

    return (
        <div>
            <div className="mb-3">{renderCreditWalletInformationCard()}</div>

            <div>
                <Flex className="mb-1" direction="row" justify="space-between" align="center">
                    <Heading type="h3">Transactions</Heading>

                    {renderCreditWalletControlsButton()}
                </Flex>

                {renderCreditWalletTransactions()}
                {renderPagination()}
            </div>
        </div>
    );
};

export default ClientCredits;
