import { PublishTaskStep } from '../enums';

const publishStepsInformation: { [publishTaskStep in PublishTaskStep]?: { title: string; description: string } } = {
    [PublishTaskStep.QUOTA]: {
        title: 'Current user subscription quota',
        description: 'Publish without payment using user quotas',
    },
    [PublishTaskStep.PAYMENT]: {
        title: 'Payment',
        description: 'Publish using payment',
    },
    [PublishTaskStep.CREDIT]: {
        title: 'Credit',
        description: 'Publish using request credit payment',
    },
    [PublishTaskStep.MANUAL]: {
        title: 'Manual',
        description: 'Publish without making any payment',
    },
};

export default publishStepsInformation;
