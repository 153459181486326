import repository from 'dy-frontend-http-repository/lib/repository';
import { TaskKanbanResource } from 'dy-frontend-http-repository/lib/modules/TaskKanban/resources';
import domain from './domain';
import { TaskKanbanFilterInput } from 'dy-frontend-http-repository/lib/modules/TaskKanban/inputs';

// Get kanban board
export const fetchTaskKanbanBoard = domain.createEffect<TaskKanbanFilterInput, TaskKanbanResource>({
    name: 'fetchTaskKanbanBoard',
    handler: async (filter) => {
        return await repository.taskKanban().get(filter);
    },
});
