import domain from './domain';
import { PlatformClientUserFilterData } from '../types';
import { ClientUserSortField } from '../enums';
import { SortData } from 'dy-frontend-http-repository/lib/data/types';

// Reset clients
export const resetClients = domain.createEvent('resetClients');

// Set page
export const setPage = domain.createEvent<number>('setPage');

// Set query hydration finished flag
export const setIsQueryHydrationFinished = domain.createEvent<boolean>('setIsQueryHydrationFinished');

// Set client user platform filter data
export const setPlatformClientUserFilterData = domain.createEvent<PlatformClientUserFilterData>(
    'setPlatformClientUserFilterData'
);

// Reset page
export const resetPage = domain.createEvent('resetPage');

// Reset platform client user filter data
export const resetPlatformClientUserFilterData = domain.createEvent('resetPlatformClientUserFilterData');

// Reset is query hydration finished
export const resetIsQueryHydrationFinished = domain.createEvent('resetIsQueryHydrationFinished');

// Set client sort data
export const setClientUserSortData = domain.createEvent<SortData<ClientUserSortField>>('setClientUserSortData');

// Reset client sort data
export const resetClientUserSortData = domain.createEvent('resetClientUserSortData');