import React, { HTMLAttributes } from 'react';
import Avatar from '@components/Avatar';
import Box from '@components/Box';
import Flex from '@components/Flex';
import { AvatarInformation } from './types';
import { Wrapper } from './styled';
import { Colors, Tooltip } from '@blueprintjs/core';
import DevText from '@components/Text';

export interface AvatarStackProps {
    tight?: boolean;
    withCounter?: boolean;
    avatarSize?: number;
    maxAmountOfAvatarsToShow?: number;
    avatars: AvatarInformation[];
}

export type Props = HTMLAttributes<HTMLUListElement> & AvatarStackProps;

const AvatarStack: React.FC<Props> = ({
    withCounter = false,
    tight = true,
    avatarSize = 36,
    maxAmountOfAvatarsToShow = 5,
    avatars,
    ...props
}) => {
    const avatarsLeftCounter = avatars.length - maxAmountOfAvatarsToShow;

    if (maxAmountOfAvatarsToShow) {
        avatars = avatars.slice(0, maxAmountOfAvatarsToShow);
    }

    const avatarSizeInPixels = `${avatarSize}px`;
    const tightAvatarMargin = '0 0 0 -7px';
    const notTightAvatarMargin = '0 3px 0 0';

    const renderAvatar = (avatar: AvatarInformation, isFirstIndex: boolean) => {
        let avatarMargin: string | undefined = notTightAvatarMargin;

        if (tight) {
            if (isFirstIndex) {
                avatarMargin = undefined;
            } else {
                avatarMargin = tightAvatarMargin;
            }
        }

        return (
            <Tooltip key={avatar.id} content={avatar.name}>
                <Avatar
                    margin={avatarMargin}
                    src={avatar.src}
                    alt={avatar.name}
                    borderColor={avatar.borderColor}
                    width={avatarSizeInPixels}
                    height={avatarSizeInPixels}
                    minWidth={avatarSizeInPixels}
                    minHeight={avatarSizeInPixels}
                />
            </Tooltip>
        );
    };

    const renderAvatarCounter = () => {
        if (!withCounter) {
            return null;
        }

        if (avatarsLeftCounter <= 0) {
            return null;
        }

        let avatarCounterMargin = notTightAvatarMargin;

        if (tight) {
            avatarCounterMargin = tightAvatarMargin;
        }

        return (
            <Box
                margin={avatarCounterMargin}
                borderRadius="50%"
                width={avatarSizeInPixels}
                height={avatarSizeInPixels}
                minWidth={avatarSizeInPixels}
                minHeight={avatarSizeInPixels}
                backgroundColor={Colors.DARK_GRAY2}
                borderColor={Colors.GRAY1}
            >
                <Flex fullHeight fullWidth align="center" justify="center">
                    <DevText inline small>
                        +{avatarsLeftCounter}
                    </DevText>
                </Flex>
            </Box>
        );
    };

    return (
        <Wrapper {...props}>
            {avatars.map((avatar, index) => renderAvatar(avatar, index === 0))}
            {renderAvatarCounter()}
        </Wrapper>
    );
};

export default AvatarStack;
