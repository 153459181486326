import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { openModal } from '@app/containers/modals/store/events';
import SetTaskDeadlineModal, {
    SetTaskDeadlineModalProps,
} from '@app/containers/pages/Task/modals/SetTaskDeadlineModal';
import AddTaskParticipantsModal from '@app/containers/pages/Task/modals/AddTaskParticipantsModal';
import TaskDeadlineSanityCheckModal, {
    TaskDeadlineSanityCheckModalProps,
} from '@app/containers/pages/Task/modals/sanityCheck/TaskDeadlineSanityCheckModal';
import { TimezoneUtils } from 'dy-frontend-shared/lib/utils';
import Circle from '@components/Circle';
import { taskStateInformation } from '@data/consts';

export interface PublishedProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & PublishedProps;

const Published: React.FC<Props> = (props) => {
    const { handleTransitionTaskState } = useTaskStateTransition();

    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);
    const [isPickupReadyAllowed, setIsPickupReadyAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsPickupReadyAllowed(
            permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_PICKUP_READY)
        );
    }, [task, permissions, me]);

    if (!task || !me) {
        return null;
    }

    // TODO: Render helper action - assign participants if none assigned
    // TODO: Set deadline - if none assigned

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    const renderPickupReadyTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isPickupReadyAllowed) {
            return null;
        }

        const handlePickupReadyTransition = () => {
            if (task.deadline_at !== null) {
                handleTransitionToState(TaskState.PICKUP_READY);
            } else {
                openModal<TaskDeadlineSanityCheckModalProps>({
                    ModalComponent: TaskDeadlineSanityCheckModal,
                    data: {
                        onSetDeadline: () =>
                            openModal<SetTaskDeadlineModalProps>({
                                ModalComponent: SetTaskDeadlineModal,
                                data: {
                                    taskId: task.id,
                                    workerTimezone: TimezoneUtils.getCurrentTimezoneName(),
                                    clientTimezone: task.deadline_timezone_name,
                                    deadlineTimezone: task.deadline_timezone_name,
                                    deliveryDays: task.category.average_delivery_days,
                                    deadlineValue: task.deadline_at,
                                    onSetDeadline: () => handleTransitionToState(TaskState.PICKUP_READY),
                                },
                            }),
                        onSkip: () => handleTransitionToState(TaskState.PICKUP_READY),
                    },
                });
            }
        };

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="pie-chart"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.PICKUP_READY}
                onClick={handlePickupReadyTransition}
            >
                Ready for work
            </Button>
        );
    };

    const renderAssignTaskParticipantButton = () => {
        // TODO: task state actions / helper actions permissions
        return (
            <Button
                disabled={transitioningTo !== null}
                icon="new-person"
                onClick={() => openModal({ ModalComponent: AddTaskParticipantsModal })}
            >
                Add participants
            </Button>
        );
    };

    const renderSetTaskDeadlineButton = () => {
        // TODO: task state actions / helper actions permissions
        return (
            <Button
                disabled={transitioningTo !== null}
                icon="time"
                onClick={() =>
                    openModal<SetTaskDeadlineModalProps>({
                        ModalComponent: SetTaskDeadlineModal,
                        data: {
                            taskId: task.id,
                            workerTimezone: TimezoneUtils.getCurrentTimezoneName(),
                            clientTimezone: task.deadline_timezone_name,
                            deadlineTimezone: task.deadline_timezone_name,
                            deliveryDays: task.category.average_delivery_days,
                            deadlineValue: task.deadline_at,
                        },
                    })
                }
            >
                {task.deadline_at === null ? 'Set deadline' : 'Update deadline'}
            </Button>
        );
    };

    const information = taskStateInformation[TaskState.PUBLISHED];

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Circle size="22px" className="mr-1" color={information.color} />
                    <Heading type="h4">{information.title}</Heading>
                </Flex>

                <Flex direction="row">
                    <div>{renderPickupReadyTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>
                Client successfully published request. Please review it, assign designers, and mark as ready for work.
            </DevText>

            {/* Helper actions */}
            <Flex className="mt-2" direction="row">
                <div className="mr-1">{renderAssignTaskParticipantButton()}</div>
                <div>{renderSetTaskDeadlineButton()}</div>
            </Flex>
        </Card>
    );
};

export default Published;
