import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Card, Colors } from '@blueprintjs/core';
import React from 'react';
import {
    NotificationResource,
    SubscriptionResource,
    SubscriptionPauseScheduleReleasedActivityPayloadResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints } from '@app/data/consts';
import NotificationTemplate from '../../../../NotificationTemplate';

export interface SubscriptionPauseScheduleReleasedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    subscription: SubscriptionResource | null;
    dismiss: () => Promise<void>;
}

type Props = SubscriptionPauseScheduleReleasedNotificationProps;

// TODO: output
const amountOfSecondsInDay = 86400;

const SubscriptionPauseScheduleReleasedNotification: React.FC<Props> = ({ notification, subscription, dismiss }) => {
    const renderContent = () => {
        if (!subscription) {
            // This is bad, subscription is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Subscription was not found</DevText>;
        }

        const payload: SubscriptionPauseScheduleReleasedActivityPayloadResource | null = notification.activity.payload;
        if (!payload) {
            // This is bad, there should be payload, but for some reason it is not there
            return <DevText color={Colors.RED2}>Payload was not found</DevText>;
        }

        return (
            <div>
                <DevText running className="mb-1">
                    <RouterLink to={Endpoints.SUBSCRIPTION.replace(':subscriptionId', subscription.id)}>
                        Subscription
                    </RouterLink>{' '}
                    of{' '}
                    <RouterLink to={Endpoints.CLIENT_BILLING.replace(':clientId', subscription.user.id)}>
                        {subscription.user.first_name} {subscription.user.last_name}
                    </RouterLink>{' '}
                    was resumed by schedule:
                </DevText>

                <Card compact>
                    <DevText>
                        <DevText inline muted>
                            Added after resume:
                        </DevText>{' '}
                        {Math.round(payload.unused_period / amountOfSecondsInDay)} days
                    </DevText>
                </Card>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="Subscription was resumed using pause schedule"
            icon="play"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default SubscriptionPauseScheduleReleasedNotification;
