import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useStore } from 'effector-react';
import { $task, $taskDeliverablesTree } from '../../../../store/states';
import Flex from '@components/Flex';
import { $authorizedUser, $permissions } from '@app/containers/store/states';
import { Button, Card, Intent } from '@blueprintjs/core';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { useTaskStateTransition } from '@pages/Task/hooks';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { useNavigate } from 'react-router-dom';
import { Endpoints, taskStateInformation } from '@app/data/consts';
import { approveTaskDeliverablesBatch } from '@app/containers/pages/Task/store/effects';
import { taskDeliverablesTreeApi } from '@app/containers/pages/Task/store/apis';
import { TaskDeliverablesTreeUtils } from '@app/containers/pages/Task/valueObjects';
import { openModal } from '@app/containers/modals/store/events';
import TaskDeliverablePresenceSanityCheckModal, {
    TaskDeliverablePresenceSanityCheckModalProps,
} from '@app/containers/pages/Task/modals/sanityCheck/TaskDeliverablePresenceSanityCheckModal';
import UnapprovedTaskDeliverablePresenceSanityCheckModal, {
    UnapprovedTaskDeliverablePresenceSanityCheckModalProps,
} from '@app/containers/pages/Task/modals/sanityCheck/UnapprovedTaskDeliverablePresenceSanityCheckModal';
import Circle from '@components/Circle';

export interface InternalQaProps {
    isStateTransitionAllowed: boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & InternalQaProps;

const InternalQa: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const permissions = useStore($permissions);
    const me = useStore($authorizedUser);
    const task = useStore($task);
    const taskDeliverablesTree = useStore($taskDeliverablesTree);

    const { handleTransitionTaskState } = useTaskStateTransition();

    const [transitioningTo, setTransitionTo] = useState<TaskState | null>(null);

    const [isInRevisionAllowed, setIsInRevisionAllowed] = useState(false);
    const [isClientReviewAllowed, setIsClientReviewAllowed] = useState(false);

    useEffect(() => {
        if (!task || !me) {
            return;
        }
        setIsInRevisionAllowed(permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_IN_REVISION));
        setIsClientReviewAllowed(
            permissions.isRoot.task || permissions.has(TaskPermission.STATE_TRANSITION_CLIENT_REVIEW)
        );
    }, [task, permissions, me]);

    if (!me || !task || !taskDeliverablesTree) {
        return null;
    }

    const handleTransitionToState = async (state: TaskState) => {
        try {
            setTransitionTo(state);
            await handleTransitionTaskState(task.id, state);
        } catch (e) {
            // TODO: handle error
        } finally {
            setTransitionTo(null);
        }
    };

    const handleApproveTaskDeliverablesAndTransitionToState = async (
        taskDeliverablesIds: ID[],
        transitionToState: TaskState
    ) => {
        try {
            await approveTaskDeliverablesBatch({
                task_deliverable_ids: taskDeliverablesIds,
            });
            taskDeliverablesTreeApi.approveTaskDeliverables({ taskDeliverablesIds });
            handleTransitionToState(transitionToState);
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderInRevisionTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isInRevisionAllowed) {
            return null;
        }

        return (
            <Button
                icon="outdated"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.IN_REVISION}
                onClick={() => handleTransitionToState(TaskState.IN_REVISION)}
            >
                Request revision from designer
            </Button>
        );
    };

    const renderClientReviewTransitionButton = () => {
        if (!props.isStateTransitionAllowed || !isClientReviewAllowed) {
            return null;
        }

        const handleClientReviewTransition = () => {
            const transitionToState = TaskState.CLIENT_REVIEW;

            // Get array of deliverables
            const deliverables = TaskDeliverablesTreeUtils.getTaskDeliverablesArray({
                deliverablesMap: taskDeliverablesTree.deliverables,
            });

            if (deliverables.length === 0) {
                // No deliverables
                openModal<TaskDeliverablePresenceSanityCheckModalProps>({
                    ModalComponent: TaskDeliverablePresenceSanityCheckModal,
                    data: {
                        transitionState: () => handleTransitionToState(transitionToState),
                        transitionToState: transitionToState,
                    },
                });
            } else {
                const unapprovedDeliverableIds: ID[] = [];
                deliverables.forEach((deliverable) => {
                    if (deliverable.approved_at === null) {
                        // Not approved
                        unapprovedDeliverableIds.push(deliverable.id);
                    }
                });

                if (unapprovedDeliverableIds.length > 0) {
                    // There are unapproved deliverables
                    openModal<UnapprovedTaskDeliverablePresenceSanityCheckModalProps>({
                        ModalComponent: UnapprovedTaskDeliverablePresenceSanityCheckModal,
                        data: {
                            approveTaskDeliverablesAndTransition: () =>
                                handleApproveTaskDeliverablesAndTransitionToState(
                                    unapprovedDeliverableIds,
                                    transitionToState
                                ),
                            unapprovedDeliverablesCount: unapprovedDeliverableIds.length,
                        },
                    });
                } else {
                    // All deliverables approved
                    handleTransitionToState(transitionToState);
                }
            }
        };

        return (
            <Button
                intent={Intent.PRIMARY}
                icon="geosearch"
                disabled={transitioningTo !== null}
                loading={transitioningTo === TaskState.CLIENT_REVIEW}
                onClick={handleClientReviewTransition}
            >
                Deliver to client
            </Button>
        );
    };

    const renderOpenUnapprovedTaskDeliverableButton = () => {
        // TODO: task state actions / helper actions permissions

        return (
            <Button
                icon="unarchive"
                disabled={transitioningTo !== null}
                onClick={() => navigate(Endpoints.TASK_DELIVERABLES_APPROVED.replace(':taskId', task.id))}
            >
                View unapproved deliverables
            </Button>
        );
    };

    const information = taskStateInformation[TaskState.INTERNAL_QA];

    return (
        <Card>
            <Flex align="center" justify="space-between" className="mb-1">
                <Flex align="center" className="mb-1">
                    <Circle size="22px" className="mr-1" color={information.color} />
                    <Heading type="h4">{information.title}</Heading>
                </Flex>

                <Flex>
                    <div className="mr-1">{renderInRevisionTransitionButton()}</div>
                    <div>{renderClientReviewTransitionButton()}</div>
                </Flex>
            </Flex>

            <DevText muted>Request is currently undergoing internal QA.</DevText>
            <DevText muted>
                Make sure that the briefing and any additional messages from the client are well-understood and
                information from brand profile is used well.
            </DevText>

            {/* Helper actions */}
            <Flex className="mt-2" direction="row">
                <div>{renderOpenUnapprovedTaskDeliverableButton()}</div>
            </Flex>
        </Card>
    );
};

export default InternalQa;
