import React from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, Elevation, Icon } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@app/components/Text';

export interface TaskDeadlineSanityCheckModalProps {
    onSetDeadline: () => any;
    onSkip: () => any;
}

type Props = ModalProps<TaskDeadlineSanityCheckModalProps>;

const TaskDeadlineSanityCheckModal: React.FC<Props> = ({ closeModal, data }) => {
    const handleModalClose = () => {
        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const handleSetDeadline = () => {
        data.onSetDeadline();
        handleModalClose();
    };

    const handleSkipSetDeadlineAction = () => {
        data.onSkip();
        handleModalClose();
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <Heading type="h4">Select delivery date</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <Card compact interactive className="mb-2" elevation={Elevation.ONE} onClick={handleSetDeadline}>
                    <Heading className="mb-1" type="h6">
                        <Icon icon="time" className="mr-1" />
                        Select deadline (Recommended)
                    </Heading>
                    <DevText muted>Open deadline picker to set this request deadline</DevText>
                </Card>

                <Card compact interactive elevation={Elevation.ONE} onClick={handleSkipSetDeadlineAction}>
                    <Heading className="mb-1" type="h6">
                        <Icon icon="history" className="mr-1" />
                        Set deadline later (To be set)
                    </Heading>
                    <DevText muted>
                        This will not set any deadline on this request. This is not recommended, and it is advisable
                        that request deadline is set as soon as possible
                    </DevText>
                </Card>
            </Card>
        </Overlay>
    );
};

export default TaskDeadlineSanityCheckModal;
