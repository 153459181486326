import ColorTilePickerFormField from '@app/components/ColorTilePickerFormField';
import {
    useColorPickerFormField,
    useCustomSelectFormField,
    useForm,
    useTeamWorkingHours,
    useTextFormField,
    useTimeFormField,
} from '@app/hooks';
import {
    getStringDateValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, FormGroup, Intent, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import Select from '@components/Select';
import TextAreaFormField from '@components/TextAreaFormField';
import TimeInputFormField from '@components/TimeInputFormField';
import { countriesData } from 'dy-frontend-shared/lib/data/consts';
import { ModalProps } from '@modals/types';
import { TeamRef } from 'dy-frontend-http-repository/lib/modules/Team/refs';
import { TimezoneUtils } from 'dy-frontend-shared/lib/utils';
import React, { useMemo } from 'react';
import { useUpdateTeam } from '../../hooks';
import { CountrySelectItem, TimezoneSelectItem } from './types';

export interface UpdateTeamInformationModalProps {
    teamId: ID;
    color: string;
    name: string;
    description: string;
    countryCode: string;
    timezoneName: string;
    workingHoursStart: string;
    workingHoursEnd: string;
}

type Props = ModalProps<UpdateTeamInformationModalProps>;

const initialWorkingHoursDate = new Date();
initialWorkingHoursDate.setHours(0, 0, 0);
const teamColorValidators = [getStringRequiredValidator()];
const teamTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 3 }),
    getStringMaxLengthValidator({ maxStringLength: 70 }),
];
const teamSummaryValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];
const teamCountryCodeValidators = [getStringRequiredValidator()];
const teamTimezoneNameValidators = [getStringRequiredValidator()];
const teamWorkingHoursStartValidators = [getStringRequiredValidator(), getStringDateValidator()];
const teamWorkingHoursEndValidators = [getStringRequiredValidator(), getStringDateValidator()];

const UpdateTeamInformationModal: React.FC<Props> = ({ closeModal, data }) => {
    const { updateTeam } = useUpdateTeam();
    const { getDateFromWorkingHours, getWorkingHoursFromDate } = useTeamWorkingHours();

    const teamTitle = useTextFormField({
        id: 'team-title',
        validators: teamTitleValidators,
        initialValue: data?.name ?? '',
    });

    const teamColor = useColorPickerFormField({
        id: 'team-color',
        validators: teamColorValidators,
        initialValue: data?.color ?? '',
    });

    const teamSummary = useTextFormField({
        id: 'team-summary',
        validators: teamSummaryValidators,
        initialValue: data?.description ?? '',
    });

    const teamCountryCode = useCustomSelectFormField<string | null>({
        id: 'team-country-code',
        validators: teamCountryCodeValidators,
        initialValue: data?.countryCode ?? null,
        formatValue: (value) => value ?? '',
    });

    const teamTimezoneName = useCustomSelectFormField<string | null>({
        id: 'team-timezone-name',
        validators: teamTimezoneNameValidators,
        initialValue: data?.timezoneName ?? null,
        formatValue: (value) => value ?? '',
    });

    const teamWorkingHoursStart = useTimeFormField({
        id: 'team-working-hours-start',
        validators: teamWorkingHoursStartValidators,
        initialValue: data?.workingHoursStart
            ? getDateFromWorkingHours(data.workingHoursStart)
            : initialWorkingHoursDate,
        formatValue: (value) => (value ? value.toString() : ''),
    });

    const teamWorkingHoursEnd = useTimeFormField({
        id: 'team-working-hours-end',
        validators: teamWorkingHoursEndValidators,
        initialValue: data?.workingHoursEnd ? getDateFromWorkingHours(data.workingHoursEnd) : initialWorkingHoursDate,
        formatValue: (value) => (value ? value.toString() : ''),
    });

    const form = useForm<TeamRef>({
        fields: [
            teamTitle,
            teamColor,
            teamSummary,
            teamCountryCode,
            teamTimezoneName,
            teamWorkingHoursStart,
            teamWorkingHoursEnd,
        ],
        apiCall: async () => {
            try {
                const teamRef = await updateTeam({
                    teamId: data!.teamId,
                    title: teamTitle.value,
                    color: teamColor.value,
                    summary: teamSummary.value,
                    country: teamCountryCode.value ?? '',
                    timezoneName: teamTimezoneName.value ?? '',
                    workingHoursStart: getWorkingHoursFromDate(teamWorkingHoursStart.value),
                    workingHoursEnd: getWorkingHoursFromDate(teamWorkingHoursEnd.value),
                });

                return { response: teamRef };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (e) => {
            // TODO: handle error
            console.error(e);
        },
    });

    // Get timezone names
    const timezoneNames = useMemo<TimezoneSelectItem[]>(() => {
        const extendedTimezoneNames: TimezoneSelectItem[] = [];

        for (const timezoneName of TimezoneUtils.getTimezoneNames()) {
            extendedTimezoneNames.push({ GMT: TimezoneUtils.getTimezoneGMTValue(timezoneName), name: timezoneName });
        }

        return extendedTimezoneNames;
    }, []);

    const renderCountryFormFieldSelect = () => {
        const filterItemOption = (query: string, item: CountrySelectItem) => {
            return item.countryName.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: CountrySelectItem, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.key === teamCountryCode.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.key}
                    text={item.countryName}
                    label={item.key}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: CountrySelectItem) => {
            teamCountryCode.handleChange(item.key);
        };

        return (
            <FormGroup
                label="Country"
                intent={!!teamCountryCode.error ? Intent.DANGER : Intent.NONE}
                helperText={teamCountryCode.error}
            >
                <Select<CountrySelectItem>
                    filterable
                    items={Object.values(countriesData)}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={filterItemOption}
                    inputProps={{
                        placeholder: 'Search for country',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                        onClose: teamCountryCode.handleClose,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select a country',
                        text: teamCountryCode.value && countriesData[teamCountryCode.value].countryName,
                        icon: 'add',
                    }}
                />
            </FormGroup>
        );
    };

    const renderTimezoneFormFieldSelect = () => {
        const filterItemOption = (query: string, item: TimezoneSelectItem) => {
            return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
        };

        const renderItem = (item: TimezoneSelectItem, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item.name === teamTimezoneName.value;

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item.name}
                    text={item.name}
                    label={item.GMT}
                    onClick={handleClick}
                />
            );
        };

        const handleItemSelect = (item: TimezoneSelectItem) => {
            teamTimezoneName.handleChange(item.name);
        };

        return (
            <FormGroup
                label="Time zone"
                intent={!!teamTimezoneName.error ? Intent.DANGER : Intent.NONE}
                helperText={teamTimezoneName.error}
            >
                <Select<TimezoneSelectItem>
                    filterable
                    items={timezoneNames}
                    itemRenderer={renderItem}
                    onItemSelect={handleItemSelect}
                    itemPredicate={filterItemOption}
                    inputProps={{
                        placeholder: 'Search for time zone',
                    }}
                    popoverProps={{
                        matchTargetWidth: true,
                        usePortal: false,
                    }}
                    selectButtonProps={{
                        fill: true,
                        rightIcon: 'double-caret-vertical',
                        placeholder: 'Select a timezone',
                        text: teamTimezoneName.value,
                    }}
                />
            </FormGroup>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '600px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Update team</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <Grid container className="mb-2">
                        {/* Color */}
                        <Grid lg={3} xs={12}>
                            <ColorTilePickerFormField field={teamColor} formGroupProps={{ label: 'Color' }} />
                        </Grid>

                        {/* Title */}
                        <Grid lg={9} xs={12}>
                            <InputFormField
                                field={teamTitle}
                                formGroupProps={{ label: 'Title' }}
                                inputProps={{ placeholder: 'Enter title' }}
                            />
                        </Grid>

                        {/* Description */}
                        <Grid lg={12}>
                            <TextAreaFormField
                                field={teamSummary}
                                formGroupProps={{ label: 'About' }}
                                textAreaProps={{ placeholder: 'Enter about' }}
                            />
                        </Grid>

                        {/* Country */}
                        <Grid lg={12}>{renderCountryFormFieldSelect()}</Grid>

                        {/* Timezone */}
                        <Grid lg={12}>{renderTimezoneFormFieldSelect()}</Grid>

                        {/* Working hours start */}
                        <Grid lg={6} xs={12}>
                            <TimeInputFormField
                                field={teamWorkingHoursStart}
                                formGroupProps={{ label: 'Shift start' }}
                            />
                        </Grid>

                        {/* Working hours end */}
                        <Grid lg={6} xs={12}>
                            <TimeInputFormField field={teamWorkingHoursEnd} formGroupProps={{ label: 'Shift end' }} />
                        </Grid>
                    </Grid>

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default UpdateTeamInformationModal;
