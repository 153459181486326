import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { UserUtils } from '@app/containers/components/Notifications/utils';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import { NotificationResource, TaskResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints } from '@app/data/consts';
import NotificationTemplate from '../../../NotificationTemplate';

export interface TaskRestoredNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    task: TaskResource | null;
    dismiss: () => Promise<void>;
}

type Props = TaskRestoredNotificationProps;

const TaskRestoredNotification: React.FC<Props> = ({ authorizedUserId, notification, task, dismiss }) => {
    const renderContent = () => {
        if (!task) {
            // This is bad, task is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Request was not found</DevText>;
        }

        return (
            <div>
                <DevText running className="mb-1">
                    {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)} restored{' '}
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.TASK_INFORMATION.replace(':taskId', task.id)}
                    >
                        {task.title}
                    </RouterLink>
                </DevText>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="Request restored"
            icon="history"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default TaskRestoredNotification;
