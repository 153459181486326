import Avatar from '@app/components/Avatar';
import Flex from '@app/components/Flex';
import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { Endpoints, imageHashPreview, userRoleInformation } from '@app/data/consts';
import { Card, Popover, PopoverProps } from '@blueprintjs/core';
import { UserRole } from 'dy-frontend-http-repository/lib/data/enums';
import { ImageHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/ImageHashPreview/enums';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import React from 'react';
import TeamTag from '../../components/TeamTag';

export interface ParticipantListInformationProviderProps {
    minWidth?: string;
    participants: {
        userId: ID;
        imageHash: ImageHash | null;
        name: string;
        teams: {
            id: ID;
            name: string;
            color: string;
        }[];
        role: UserRole;
    }[];
}

export type Props = PopoverProps & ParticipantListInformationProviderProps;

const ParticipantListInformationProvider: React.FC<Props> = ({
    minWidth = '400px',
    participants,
    children,
    ...props
}) => {
    const renderTeams = (teams: (typeof participants)[number]['teams']) => {
        if (teams.length === 0) {
            // No teams
            return <DevText muted>No team</DevText>;
        }

        return teams.map((team, index) => (
            <TeamTag className={index === 0 ? '' : 'mt-small'} id={team.id} color={team.color} name={team.name} />
        ));
    };

    const renderParticipant = (participant: (typeof participants)[number]) => {
        // Get imageHash
        let avatarSrc: string | null = null;
        if (participant.imageHash) {
            avatarSrc = imageHashPreview.userImage(participant.imageHash, ImageHashPreviewSize.XS);
        }

        return (
            <Flex direction="row" align="flex-start">
                <Avatar
                    className="mt-small mr-1"
                    width="35px"
                    height="35px"
                    minWidth="35px"
                    minHeight="35px"
                    src={avatarSrc}
                    alt={participant.name}
                />
                <div>
                    <DevText>
                        <RouterLink
                            color="inherit"
                            to={Endpoints.CORE_USER_TASKS.replace(':coreUserId', participant.userId)}
                        >
                            {participant.name}
                        </RouterLink>{' '}
                        -{' '}
                        <DevText inline muted>
                            {userRoleInformation[participant.role].label}
                        </DevText>
                    </DevText>

                    {renderTeams(participant.teams)}
                </div>
            </Flex>
        );
    };

    const renderPopoverContent = () => {
        return (
            <Card style={{ minWidth }}>
                <DevText className="mb-2" muted>
                    {TextFormatUtils.getMultiple(participants.length, 'Participant')}
                </DevText>

                {participants.map((participant, index) => (
                    <div key={participant.userId} className={index === 0 ? '' : 'mt-2'}>
                        {renderParticipant(participant)}
                    </div>
                ))}
            </Card>
        );
    };

    return (
        <Popover content={renderPopoverContent()} {...props}>
            {children}
        </Popover>
    );
};

export default ParticipantListInformationProvider;
