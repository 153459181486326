import { IconName } from '@blueprintjs/core';
import { PlatformCoreUserFilterType } from '../enums';

const platformCoreUserFiltersInformation: {
    [type in PlatformCoreUserFilterType]: {
        title: string;
        isPinned: boolean;
        icon: IconName;
    };
} = {
    [PlatformCoreUserFilterType.CORE_USER_TEAMS]: {
        title: 'Teams',
        icon: 'inherited-group',
        isPinned: true,
    },

    [PlatformCoreUserFilterType.CORE_USER_ROLES]: {
        title: 'Roles',
        icon: 'user',
        isPinned: true,
    },

    [PlatformCoreUserFilterType.CORE_USER_VERIFIED]: {
        title: 'Verified',
        icon: 'endorsed',
        isPinned: true,
    },

    [PlatformCoreUserFilterType.CORE_USER_ARCHIVED]: {
        title: 'Archived',
        icon: 'remove',
        isPinned: true,
    },
};

export default platformCoreUserFiltersInformation;
