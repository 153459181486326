import React from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Divider, Intent, MenuItem } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { useCustomSelectFormField, useForm, useTextFormField } from '@app/hooks';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import SelectFormField from '@app/components/SelectFormField';
import InputFormField from '@app/components/InputFormField';
import { taskStateInformation } from '@app/data/consts';
import { transitionTaskState } from '../../store/effects';
import { taskApi } from '../../store/apis';
import { ItemRendererProps } from '@blueprintjs/select';
import Circle from '@app/components/Circle';

export interface ChangeTaskStateForcefullyModalProps {
    taskId: ID;
    currentState: TaskState;
}

type Props = ModalProps<ChangeTaskStateForcefullyModalProps>;

const stateToApplyValidators = [getStringRequiredValidator()];
const commentValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 1 }),
    getStringMaxLengthValidator({ maxStringLength: 255 }),
];

const ChangeTaskStateForcefullyModal: React.FC<Props> = ({ closeModal, data }) => {
    const stateToApply = useCustomSelectFormField<string | null>({
        id: 'state-to-apply',
        validators: stateToApplyValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });

    const comment = useTextFormField({
        id: 'comment',
        validators: commentValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [stateToApply, comment],
        apiCall: async () => {
            if (!data) {
                throw new Error('Data was NOT provided to modal');
            }

            try {
                await transitionTaskState({
                    id: data.taskId,
                    input: { state: stateToApply.value, comment: comment.value, is_forced: true },
                });
                taskApi.transitionState({ state: stateToApply.value as TaskState });
                handleModalClose();
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
            return { response: true };
        },
    });

    const handleModalClose = () => {
        closeModal?.();
    };

    if (!data) {
        handleModalClose();
        return null;
    }

    const getAllowedStateSelectList = (): TaskState[] => {
        const allowedStates = [
            TaskState.PUBLISHED,
            TaskState.PICKUP_READY,
            TaskState.IN_PROGRESS,
            TaskState.INTERNAL_QA,
            TaskState.CLIENT_REVIEW,
            TaskState.REVISION_REQUIRED,
            TaskState.IN_REVISION,
            TaskState.PRE_DELIVERED,
            TaskState.DELIVERED,
        ];

        const filterOutStateMap: { [state in TaskState]: TaskState[] } = {
            [TaskState.DRAFT]: [],
            [TaskState.PUBLISHED]: [TaskState.PICKUP_READY],
            [TaskState.PICKUP_READY]: [TaskState.IN_PROGRESS],
            [TaskState.IN_PROGRESS]: [TaskState.INTERNAL_QA, TaskState.CLIENT_REVIEW],
            [TaskState.INTERNAL_QA]: [TaskState.IN_REVISION, TaskState.CLIENT_REVIEW],
            [TaskState.CLIENT_REVIEW]: [TaskState.IN_REVISION, TaskState.REVISION_REQUIRED, TaskState.PRE_DELIVERED],
            [TaskState.REVISION_REQUIRED]: [TaskState.IN_REVISION, TaskState.CLIENT_REVIEW],
            [TaskState.IN_REVISION]: [TaskState.INTERNAL_QA, TaskState.CLIENT_REVIEW],
            [TaskState.PRE_DELIVERED]: [TaskState.DELIVERED, TaskState.IN_REVISION],
            [TaskState.DELIVERED]: [],
        };

        return allowedStates.filter(
            (state) => state !== data.currentState && !filterOutStateMap[data.currentState].includes(state)
        );
    };

    const renderSelect = () => {
        const renderItem = (item: string, { handleClick }: ItemRendererProps) => {
            const isMenuItemActive = item === stateToApply.value;
            const information = taskStateInformation[item];

            return (
                <MenuItem
                    active={isMenuItemActive}
                    key={item}
                    icon={<Circle size="16px" color={information.color} />}
                    text={information.title}
                    onClick={handleClick}
                />
            );
        };

        return (
            <SelectFormField
                items={getAllowedStateSelectList()}
                field={stateToApply}
                formGroupProps={{ label: 'Status to change to' }}
                selectProps={{
                    itemRenderer: renderItem,
                    selectButtonProps: {
                        placeholder: 'Select status to change to',
                        icon:
                            stateToApply.value !== null ? (
                                <Circle size="16px" color={taskStateInformation[stateToApply.value].color} />
                            ) : (
                                'add'
                            ),
                    },
                }}
                itemToReadableFormatter={(state) => taskStateInformation[state].title}
            />
        );
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="start" justify="space-between">
                    <Heading type="h4" className="mb-1">
                        Force status change
                    </Heading>

                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <Heading type="h5" className="mb-2">
                    Warning: You are changing request status ignoring expected flow.
                </Heading>

                <DevText running className="mb-3">
                    Please use this functionality only in cases, where correcting task status is an absolute must.
                    Please note that by correcting status this way, some of the triggers (like sending notifications)
                    may not work. Select status you want to transition to, and provide short description.
                </DevText>

                <form onSubmit={form.handleFormSubmit}>
                    {renderSelect()}

                    <InputFormField
                        field={comment}
                        formGroupProps={{ label: 'Reason', labelInfo: '(required)' }}
                        inputProps={{ placeholder: 'i.e. "Requested by client"' }}
                    />

                    <Flex align="center" justify="space-between">
                        <DevText muted>Proceed with status change?</DevText>
                        <div>
                            <Button minimal onClick={handleModalClose} className="mr-1" disabled={form.isSubmitting}>
                                No, cancel
                            </Button>
                            <Button
                                disabled={form.hasFieldErrors}
                                loading={form.isSubmitting}
                                type="submit"
                                intent={Intent.PRIMARY}
                            >
                                Yes, change
                            </Button>
                        </div>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default ChangeTaskStateForcefullyModal;
