import { Button, Collapse, Icon, Spinner } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { TaskResource } from 'dy-frontend-http-repository/lib/modules/OpenTaskList/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { OpenTaskType } from 'dy-frontend-shared/lib/data/enums';
import { teamOpenTaskListDataApi } from './store/apis';
import { fetchTeamOpenTaskList } from './store/effects';
import { resetTeamOpenTaskListData } from './store/events';
import { $teamOpenTaskListData } from './store/states';
import { $team } from '../../store/states';
import NonIdealState from '@app/components/NonIdealState';
import OpenTaskCard from '@app/containers/components/OpenTaskCard';
import { usePageTitle } from '@app/hooks';

const TeamTasks: React.FC = () => {
    usePageTitle('Team Requests');

    const team = useStore($team);

    const teamOpenTaskListData = useStore($teamOpenTaskListData);

    const [isPausedTaskListCollapsed, setIsPauseListCollapsed] = useState(true);

    useEffect(() => {
        if (!team) {
            return;
        }

        fetchTeamOpenTaskList({ filter: { team_id: team.id } })
            .then((openTaskList) => teamOpenTaskListDataApi.initialize({ openTaskList }))
            .catch((e) => {
                // TODO: handle error
                console.error(e);
            });

        return () => {
            resetTeamOpenTaskListData();
        };

        // eslint-disable-next-line
    }, [team?.id]);

    if (!team) {
        return null;
    }

    if (!teamOpenTaskListData) {
        // Team open task list data is NOT fetched yet
        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    // TODO: add "View all requests" button whenever task filter for team will be implemented on backend
    // const renderHeader = () => {
    //     const handleNavigateToAllTeamTasks = () => {
    //         const taskFilterQueryParametersString = qs.stringify(
    //             PlatformTaskFilter.APIFilterInputToQuery(1, {
    //                 participant_user_id: [team.id],
    //             })
    //         );
    //         console.log('taskFilterQueryParametersString: ', taskFilterQueryParametersString);
    //         navigate(`${Endpoints.TASKS}?${taskFilterQueryParametersString}`);
    //     };

    //     return (
    //         <Flex className="mb-2" justify="space-between" align="center">
    //             <Button intent={Intent.PRIMARY} className="mr-1" onClick={handleNavigateToAllTeamTasks}>
    //                 View all requests
    //             </Button>
    //         </Flex>
    //     );
    // };

    const renderTasksList = (tasks: TaskResource[]) => {
        return tasks.map((task, index) => (
            <OpenTaskCard
                key={task.id}
                className={index === 0 ? '' : 'mt-1'}
                task={task}
                taskData={teamOpenTaskListData}
            />
        ));
    };

    const renderPausedTasks = (tasks: TaskResource[], isForceOpen = false) => {
        if (tasks.length === 0) {
            return null;
        }

        return (
            <div>
                <Flex className={isPausedTaskListCollapsed && !isForceOpen ? '' : 'mb-1'} align="center" justify="space-between">
                    <Heading type="h3">
                        {isPausedTaskListCollapsed && !isForceOpen ? `Paused: ${tasks.length} request(s)` : 'Paused'}
                    </Heading>
                    {!isForceOpen && (
                        <Button
                            minimal
                            icon={isPausedTaskListCollapsed ? 'chevron-down' : 'chevron-up'}
                            onClick={() => setIsPauseListCollapsed(!isPausedTaskListCollapsed)}
                        >{isPausedTaskListCollapsed ? 'Click to show' : 'Click to hide'}</Button>
                    )}
                </Flex>

                <Collapse isOpen={isForceOpen || !isPausedTaskListCollapsed}>
                    {renderTasksList(tasks)}
                </Collapse>
            </div>
        );
    };

    const renderActiveTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        return (
            <div>
                <Heading className="mb-1" type="h3">
                    Active
                </Heading>
                {renderTasksList(tasks)}
            </div>
        );
    };

    const renderBacklogTasks = (tasks: TaskResource[]) => {
        if (tasks.length === 0) {
            return null;
        }

        return (
            <div>
                <Heading className="mb-1" type="h3">
                    Backlog
                </Heading>
                {renderTasksList(tasks)}
            </div>
        );
    };

    const renderOpenTasks = () => {
        const activeTasks = Object.values(teamOpenTaskListData.tasks[OpenTaskType.ACTIVE]);
        const backlogTasks = Object.values(teamOpenTaskListData.tasks[OpenTaskType.BACKLOG]);
        const pausedTasks = Object.values(teamOpenTaskListData.tasks[OpenTaskType.PAUSED]);

        const isTaskListEmpty = ![activeTasks, backlogTasks, pausedTasks].some((tasks) => tasks.length > 0);
        if (isTaskListEmpty) {
            return (
                <NonIdealState
                    icon={<Icon className="mb-2" icon="search" size={70} />}
                    title={
                        <Heading type="h4" className="mb-1">
                            Team do not have any requests associated with it
                        </Heading>
                    }
                />
            );
        }

        return (
            <div>
                <div className="mb-3">{renderPausedTasks(
                    pausedTasks,
                    activeTasks.length === 0 && backlogTasks.length === 0
                )}</div>
                <div className="mb-3">{renderActiveTasks(activeTasks)}</div>
                <div>{renderBacklogTasks(backlogTasks)}</div>
            </div>
        );
    };

    return <div>{renderOpenTasks()}</div>;
};

export default TeamTasks;
