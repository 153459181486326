import DropzoneArea from '@app/components/DropzoneArea';
import FileTilePreview from '@app/components/FileTilePreview';
import FileUploadButton from '@app/components/FileUploadButton';
import NonIdealState from '@app/components/NonIdealState';
import DevText from '@app/components/Text';
import { fileHashPreview } from '@app/data/consts';
import { useCustomSelectFormField, useForm, useUpload } from '@app/hooks';
import useDraggingFlag from '@app/hooks/useDraggingFlag';
import { FileInformationStatus } from '@app/hooks/useUpload/enums';
import { FileInformation } from '@app/hooks/useUpload/types';
import { getFileMaxSizeValidator, getStringRequiredValidator } from '@app/hooks/validation/functions';
import { Button, Card, Divider, Icon, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import SelectFormField from '@components/SelectFormField';
import { ModalProps } from '@modals/types';
import { FilePurpose } from 'dy-frontend-http-repository/lib/data/enums';
import {
    BrandProfileAssetCategory,
    BrandProfileAssetType,
} from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/enums';
import { BrandProfileAssetRefList } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/refs';
import { BrandProfileAssetListItemResource } from 'dy-frontend-http-repository/lib/modules/BrandProfileAsset/resources';
import { FileHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/FileHashPreview/enums';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useState } from 'react';
import { brandProfileAssetCategoriesInformation } from '../../consts';
import { brandProfileAssetsApi } from '../../store/apis';
import { createBrandProfileAssets } from '../../store/effects';
import { $brandProfile } from '../../store/states';
import { FileInformationToBrandAssetFileResourceMapper } from './valueObjects';

type Props = ModalProps;

const brandProfileAssetsValidators = [getFileMaxSizeValidator({ maxSize: 125000000 })];

const brandProfileAssetCategoryValidators = [getStringRequiredValidator()];

const AddBrandProfileAssetsModal: React.FC<Props> = ({ closeModal }) => {
    const { isDragging } = useDraggingFlag();

    const {
        isUploading,
        files: uploadingFiles,
        upload: uploadFiles,
    } = useUpload({
        validators: brandProfileAssetsValidators,
        clearFilesOnUploadSuccess: true,
        onUploadSuccess: (files) => {
            setUploadedFiles((prevUploadedFiles) => [...files, ...prevUploadedFiles]);
        },
    });

    const brandProfile = useStore($brandProfile);

    const brandProfileAssetCategory = useCustomSelectFormField<string | null>({
        id: 'brand-profile-asset-category',
        validators: brandProfileAssetCategoryValidators,
        initialValue: null,
        formatValue: (value) => value ?? '',
    });
    const [uploadedFiles, setUploadedFiles] = useState<FileInformation[]>([]);

    const form = useForm<BrandProfileAssetRefList>({
        fields: [brandProfileAssetCategory],
        apiCall: async () => {
            try {
                const brandProfileAssetRefList = await createBrandProfileAssets({
                    brand_profile_id: brandProfile!.id,
                    assets: uploadedFiles.map((asset) => ({
                        type: BrandProfileAssetType.FILE,
                        category: brandProfileAssetCategory.value as BrandProfileAssetCategory,
                        title: asset.file.name,
                        file_id: asset.verifiedFileResource!.id,
                    })),
                });

                const momentNowUtc = moment().utc().format();

                const brandProfileAssets: BrandProfileAssetListItemResource[] = uploadedFiles.map((file, index) => ({
                    id: brandProfileAssetRefList.ids[index],
                    archived_at: null,
                    brand_profile_id: brandProfile!.id,
                    category: brandProfileAssetCategory.value as BrandProfileAssetCategory,
                    type: BrandProfileAssetType.FILE,
                    created_at: momentNowUtc,
                    file: FileInformationToBrandAssetFileResourceMapper.transform(file),
                    title: file.file.name,
                }));

                brandProfileAssetsApi.addBrandProfileAssets(brandProfileAssets);

                return { response: brandProfileAssetRefList };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!brandProfile) {
        return null;
    }

    const renderFiles = () => {
        // Get files empty flag
        const isFilesListEmpty = uploadedFiles.length === 0 && uploadingFiles.length === 0;

        if (isFilesListEmpty && !isUploading) {
            if (isDragging) {
                return null;
            } else {
                return (
                    <NonIdealState
                        icon={<Icon className="mb-1" icon="document" size={32} />}
                        title={<DevText className="mb-1">You didn't upload any assets yet</DevText>}
                    />
                );
            }
        }

        const renderUploadingFiles = () => {
            return uploadingFiles.map((asset) => {
                // Get preview url
                let previewUrl: string | null = null;
                if (asset.verifiedFileResource?.preview_path) {
                    previewUrl = fileHashPreview.getPreviewUrl(
                        asset.verifiedFileResource?.preview_path,
                        FileHashPreviewSize.SM
                    );
                }

                return (
                    <FileTilePreview
                        loading
                        useOnlySrcPresenceCheck
                        className="mb-1"
                        key={asset.key}
                        fileName={asset.file.name}
                        src={previewUrl}
                        extension={FileUtils.getFileExtension(asset.file.name)}
                        progress={asset.progress}
                        fileSize={asset.file.size}
                    />
                );
            });
        };

        const renderUploadedFiles = () => {
            const handleRemoveUploadedFile = async (fileKey: string) => {
                setUploadedFiles((prevValue) => [...prevValue].filter((file) => file.key !== fileKey));
            };

            const renderUploadedFileActions = (fileKey: string) => {
                return (
                    <>
                        <Button
                            minimal
                            small
                            icon="trash"
                            intent={Intent.DANGER}
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveUploadedFile(fileKey);
                            }}
                        />
                    </>
                );
            };

            const renderUploadedFilePreview = (asset: FileInformation, index: number) => {
                // Get preview url
                let previewUrl: string | null = null;
                if (asset.verifiedFileResource?.preview_path) {
                    previewUrl = fileHashPreview.getPreviewUrl(
                        asset.verifiedFileResource?.preview_path,
                        FileHashPreviewSize.SM
                    );
                }

                return (
                    <FileTilePreview
                        useOnlySrcPresenceCheck
                        className={index === 0 ? '' : 'mt-1'}
                        fileName={asset.file.name}
                        src={previewUrl}
                        extension={FileUtils.getFileExtension(asset.file.name)}
                        fileSize={asset.file?.size}
                        actions={renderUploadedFileActions(asset.key)}
                    />
                );
            };

            return uploadedFiles.map(renderUploadedFilePreview);
        };

        return (
            <div>
                {renderUploadingFiles()}
                {renderUploadedFiles()}
            </div>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Add New Brand Assets</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <SelectFormField
                        field={brandProfileAssetCategory}
                        items={Object.values(BrandProfileAssetCategory)}
                        formGroupProps={{ label: 'Brand asset type' }}
                        selectProps={{
                            selectButtonProps: {
                                placeholder: 'Select brand asset type',
                            },
                        }}
                        itemToReadableFormatter={(item) => brandProfileAssetCategoriesInformation[item]}
                    />

                    <div>
                        <Flex className="mb-1" direction="row" align="center" justify="space-between">
                            <DevText>Files</DevText>

                            <FileUploadButton
                                multiple
                                disabled={isUploading}
                                loading={isUploading}
                                icon="upload"
                                onAttachFiles={(files) => uploadFiles(FilePurpose.BRAND_PROFILE_ASSET, files)}
                            >
                                Upload
                            </FileUploadButton>
                        </Flex>

                        <DropzoneArea
                            className="mb-1"
                            visible={isDragging}
                            uploadFiles={(files) => uploadFiles(FilePurpose.BRAND_PROFILE_ASSET, files)}
                        >
                            <Flex fullHeight fullWidth direction="column" align="center" justify="center">
                                <Icon className="mb-small" icon="upload" size={48} />
                                <Heading type="h6">Drop files here to upload</Heading>
                            </Flex>
                        </DropzoneArea>

                        {renderFiles()}
                    </div>

                    <Flex className="mt-2" justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>
                        <Button
                            loading={form.isSubmitting}
                            disabled={
                                brandProfileAssetCategory.value === undefined ||
                                uploadedFiles.length === 0 ||
                                uploadingFiles.length > 0 ||
                                isUploading
                            }
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default AddBrandProfileAssetsModal;
