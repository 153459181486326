import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { PlatformClientUserFilterData } from '@app/containers/pages/Clients/types';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { fetchStaticData } from '@app/containers/pages/Clients/store/effects';
import { $isQueryHydrationFinished } from '@app/containers/pages/Clients/store/states';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import { platformClientUserFiltersInformation } from '@app/containers/pages/Clients/consts';
import { PlatformClientUserFilterType } from '@app/containers/pages/Clients/enums';
import { setPlatformClientUserFilterData } from '@app/containers/pages/Clients/store/events';

export interface ClientUserPlansPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformClientUserFilterData: PlatformClientUserFilterData;
    onRemove: () => void;
}

export type Props = ClientUserPlansPlatformFilterProps;

const ClientUserPlansPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformClientUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isPlanInitialized, setIsPlansInitialized] = useState(false);
    const [selectedPlans, setSelectedPlans] = useState<PlatformClientUserFilterData['plans']>([]);
    const [foundPlans, setFoundPlans] = useState<PlatformClientUserFilterData['plans']>([]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            fetchStaticData({ plan: '1' }).then((staticData) => {
                setFoundPlans(staticData.plan);
                setSelectedPlans(
                    staticData.plan.filter((sp) => platformClientUserFilterData.plans.find((p) => p.id === sp.id))
                );
                setTimeout(() => setIsPlansInitialized(true), 0);
            });
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformClientUserFilterData.plans.length === 0) {
            setSelectedPlans([]);
        }
    }, [platformClientUserFilterData.plans]);

    const getFilterTagLabel = () => {
        if (selectedPlans.length === 0) return '';

        return selectedPlans
            .map((p, index) => {
                if (index === selectedPlans.length - 1) {
                    // Last element
                    return `${p.title}`;
                }

                return `${p.title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedPlans.length > 0) {
            // At least 1 plan selected
            return 'Plan:';
        }

        return 'Plan';
    };

    const handleRemoveFilter = () => {
        if (selectedPlans.length !== 0) {
            // At least 1 plan was selected

            setSelectedPlans([]);

            if (platformClientUserFilterData.plans.length !== 0) {
                // Reset plan to empty array
                setPlatformClientUserFilterData({ ...platformClientUserFilterData, plans: [] });
            }

            setIsOpen(false);
        }

        if (!platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_PLANS].isPinned) {
            // Platform plan filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (plan: PlatformClientUserFilterData['plans'][number]) => {
        if (!!selectedPlans.find((selectedPlan) => selectedPlan.id === plan.id)) {
            // Plan is already checked
            setSelectedPlans(selectedPlans.filter((selectedPlan) => selectedPlan.id !== plan.id));
        } else {
            // Plan is NOT checked yet
            setSelectedPlans([...selectedPlans, plan]);
        }
    };

    const handleApplyFilter = () => {
        if (selectedPlans.length !== platformClientUserFilterData.plans.length) {
            // If task plans array length didn't change since opening the filter then nothing apply to
            setPlatformClientUserFilterData({ ...platformClientUserFilterData, plans: selectedPlans });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_PLANS].isPinned) {
            return;
        }

        if (selectedPlans.length === 0) {
            onRemove();
        }
    };

    const renderRow = (plan: PlatformClientUserFilterData['plans'][number]) => {
        return (
            <li className="mb-1" key={plan.id}>
                <Checkbox
                    checked={!!selectedPlans.find((selectedPlan) => selectedPlan.id === plan.id)}
                    className="mr-2"
                    label={plan.title}
                    onClick={() => handleCheckboxClick(plan)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformClientUserFilterData['plans'][number]>
            isOpen={isOpen}
            loading={!isPlanInitialized}
            removable={selectedPlans.length > 0}
            title="Plan"
            position={Position.BOTTOM_LEFT}
            minWidth={320}
            maxListHeight={400}
            list={foundPlans}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_PLANS].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default ClientUserPlansPlatformFilter;
