import { PlatformTaskFilterData } from '../types';

const initialPlatformTaskFilterData: PlatformTaskFilterData = {
    query: '',
    clients: [],
    participants: [],
    teams: [],
    states: [],
    queue: null,
    taskCategories: [],
    isArchived: null,
    isPaused: null,
    publishTypes: [],
    plans: [],
    deadlineState: null,
};

export default initialPlatformTaskFilterData;
