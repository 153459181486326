import { createGlobalStyle, css } from 'styled-components';
import { Classes, Colors } from '@blueprintjs/core';
import { breakpoints } from '@app/data/consts';

export const GlobalStyles = createGlobalStyle`
    *, *:before, *:after {
      box-sizing: border-box
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    html,body {
      width: 100%;
      min-height: 100vh;
      margin: 0;
      padding: 0;
      font-size: 14px;
      overflow: hidden;
    }

    ul, ol {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .${Classes.HEADING}, .${Classes.CONTROL}, p {
        margin: 0;
    }

    // Disable border for cards with 0 elevation in dark theme
    .${Classes.DARK} .${Classes.CARD}.${Classes.ELEVATION_0} {
        box-shadow: none !important;
    }

    .control-card-group-row {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        width: 100%;
    }

    .custom-thin-scroll {
      scrollbar-width: thin;
      scrollbar-color: ${Colors.DARK_GRAY5} transparent;

      &::-webkit-scrollbar {
          background-color: transparent;
          width: 8px;
          height: 8px;
      }

      &::-webkit-scrollbar-track {
          background-color: ${Colors.DARK_GRAY1};
      }

      &::-webkit-scrollbar-track:hover {
        background-color: ${Colors.DARK_GRAY1};
      }

      &::-webkit-scrollbar-thumb {
          background-color: ${Colors.DARK_GRAY5};
          border-radius: 4px;
          border: 1px solid ${Colors.DARK_GRAY5};
      }

      &::-webkit-scrollbar-thumb:hover {
          background-color: ${Colors.DARK_GRAY2};
          border: 1px solid ${Colors.DARK_GRAY2};
      }

      &::-webkit-scrollbar-button {
          display: none;
      }
    }

    .custom-thin-scroll-light {
        scrollbar-width: thin;
        scrollbar-color: ${Colors.DARK_GRAY2} transparent;

        &::-webkit-scrollbar {
            background-color: transparent;
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-track {
            background-color: transparent
        }

        &::-webkit-scrollbar-track:hover {
            background-color: transparent
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${Colors.DARK_GRAY2};
            border-radius: 4px;
            border: 1px solid ${Colors.DARK_GRAY2};
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color:${Colors.DARK_GRAY2};
            border: 1px solid #161d23;
        }

        &::-webkit-scrollbar-button {
            display: none;
        }
    }


    .my {
      &-small {
        margin-top: 4px;
        margin-bottom: 4px;
      }

      &-unit {
        margin-top: 1px;
        margin-bottom: 1px;
      }

      &-bz-small {
        margin-top: -4px;
        margin-bottom: -4px;
      }

      &-bz-unit {
        margin-top: -1px;
        margin-bottom: -1px;
      }

      &-1 {
        margin-top: 8px;
        margin-bottom: 8px;
      }

      &-bz-1 {
        margin-top: -8px;
        margin-bottom: -8px;
      }

      &-2 {
        margin-top: 16px;
        margin-bottom: 16px;
      }

      &-bz-2 {
        margin-top: -16px;
        margin-bottom: -16px;
      }

      &-3 {
        margin-top: 24px;
        margin-bottom: 24px;
      }

      &-bz-3 {
        margin-top: -24px;
        margin-bottom: -24px;
      }

      &-4 {
        margin-top: 32px;
        margin-bottom: 32px;
      }

      &-bz-4 {
        margin-top: -32px;
        margin-bottom: -32px;
      }

      &-md {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.md}px) {
                &-small {
                    margin-top: 4px;
                    margin-bottom: 4px;
                }

                &-unit {
                    margin-top: 1px;
                    margin-bottom: 1px;
                }

                &-bz-small {
                    margin-top: -4px;
                    margin-bottom: -4px;
                }

                &-bz-unit {
                    margin-top: -1px;
                    margin-bottom: -1px;
                }

                &-1 {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }

                &-bz-1 {
                    margin-top: -8px;
                    margin-bottom: -8px;
                }

                &-2 {
                    margin-top: 16px;
                    margin-bottom: 16px;
                }

                &-bz-2 {
                    margin-top: -16px;
                    margin-bottom: -16px;
                }

                &-3 {
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                &-bz-3 {
                    margin-top: -24px;
                    margin-bottom: -24px;
                }

                &-4 {
                    margin-top: 32px;
                    margin-bottom: 32px;
                }

                &-bz-4 {
                    margin-top: -32px;
                    margin-bottom: -32px;
                }
            }
        `}
      }

      &-sm {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.sm}px) {
                &-small {
                    margin-top: 4px;
                    margin-bottom: 4px;
                }

                &-unit {
                    margin-top: 1px;
                    margin-bottom: 1px;
                }

                &-bz-small {
                    margin-top: -4px;
                    margin-bottom: -4px;
                }

                &-bz-unit {
                    margin-top: -1px;
                    margin-bottom: -1px;
                }

                &-1 {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }

                &-bz-1 {
                    margin-top: -8px;
                    margin-bottom: -8px;
                }

                &-2 {
                    margin-top: 16px;
                    margin-bottom: 16px;
                }

                &-bz-2 {
                    margin-top: -16px;
                    margin-bottom: -16px;
                }

                &-3 {
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                &-bz-3 {
                    margin-top: -24px;
                    margin-bottom: -24px;
                }

                &-4 {
                    margin-top: 32px;
                    margin-bottom: 32px;
                }

                &-bz-4 {
                    margin-top: -32px;
                    margin-bottom: -32px;
                }
            }
        `}
      }

      &-xs {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.xs}px) {
                &-small {
                    margin-top: 4px;
                    margin-bottom: 4px;
                }

                &-bz-small {
                    margin-top: -4px;
                    margin-bottom: -4px;
                }

                &-unit {
                    margin-top: 1px;
                    margin-bottom: 1px;
                }

                &-bz-unit {
                    margin-top: -1px;
                    margin-bottom: -1px;
                }

                &-1 {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }

                &-bz-1 {
                    margin-top: -8px;
                    margin-bottom: -8px;
                }

                &-2 {
                    margin-top: 16px;
                    margin-bottom: 16px;
                }

                &-bz-2 {
                    margin-top: -16px;
                    margin-bottom: -16px;
                }

                &-3 {
                    margin-top: 24px;
                    margin-bottom: 24px;
                }

                &-bz-3 {
                    margin-top: -24px;
                    margin-bottom: -24px;
                }

                &-4 {
                    margin-top: 32px;
                    margin-bottom: 32px;
                }

                &-bz-4 {
                    margin-top: -32px;
                    margin-bottom: -32px;
                }
            }
        `}
      }
    }

    .mx {
      &-small {
        margin-left: 4px;
        margin-right: 4px;
      }

      &-bz-small {
        margin-left: -4px;
        margin-right: -4px;
      }

      &-unit {
        margin-left: 1px;
        margin-right: 1px;
      }

      &-bz-unit {
        margin-left: -1px;
        margin-right: -1px;
      }

      &-1 {
        margin-left: 8px;
        margin-right: 8px;
      }

      &-bz-1 {
        margin-left: -8px;
        margin-right: -8px;
      }

      &-2 {
        margin-left: 16px;
        margin-right: 16px;
      }

      &-bz-2 {
        margin-left: -16px;
        margin-right: -16px;
      }

      &-3 {
        margin-left: 24px;
        margin-right: 24px;
      }

      &-bz-3 {
        margin-left: -24px;
        margin-right: -24px;
      }

      &-4 {
        margin-left: 32px;
        margin-right: 32px;
      }

      &-bz-4 {
        margin-left: -32px;
        margin-right: -32px;
      }

      &-md {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.md}px) {
                &-small {
                    margin-left: 4px;
                    margin-right: 4px;
                }

                &-bz-small {
                    margin-left: -4px;
                    margin-right: -4px;
                }

                &-unit {
                    margin-left: 1px;
                    margin-right: 1px;
                }

                &-bz-unit {
                    margin-left: -1px;
                    margin-right: -1px;
                }

                &-1 {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                &-bz-1 {
                    margin-left: -8px;
                    margin-right: -8px;
                }

                &-2 {
                    margin-left: 16px;
                    margin-right: 16px;
                }

                &-bz-2 {
                    margin-left: -16px;
                    margin-right: -16px;
                }

                &-3 {
                    margin-left: 24px;
                    margin-right: 24px;
                }

                &-bz-3 {
                    margin-left: -24px;
                    margin-right: -24px;
                }

                &-4 {
                    margin-left: 32px;
                    margin-right: 32px;
                }

                &-bz-4 {
                    margin-left: -32px;
                    margin-right: -32px;
                }
            }
        `}
      }

      &-sm {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.sm}px) {
                &-small {
                    margin-left: 4px;
                    margin-right: 4px;
                }

                &-bz-small {
                    margin-left: -4px;
                    margin-right: -4px;
                }

                &-unit {
                    margin-left: 1px;
                    margin-right: 1px;
                }

                &-bz-unit {
                    margin-left: -1px;
                    margin-right: -1px;
                }

                &-1 {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                &-bz-1 {
                    margin-left: -8px;
                    margin-right: -8px;
                }

                &-2 {
                    margin-left: 16px;
                    margin-right: 16px;
                }

                &-bz-2 {
                    margin-left: -16px;
                    margin-right: -16px;
                }

                &-3 {
                    margin-left: 24px;
                    margin-right: 24px;
                }

                &-bz-3 {
                    margin-left: -24px;
                    margin-right: -24px;
                }

                &-4 {
                    margin-left: 32px;
                    margin-right: 32px;
                }

                &-bz-4 {
                    margin-left: -32px;
                    margin-right: -32px;
                }
            }
        `}
      }

      &-xs {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.xs}px) {
                &-small {
                    margin-left: 4px;
                    margin-right: 4px;
                }

                &-bz-small {
                    margin-left: -4px;
                    margin-right: -4px;
                }

                &-unit {
                    margin-left: 1px;
                    margin-right: 1px;
                }

                &-bz-unit {
                    margin-left: -1px;
                    margin-right: -1px;
                }

                &-1 {
                    margin-left: 8px;
                    margin-right: 8px;
                }

                &-bz-1 {
                    margin-left: -8px;
                    margin-right: -8px;
                }

                &-2 {
                    margin-left: 16px;
                    margin-right: 16px;
                }

                &-bz-2 {
                    margin-left: -16px;
                    margin-right: -16px;
                }

                &-3 {
                    margin-left: 24px;
                    margin-right: 24px;
                }

                &-bz-3 {
                    margin-left: -24px;
                    margin-right: -24px;
                }

                &-4 {
                    margin-left: 32px;
                    margin-right: 32px;
                }

                &-bz-4 {
                    margin-left: -32px;
                    margin-right: -32px;
                }
            }
        `}
      }
    }

    .mt {
      &-small {
        margin-top: 4px;
      }

      &-bz-small {
        margin-top: -4px;
      }

      &-unit {
        margin-top: 1px;
      }

      &-bz-unit {
        margin-top: -1px;
      }

      &-1 {
        margin-top: 8px;
      }

      &-bz-1 {
        margin-top: -8px;
      }

      &-2 {
        margin-top: 16px;
      }

      &-bz-2 {
        margin-top: -16px;
      }

      &-3 {
        margin-top: 24px;
      }

      &-bz-3 {
        margin-top: -24px;
      }

      &-4 {
        margin-top: 32px;
      }

      &-bz-4 {
        margin-top: -32px;
      }

      &-md {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.md}px) {
                &-small {
                    margin-top: 4px;
                }

                &-bz-small {
                    margin-top: -4px;
                }

                &-unit {
                    margin-top: 1px;
                }

                &-bz-unit {
                    margin-top: -1px;
                }

                &-1 {
                    margin-top: 8px;
                }

                &-bz-1 {
                    margin-top: -8px;
                }

                &-2 {
                    margin-top: 16px;
                }

                &-bz-2 {
                    margin-top: -16px;
                }

                &-3 {
                    margin-top: 24px;
                }

                &-bz-3 {
                    margin-top: -24px;
                }

                &-4 {
                    margin-top: 32px;
                }

                &-bz-4 {
                    margin-top: -32px;
                }
            }
        `}
      }

      &-sm {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.sm}px) {
                &-small {
                    margin-top: 4px;
                }

                &-bz-small {
                    margin-top: -4px;
                }

                &-unit {
                    margin-top: 1px;
                }

                &-bz-unit {
                    margin-top: -1px;
                }

                &-1 {
                    margin-top: 8px;
                }

                &-bz-1 {
                    margin-top: -8px;
                }

                &-2 {
                    margin-top: 16px;
                }

                &-bz-2 {
                    margin-top: -16px;
                }

                &-3 {
                    margin-top: 24px;
                }

                &-bz-3 {
                    margin-top: -24px;
                }

                &-4 {
                    margin-top: 32px;
                }

                &-bz-4 {
                    margin-top: -32px;
                }
            }
        `}
      }

      &-xs {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.xs}px) {
                &-small {
                    margin-top: 4px;
                }

                &-bz-small {
                    margin-top: -4px;
                }

                &-unit {
                    margin-top: 1px;
                }

                &-bz-unit {
                    margin-top: -1px;
                }

                &-1 {
                    margin-top: 8px;
                }

                &-bz-1 {
                    margin-top: -8px;
                }

                &-2 {
                    margin-top: 16px;
                }

                &-bz-2 {
                    margin-top: -16px;
                }

                &-3 {
                    margin-top: 24px;
                }

                &-bz-3 {
                    margin-top: -24px;
                }

                &-4 {
                    margin-top: 32px;
                }

                &-bz-4 {
                    margin-top: -32px;
                }
            }
        `}
      }
    }

    .mb {
      &-small {
        margin-bottom: 4px;
      }

      &-bz-small {
        margin-bottom: -4px;
      }

      &-unit {
        margin-bottom: 1px;
      }

      &-bz-unit {
        margin-bottom: -1px;
      }

      &-1 {
        margin-bottom: 8px;
      }

      &-bz-1 {
        margin-bottom: -8px;
      }

      &-2 {
        margin-bottom: 16px;
      }

      &-bz-2 {
        margin-bottom: -16px;
      }

      &-3 {
        margin-bottom: 24px;
      }

      &-bz-3 {
        margin-bottom: -24px;
      }

      &-4 {
        margin-bottom: 32px;
      }

      &-bz-4 {
        margin-bottom: -32px;
      }

      &-md {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.md}px) {
                &-small {
                    margin-bottom: 4px;
                }

                &-bz-small {
                    margin-bottom: -4px;
                }

                &-unit {
                    margin-bottom: 1px;
                }

                &-bz-unit {
                    margin-bottom: -1px;
                }

                &-1 {
                    margin-bottom: 8px;
                }

                &-bz-1 {
                    margin-bottom: -8px;
                }

                &-2 {
                    margin-bottom: 16px;
                }

                &-bz-2 {
                    margin-bottom: -16px;
                }

                &-3 {
                    margin-bottom: 24px;
                }

                &-bz-3 {
                    margin-bottom: -24px;
                }

                &-4 {
                    margin-bottom: 32px;
                }

                &-bz-4 {
                    margin-bottom: -32px;
                }
            }
        `}
      }

      &-sm {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.sm}px) {
                &-small {
                    margin-bottom: 4px;
                }

                &-bz-small {
                    margin-bottom: -4px;
                }

                &-unit {
                    margin-bottom: 1px;
                }

                &-bz-unit {
                    margin-bottom: -1px;
                }

                &-1 {
                    margin-bottom: 8px;
                }

                &-bz-1 {
                    margin-bottom: -8px;
                }

                &-2 {
                    margin-bottom: 16px;
                }

                &-bz-2 {
                    margin-bottom: -16px;
                }

                &-3 {
                    margin-bottom: 24px;
                }

                &-bz-3 {
                    margin-bottom: -24px;
                }

                &-4 {
                    margin-bottom: 32px;
                }

                &-bz-4 {
                    margin-bottom: -32px;
                }
            }
        `}
      }

      &-xs {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.xs}px) {
                &-small {
                    margin-bottom: 4px;
                }

                &-bz-small {
                    margin-bottom: -4px;
                }

                &-unit {
                    margin-bottom: 1px;
                }

                &-bz-unit {
                    margin-bottom: -1px;
                }

                &-1 {
                    margin-bottom: 8px;
                }

                &-bz-1 {
                    margin-bottom: -8px;
                }

                &-2 {
                    margin-bottom: 16px;
                }

                &-bz-2 {
                    margin-bottom: -16px;
                }

                &-3 {
                    margin-bottom: 24px;
                }

                &-bz-3 {
                    margin-bottom: -24px;
                }

                &-4 {
                    margin-bottom: 32px;
                }

                &-bz-4 {
                    margin-bottom: -32px;
                }
            }
        `}
      }
    }

    .mr {
      &-auto {
        margin-right: auto;
      }

      &-small {
        margin-right: 4px;
      }

      &-bz-small {
        margin-right: -4px;
      }

      &-unit {
        margin-right: 1px;
      }

      &-bz-unit {
        margin-right: -1px;
      }

      &-1 {
        margin-right: 8px;
      }

      &-bz-1 {
        margin-right: -8px;
      }

      &-2 {
        margin-right: 16px;
      }

      &-bz-2 {
        margin-right: -16px;
      }

      &-3 {
        margin-right: 24px;
      }

      &-bz-3 {
        margin-right: -24px;
      }

      &-4 {
        margin-right: 32px;
      }

      &-bz-4 {
        margin-right: -32px;
      }

      &-md {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.md}px) {
                &-small {
                    margin-right: 4px;
                }

                &-bz-small {
                    margin-right: -4px;
                }

                &-unit {
                    margin-right: 1px;
                }

                &-bz-unit {
                    margin-right: -1px;
                }

                &-1 {
                    margin-right: 8px;
                }

                &-bz-1 {
                    margin-right: -8px;
                }

                &-2 {
                    margin-right: 16px;
                }

                &-bz-2 {
                    margin-right: -16px;
                }

                &-3 {
                    margin-right: 24px;
                }

                &-bz-3 {
                    margin-right: -24px;
                }

                &-4 {
                    margin-right: 32px;
                }

                &-bz-4 {
                    margin-right: -32px;
                }
            }
        `}
      }

      &-sm {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.sm}px) {
                &-small {
                    margin-right: 4px;
                }

                &-bz-small {
                    margin-right: -4px;
                }

                &-unit {
                    margin-right: 1px;
                }

                &-bz-unit {
                    margin-right: -1px;
                }

                &-1 {
                    margin-right: 8px;
                }

                &-bz-1 {
                    margin-right: -8px;
                }

                &-2 {
                    margin-right: 16px;
                }

                &-bz-2 {
                    margin-right: -16px;
                }

                &-3 {
                    margin-right: 24px;
                }

                &-bz-3 {
                    margin-right: -24px;
                }

                &-4 {
                    margin-right: 32px;
                }

                &-bz-4 {
                    margin-right: -32px;
                }
            }
        `}
      }

      &-xs {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.xs}px) {
                &-small {
                    margin-right: 4px;
                }

                &-bz-small {
                    margin-right: -4px;
                }

                &-unit {
                    margin-right: 1px;
                }

                &-bz-unit {
                    margin-right: -1px;
                }

                &-1 {
                    margin-right: 8px;
                }

                &-bz-1 {
                    margin-right: -8px;
                }

                &-2 {
                    margin-right: 16px;
                }

                &-bz-2 {
                    margin-right: -16px;
                }

                &-3 {
                    margin-right: 24px;
                }

                &-bz-3 {
                    margin-right: -24px;
                }

                &-4 {
                    margin-right: 32px;
                }

                &-bz-4 {
                    margin-right: -32px;
                }
            }
        `}
      }
    }

    .ml {
      &-auto {
        margin-left: auto;
      }

      &-small {
        margin-left: 4px;
      }

      &-bz-small {
        margin-left: -4px;
      }

      &-unit {
        margin-left: 1px;
      }

      &-bz-unit {
        margin-left: -1px;
      }

      &-1 {
        margin-left: 8px;
      }

      &-bz-1 {
        margin-left: -8px;
      }

      &-2 {
        margin-left: 16px;
      }

      &-bz-2 {
        margin-left: -16px;
      }

      &-3 {
        margin-left: 24px;
      }

      &-bz-3 {
        margin-left: -24px;
      }

      &-4 {
        margin-left: 32px;
      }

      &-bz-4 {
        margin-left: -32px;
      }

      &-md {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.md}px) {
                &-small {
                    margin-left: 4px;
                }

                &-bz-small {
                    margin-left: -4px;
                }

                &-unit {
                    margin-left: 1px;
                }

                &-bz-unit {
                    margin-left: -1px;
                }

                &-1 {
                    margin-left: 8px;
                }

                &-bz-1 {
                    margin-left: -8px;
                }

                &-2 {
                    margin-left: 16px;
                }

                &-bz-2 {
                    margin-left: -16px;
                }

                &-3 {
                    margin-left: 24px;
                }

                &-bz-3 {
                    margin-left: -24px;
                }

                &-4 {
                    margin-left: 32px;
                }

                &-bz-4 {
                    margin-left: -32px;
                }
            }
        `}
      }

      &-sm {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.sm}px) {
                &-small {
                    margin-left: 4px;
                }

                &-bz-small {
                    margin-left: -4px;
                }

                &-unit {
                    margin-left: 1px;
                }

                &-bz-unit {
                    margin-left: -1px;
                }

                &-1 {
                    margin-left: 8px;
                }

                &-bz-1 {
                    margin-left: -8px;
                }

                &-2 {
                    margin-left: 16px;
                }

                &-bz-2 {
                    margin-left: -16px;
                }

                &-3 {
                    margin-left: 24px;
                }

                &-bz-3 {
                    margin-left: -24px;
                }

                &-4 {
                    margin-left: 32px;
                }

                &-bz-4 {
                    margin-left: -32px;
                }
            }
        `}
      }

      &-xs {
        ${({ theme }) => css`
            @media screen and (max-width: ${breakpoints.xs}px) {
                &-small {
                    margin-left: 4px;
                }

                &-bz-small {
                    margin-left: -4px;
                }

                &-unit {
                    margin-left: 1px;
                }

                &-bz-unit {
                    margin-left: -1px;
                }

                &-1 {
                    margin-left: 8px;
                }

                &-bz-1 {
                    margin-left: -8px;
                }

                &-2 {
                    margin-left: 16px;
                }

                &-bz-2 {
                    margin-left: -16px;
                }

                &-3 {
                    margin-left: 24px;
                }

                &-bz-3 {
                    margin-left: -24px;
                }

                &-4 {
                    margin-left: 32px;
                }

                &-bz-4 {
                    margin-left: -32px;
                }
            }
        `}
      }
    }

    /* ColorPicker: ChromePicker*/
    /* Unfortunately, this seems to be the only way to change input color CSS property */
    .chrome-picker {
        & input {
            color: white !important;
        }
    }


    /* Stripe elements/inputs */
    .StripeElement {
        height: 30px;
        padding: 6px 10px;
        width: 100%;
        border-radius: 2px;
        line-height: 30px;
        background: ${Colors.DARK_GRAY2};
        box-shadow: 0 0 0 1px ${Colors.DARK_GRAY5};

        color: ${Colors.WHITE};
        transition: background 60ms;

        /* Placeholder */
        &::placeholder {
            color: ${Colors.GRAY3};
        }

        /* Disabled */
        &:disabled {
            opacity: 0.4;
        }

        /* Invalid */
        &--invalid {
            box-shadow: 0 0 0 1px ${Colors.RED2};
        }
    }
`;
