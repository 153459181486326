import styled from 'styled-components';

export const NotificationTemplateWrapper = styled.div`
    .dismiss-action {
        display: none;
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    &:hover {
        .dismiss-action {
            display: block;
        }

        .notification-at {
            display: none;
        }
    }
`;
