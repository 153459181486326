import { SortDirection } from 'dy-frontend-http-repository/lib/data/enums';
import { ClientUserSortField } from '../enums';

const clientUserSortItemsInformation: { [key in string]: string } = {
    [`${ClientUserSortField.LATEST_ACTIVITY_AT}${SortDirection.DESC}`]: 'Latest activity - recent first',
    [`${ClientUserSortField.LATEST_ACTIVITY_AT}${SortDirection.ASC}`]: 'Latest activity - oldest first',
    [`${ClientUserSortField.NAME}${SortDirection.ASC}`]: 'Name - A-Z',
    [`${ClientUserSortField.NAME}${SortDirection.DESC}`]: 'Name - Z-A',
    [`${ClientUserSortField.VERIFIED_AT}${SortDirection.DESC}`]: 'Verification date - recent first',
    [`${ClientUserSortField.VERIFIED_AT}${SortDirection.ASC}`]: 'Verification date - oldest first',
};

export default clientUserSortItemsInformation;
