import { Button, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Flex from '@components/Flex';
import { useStore } from 'effector-react';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { initialPlatformClientUserFilterData, platformClientUserFiltersInformation } from '../../consts';
import { PlatformClientUserFilterType } from '../../enums';
import { resetPage, resetPlatformClientUserFilterData, setPlatformClientUserFilterData } from '../../store/events';
import { $isQueryHydrationFinished, $platformClientUserFilterData } from '../../store/states';
import { PlatformClientUserFilter as PlatformClientUserFilterValueObject } from '../../valueObjects';
import ClientUserArchivedPlatformFilter from './components/ClientUserArchivedPlatformFilter';
import ClientUserPlansPlatformFilter from './components/ClientUserPlansPlatformFilter';
import ClientUserVerifiedPlatformFilter from './components/ClientUserVerifiedPlatformFilter';
import PlatformClientUserFilterQueryInput from './components/PlatformClientUserFilterQueryInput';
import { AddFilterPopoverContent } from './styled';
import DevText from '@components/Text';
import { fetchClients } from '../../store/effects';

const platformClientUserFilterTypes = Object.values(PlatformClientUserFilterType);

export type Props = HTMLAttributes<HTMLDivElement>;

const PlatformClientUserFilter: React.FC<Props> = (props) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);
    const platformClientUserFilterData = useStore($platformClientUserFilterData);
    const isFetching = useStore(fetchClients.pending);

    const [query, setQuery] = useState('');
    const [isAddFilterPopoverOpened, setIsAddFilterPopoverOpened] = useState(false);
    const [platformFilters, setPlatformFilters] = useState<PlatformClientUserFilterType[]>([]);
    const [isLastAddedShouldBeOpenedMode, setIsLastAddedShouldBeOpenedMode] = useState(false);

    const isAllFiltersAdded = platformClientUserFilterTypes.length === platformFilters.length;

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setPlatformFilters(
                PlatformClientUserFilterValueObject.getAppliedPlatformFilters(platformClientUserFilterData)
            );
            setQuery(platformClientUserFilterData.query);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    const handleAddFilter = (type: PlatformClientUserFilterType) => {
        if (!platformFilters.includes(type)) {
            setIsAddFilterPopoverOpened(false);
            setPlatformFilters((filters) => [...filters, type]);
        }
    };

    const renderPlatformClientUserFilterQueryInput = () => {
        const trimmedCurrentQuery = query.trim();
        const isLastQueryDiffersFromCurrentOne = platformClientUserFilterData.query.trim() !== trimmedCurrentQuery;

        return (
            <PlatformClientUserFilterQueryInput
                loading={isFetching && (trimmedCurrentQuery.length !== 0 || isLastQueryDiffersFromCurrentOne)}
                shouldBeSearched={isLastQueryDiffersFromCurrentOne}
                className="mb-2"
                placeholder="Search by client name or ID"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                onSearch={(newSearch: string) => {
                    setPlatformClientUserFilterData({ ...platformClientUserFilterData, query: newSearch });
                    resetPage();
                }}
            />
        );
    };

    const renderFilterOfType = (type: PlatformClientUserFilterType, defaultIsOpen: boolean) => {
        const commonPlatformFilterProps = {
            defaultIsOpen,
            platformClientUserFilterData: platformClientUserFilterData,
            onRemove: () =>
                setPlatformFilters((platformFilters) =>
                    platformFilters.filter((platformFilterType) => platformFilterType !== type)
                ),
        };

        switch (type) {
            // case PlatformClientUserFilterType.WORKSPACES:
            //     return <ClientUserWorkspacesPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformClientUserFilterType.CLIENT_PLANS:
                return <ClientUserPlansPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformClientUserFilterType.CLIENT_ARCHIVED:
                return <ClientUserArchivedPlatformFilter {...commonPlatformFilterProps} />;
            case PlatformClientUserFilterType.CLIENT_VERIFIED:
                return <ClientUserVerifiedPlatformFilter {...commonPlatformFilterProps} />;
            default:
                return null;
        }
    };

    const renderPlatformFilters = () => {
        return platformFilters.map((type, index) => (
            <div key={type}>
                {renderFilterOfType(type, isLastAddedShouldBeOpenedMode && index === platformFilters.length - 1)}
            </div>
        ));
    };

    const renderAddPlatformFilterButton = () => {
        if (isAllFiltersAdded) {
            // No need to show "Add filter" button if all filters are already added
            return null;
        }

        const renderAddFilterPopoverContent = () => {
            const handleClickMenuItem = (platformClientUserFilterType: PlatformClientUserFilterType) => {
                if (isLastAddedShouldBeOpenedMode === false) {
                    setIsLastAddedShouldBeOpenedMode(true);
                }

                handleAddFilter(platformClientUserFilterType);
            };

            return (
                <AddFilterPopoverContent>
                    <DevText muted>Select filter</DevText>

                    <Menu>
                        {Object.entries(platformClientUserFiltersInformation).map(([key, { icon, title }]) => {
                            const platformClientUserFilterType = key as PlatformClientUserFilterType;

                            if (platformFilters.includes(platformClientUserFilterType)) {
                                // Platform filter is already added, allowing to add it again doesn't make sense
                                return null;
                            }

                            return (
                                <MenuItem
                                    className="mt-1"
                                    key={key}
                                    icon={icon}
                                    text={title}
                                    onClick={() => handleClickMenuItem(platformClientUserFilterType)}
                                />
                            );
                        })}
                    </Menu>
                </AddFilterPopoverContent>
            );
        };

        return (
            <div>
                <Popover
                    isOpen={isAddFilterPopoverOpened}
                    disabled={!isQueryHydrationFinished}
                    content={renderAddFilterPopoverContent()}
                    onClose={() => setIsAddFilterPopoverOpened(false)}
                >
                    <Button
                        className="mr-1"
                        icon="filter-list"
                        minimal
                        onClick={() => setIsAddFilterPopoverOpened(!isAddFilterPopoverOpened)}
                        loading={!isQueryHydrationFinished}
                    >
                        Add filter
                    </Button>
                </Popover>
            </div>
        );
    };

    const renderResetButton = () => {
        const isArchivedSameAsInitialized =
            platformClientUserFilterData.isArchived === initialPlatformClientUserFilterData.isArchived;
        const isVerifiedSameAsInitialized =
            platformClientUserFilterData.isVerified === initialPlatformClientUserFilterData.isVerified;
        const isNullableValuesSameAsInitialized = isArchivedSameAsInitialized && isVerifiedSameAsInitialized;

        if (
            isNullableValuesSameAsInitialized &&
            JSON.stringify(platformClientUserFilterData).length ===
                JSON.stringify(initialPlatformClientUserFilterData).length
        ) {
            // Platform client user filter data same as whenever it was initialized
            return null;
        }

        const handleResetButton = () => {
            // Reset page to 1
            resetPage();

            // Reset platform client user filter data to initial platform client user filter data
            resetPlatformClientUserFilterData();

            // Create array of pinned platform filters
            const initialPlatformFilters: PlatformClientUserFilterType[] = [];
            for (let i = 0; i < platformClientUserFilterTypes.length; i++) {
                // Get platform client user filter information for platform client user filter type
                const platformClientUserFilterInformation =
                    platformClientUserFiltersInformation[platformClientUserFilterTypes[i]];

                if (!platformClientUserFilterInformation.isPinned) {
                    // We only push pinned platform filters
                    continue;
                }

                initialPlatformFilters.push(platformClientUserFilterTypes[i]);
            }

            setPlatformFilters(initialPlatformFilters);
            setQuery('');
        };

        return (
            <Button minimal icon="trash" intent={Intent.DANGER} onClick={handleResetButton}>
                Reset
            </Button>
        );
    };

    return (
        <div {...props}>
            {renderPlatformClientUserFilterQueryInput()}

            <Flex gap="8px" flexWrap="wrap">
                {renderPlatformFilters()}
                {renderAddPlatformFilterButton()}
                {renderResetButton()}
            </Flex>
        </div>
    );
};

export default PlatformClientUserFilter;
