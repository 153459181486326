import { TaskDeliverablesTreeUtils } from '@app/containers/pages/Task/valueObjects';
import TaskDeliverableRef from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/refs/TaskDeliverableRefList';
import { TaskDeliverablesParsedInformation } from '../../../../types';
import { taskDeliverablesTreeApi } from '@pages/Task/store/apis';
import { moveTaskDeliverablesBatchToDirectory } from '../../store/effects';

const updateRenameDirectory = () => {
    const handleRenameDirectory = async ({
        taskDeliverablesTree,
        commonDirectoryPath,
        oldDirectoryName,
        newDirectoryName,
    }: {
        taskDeliverablesTree: TaskDeliverablesParsedInformation;
        commonDirectoryPath: string;
        oldDirectoryName: string;
        newDirectoryName: string;
    }) => {
        // Get old path with directory name
        const oldDirectoryPathWithDirectoryName = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: commonDirectoryPath,
            directoryName: oldDirectoryName,
        });

        // Get new path with directory name
        const newDirectoryPathWithDirectoryName = TaskDeliverablesTreeUtils.getFullDirectoryPath({
            directoryPath: commonDirectoryPath,
            directoryName: newDirectoryName,
        });

        const updateDirectoryNamePromises: Promise<TaskDeliverableRef>[] = [];

        for (const deliverablePath in taskDeliverablesTree.deliverables) {
            try {
                const renamedPath = TaskDeliverablesTreeUtils.getRenamedPath({
                    currentPath: deliverablePath,
                    oldPath: oldDirectoryPathWithDirectoryName,
                    newPath: newDirectoryPathWithDirectoryName,
                });

                if (renamedPath) {
                    // Current path should be renamed

                    const taskDeliverableIdsToRename = Object.values(
                        taskDeliverablesTree.deliverables[deliverablePath]
                    ).map((deliverable) => deliverable.id);

                    updateDirectoryNamePromises.push(
                        moveTaskDeliverablesBatchToDirectory({
                            path: renamedPath,
                            task_deliverable_ids: taskDeliverableIdsToRename,
                        })
                    );
                }
            } catch (e) {
                // TODO: handle error
                console.error(e);
            }
        }

        try {
            await Promise.all(updateDirectoryNamePromises);
            taskDeliverablesTreeApi.renameDirectory({
                newDirectoryName,
                oldDirectoryName,
                commonDirectoryPath,
            });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    return { renameDirectory: handleRenameDirectory };
};

export default updateRenameDirectory;
