import { useStore } from 'effector-react';
import React, { HTMLAttributes } from 'react';
import { $task, $taskAddons } from '@app/containers/pages/Task/store/states';
import { Button, Card, Classes, Elevation, Intent, Spinner } from '@blueprintjs/core';
import Flex from '@app/components/Flex';
import Heading from '@app/components/Heading';
import { openModal } from '@app/containers/modals/store/events';
import AssignTaskAddonModal, { AssignTaskAddonModalProps } from '../../modals/AssignTaskAddonModal';
import { AddonTaskResource } from 'dy-frontend-http-repository/lib/modules/AddonTask/resources';
import DevText from '@app/components/Text';
import { removeTaskAddon, restoreTaskAddon } from '@app/containers/pages/Task/store/effects';
import ConfirmationPopover from '@app/components/ConfirmationPopover';
import { taskAddonsApi } from '@app/containers/pages/Task/store/apis';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { $permissions } from '@containers/store/states';
import { AddonTaskPermission } from 'dy-frontend-permissions/lib/permission';

type Props = HTMLAttributes<HTMLDivElement>;

// TODO: add permission check
const Addons: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const task = useStore($task);
    const taskAddons = useStore($taskAddons);
    const isRemovingTaskAddon = useStore(removeTaskAddon.pending);
    const isRestoreTaskAddon = useStore(restoreTaskAddon.pending);

    if (!task) {
        return null;
    }

    // Root permissions for addon task
    const isAddonTaskRootPermission = permissions.has(AddonTaskPermission.ROOT);

    const handleRemoveAddon = async (taskAddonId: ID) => {
        try {
            await removeTaskAddon(taskAddonId);

            taskAddonsApi.remove({ taskAddonId });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const handleRestoreAddon = async (taskAddonId: ID) => {
        try {
            await restoreTaskAddon(taskAddonId);

            taskAddonsApi.restore({ taskAddonId });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderCreateTaskAddonButton = () => {
        const isAllowed =
            isAddonTaskRootPermission ||
            permissions.has(AddonTaskPermission.CREATE_CHECKOUT) ||
            permissions.has(AddonTaskPermission.CREATE_MANUAL);
        if (!isAllowed) {
            return null;
        }

        if (task.archived_at !== null) {
            // Task is archived
            return null;
        }

        if (task.paused_at !== null) {
            // Task is paused
            return null;
        }

        if ([TaskState.DRAFT, TaskState.PRE_DELIVERED, TaskState.DELIVERED].some((state) => state === task.state)) {
            // Task is either draft or in stage of delivery
            return null;
        }

        const handleAssignTaskAddon = () => {
            openModal<AssignTaskAddonModalProps>({
                ModalComponent: AssignTaskAddonModal,
                data: {
                    taskCategoryId: task.category.id,
                    selectedTaskCategoryAddonIds: taskAddons ? taskAddons.items.map((addon) => addon.addon.id) : [],
                },
            });
        };

        return (
            <Button disabled={!taskAddons} icon="plus" intent={Intent.PRIMARY} onClick={handleAssignTaskAddon}>
                Add new
            </Button>
        );
    };

    const renderHeader = () => {
        return (
            <Flex justify="space-between">
                <Heading type="h4">Addons</Heading>
                {renderCreateTaskAddonButton()}
            </Flex>
        );
    };

    const renderRemoveAddonButton = (addon: AddonTaskResource) => {
        if (!isAddonTaskRootPermission && !permissions.has(AddonTaskPermission.ARCHIVE)) {
            return null;
        }

        if (addon.archived_at) {
            // Addon is archived
            return null;
        }

        if (addon.checkout_id !== null) {
            // Client payed for addon
            return null;
        }

        return (
            <ConfirmationPopover
                title="Would you like to remove addon?"
                description="When confirmed, addon will be removed from the request"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleRemoveAddon(addon.id)}
                    >
                        Yes, remove addon
                    </Button>,
                ]}
            >
                <Button
                    minimal
                    loading={isRemovingTaskAddon}
                    disabled={isRemovingTaskAddon}
                    icon="trash"
                    intent={Intent.DANGER}
                >
                    Remove
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderRestoreAddonButton = (addon: AddonTaskResource) => {
        if (!isAddonTaskRootPermission && !permissions.has(AddonTaskPermission.RESTORE)) {
            return null;
        }

        if (!addon.archived_at) {
            // Addon is archived
            return null;
        }

        return (
            <ConfirmationPopover
                title="Would you like to restore addon?"
                description="When confirmed, addon will be restored"
                actions={[
                    <Button
                        intent={Intent.PRIMARY}
                        className={Classes.POPOVER_DISMISS}
                        onClick={() => handleRestoreAddon(addon.id)}
                    >
                        Yes, restore addon
                    </Button>,
                ]}
            >
                <Button
                    minimal
                    disabled={isRestoreTaskAddon}
                    loading={isRestoreTaskAddon}
                    icon="confirm"
                    intent={Intent.SUCCESS}
                >
                    Restore
                </Button>
            </ConfirmationPopover>
        );
    };

    const renderAddon = (addon: AddonTaskResource) => {
        return (
            <Card elevation={Elevation.ONE}>
                <Flex direction="row" justify="space-between">
                    <div>
                        <DevText>{addon.addon.title}</DevText>
                        <DevText muted>{addon.addon.summary}</DevText>
                    </div>

                    {renderRemoveAddonButton(addon)}
                    {renderRestoreAddonButton(addon)}
                </Flex>
            </Card>
        );
    };

    const renderAddons = () => {
        if (!taskAddons) {
            return (
                <Flex direction="row" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (taskAddons.items.length === 0) {
            return <DevText muted>No addons were attached to this request</DevText>;
        }

        return (
            <div className="mt-1">
                {taskAddons.items.map((addon, index) => (
                    <div key={addon.id} className={index === 0 ? '' : 'mt-1'}>
                        {renderAddon(addon)}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <Card {...props}>
            {renderHeader()}
            {renderAddons()}
        </Card>
    );
};

export default Addons;
