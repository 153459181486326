import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import { $isQueryHydrationFinished } from '@app/containers/pages/CoreUsers/store/states';
import { userRoleInformation } from '@app/data/consts';
import { UserRole } from 'dy-frontend-http-repository/lib/data/enums';
import { platformCoreUserFiltersInformation } from '@app/containers/pages/CoreUsers/consts';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { PlatformCoreUserFilterType } from '@app/containers/pages/CoreUsers/enums';
import { setPlatformCoreUserFilterData } from '@app/containers/pages/CoreUsers/store/events';
import { PlatformCoreUserFilterData } from '@app/containers/pages/CoreUsers/types';

export interface CoreUserRolePlatformFilterProps {
    defaultIsOpen?: boolean;
    platformCoreUserFilterData: PlatformCoreUserFilterData;
    onRemove: () => void;
}

export type Props = CoreUserRolePlatformFilterProps;

const CoreUserRolePlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformCoreUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [selectedStates, setSelectedStates] = useState<PlatformCoreUserFilterData['roles']>(
        platformCoreUserFilterData.roles
    );

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedStates(platformCoreUserFilterData.roles);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformCoreUserFilterData.roles.length === 0) {
            setSelectedStates([]);
        }
    }, [platformCoreUserFilterData.roles]);

    const getFilterTagLabel = () => {
        if (selectedStates.length === 0) return '';

        return selectedStates
            .map((role, index) => {
                if (index === selectedStates.length - 1) {
                    // Last element
                    return `${userRoleInformation[role].label}`;
                }

                return `${userRoleInformation[role].label}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedStates.length > 0) {
            // At least 1 core user role selected
            return 'Role:';
        }

        return 'Role';
    };

    const handleRemoveFilter = () => {
        if (selectedStates.length !== 0) {
            // At least 1 core user role was selected

            setSelectedStates([]);

            if (platformCoreUserFilterData.roles.length !== 0) {
                // Reset core user roles to empty array
                setPlatformCoreUserFilterData({ ...platformCoreUserFilterData, roles: [] });
            }

            setIsOpen(false);
        }

        if (!platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_ROLES].isPinned) {
            // Platform core user role filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (key: PlatformCoreUserFilterData['roles'][number]) => {
        if (selectedStates.includes(key)) {
            // CoreUser role is already checked
            setSelectedStates(selectedStates.filter((role) => role !== key));
        } else {
            // CoreUser role is NOT checked yet
            setSelectedStates([...selectedStates, key]);
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedStates) !== JSON.stringify(platformCoreUserFilterData.roles)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformCoreUserFilterData({ ...platformCoreUserFilterData, roles: selectedStates });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_ROLES].isPinned) {
            return;
        }

        if (selectedStates.length === 0) {
            onRemove();
        }
    };

    const renderRow = (key: PlatformCoreUserFilterData['roles'][number]) => {
        return (
            <li className="mb-1" key={key}>
                <Checkbox
                    checked={selectedStates.includes(key)}
                    label={userRoleInformation[key].label}
                    onClick={() => handleCheckboxClick(key)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformCoreUserFilterData['roles'][number]>
            isOpen={isOpen}
            removable={selectedStates.length > 0}
            title="Role"
            position={Position.BOTTOM_LEFT}
            list={[
                UserRole.CORE_ADMIN,
                UserRole.CORE_OPERATIONS_MANAGER,
                UserRole.CORE_HR,
                UserRole.CORE_PROJECT_MANAGER,
                UserRole.CORE_ART_DIRECTOR,
                UserRole.CORE_ASSISTANT_ART_DIRECTOR,
                UserRole.CORE_DESIGNER,
            ]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformCoreUserFiltersInformation[PlatformCoreUserFilterType.CORE_USER_ROLES].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default CoreUserRolePlatformFilter;
