import { resetPageBreadcrumbs, setPageBreadcrumbs } from '@app/containers/store/events';
import { $permissions } from '@app/containers/store/states';
import { ToastUtils } from '@app/data/utils';
import { usePageTitle } from '@app/hooks';
import { BreadcrumbProps, Button, Callout, Colors, Intent, Menu, MenuItem, Popover, Spinner } from '@blueprintjs/core';
import Circle from '@components/Circle';
import FixedWidthPageContainer from '@components/FixedWidthPageContainer';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { Endpoints } from '@data/consts';
import { openModal } from '@modals/store/events';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import { TeamPermission } from 'dy-frontend-permissions/lib/permission';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import LeadParticipantInformation from './components/LeadParticipantInformation';
import ManagersInformation from './components/ManagersInformation';
import Tabs from './components/Tabs';
import TeamInformation from './components/TeamInformation';
import TeamOccupancyInformation from './components/TeamOccupancyInformation';
import { useArchiveTeam, useRestoreTeam } from './hooks';
import AddTeamParticipantUsersModal from './modals/AddTeamParticipantUsersModal';
import UpdateTeamInformationModal, { UpdateTeamInformationModalProps } from './modals/UpdateTeamInformationModal';
import { fetchTeam, fetchTeamOccupancy, fetchUserOccupancy } from './store/effects';
import {
    resetTeam,
    resetTeamOccupancy,
    resetUserOccupancyByTeamParticipantUserIdMap,
    setUserOccupancyByTeamParticipantUserIdMap,
} from './store/events';
import { $team } from './store/states';
import { UserOccupancyByTeamParticipantUserIdMap } from './types';

const Team: React.FC = () => {
    const permissions = useStore($permissions);
    if (!permissions.isEnabled.team) {
        // TODO: Redirect to dashboard
    }

    usePageTitle('Team');

    const navigate = useNavigate();
    const { teamId } = useParams() as { teamId: ID };

    const { archiveTeam } = useArchiveTeam();
    const { restoreTeam } = useRestoreTeam();

    const team = useStore($team);

    const [isArchiveProcessRunning, setIsArchiveProcessRunning] = useState(false);

    useEffect(() => {
        if (!team) {
            return;
        }

        const teamBreadcrumbs: BreadcrumbProps[] = [
            {
                text: 'Teams',
                onClick: () => navigate(Endpoints.TEAMS),
            },
            { text: team.title },
        ];

        setPageBreadcrumbs(teamBreadcrumbs);
    }, [team?.id]);

    useEffect(() => {
        fetchTeam(teamId).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Team with ID of ${teamId} was not found`,
                    intent: Intent.DANGER,
                });

                // Go to team page
                navigate(Endpoints.TEAM_MEMBERS.replace(':teamId', teamId));
            }
        });
    }, [teamId]);

    useEffect(() => {
        fetchTeamOccupancy({ team_id: [teamId] }).catch((e) => {
            // Log
            console.error(e);

            const response = e.response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({
                    message: `Team occupancy for team with ID of ${teamId} was not found`,
                    intent: Intent.DANGER,
                });
            }
        });
    }, [teamId]);

    useEffect(() => {
        if (!team) {
            // Team is NOT fetched yet
            return;
        }

        if (team.participants.length === 0) {
            // Team do NOT have any participants yet
            return;
        }

        fetchUserOccupancy({ user_id: team.participants.map((participant) => participant.user.id) })
            .then((userOccupancy) => {
                // Set user occupancy by team participant user id map

                const userOccupancyByTeamParticipantUserIdMap: UserOccupancyByTeamParticipantUserIdMap = {};
                userOccupancy.items.map((occupancy) => {
                    userOccupancyByTeamParticipantUserIdMap[occupancy.user_id] = occupancy;
                });

                setUserOccupancyByTeamParticipantUserIdMap(userOccupancyByTeamParticipantUserIdMap);
            })
            .catch((e) => {
                // Log
                console.error(e);

                const response = e.response as HTTPErrorResponse;
                if (response.data.type === HTTPErrorType.MISSING) {
                    // Show error message
                    ToastUtils.showToast({
                        message: `Team members occupancy for team with ID of ${teamId} was not found`,
                        intent: Intent.DANGER,
                    });
                }
            });
    }, [team]);

    useEffect(() => {
        return () => {
            resetTeam();
            resetTeamOccupancy();
            resetUserOccupancyByTeamParticipantUserIdMap();
            resetPageBreadcrumbs();
        };
    }, []);

    if (!team) {
        // Team is NOT fetched yet

        return (
            <Flex justify="center">
                <Spinner />
            </Flex>
        );
    }

    const handleArchiveTeam = async () => {
        // Skip if team already archived
        if (team.archived_at !== null) {
            return;
        }

        setIsArchiveProcessRunning(true);

        try {
            await archiveTeam({ teamId: team.id });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsArchiveProcessRunning(false);
        }
    };

    const handleRestoreTeam = async () => {
        // Skip if team not archived
        if (team.archived_at === null) {
            return;
        }

        setIsArchiveProcessRunning(true);

        try {
            await restoreTeam({ teamId: team.id });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsArchiveProcessRunning(false);
        }
    };

    const renderAddTeamMembers = () => {
        const isAllowed = permissions.isRoot.team || permissions.has(TeamPermission.PARTICIPATION_MANAGE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                icon="new-person"
                className="mr-1"
                intent={Intent.PRIMARY}
                onClick={() => openModal({ ModalComponent: AddTeamParticipantUsersModal })}
            >
                Add members
            </Button>
        );
    };

    const renderUpdateTeam = () => {
        const isAllowed = permissions.isRoot.team || permissions.has(TeamPermission.INFO_UPDATE);
        if (!isAllowed) {
            return null;
        }

        return (
            <Button
                minimal
                icon="edit"
                className="mr-1"
                onClick={() =>
                    openModal<UpdateTeamInformationModalProps>({
                        ModalComponent: UpdateTeamInformationModal,
                        data: {
                            teamId: team.id,
                            color: team.color,
                            name: team.title,
                            description: team.summary,
                            countryCode: team.country,
                            timezoneName: team.timezone_name,
                            workingHoursStart: team.working_hours_start,
                            workingHoursEnd: team.working_hours_end,
                        },
                    })
                }
            >
                Edit team info
            </Button>
        );
    };

    const renderHeader = () => {
        // Get team color
        let teamColor = Colors.WHITE;
        if (team.color) {
            teamColor = team.color;
        }

        const isRemoveAllowed = permissions.isRoot.team || permissions.has(TeamPermission.ARCHIVE);

        const renderTeamControlsButton = () => {
            if (!isRemoveAllowed) {
                return null;
            }

            // TODO: Because there is no option of restoring team, this results in empty menu being opened
            const renderTeamControlsMenu = () => {
                const renderRemoveTeamMenuItem = () => {
                    if (team.archived_at !== null) {
                        // Team is already archived
                        return null;
                    }

                    if (!isRemoveAllowed) {
                        return null;
                    }

                    return (
                        <MenuItem
                            disabled={isArchiveProcessRunning}
                            intent={Intent.DANGER}
                            icon="trash"
                            text="Delete team"
                            onClick={handleArchiveTeam}
                        />
                    );
                };

                return <Menu>{renderRemoveTeamMenuItem()}</Menu>;
            };

            return (
                <Popover content={renderTeamControlsMenu()}>
                    <Button minimal icon="more" />
                </Popover>
            );
        };

        return (
            <Flex className="mb-2" justify="space-between">
                <Flex align="center">
                    <Circle className="mr-1" color={teamColor} size="20px" />
                    <Heading type="h3">{team.title}</Heading>
                </Flex>

                <Flex>
                    {renderUpdateTeam()}
                    {renderAddTeamMembers()}
                    {renderTeamControlsButton()}
                </Flex>
            </Flex>
        );
    };

    const renderRestoreTeamSection = () => {
        const isRestoreAllowed = permissions.isRoot.team || permissions.has(TeamPermission.RESTORE);

        if (team.archived_at === null) {
            return null;
        }

        return (
            <Callout className="mb-2" intent={Intent.WARNING} title="This team is archived" icon="warning-sign">
                <DevText>
                    This team is archived. You can still make changes to it, but to use it, you need to remove it from
                    archive first
                </DevText>

                {isRestoreAllowed && (
                    <Button
                        icon="unarchive"
                        className="mt-2 mb-1"
                        outlined
                        intent={Intent.PRIMARY}
                        onClick={handleRestoreTeam}
                        loading={isArchiveProcessRunning}
                    >
                        Restore from archive
                    </Button>
                )}
            </Callout>
        );
    };

    return (
        <FixedWidthPageContainer>
            {renderHeader()}
            {renderRestoreTeamSection()}

            <Grid container>
                <Grid lg={3}>
                    <TeamInformation className="mb-2" />
                    <LeadParticipantInformation className="mb-2" />
                    <ManagersInformation className="mb-2" />
                    <TeamOccupancyInformation />
                </Grid>

                <Grid lg={9}>
                    <Tabs className="mb-2" />
                    <Outlet />
                </Grid>
            </Grid>
        </FixedWidthPageContainer>
    );
};

export default Team;
