import { breakpoints } from '@app/data/consts';
import { Breakpoint } from '@app/data/enums';
import { useEffect, useState } from 'react';

const useBreakpoint = () => {
    const [breakpoint, setBreakPoint] = useState<Breakpoint | null>(null);
    const [windowSize, setWindowSize] = useState<{ width: number; height: number } | null>(null);

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();

        if (!windowSize) {
            return;
        }

        // Get window width & height
        const width = windowSize.width;

        // Decide breakpoint
        let currentBreakpoint = Breakpoint.XL;
        if (width >= breakpoints.xs && width < breakpoints.sm) {
            currentBreakpoint = Breakpoint.XS;
        } else if (width >= breakpoints.sm && width < breakpoints.md) {
            currentBreakpoint = Breakpoint.SM;
        } else if (width >= breakpoints.md && width < breakpoints.lg) {
            currentBreakpoint = Breakpoint.MD;
        } else if (width >= breakpoints.lg && width < breakpoints.xl) {
            currentBreakpoint = Breakpoint.LG;
        }

        // Set breakpoint
        setBreakPoint(currentBreakpoint);

        return () => window.removeEventListener('resize', handleResize);
    }, [windowSize?.width]);

    return {
        breakpoint,
    };
};

export default useBreakpoint;
