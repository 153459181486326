import { HydrateFilterDataFilterInput } from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { ClientUserFilterInput } from 'dy-frontend-http-repository/lib/modules/ClientUser/inputs';
import { PlatformClientUserFilterType } from '../../enums';
import { PlatformClientUserFilterData, ClientUserFilterQueryParameters } from '../../types';
import { platformClientUserFiltersInformation } from '../../consts';
import { HydratedQueryParameters } from './types';
import { TaskFilterInput } from 'dy-frontend-http-repository/lib/modules/Task/inputs';

export default class PlatformClientUserFilter {
    // query -> hydrate input
    static queryToHydrationInput = (queryParameters: ClientUserFilterQueryParameters) => {
        const hydrateQueryInput: HydrateFilterDataFilterInput = {};

        if (queryParameters.planIds && queryParameters.planIds.length > 0) {
            hydrateQueryInput.plan_id = queryParameters.planIds;
        }

        return hydrateQueryInput;
    };

    // hydrated -> platform filter
    static hydratedToPlatformFilter(hydratedQueryParameters: HydratedQueryParameters): PlatformClientUserFilterData {
        // Create empty platform task filter data object
        const platformClientUserFilterData: PlatformClientUserFilterData = {
            query: '',
            isArchived: null,
            isVerified: null,
            plans: [],
        };

        if (hydratedQueryParameters.isArchived !== null && hydratedQueryParameters.isArchived !== undefined) {
            //  Set archived flag
            platformClientUserFilterData.isArchived = hydratedQueryParameters.isArchived;
        }

        if (hydratedQueryParameters.isVerified !== null && hydratedQueryParameters.isVerified !== undefined) {
            //  Set verified flag
            platformClientUserFilterData.isVerified = hydratedQueryParameters.isVerified;
        }

        if (hydratedQueryParameters.plans) {
            // Set plans
            platformClientUserFilterData.plans = hydratedQueryParameters.plans;
        }

        return platformClientUserFilterData;
    }

    // platform filter -> API filter input
    static platformFilterToAPIFilterInput(platformFilterData: PlatformClientUserFilterData): TaskFilterInput {
        const taskFilterInput: ClientUserFilterInput = {};

        if (platformFilterData.query) {
            // Set query
            taskFilterInput.query = platformFilterData.query;
        }

        if (platformFilterData.isArchived !== null) {
            // Set archived flag
            taskFilterInput.is_shutdown = platformFilterData.isArchived ? '1' : '0';
        }

        if (platformFilterData.isVerified !== null) {
            // Set archived flag
            taskFilterInput.is_verified = platformFilterData.isVerified ? '1' : '0';
        }

        if (platformFilterData.plans.length > 0) {
            // Set plans
            taskFilterInput.licence_plan_id = platformFilterData.plans.map((p) => p.id);
        }

        return taskFilterInput;
    }

    // platform filter -> query parameters
    static platformFilterToQueryParameters(
        page: number,
        platformFilter: PlatformClientUserFilterData
    ): ClientUserFilterQueryParameters {
        const queryParameters: ClientUserFilterQueryParameters = {
            page: `${page}`,
        };

        if (platformFilter.isArchived !== null) {
            queryParameters.isArchived = platformFilter.isArchived ? 1 : 0;
        }

        if (platformFilter.isVerified !== null) {
            queryParameters.isVerified = platformFilter.isVerified ? 1 : 0;
        }

        if (platformFilter.plans && platformFilter.plans.length > 0) {
            queryParameters.planIds = platformFilter.plans.map((t) => t.id);
        }

        return queryParameters;
    }

    static getAppliedPlatformFilters(platformFilter: PlatformClientUserFilterData): PlatformClientUserFilterType[] {
        const appliedPlatformFilters: PlatformClientUserFilterType[] = [];

        for (const filterType of Object.keys(platformClientUserFiltersInformation) as PlatformClientUserFilterType[]) {
            console.log('filterType: ', filterType);
            if (platformClientUserFiltersInformation[filterType].isPinned) {
                // Filter is pinned by default
                appliedPlatformFilters.push(filterType);
                continue;
            }

            switch (filterType) {
                case PlatformClientUserFilterType.CLIENT_ARCHIVED:
                    if (platformFilter.isArchived !== null) {
                        appliedPlatformFilters.push(PlatformClientUserFilterType.CLIENT_ARCHIVED);
                    }
                    break;
                case PlatformClientUserFilterType.CLIENT_VERIFIED:
                    if (platformFilter.isVerified !== null) {
                        appliedPlatformFilters.push(PlatformClientUserFilterType.CLIENT_VERIFIED);
                    }
                    break;

                case PlatformClientUserFilterType.CLIENT_PLANS:
                    if (platformFilter.plans.length > 0) {
                        appliedPlatformFilters.push(PlatformClientUserFilterType.CLIENT_PLANS);
                    }
                    break;
            }
        }

        return appliedPlatformFilters;
    }
}
