import Link from '@app/components/Link';
import Select from '@app/components/Select';
import DevText from '@app/components/Text';
import { Endpoints } from '@app/data/consts';
import { useCustomSelectFormField, useForm } from '@app/hooks';
import { getStringRequiredValidator } from '@app/hooks/validation/functions';
import {
    Button,
    Card,
    Checkbox,
    Divider,
    Elevation,
    FormGroup,
    Icon,
    Intent,
    MenuItem,
    Spinner,
} from '@blueprintjs/core';
import { ItemRendererProps } from '@blueprintjs/select';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import { ModalProps } from '@modals/types';
import { PlanType, PricePlanVisibility } from 'dy-frontend-http-repository/lib/data/enums';
import { PlanResource } from 'dy-frontend-http-repository/lib/modules/Plan/resources';
import { UpdatePricePlanAttachmentDataInput } from 'dy-frontend-http-repository/lib/modules/Price/inputs';
import { PriceResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { PriceUtils, StripeUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useEffect, useState } from 'react';
import { fetchPlans, updatePriceToPlanAttachment } from './store/effects';
import { $plans } from './store/states';

export interface CreatePlanToPriceAttachmentModalProps {
    subscriptionExternalId: ID;
    planType: PlanType;
    price: PriceResource;
    priceExternalId: string;
    refetchOnSuccess?: () => Promise<unknown>;
}

type Props = ModalProps<CreatePlanToPriceAttachmentModalProps>;

const selectedPlanValidators = [getStringRequiredValidator()];

const CreatePlanToPriceAttachmentModal: React.FC<Props> = ({ closeModal, data }) => {
    const plans = useStore($plans);

    const selectedPlan = useCustomSelectFormField<PlanResource | null>({
        id: 'plan',
        validators: selectedPlanValidators,
        initialValue: null,
        formatValue: (value) => (value ? value.id : ''),
    });

    const [isPriceLegacy, setIsPriceLegacy] = useState(false);

    useEffect(() => {
        if (!data) {
            return;
        }

        fetchPlans({ type: [data.planType] });
    }, []);

    const form = useForm({
        fields: [selectedPlan],
        apiCall: async () => {
            try {
                // Create input
                const createPriceToPlanAttachmentInput: UpdatePricePlanAttachmentDataInput = {
                    plan_id: selectedPlan.value?.id ?? '0',
                    is_legacy: isPriceLegacy,
                    plan_visibility: PricePlanVisibility.INTERNAL,
                };

                // Create price to plan attachment
                await updatePriceToPlanAttachment({
                    priceId: data?.price.id ?? '0',
                    input: createPriceToPlanAttachmentInput,
                });

                // Call refetch callback
                data?.refetchOnSuccess?.();

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    const renderForm = () => {
        if (!plans) {
            return (
                <Flex direction="row" align="center" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        const renderPlanSelect = () => {
            let selectButtonText: string | null = null;
            if (selectedPlan.value) {
                selectButtonText = selectedPlan.value.title;
            }

            const renderItem = (item: PlanResource, { handleClick }: ItemRendererProps) => {
                const isMenuItemActive = item.id === selectedPlan.value?.id;

                return (
                    <MenuItem
                        icon={<Icon icon="box" color={item.color} />}
                        active={isMenuItemActive}
                        key={item.id}
                        text={item.title}
                        onClick={handleClick}
                    />
                );
            };

            const handleItemSelect = (item: PlanResource) => {
                selectedPlan.handleChange(item);
            };

            return (
                <FormGroup
                    label="Plan"
                    intent={!!selectedPlan.error ? Intent.DANGER : Intent.NONE}
                    helperText={selectedPlan.error}
                >
                    <Select<PlanResource>
                        items={plans?.items}
                        itemRenderer={renderItem}
                        onItemSelect={handleItemSelect}
                        popoverProps={{
                            matchTargetWidth: true,
                            usePortal: false,
                        }}
                        selectButtonProps={{
                            fill: true,
                            icon: selectedPlan.value ? <Icon icon="box" color={selectedPlan.value.color} /> : undefined,
                            rightIcon: 'double-caret-vertical',
                            placeholder: 'Select plan',
                            text: selectButtonText,
                        }}
                    />
                </FormGroup>
            );
        };

        const renderPrice = () => {
            // Get price
            const price = data.price;

            return (
                <Card compact elevation={Elevation.ONE}>
                    <Flex align="center" justify="space-between">
                        <DevText className="mr-1">
                            {PriceUtils.formatPrice({
                                price: price.unit_amount,
                                shouldDisplayCents: true,
                                currency: price.currency,
                            })}
                        </DevText>

                        <Link
                            onClick={() =>
                                window.open(
                                    StripeUtils.getSubscriptionStripeURL(
                                        data.subscriptionExternalId,
                                        process.env.STRIPE_BASE_INFORMATION_URL ?? Endpoints.TASK_KANBAN_BOARD
                                    )
                                )
                            }
                        >
                            <DevText muted>Stripe ID: {data.priceExternalId}</DevText>
                        </Link>
                    </Flex>
                </Card>
            );
        };

        return (
            <form onSubmit={form.handleFormSubmit}>
                {/* Price view */}
                <div className="mb-2">{renderPrice()}</div>

                {/* Plan select */}
                {renderPlanSelect()}

                {/* Legacy flag */}
                <FormGroup>
                    <Checkbox
                        label="Price is legacy"
                        checked={isPriceLegacy}
                        onChange={() => {
                            setIsPriceLegacy((prevValue) => !prevValue);
                        }}
                    />
                </FormGroup>

                <Flex justify="flex-end">
                    <Button className="mr-1" outlined onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button
                        loading={form.isSubmitting}
                        disabled={form.isSubmitting || form.hasFieldErrors}
                        type="submit"
                        intent={Intent.PRIMARY}
                    >
                        Attach
                    </Button>
                </Flex>
            </form>
        );
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Attach price to plan</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                {renderForm()}
            </Card>
        </Overlay>
    );
};

export default CreatePlanToPriceAttachmentModal;
