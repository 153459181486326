import { taskDeliverablesTreeApi } from '@pages/Task/store/apis';
import { archiveTaskDeliverablesBatch } from '../../store/effects';

const useRemoveTaskDeliverable = () => {
    const handleRemoveTaskDeliverable = async ({
        taskDeliverableId,
        path,
    }: {
        taskDeliverableId: ID;
        path: string;
    }) => {
        try {
            // Remove task deliverable by ID
            await archiveTaskDeliverablesBatch({
                task_deliverable_ids: [taskDeliverableId],
            });
            taskDeliverablesTreeApi.removeTaskDeliverable({
                taskDeliverableId: taskDeliverableId,
                path,
            });
        } catch (e) {
            throw e;
        }
    };

    return { removeTaskDeliverable: handleRemoveTaskDeliverable };
};

export default useRemoveTaskDeliverable;
