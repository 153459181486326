import { useForm, useTextFormField } from '@app/hooks';
import { Button, Checkbox, Divider, Intent, Radio } from '@blueprintjs/core';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskPublishOutputQueue } from 'dy-frontend-http-repository/lib/data/enums';
import { PublishTaskPublishCreditInput } from 'dy-frontend-http-repository/lib/modules/TaskPublish/inputs';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { useState } from 'react';
import {
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringNumericValueMaxValidator,
    getStringNumericValueMinValidator,
    getStringNumericValueOfTypeIntValidator,
    getStringNumericValueValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { FormField } from '@app/hooks/validation/types';
import Flex from '@components/Flex';
import InputFormField from '@components/InputFormField';
import { $authorizedUser, $permissions } from '@containers/store/states';
import { taskApi, taskStateTransitionLogApi } from '@pages/Task/store/apis';
import { PublishTaskStep } from '../../enums';
import { useTaskPublish } from '../../hooks';
import { changeStep } from '../../store/events';
import { $clientCreditWallet, $taskCategory } from '../../store/states';
import { CommonStepProps } from '../../types';
import { AmountType } from './enums';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { TaskPublishPermission } from 'dy-frontend-permissions/lib/permission';

export type Props = CommonStepProps;

const creditTaskPublishCreditAmountValidators = [
    getStringRequiredValidator(),
    getStringNumericValueValidator(),
    getStringNumericValueOfTypeIntValidator(),
    getStringNumericValueMinValidator({ minValue: 0 }),
];
const creditTaskPublishCommentValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];

const PublishTaskViaCreditStep: React.FC<Props> = ({ closeModal, data }) => {
    const permissions = useStore($permissions);

    const creditWallet = useStore($clientCreditWallet);

    const { handlePublishTaskViaCredit } = useTaskPublish({
        taskId: data.taskId,
        shouldCheckTaskPublishChoices: false,
    });

    const me = useStore($authorizedUser);
    const taskCategory = useStore($taskCategory);

    const [amountType, setAmountType] = useState<AmountType | null>(
        AmountType.TASK_CATEGORY,
    );
    const [publishOutputQueue, setPublishOutputQueue] = useState<TaskPublishOutputQueue>(TaskPublishOutputQueue.ACTIVE);

    const creditTaskPublishCreditAmount = useTextFormField({
        id: 'credit-task-publish-usd-amount',
        validators: creditTaskPublishCreditAmountValidators,
        initialValue: '',
    });

    const creditTaskPublishComment = useTextFormField({
        id: 'credit-task-publish-comment',
        validators: creditTaskPublishCommentValidators,
        initialValue: '',
    });

    const getFormFields = () => {
        const fields: FormField[] = [creditTaskPublishComment];

        switch (amountType) {
            case AmountType.MANUAL_AMOUNT:
                if (creditWallet) {
                    creditTaskPublishCreditAmountValidators.push(
                        getStringNumericValueMaxValidator({ maxValue: creditWallet?.balance }),
                    );
                }

                fields.push(creditTaskPublishCreditAmount);

                break;
        }

        return fields;
    };

    const form = useForm({
        fields: getFormFields(),
        apiCall: async () => {
            try {
                // Create common task publish credit input
                const taskPublishInput: PublishTaskPublishCreditInput = {
                    output_queue: publishOutputQueue,
                    task_id: data.taskId,
                    comment: creditTaskPublishComment.value,
                };

                // Add specific fields to task publish input depending on chosen amount type
                switch (amountType) {
                    case AmountType.MANUAL_AMOUNT:
                        taskPublishInput.is_custom = true;
                        taskPublishInput.credit_amount = parseInt(creditTaskPublishCreditAmount.value);
                        break;
                }

                // Publish task
                const momentNowUtc = moment().utc().format();
                const response = await handlePublishTaskViaCredit(taskPublishInput);
                taskApi.transitionState({ state: TaskState.PUBLISHED });
                taskStateTransitionLogApi.update([
                    {
                        comment: '',
                        applied_at: momentNowUtc,
                        is_forced: false,
                        state: TaskState.PUBLISHED,
                        user: {
                            first_name: me!.user.first_name,
                            id: me!.user.id,
                            last_name: me!.user.last_name,
                            image_hash: me!.user.image_hash,
                            type: me!.user.type,
                            role: me!.user.role,
                        },
                    },
                ]);

                return { response };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!taskCategory) {
        return null;
    }

    if (!me) {
        return null;
    }

    const renderCreditAmountBlock = () => {
        const renderTaskCategoryRadio = () => {
            const radioLabel = `"${taskCategory.title}" request category price - ${taskCategory.credit_amount} credits`;
            return (
                <Radio
                    className="mb-1"
                    checked={amountType === AmountType.TASK_CATEGORY}
                    label={radioLabel}
                    onChange={() => setAmountType(AmountType.TASK_CATEGORY)}
                />
            );
        };

        const renderManualCreditAmountRadio = () => {
            const isAllowed = permissions.isRoot.taskPublish || permissions.has(TaskPublishPermission.CREATE_CUSTOM);
            if (!isAllowed) {
                return null;
            }

            return (
                <Radio
                    className="mb-1"
                    checked={amountType === AmountType.MANUAL_AMOUNT}
                    label="Manual amount"
                    onChange={() => setAmountType(AmountType.MANUAL_AMOUNT)}
                />
            );
        };

        const renderManualCreditAmountInput = () => {
            // Show manual credit amount input only whenever <code>amountType === AmountType.MANUAL</code>
            if (amountType !== AmountType.MANUAL_AMOUNT) {
                return null;
            }

            return (
                <InputFormField
                    field={creditTaskPublishCreditAmount}
                    formGroupProps={{ label: 'Credits' }}
                    inputProps={{ placeholder: 'Enter amount of credits' }}
                />
            );
        };

        return (
            <div>
                <DevText muted className="mb-1">
                    Amount
                </DevText>

                {/* Task category credit price */}
                {renderTaskCategoryRadio()}

                {/* Manual amount of credits */}
                {renderManualCreditAmountRadio()}
                {renderManualCreditAmountInput()}
            </div>
        );
    };

    const renderCurrentBalance = () => {
        let creditBalanceLabel = `Unknown amount of credit(s)`;
        if (creditWallet) {
            creditBalanceLabel = `${creditWallet.balance} credit(s)`;
        }

        return (
            <div className="mt-2 mb-2">
                <DevText muted>Current balance</DevText>
                <DevText>{creditBalanceLabel}</DevText>
            </div>
        );
    };

    return (
        <div>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Credit publish</Heading>
                <Button minimal icon="cross" onClick={closeModal} />
            </Flex>

            <Divider className="mb-2" />

            <form onSubmit={form.handleFormSubmit}>
                {/* Amount */}
                {renderCreditAmountBlock()}

                {/* Credit balance */}
                {renderCurrentBalance()}

                {/* Comment*/}
                <InputFormField
                    field={creditTaskPublishComment}
                    formGroupProps={{ label: 'Publish comment' }}
                    inputProps={{ placeholder: 'Enter publish comment' }}
                />

                {/* Publish output queue */}
                <Checkbox
                    checked={publishOutputQueue === TaskPublishOutputQueue.BACKLOG}
                    label='Publish to "Backlog"'
                    onChange={() =>
                        setPublishOutputQueue((prevValue) =>
                            prevValue === TaskPublishOutputQueue.ACTIVE
                                ? TaskPublishOutputQueue.BACKLOG
                                : TaskPublishOutputQueue.ACTIVE,
                        )
                    }
                />
                <Flex justify="flex-end">
                    <Button className="mr-1" outlined onClick={() => changeStep(PublishTaskStep.PUBLISH_METHOD_SELECT)}>
                        Select other method
                    </Button>
                    <Button
                        disabled={form.hasFieldErrors}
                        loading={form.isSubmitting}
                        type="submit"
                        intent={Intent.PRIMARY}
                    >
                        Publish
                    </Button>
                </Flex>
            </form>
        </div>
    );
};

export default PublishTaskViaCreditStep;
