import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import { Button, Colors, Icon, IconName, Tooltip } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';
import { dismissNotification } from '../../store/effects';
import { NotificationTemplateWrapper } from './styled';

export interface NotificationTemplateProps {
    // Icon associated with notification
    icon: IconName;

    // Notification title
    title: string;

    // When notification occurred
    at: string;

    // Notification content
    content: React.ReactNode;

    // Dismiss notification function
    dismiss: () => Promise<void>;
}

type Props = Omit<HTMLAttributes<HTMLDivElement>, 'content'> & NotificationTemplateProps;

const NotificationTemplate: React.FC<Props> = ({ icon, title, at, content, dismiss, ...props }) => {
    const isDismissProcessing = useStore(dismissNotification.pending);

    return (
        <NotificationTemplateWrapper {...props}>
            <Flex className="mb-1" direction="row" justify="space-between" align="flex-start">
                <Flex direction="row" align="flex-start">
                    <Icon className="mr-1" icon={icon} color={Colors.GRAY3} />
                    <DevText muted>{title}</DevText>
                </Flex>

                <DevText muted className="notification-at" align="end" style={{ minWidth: '68px' }}>
                    {moment(at).format('HH:mm')}
                </DevText>

                <DevText
                    muted
                    className="dismiss-action"
                    onClick={isDismissProcessing ? undefined : dismiss}
                    align="end"
                    style={{ minWidth: '68px' }}
                >
                    <Icon className="mr-small" icon="cross" />
                    Dismiss
                </DevText>
            </Flex>

            {content}
        </NotificationTemplateWrapper>
    );
};

export default NotificationTemplate;
