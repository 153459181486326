import repository from 'dy-frontend-http-repository/lib/repository';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import {
    ApproveTaskDeliverablesInput,
    ArchiveTaskDeliverablesInput,
    ChangeTaskDeliverablesPathLocation,
    CreateTaskDeliverablesInput,
    RestoreTaskDeliverablesInput,
    UpdateTaskDeliverableInformationInput,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/inputs';
import {
    TaskDeliverableRef,
    TaskDeliverableRefList,
} from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/refs';
import domain from './domain';

// Fetch single task deliverable by ID
export const fetchTaskDeliverableById = domain.createEffect<ID, TaskDeliverableResource>({
    name: 'fetchTaskDeliverableById',
    handler: async (id) => {
        return await repository.taskDeliverable().getById(id);
    },
});

// Create multiple task deliverables
export const createTaskDeliverablesBatch = domain.createEffect<CreateTaskDeliverablesInput, TaskDeliverableRefList>({
    name: 'createTaskDeliverablesBatch',
    handler: async (input) => {
        return await repository.taskDeliverable().createBatch(input);
    },
});

// Archive task deliverables
export const archiveTaskDeliverablesBatch = domain.createEffect<ArchiveTaskDeliverablesInput, TaskDeliverableRefList>({
    name: 'archiveTaskDeliverablesBatch',
    handler: async (input) => {
        return await repository.taskDeliverable().archiveBatch(input);
    },
});

// Restore task deliverables
export const restoreTaskDeliverablesBatch = domain.createEffect<RestoreTaskDeliverablesInput, TaskDeliverableRefList>({
    name: 'restoreTaskDeliverablesBatch',
    handler: async (input) => {
        return await repository.taskDeliverable().restoreBatch(input);
    },
});

// Change location path of multiple task deliverables
export const moveTaskDeliverablesBatchToDirectory = domain.createEffect<
    ChangeTaskDeliverablesPathLocation,
    TaskDeliverableRefList
>({
    name: 'moveTaskDeliverablesBatchToDirectory',
    handler: async (input) => {
        return await repository.taskDeliverable().changePathLocation(input);
    },
});

// Update task deliverable information
export const updateTaskDeliverableInformation = domain.createEffect<
    { id: ID; input: UpdateTaskDeliverableInformationInput },
    TaskDeliverableRef
>({
    name: 'updateTaskDeliverableInformation',
    handler: async ({ id, input }) => {
        return await repository.taskDeliverable().update(id, input);
    },
});
