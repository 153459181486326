import React, { useEffect } from 'react';
import MenuItemSection from '@containers/components/Layout/components/NextMenu/components/MenuItemSection';
import MenuItem from '@containers/components/Layout/components/NextMenu/components/MenuItem';
import { Endpoints, teamParticipationRoleInformation } from '@data/consts';
import { useStore } from 'effector-react';
import { $authorizedUser, $permissions } from '@containers/store/states';
import {
    PlanPermission,
    TaskCategoryFeaturedBlockPermission,
    TaskCategoryGroupPermission,
    TaskMessageTemplatePermission,
} from 'dy-frontend-permissions/lib/permission';
import { UserRole } from 'dy-frontend-http-repository/lib/data/enums';
import { useNavigate } from 'react-router-dom';

const DotYetiMenuItems: React.FC = () => {
    const navigate = useNavigate();

    const me = useStore($authorizedUser);
    const permissions = useStore($permissions);

    if (!me) {
        return null;
    }

    const lazyClientStatisticsAccessRoles: UserRole[] = [
        UserRole.CORE_ROOT,
        UserRole.CORE_ADMIN,
        UserRole.CORE_PLATFORM_DEV,
        UserRole.CORE_OPERATIONS_MANAGER,
        UserRole.CORE_ACCOUNT_MANAGER,
    ];

    const items = {
        lazyClientStatistics: lazyClientStatisticsAccessRoles.includes(me.user.role),
        task: {
            kanban: permissions.isEnabled.task,
            all: permissions.isEnabled.task,
            client_feedback: permissions.isEnabled.task,
        },
        clients: {
            all: permissions.isEnabled.clientUser,
        },
        members: {
            all: permissions.isEnabled.coreUser,
        },
        teams: {
            all: permissions.isEnabled.team,
        },
        categories: {
            all: permissions.isEnabled.taskCategory,
            groups:
                permissions.isRoot.taskCategoryGroup ||
                (permissions.isEnabled.taskCategoryGroup &&
                    (permissions.has(TaskCategoryGroupPermission.CREATE) ||
                        permissions.has(TaskCategoryGroupPermission.UPDATE) ||
                        permissions.has(TaskCategoryGroupPermission.ARCHIVE) ||
                        permissions.has(TaskCategoryGroupPermission.RESTORE) ||
                        permissions.has(TaskCategoryGroupPermission.COMPLEXITY_MANAGE))),
            featured:
                permissions.isRoot.taskCategoryFeaturedBlock ||
                (permissions.isEnabled.taskCategoryFeaturedBlock &&
                    (permissions.has(TaskCategoryFeaturedBlockPermission.CREATE) ||
                        permissions.has(TaskCategoryFeaturedBlockPermission.UPDATE) ||
                        permissions.has(TaskCategoryFeaturedBlockPermission.ARCHIVE) ||
                        permissions.has(TaskCategoryFeaturedBlockPermission.RESTORE))),
        },
        plans:
            permissions.isRoot.plan ||
            (permissions.isEnabled.plan &&
                (permissions.has(PlanPermission.CREATE) ||
                    permissions.has(PlanPermission.INFO_UPDATE) ||
                    permissions.has(PlanPermission.PUBLISH) ||
                    permissions.has(PlanPermission.ARCHIVE) ||
                    permissions.has(PlanPermission.RESTORE) ||
                    permissions.has(PlanPermission.ATTACHMENT_MANAGE))),
        task_message_templates:
            permissions.isRoot.taskMessageTemplate ||
            (permissions.isEnabled.taskMessageTemplate &&
                (permissions.has(TaskMessageTemplatePermission.CREATE) ||
                    permissions.has(TaskMessageTemplatePermission.UPDATE) ||
                    permissions.has(TaskMessageTemplatePermission.ARCHIVE) ||
                    permissions.has(TaskMessageTemplatePermission.RESTORE))),
    };

    // TODO: Remove this hack
    const isSettingsAvailable = items.plans || items.task_message_templates;

    return (
        <>
            {/* <MenuItem icon="control" title="Dashboard" link={Endpoints.APP} /> */}
            {items.lazyClientStatistics && (
                <MenuItem icon="th" title="Client overview" link={Endpoints.LAZY_CLIENT_OVERVIEW} />
            )}

            <MenuItemSection collapsible title="Requests" className="mt-2 mb-3">
                <MenuItem icon="full-stacked-chart" title="Kanban board" link={Endpoints.TASK_KANBAN_BOARD} />
                <MenuItem icon="form" title="All requests" link={Endpoints.TASKS} />
                <MenuItem icon="chart" title="Feedback summary" link={Endpoints.TASK_CLIENT_FEEDBACK} />
            </MenuItemSection>

            <MenuItemSection collapsible title="Clients" className="mb-3">
                {items.clients.all && <MenuItem icon="person" title="All clients" link={Endpoints.CLIENTS} />}
            </MenuItemSection>

            <MenuItemSection collapsible title="Members" className="mb-3">
                {items.members.all && (
                    <MenuItem icon="inherited-group" title="All members" link={Endpoints.CORE_USERS} />
                )}
            </MenuItemSection>

            <MenuItemSection collapsible title="Teams" className="mb-3">
                {items.teams.all && <MenuItem icon="team" title="All teams" link={Endpoints.TEAMS} />}
                {me.team.length > 0 &&
                    me.team.map((t) => (
                        <MenuItem
                            icon="full-circle"
                            iconColor={t.color}
                            title={t.title}
                            label={teamParticipationRoleInformation[t.role]}
                            onClick={() => navigate(Endpoints.TEAM_MEMBERS.replace(':teamId', t.id))}
                        />
                    ))}
            </MenuItemSection>

            <MenuItemSection collapsible title="Request categories" className="mb-3">
                {items.categories.all && (
                    <MenuItem icon="new-grid-item" title="All categories" link={Endpoints.TASK_CATEGORIES} />
                )}
                {items.categories.groups && (
                    <MenuItem icon="comparison" title="Category groups" link={Endpoints.TASK_CATEGORY_GROUPS} />
                )}
                {items.categories.featured && (
                    <MenuItem
                        icon="horizontal-distribution"
                        title="Featured categories"
                        link={Endpoints.TASK_CATEGORY_FEATURED_BLOCKS}
                    />
                )}
            </MenuItemSection>

            {isSettingsAvailable && (
                <MenuItemSection collapsible title="Settings" className="mb-3">
                    {items.plans && <MenuItem icon="box" title="Plans" link={Endpoints.PLANS} />}
                    {items.task_message_templates && (
                        <MenuItem
                            icon="align-left"
                            title="Request message templates"
                            link={Endpoints.TASK_MESSAGE_TEMPLATES}
                        />
                    )}
                </MenuItemSection>
            )}
        </>
    );
};

export default DotYetiMenuItems;
