import React, { HTMLAttributes } from 'react';
import { useStore } from 'effector-react';
import { Button, Card, Colors, Icon, IconName } from '@blueprintjs/core';
import { openModal } from '@modals/store/events';
import { $brandProfile } from '@pages/BrandProfile/store/states';
import Flex from '@components/Flex';
import { $permissions } from '@app/containers/store/states';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import UpdateBrandProfileMainInformationModal, {
    UpdateBrandProfileMainInformationModalProps,
} from '@app/containers/pages/BrandProfile/modals/UpdateBrandProfileMainInformationModal';
import { BrandProfilePermission } from 'dy-frontend-permissions/lib/permission';

type Props = HTMLAttributes<HTMLDivElement>;

const Information: React.FC<Props> = (props) => {
    const permissions = useStore($permissions);

    const brandProfile = useStore($brandProfile);

    if (!brandProfile) {
        return null;
    }

    // Root permissions
    const isUpdateAllowed = permissions.isRoot.brandProfile || permissions.has(BrandProfilePermission.UPDATE);

    const renderInformationLine = (key: string, title: string, icon: IconName, text?: string | React.ReactNode) => {
        if (!text) {
            return (
                <div className="mb-2" key={key}>
                    <DevText muted className="mb-small">
                        <Icon className="mr-1" icon={icon} size={16} color={Colors.GRAY2} />
                        {title}
                    </DevText>
                    <DevText muted>No data</DevText>
                </div>
            );
        }

        return (
            <div className="mb-2" key={key}>
                <DevText muted className="mb-small">
                    <Icon className="mr-1" icon={icon} size={16} color={Colors.GRAY2} />
                    {title}
                </DevText>
                <DevText>{text}</DevText>
            </div>
        );
    };

    const renderUpdateBrandProfileButton = () => {
        if (!isUpdateAllowed) {
            return null;
        }

        return (
            <Button
                icon="edit"
                onClick={() =>
                    openModal<UpdateBrandProfileMainInformationModalProps>({
                        ModalComponent: UpdateBrandProfileMainInformationModal,
                        data: {
                            brandProfileId: brandProfile.id,
                            title: brandProfile.title,
                            industryName: brandProfile.industry_name,
                            values: brandProfile.values,
                            targetAudience: brandProfile.target_audience,
                            productDescription: brandProfile.product_description,
                            comment: brandProfile.comment,
                        },
                    })
                }
            />
        );
    };

    return (
        <Card {...props}>
            <Flex className="mb-2" align="center" justify="space-between">
                <Heading type="h4">Main information</Heading>

                {renderUpdateBrandProfileButton()}
            </Flex>

            {/* Brand profile */}
            {renderInformationLine('brand-profile', 'Brand profile', 'helper-management', brandProfile.title)}

            {/* Industry */}
            {renderInformationLine('industry', 'Industry', 'id-number', brandProfile.industry_name)}

            {/* Values */}
            {renderInformationLine('values', 'Values', 'heart', brandProfile.values)}

            {/* Service/production description */}
            {renderInformationLine(
                'service-description',
                'Service/production description',
                'shop',
                brandProfile.product_description
            )}

            {/* Additional remarks */}
            {renderInformationLine(
                'additional-remarks',
                'Additional remarks',
                'comment',
                brandProfile.comment ? (
                    <span style={{ wordBreak: 'break-all' }}>{brandProfile.comment}</span>
                ) : (
                    brandProfile.comment
                )
            )}
        </Card>
    );
};

export default Information;
