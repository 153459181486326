import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { PlatformTaskFilterData } from '@app/containers/pages/Tasks/types';
import { $isQueryHydrationFinished } from '@app/containers/pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@app/containers/pages/Tasks/store/events';
import { initialPlatformTaskFilterData, platformFiltersInformation } from '@app/containers/pages/Tasks/consts';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { Radio, Position } from '@blueprintjs/core';
import { PlatformTaskFilterType } from '@app/containers/pages/Tasks/enums';

export interface TaskPausedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskPausedPlatformFilterProps;

const TaskPausedPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isOnlyPausedVisible, setIsOnlyPausedVisible] = useState<NonNullable<
        PlatformTaskFilterData['isPaused']
    > | null>(platformTaskFilterData.isPaused);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsOnlyPausedVisible(platformTaskFilterData.isPaused);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.isPaused === null) {
            setIsOnlyPausedVisible(null);
        }
    }, [platformTaskFilterData.isPaused]);

    const getFilterTagLabel = () => {
        if (isOnlyPausedVisible === null) {
            return '';
        }

        if (isOnlyPausedVisible) {
            return 'Only paused requests';
        } else {
            return 'Only not paused requests';
        }
    };

    const getFilterTagTitle = () => {
        if (isOnlyPausedVisible === null) {
            return 'Paused';
        }

        return 'Paused:';
    };

    const handleRemoveFilter = () => {
        setIsOnlyPausedVisible(null);
        setPlatformTaskFilterData({
            ...platformTaskFilterData,
            isPaused: initialPlatformTaskFilterData.isPaused,
        });
        setIsOpen(false);
        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_PAUSED].isPinned) {
            // Platform task paused filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformTaskFilterData.isPaused !== isOnlyPausedVisible) {
            setPlatformTaskFilterData({ ...platformTaskFilterData, isPaused: isOnlyPausedVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_PAUSED].isPinned) {
            return;
        }

        if (isOnlyPausedVisible === null) {
            onRemove();
        }
    };

    const renderRow = (key: number) => {
        return (
            <li key={key}>
                <Radio
                    className="mb-1"
                    checked={isOnlyPausedVisible !== null && !isOnlyPausedVisible}
                    label="Only not paused requests"
                    onChange={() => setIsOnlyPausedVisible(false)}
                />

                <Radio
                    checked={isOnlyPausedVisible !== null && isOnlyPausedVisible}
                    label="Only paused requests"
                    onChange={() => setIsOnlyPausedVisible(true)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Paused"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_PAUSED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskPausedPlatformFilter;
