import { Card, Colors, Icon } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { DiscountResource, SubscriptionResource } from 'dy-frontend-http-repository/lib/modules/Subscription/resources';
import { CouponUtils } from 'dy-frontend-shared/lib/utils';
import moment from 'moment';
import React, { HTMLAttributes } from 'react';

export interface SubscriptionDiscountsProps {
    subscription: SubscriptionResource;
}

export type Props = HTMLAttributes<HTMLDivElement> & SubscriptionDiscountsProps;

const SubscriptionDiscounts: React.FC<Props> = ({ subscription, ...props }) => {
    // Get discounts
    const discounts = subscription.discounts;
    if (discounts.length === 0) {
        return null;
    }

    const getDiscountEndAtDate = (discount: DiscountResource) => {
        let discountEndAtFormatted = 'removed';

        if (discount.end_at !== null) {
            discountEndAtFormatted = moment(discount.end_at).format('D MMM, YYYY');
        }

        return discountEndAtFormatted;
    };

    const getDiscountTitle = (discount: DiscountResource) => {
        let discountTitle = discount.external_id;
        if (discount.coupon) {
            discountTitle = `Coupon: ${discount.coupon.title}`;
        }

        return discountTitle;
    };

    const renderCouponSubTitle = (discount: DiscountResource) => {
        const discountEndAtDate = getDiscountEndAtDate(discount);

        if (discount.coupon) {
            const couponDiscount = CouponUtils.getCouponDiscountText(discount.coupon);

            return (
                <Flex direction="row" align="center">
                    <DevText muted>{couponDiscount}</DevText>
                    <Circle className="ml-1 mr-1" size="6px" color={Colors.GRAY2} />
                    <DevText muted>Valid until {discountEndAtDate}</DevText>
                </Flex>
            );
        }

        return (
            <Flex direction="row" align="center">
                <Icon className="mr-1" icon="warning-sign" color={Colors.ORANGE4} />
                <DevText muted>Coupon details are unknown, sync it to get info</DevText>
                <Circle className="ml-1 mr-1" size="6px" color={Colors.GRAY2} />
                <DevText muted>Valid until {discountEndAtDate}</DevText>
            </Flex>
        );
    };

    return (
        <div {...props}>
            <Heading className="mb-1" type="h4">
                {discounts.length > 1 ? 'Discounts' : 'Discount'}
            </Heading>

            {discounts.map((discount, index) => (
                <Card className={index === 0 ? '' : 'mt-1'} key={discount.external_id}>
                    <Flex direction="row" justify="space-between">
                        <div>
                            <Flex className="mb-1" direction="row" align="center">
                                <Icon className="mr-1" icon="tag" />
                                <Heading type="h5">{getDiscountTitle(discount)}</Heading>
                            </Flex>
                            {renderCouponSubTitle(discount)}
                        </div>

                        <DevText muted>Applied: {moment(discount.start_at).format('D MMM, YYYY')}</DevText>
                    </Flex>
                </Card>
            ))}
        </div>
    );
};

export default SubscriptionDiscounts;
