import ConfirmationPopover from '@app/components/ConfirmationPopover';
import NonIdealState from '@app/components/NonIdealState';
import DevText from '@app/components/Text';
import FileViewerModal, { FileViewerModalProps } from '@app/containers/modals/FileViewerModal';
import { openModal } from '@app/containers/modals/store/events';
import { taskApi } from '@app/containers/pages/Task/store/apis';
import { updateTask } from '@app/containers/pages/Task/store/effects';
import { $task } from '@app/containers/pages/Task/store/states';
import { $permissions } from '@app/containers/store/states';
import { fileHashPreview } from '@app/data/consts';
import { useUpload } from '@app/hooks';
import { FileInformationStatus } from '@app/hooks/useUpload/enums';
import { FileInformation } from '@app/hooks/useUpload/types';
import { getFileMaxSizeValidator } from '@app/hooks/validation/functions';
import { Button, Card, Classes, Icon, Intent } from '@blueprintjs/core';
import FileTilePreview from '@components/FileTilePreview';
import FileUploadButton from '@components/FileUploadButton';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import { FilePurpose } from 'dy-frontend-http-repository/lib/data/enums';
import { FileAttachmentResource } from 'dy-frontend-http-repository/lib/modules/Task/resources';
import { TaskPermission } from 'dy-frontend-permissions/lib/permission';
import { FileHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/FileHashPreview/enums';
import { FileUtils } from 'dy-frontend-shared/lib/utils';
import { useStore } from 'effector-react';
import React, { useState } from 'react';
import { FileInformationToTaskFileResourceMapper } from '../../valueObjects';

const taskAssetValidators = [getFileMaxSizeValidator({ maxSize: 125000000 })];

const Assets: React.FC = () => {
    const permissions = useStore($permissions);

    const task = useStore($task);
    const { files: taskUploadingFileAssets, upload: uploadTaskFileAssets } = useUpload({
        clearFilesOnUploadSuccess: true,
        validators: taskAssetValidators,
        onUploadSuccess: (files) => {
            handleCreateTaskAsset(files);
        },
    });

    const [removingAssetId, setRemovingAssetId] = useState<ID | null>(null);

    if (!task) {
        return null;
    }

    const handleCreateTaskAsset = (files: FileInformation[]) => {
        // Get verified files
        const verifiedFiles = files.filter(
            (file) => file.status === FileInformationStatus.VERIFIED && file.verifiedFileResource !== null
        );
        const uploadedFileIds = task.files.map((asset) => asset.file.id);

        // Update task file attachments
        updateTask({
            id: task.id,
            input: {
                file_ids: [
                    ...uploadedFileIds,
                    ...verifiedFiles.map((verifiedFile) => verifiedFile.verifiedFileResource!.id),
                ],
            },
        })
            .then(() => {
                const taskFiles: FileAttachmentResource[] = verifiedFiles.map((file, index) => ({
                    index,
                    file: FileInformationToTaskFileResourceMapper.transform(file)!,
                }));

                taskApi.addFiles(taskFiles);
            })
            .catch((e) => {
                // TODO: handle error
                console.error(e);
            });
    };

    const handleRemoveTaskAsset = async (fileId: ID) => {
        // Remove task from task files
        const filteredFileIds = task.files
            .map((asset) => asset.file.id)
            .filter((assetFileId) => assetFileId !== fileId);

        try {
            setRemovingAssetId(fileId);
            await updateTask({
                id: task.id,
                input: {
                    file_ids: filteredFileIds,
                },
            });
            taskApi.removeFile({ fileId });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setRemovingAssetId(null);
        }
    };

    const renderUploadAsset = () => {
        const requiredPermission =
            task.publish === null ? TaskPermission.BRIEF_UPDATE_UNPUBLISHED : TaskPermission.BRIEF_UPDATE_PUBLISHED;
        const isUploadTaskAssetAllowed = permissions.isRoot.task || permissions.has(requiredPermission);
        if (!isUploadTaskAssetAllowed) {
            return null;
        }

        return (
            <FileUploadButton
                multiple
                className="ml-1"
                icon="plus"
                intent={Intent.PRIMARY}
                onAttachFiles={(files) => uploadTaskFileAssets(FilePurpose.TASK_ATTACHMENT, files)}
            >
                Add new
            </FileUploadButton>
        );
    };

    const renderHeader = () => {
        return (
            <Flex justify="space-between">
                <Heading type="h4">Assets</Heading>
                {renderUploadAsset()}
            </Flex>
        );
    };

    const renderAssets = () => {
        if (task.files.length === 0 && taskUploadingFileAssets.length === 0) {
            return <DevText muted>No assets were attached to this request</DevText>;
        }

        const renderUploadingFiles = () => {
            return taskUploadingFileAssets.map((assetFileInformation) => (
                <FileTilePreview
                    loading
                    className="mt-1"
                    fileName={assetFileInformation.file.name}
                    fileSize={assetFileInformation.file.size}
                    alt={assetFileInformation.file.name}
                    extension={FileUtils.getFileExtension(assetFileInformation.file.name)}
                    src={null}
                    progress={assetFileInformation.progress}
                />
            ));
        };

        const renderUploadedTaskFileAttachments = () => {
            const requiredPermission =
                task.publish === null ? TaskPermission.BRIEF_UPDATE_UNPUBLISHED : TaskPermission.BRIEF_UPDATE_PUBLISHED;
            const isRemoveTaskFileAttachmentAllowed = permissions.isRoot.task || permissions.has(requiredPermission);

            const renderViewTaskFileAssetButton = (index: number) => {
                const handleViewTaskFileAsset = () => {
                    openModal<FileViewerModalProps>({
                        ModalComponent: FileViewerModal,
                        data: {
                            currentFileIndex: index,
                            files: task.files.map((f) => ({
                                src: f.file.url,
                                extension: f.file.extension,
                                name: f.file.original_name,
                                size: f.file.size,
                            })),
                        },
                    });
                };

                return <Button minimal icon="eye-open" onClick={handleViewTaskFileAsset} />;
            };

            const renderDownloadAssetButton = (url: string) => {
                return <Button minimal className="ml-small" icon="import" onClick={() => window.open(url)} />;
            };

            const renderRemoveTaskFileAssetRemoveButton = (assetFileId: ID) => {
                if (!isRemoveTaskFileAttachmentAllowed) {
                    // Asset removal is NOT allowed
                    return null;
                }

                const isRemovingTaskFileAsset = removingAssetId === assetFileId;

                return (
                    <ConfirmationPopover
                        className="ml-small"
                        title="Are you sure you want to remove asset?"
                        description="When confirmed, asset will be removed"
                        actions={[
                            <Button
                                intent={Intent.DANGER}
                                className={Classes.POPOVER_DISMISS}
                                onClick={() => handleRemoveTaskAsset(assetFileId)}
                            >
                                Yes, remove asset
                            </Button>,
                        ]}
                    >
                        <Button
                            minimal
                            loading={isRemovingTaskFileAsset}
                            disabled={isRemovingTaskFileAsset}
                            icon="trash"
                            intent={Intent.DANGER}
                        />
                    </ConfirmationPopover>
                );
            };

            const renderFileTilePreviewActions = (file: FileAttachmentResource, index: number) => {
                return (
                    <>
                        {renderViewTaskFileAssetButton(index)}
                        {renderDownloadAssetButton(file.file.url)}
                        {renderRemoveTaskFileAssetRemoveButton(file.file.id)}
                    </>
                );
            };

            return task.files.map((asset, index) => {
                let previewUrl: string | null = null;
                if (asset.file.preview_path) {
                    previewUrl = fileHashPreview.getPreviewUrl(asset.file.preview_path, FileHashPreviewSize.SM);
                }

                return (
                    <FileTilePreview
                        useOnlySrcPresenceCheck
                        className="mt-1"
                        fileSize={asset.file.size}
                        fileName={asset.file.original_name}
                        extension={asset.file.extension}
                        src={previewUrl}
                        alt={asset.file.original_name}
                        key={asset.file.id}
                        actions={renderFileTilePreviewActions(asset, index)}
                    />
                );
            });
        };

        return (
            <div>
                {renderUploadingFiles()}
                {renderUploadedTaskFileAttachments()}
            </div>
        );
    };

    return (
        <Card>
            {renderHeader()}
            {renderAssets()}
        </Card>
    );
};

export default Assets;
