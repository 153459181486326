import Box from '@app/components/Box';
import FilePreview from '@app/components/FilePreview';
import Flex from '@app/components/Flex';
import DevText from '@app/components/Text';
import { Card, CardProps, Colors, Divider, Elevation, Icon, Intent, Tag, Tooltip } from '@blueprintjs/core';
import { TaskDeliverableType } from 'dy-frontend-http-repository/lib/data/enums';
import { TaskDeliverableResource } from 'dy-frontend-http-repository/lib/modules/TaskDeliverable/resources';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import React, { ReactNode } from 'react';
import Link from '@app/components/Link';
import moment from 'moment';
import { fileHashPreview } from '@app/data/consts';
import { FileHashPreviewSize } from 'dy-frontend-shared/lib/data/valueObjects/FileHashPreview/enums';
import Circle from '@app/components/Circle';

export interface TaskDeliverableListViewListItemProps {
    createdAt: string;
    approvementTagVisible?: boolean;
    taskDeliverable: TaskDeliverableResource;
    actions?: ReactNode;
    onOpenViewer?: () => void;
}

export type Props = CardProps & TaskDeliverableListViewListItemProps;

const TaskDeliverableListViewListItem: React.FC<Props> = ({
    selected = false,
    approvementTagVisible = true,
    createdAt,
    taskDeliverable,
    actions,
    style,
    onOpenViewer,
    ...props
}) => {
    const renderPreview = () => {
        if (taskDeliverable.file) {
            // Task deliverable has file attached to it

            // Get preview url
            let previewUrl: string | null = null;
            if (taskDeliverable.file.preview_path) {
                previewUrl = fileHashPreview.getPreviewUrl(taskDeliverable.file.preview_path, FileHashPreviewSize.SM);
            }

            return (
                <FilePreview
                    useOnlySrcPresenceCheck
                    width="100%"
                    height="100%"
                    minWidth="100%"
                    minHeight="100%"
                    objectFit="cover"
                    style={{ cursor: 'pointer' }}
                    onClick={onOpenViewer}
                    extension={taskDeliverable.file.extension}
                    src={previewUrl}
                />
            );
        }

        // Get icon
        let icon: JSX.Element;
        switch (taskDeliverable.type) {
            case TaskDeliverableType.BLANK:
                icon = <Icon icon="circle" size={42} />;
                break;
            case TaskDeliverableType.TEXT:
                icon = <Icon icon="text-highlight" size={42} />;
                break;
            case TaskDeliverableType.EMBED:
                icon = <Icon icon="insert" size={42} />;
                break;
            default:
                icon = <Icon icon="error" size={42} />;
        }

        return (
            <Box width="56px" height="56px" minHeight="56px" minWidth="56px" cursor="pointer" onClick={onOpenViewer}>
                <Flex fullHeight fullWidth align="center" justify="center">
                    {icon}
                </Flex>
            </Box>
        );
    };

    const renderApprovementTag = () => {
        if (!approvementTagVisible) {
            // Do not show approvement tag
            return null;
        }

        if (taskDeliverable.approved_at) {
            // Approved
            return null;
        }

        // Not approved
        return (
            <Tag minimal className="mr-1" icon="warning-sign" intent={Intent.WARNING}>
                Not approved
            </Tag>
        );
    };

    const renderTitle = () => {
        return (
            <Tooltip content={taskDeliverable.title}>
                <Link onClick={onOpenViewer}>{TextFormatUtils.truncate(taskDeliverable.title, 70)}</Link>
            </Tooltip>
        );
    };

    const renderCommentsCounter = () => {
        const metadata = taskDeliverable.metadata;

        if (metadata.comment_count_total === 0) {
            return <DevText muted>No comments</DevText>;
        }

        if (metadata.comment_count_unresolved === 0) {
            // No unresolved comments
            return (
                <DevText muted>
                    <Icon className="mr-small" icon="chat" />
                    {metadata.comment_count_total} total
                </DevText>
            );
        } else {
            return (
                <Tooltip content={`${metadata.comment_count_total} total`}>
                    <DevText>
                        <Icon className="mr-small" icon="chat" intent={Intent.PRIMARY} />
                        {metadata.comment_count_unresolved} unresolved
                    </DevText>
                </Tooltip>
            );
        }
    };

    return (
        <Card elevation={Elevation.ONE} style={{ padding: '8px', ...style }} {...props}>
            <Flex direction="row" align="center" justify="space-between">
                <Flex direction="row" align="center">
                    <Box className="mr-1" width="56px" height="56px" minWidth="56px" minHeight="56px">
                        <Flex fullHeight align="center" justify="center">
                            {renderPreview()}
                        </Flex>
                    </Box>

                    <div>
                        {renderTitle()}
                        <Flex direction="row" align="center">
                            {renderApprovementTag()}
                            <DevText muted className="mr-1">
                                {moment(createdAt).format('D MMM, YYYY')}
                            </DevText>
                            <Circle color={Colors.GRAY2} size="6px" className="mr-1" />
                            {renderCommentsCounter()}
                        </Flex>
                    </div>
                </Flex>

                {actions && <Flex align="center">{actions}</Flex>}
            </Flex>
        </Card>
    );
};

export default TaskDeliverableListViewListItem;
