import { Button, Classes, Colors, InputGroup, Intent, Position } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilterWithDynamicSearch from '@components/PlatformListFilterWithDynamicSearch';
import { platformFiltersInformation } from '@pages/TaskClientFeedback/consts';
import { PlatformTaskClientFeedbackFilterType } from '@pages/TaskClientFeedback/enums';
import { fetchTeamData } from '@pages/TaskClientFeedback/store/effects';
import { setPlatformTaskClientFeedbackFilterData } from '@pages/TaskClientFeedback/store/events';
import { $isQueryHydrationFinished } from '@pages/TaskClientFeedback/store/states';
import { PlatformTaskClientFeedbackFilterData } from '@pages/TaskClientFeedback/types';
import { TeamResource } from 'dy-frontend-http-repository/lib/modules/Filter/resources';
import { useStore } from 'effector-react';
import React, { useEffect, useRef, useState } from 'react';
import DevText from '@components/Text';

export interface TeamsPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskClientFeedbackData: PlatformTaskClientFeedbackFilterData;
    onRemove: () => void;
}

export type Props = TeamsPlatformFilterProps;

const TeamsPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskClientFeedbackData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [query, setQuery] = useState('');
    const [isCoreUserTeamsSearching, setIsCoreUserTeamsSearching] = useState(false);
    const [selectedCoreUserTeams, setSelectedCoreUserTeams] = useState<PlatformTaskClientFeedbackFilterData['teams']>(
        []
    );
    const [foundCoreUserTeams, setFoundCoreUserTeams] = useState<PlatformTaskClientFeedbackFilterData['teams']>([]);

    const searchInputRef = useRef<null | HTMLInputElement>(null);

    useEffect(() => {
        if (query.trim().length === 0) {
            setFoundCoreUserTeams([]);
        }
    }, [query]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setSelectedCoreUserTeams(platformTaskClientFeedbackData.teams);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (query.trim().length !== 0) {
            fetchTeamData({ query, exclude_id: selectedCoreUserTeams.map((team) => team.id) }).then((teams) =>
                setFoundCoreUserTeams(teams.items)
            );
        }

        handleFocusInput();

        // eslint-disable-next-line
    }, [selectedCoreUserTeams]);

    useEffect(() => {
        setSelectedCoreUserTeams(platformTaskClientFeedbackData.teams);
        setFoundCoreUserTeams([]);
    }, [platformTaskClientFeedbackData.teams]);

    const getFilterTagTitle = () => {
        return selectedCoreUserTeams.length > 0 ? 'Teams:' : 'Teams';
    };

    const getFilterTagLabel = () => {
        if (selectedCoreUserTeams.length === 0) return '';

        return selectedCoreUserTeams
            .map((team, index) => {
                if (index === selectedCoreUserTeams.length - 1) {
                    // Last element
                    return `${team.title}`;
                }

                return `${team.title}, `;
            })
            .join('');
    };

    const handleRemoveFilter = () => {
        if (platformTaskClientFeedbackData.teams.length !== 0) {
            // Reset core user teams to empty array
            setPlatformTaskClientFeedbackFilterData({ ...platformTaskClientFeedbackData, teams: [] });
        }

        if (!platformFiltersInformation[PlatformTaskClientFeedbackFilterType.TEAMS].isPinned) {
            // Platform core user teams filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleSearch = (searchValue: string = query) => {
        setIsCoreUserTeamsSearching(true);

        if (searchValue.trim().length !== 0) {
            // Search value is NOT empty string

            // Fetch users matched by searchValue
            fetchTeamData({
                query: searchValue,
                exclude_id: selectedCoreUserTeams.map((team) => team.id),
            })
                .then((teams) => setFoundCoreUserTeams(teams.items))
                .finally(() => setTimeout(() => setIsCoreUserTeamsSearching(false), 1000));
        }
    };

    const handleAddCoreUserTeam = (newTeam: TeamResource) => {
        if (!selectedCoreUserTeams.find((team) => team.id === newTeam.id)) {
            // Team user with such ID was not added yet
            setSelectedCoreUserTeams([newTeam, ...selectedCoreUserTeams]);
        }
    };

    const handleRemoveCoreUserTeam = (id: ID) => {
        setSelectedCoreUserTeams(selectedCoreUserTeams.filter((team) => team.id !== id));
    };

    const handleFocusInput = () => {
        if (searchInputRef.current !== null) {
            // Focus input
            searchInputRef.current.focus();
        }
    };

    const handleApplyFilter = () => {
        if (JSON.stringify(selectedCoreUserTeams) !== JSON.stringify(platformTaskClientFeedbackData.teams)) {
            // If nothing changed since opening the filter then nothing apply to
            setPlatformTaskClientFeedbackFilterData({
                ...platformTaskClientFeedbackData,
                teams: selectedCoreUserTeams,
            });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);
        setQuery('');

        if (platformFiltersInformation[PlatformTaskClientFeedbackFilterType.TEAMS].isPinned) {
            return;
        }

        if (selectedCoreUserTeams.length === 0) {
            onRemove();
        }
    };

    const renderSearchInput = () => {
        return (
            <InputGroup
                leftIcon="search"
                placeholder="Search for team"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                inputRef={searchInputRef}
            />
        );
    };

    const renderSkeletonRow = (id: ID) => {
        return (
            <li key={id}>
                <Flex justify="space-between" align="center">
                    <Flex>
                        <DevText className={Classes.SKELETON}>Title for team</DevText>
                    </Flex>

                    <Button minimal icon="cross" className={Classes.SKELETON} intent={Intent.DANGER} />
                </Flex>
            </li>
        );
    };

    const renderSelectedCoreUserTeamRow = ({ id, title, color }: TeamResource) => {
        return (
            <li key={id}>
                <Flex justify="space-between" align="center">
                    <Flex align="center">
                        <Circle className="mr-1" size="14px" color={color} />
                        <DevText>{title}</DevText>
                    </Flex>

                    <Button minimal icon="cross" intent={Intent.DANGER} onClick={() => handleRemoveCoreUserTeam(id)} />
                </Flex>
            </li>
        );
    };

    const renderFoundCoreUserTeamRow = ({ id, title, color }: TeamResource) => {
        return (
            <li key={id}>
                <Flex justify="space-between" align="center">
                    <Flex align="center">
                        <Circle className="mr-1" size="14px" color={color} />
                        <DevText>{title}</DevText>
                    </Flex>

                    <Button
                        minimal
                        icon="plus"
                        intent={Intent.SUCCESS}
                        onClick={() =>
                            handleAddCoreUserTeam({
                                id,
                                title,
                                color,
                            })
                        }
                    />
                </Flex>
            </li>
        );
    };

    const renderEmptyList = () => {
        return (
            <div>
                <Flex className="mb-1" align="center">
                    <Circle className="mr-1" size="16px" color={Colors.GRAY2} />
                    <DevText>No results</DevText>
                </Flex>
                <DevText muted>Please try different search query</DevText>
            </div>
        );
    };

    return (
        <PlatformListFilterWithDynamicSearch<TeamResource>
            loading={isCoreUserTeamsSearching && query.trim().length > 0}
            isOpen={isOpen}
            removable={selectedCoreUserTeams.length > 0}
            title="Teams"
            position={Position.BOTTOM_LEFT}
            search={query}
            maxListHeight={318}
            selectedList={selectedCoreUserTeams}
            foundList={foundCoreUserTeams}
            skeletonList={Array.from({ length: 3 }, (_, index) => `${index + 1}`)}
            selectedItemRenderer={renderSelectedCoreUserTeamRow}
            foundItemRenderer={renderFoundCoreUserTeamRow}
            emptyListRenderer={renderEmptyList}
            searchInputRenderer={renderSearchInput}
            skeletonItemRenderer={renderSkeletonRow}
            onSearch={handleSearch}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
            onOpened={handleFocusInput}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskClientFeedbackFilterType.TEAMS].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilterWithDynamicSearch>
    );
};

export default TeamsPlatformFilter;
