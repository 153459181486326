import repository from 'dy-frontend-http-repository/lib/repository';
import {
    ClientUserListItemResource,
    ClientUserResource,
} from 'dy-frontend-http-repository/lib/modules/ClientUser/resources';
import {
    ClientUserFilterInput,
    CreateClientUserInput,
} from 'dy-frontend-http-repository/lib/modules/ClientUser/inputs';
import { ClientUserRef } from 'dy-frontend-http-repository/lib/modules/ClientUser/refs';
import { OffsetPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { CollectionResource } from 'dy-frontend-http-repository/lib/data/resources';
import domain from './domain';
import {
    HydrateFilterDataFilterInput,
    PlanFilterDataFilterInput,
    StaticDataFilterInput,
    WorkspaceFilterDataFilterInput,
} from 'dy-frontend-http-repository/lib/modules/Filter/inputs';
import { HydratedFilterResource, StaticFilterDataResource } from 'dy-frontend-http-repository/lib/modules/Filter/resources';
import { WorkspaceResource, PlanResource } from 'dy-frontend-http-repository/lib/modules/Filter/resources';

// Fetch clients list
export const fetchClients = domain.createEffect<
    { pagination: OffsetPaginationInput; filter?: ClientUserFilterInput },
    CollectionResource<ClientUserListItemResource, {}>
>({
    name: 'fetchClients',
    handler: async ({ pagination, filter }) => {
        return await repository.clientUser().get(pagination, filter);
    },
});

// Fetch client
export const fetchClient = domain.createEffect<ID, ClientUserResource>({
    name: 'fetchClient',
    handler: async (clientId) => {
        return await repository.clientUser().getById(clientId);
    },
});

// Create client
export const createClient = domain.createEffect<CreateClientUserInput, ClientUserRef>({
    name: 'createClient',
    handler: async (input) => {
        return await repository.clientUser().create(input);
    },
});

// Hydrate query parameters
export const hydrateQueryParameters = domain.createEffect<HydrateFilterDataFilterInput, HydratedFilterResource>({
    name: 'hydrateQueryParameters',
    handler: async (input) => {
        return await repository.filter().hydrateData(input);
    },
});

// Fetch workspace data
export const fetchWorkspaceData = domain.createEffect<
    WorkspaceFilterDataFilterInput,
    CollectionResource<WorkspaceResource, {}>
>({
    name: 'fetchWorkspaceData',
    handler: async (input) => {
        return await repository.filter().getWorkspaceData(input);
    },
});

// Fetch plan data
export const fetchPlanData = domain.createEffect<PlanFilterDataFilterInput, CollectionResource<PlanResource, {}>>({
    name: 'fetchPlanData',
    handler: async (input) => {
        return await repository.filter().getPlanData(input);
    },
});

// Fetch plan data
export const fetchStaticData = domain.createEffect<
    StaticDataFilterInput,
    StaticFilterDataResource
>({
    name: 'fetchStaticData',
    handler: async (filter) => {
        return await repository.filter().getStaticData(filter);
    },
});
