import { CursorPaginationInput } from 'dy-frontend-http-repository/lib/data/inputs';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import { NotificationListResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { DismissNotificationListInput } from 'dy-frontend-http-repository/lib/modules/Notification/inputs';
import domain from './domain';

// Fetch task message list
export const fetchNotifications = domain.createEffect<{ pagination: CursorPaginationInput }, NotificationListResource>({
    name: 'fetchNotifications',
    handler: async ({ pagination }) => {
        return await repository.notification().get(pagination);
    },
});

// Dismiss single notification
export const dismissNotification = domain.createEffect<DismissNotificationListInput, void>({
    name: 'dismissNotification',
    handler: async (input) => {
        return await repository.notification().dismiss({ ...input, all: false });
    },
});

// Dismiss all notifications
export const dismissAllNotifications = domain.createEffect<void, void>({
    name: 'dismissNotification',
    handler: async () => {
        return await repository.notification().dismiss({ ids: [], all: true });
    },
});
