import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { Checkbox, Position } from '@blueprintjs/core';
import { PlatformTaskFilterData } from '@pages/Tasks/types';
import { platformFiltersInformation } from '@pages/Tasks/consts';
import { PlatformTaskFilterType } from '@pages/Tasks/enums';
import { $isQueryHydrationFinished } from '@pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@pages/Tasks/store/events';
import PlatformFilterButton from '@components/PlatformFilterButton';
import PlatformListFilter from '@components/PlatformListFilter';
import { fetchStaticData } from '@app/containers/pages/Tasks/store/effects';

export interface TaskPlansPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskPlansPlatformFilterProps;

const TaskPlansPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isPlanInitialized, setIsPlansInitialized] = useState(false);
    const [selectedPlans, setSelectedPlans] = useState<PlatformTaskFilterData['plans']>([]);
    const [foundPlans, setFoundPlans] = useState<PlatformTaskFilterData['plans']>([]);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            fetchStaticData({ plan: '1' }).then((staticData) => {
                setFoundPlans(staticData.plan);
                setSelectedPlans(
                    staticData.plan.filter((sp) => platformTaskFilterData.plans.find((p) => p.id === sp.id))
                );
                setTimeout(() => setIsPlansInitialized(true), 0);
            });
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.plans.length === 0) {
            setSelectedPlans([]);
        }
    }, [platformTaskFilterData.plans]);

    const getFilterTagLabel = () => {
        if (selectedPlans.length === 0) return '';

        return selectedPlans
            .map((p, index) => {
                if (index === selectedPlans.length - 1) {
                    // Last element
                    return `${p.title}`;
                }

                return `${p.title}, `;
            })
            .join('');
    };

    const getFilterTagTitle = () => {
        if (selectedPlans.length > 0) {
            // At least 1 plan selected
            return 'Client plan:';
        }

        return 'Client plan';
    };

    const handleRemoveFilter = () => {
        if (selectedPlans.length !== 0) {
            // At least 1 plan was selected

            setSelectedPlans([]);

            if (platformTaskFilterData.plans.length !== 0) {
                // Reset plan to empty array
                setPlatformTaskFilterData({ ...platformTaskFilterData, plans: [] });
            }

            setIsOpen(false);
        }

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_PLANS].isPinned) {
            // Platform plan filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleCheckboxClick = (plan: PlatformTaskFilterData['plans'][number]) => {
        if (!!selectedPlans.find((selectedPlan) => selectedPlan.id === plan.id)) {
            // Plan is already checked
            setSelectedPlans(selectedPlans.filter((selectedPlan) => selectedPlan.id !== plan.id));
        } else {
            // Plan is NOT checked yet
            setSelectedPlans([...selectedPlans, plan]);
        }
    };

    const handleApplyFilter = () => {
        if (selectedPlans.length !== platformTaskFilterData.plans.length) {
            // If task plans array length didn't change since opening the filter then nothing apply to
            setPlatformTaskFilterData({ ...platformTaskFilterData, plans: selectedPlans });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_PLANS].isPinned) {
            return;
        }

        if (selectedPlans.length === 0) {
            onRemove();
        }
    };

    const renderRow = (plan: PlatformTaskFilterData['plans'][number]) => {
        return (
            <li className="mb-1" key={plan.id}>
                <Checkbox
                    checked={!!selectedPlans.find((selectedPlan) => selectedPlan.id === plan.id)}
                    className="mr-2"
                    label={plan.title}
                    onClick={() => handleCheckboxClick(plan)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<PlatformTaskFilterData['plans'][number]>
            isOpen={isOpen}
            loading={!isPlanInitialized}
            removable={selectedPlans.length > 0}
            title="Client plan"
            position={Position.BOTTOM_LEFT}
            minWidth={320}
            maxListHeight={400}
            list={foundPlans}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_PLANS].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskPlansPlatformFilter;
