import styled, { css } from 'styled-components';

export const Iframe = styled.iframe<{ $isVisible: boolean }>`
    width: 100%;
    height: 100%;
    border: none;

    ${({ $isVisible }) => css`
        visibility: ${$isVisible ? 'visible' : 'hidden'};
    `}
`;
