import Flex from '@app/components/Flex';
import PageContainer from '@app/components/PageContainer';
import { Links } from '@app/data/enums';
import { usePageTitle } from '@app/hooks';
import { Spinner } from '@blueprintjs/core';
import React, { useState } from 'react';
import { Iframe } from './styled';

const LazyClientStatistics: React.FC = () => {
    usePageTitle('Client Overview');

    const [isLoaded, setIsLoaded] = useState(false);

    const renderSpinner = () => {
        if (isLoaded) {
            // Already loaded
            return null;
        }

        return (
            <Flex align="center" justify="center">
                <Spinner />
            </Flex>
        );
    };

    const renderClientStatistics = () => {
        return <Iframe $isVisible={isLoaded} src={Links.AIRTABLE_LINK} onLoad={() => setIsLoaded(true)} />;
    };

    return (
        <PageContainer>
            {renderSpinner()}
            {renderClientStatistics()}
        </PageContainer>
    );
};

export default LazyClientStatistics;
