import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { Radio, Position } from '@blueprintjs/core';
import { $isQueryHydrationFinished } from '@app/containers/pages/Clients/store/states';
import { PlatformClientUserFilterData } from '@app/containers/pages/Clients/types';
import { setPlatformClientUserFilterData } from '@app/containers/pages/Clients/store/events';
import {
    initialPlatformClientUserFilterData,
    platformClientUserFiltersInformation,
} from '@app/containers/pages/Clients/consts';
import { PlatformClientUserFilterType } from '@app/containers/pages/Clients/enums';

export interface ClientUserVerifiedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformClientUserFilterData: PlatformClientUserFilterData;
    onRemove: () => void;
}

export type Props = ClientUserVerifiedPlatformFilterProps;

const ClientUserVerifiedPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformClientUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isOnlyVerifiedVisible, setIsOnlyVerifiedVisible] = useState<NonNullable<
        PlatformClientUserFilterData['isVerified']
    > | null>(platformClientUserFilterData.isVerified);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsOnlyVerifiedVisible(platformClientUserFilterData.isVerified);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformClientUserFilterData.isVerified === null) {
            setIsOnlyVerifiedVisible(null);
        }
    }, [platformClientUserFilterData.isVerified]);

    const getFilterTagLabel = () => {
        if (isOnlyVerifiedVisible === null) {
            return '';
        }

        if (isOnlyVerifiedVisible) {
            return 'Only verified accounts';
        } else {
            return 'Only unverified accounts';
        }
    };

    const getFilterTagTitle = () => {
        if (isOnlyVerifiedVisible === null) {
            return 'Verified';
        }

        return 'Verified:';
    };

    const handleRemoveFilter = () => {
        setIsOnlyVerifiedVisible(null);
        setPlatformClientUserFilterData({
            ...platformClientUserFilterData,
            isVerified: initialPlatformClientUserFilterData.isVerified,
        });
        setIsOpen(false);
        if (!platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_VERIFIED].isPinned) {
            // Platform client user verified filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformClientUserFilterData.isVerified !== isOnlyVerifiedVisible) {
            setPlatformClientUserFilterData({ ...platformClientUserFilterData, isVerified: isOnlyVerifiedVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_VERIFIED].isPinned) {
            return;
        }

        if (isOnlyVerifiedVisible === null) {
            onRemove();
        }
    };

    const renderRow = (key: number) => {
        return (
            <li key={key}>
                <Radio
                    className="mb-1"
                    checked={isOnlyVerifiedVisible !== null && isOnlyVerifiedVisible}
                    label="Only verified accounts"
                    onChange={() => setIsOnlyVerifiedVisible(true)}
                />

                <Radio
                    checked={isOnlyVerifiedVisible !== null && !isOnlyVerifiedVisible}
                    label="Only unverified accounts"
                    onChange={() => setIsOnlyVerifiedVisible(false)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Verified"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_VERIFIED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default ClientUserVerifiedPlatformFilter;
