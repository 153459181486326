import React, { HTMLAttributes } from 'react';
import { Classes } from '@blueprintjs/core';

export interface TextProps {
    // Display as <span>
    inline?: boolean;

    // Renders with monospace font
    monospace?: boolean;

    // Increases line height slightly to increase readability in paragraphs
    running?: boolean;

    // Increases font size
    large?: boolean;

    // Decreases font size
    small?: boolean;

    // Changes color to light gray
    muted?: boolean;

    // Changes color
    disabled?: boolean;

    // Truncates text automatically to fit in single line
    ellipsis?: boolean;

    // Changes alignment
    align?: 'start' | 'center' | 'end';

    // Sets custom text color
    color?: string;

    multiline?: boolean;
}

export type Props = HTMLAttributes<HTMLElement> & TextProps;

const DevText: React.FC<Props> = ({
    className,
    children,
    inline = false,
    monospace = false,
    running = false,
    large = false,
    small = false,
    muted = false,
    disabled = false,
    ellipsis = false,
    multiline = true,
    align,
    color,
    style,
    ...props
}) => {
    const classes = `
        ${monospace ? Classes.MONOSPACE_TEXT : ''}
        ${running ? Classes.RUNNING_TEXT : ''}
        ${large ? Classes.TEXT_LARGE : ''}
        ${small ? Classes.TEXT_SMALL : ''}
        ${muted ? Classes.TEXT_MUTED : ''}
        ${disabled ? Classes.TEXT_DISABLED : ''}
        ${ellipsis ? Classes.TEXT_OVERFLOW_ELLIPSIS : ''}
        ${className ?? ''}
    `;

    const styles: React.CSSProperties = {
        ...style,
        textAlign: align,
        color: color,
        whiteSpace: multiline ? 'pre-wrap' : 'normal',
    };

    if (inline) {
        return (
            <span className={classes} style={styles} {...props}>
                {children}
            </span>
        );
    }

    return (
        <p className={classes} style={styles} {...props}>
            {children}
        </p>
    );
};

export default DevText;
