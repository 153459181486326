import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = (scrollOnLoad = false, containerClassName = 'main-content') => {
    const { pathname } = useLocation();

    const handleScrollToTop = () => {
        const container = document.querySelector(`.${containerClassName}`);
        if (container) {
            container.scroll({
                top: 0,
                left: 0,
            });
        }
    };

    useEffect(() => {
        if (!scrollOnLoad) {
            return;
        }

        handleScrollToTop();
    }, [scrollOnLoad, pathname, containerClassName]);

    return {
        scrollToTop: handleScrollToTop,
    };
};

export default useScrollToTop;
