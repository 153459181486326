import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';
import { repository } from 'dy-frontend-http-repository/lib/modules';
import App from './App';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/icons/lib/css/blueprint-icons.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@blueprintjs/datetime2/lib/css/blueprint-datetime2.css';
import '@blueprintjs/select/lib/css/blueprint-select.css';
import '@app/styles/css/flexboxgrid.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import '@containers/store/init';

Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.NODE_ENV,
    maxBreadcrumbs: parseInt(process.env.SENTRY_MAX_BREADCRUMBS ?? '15'),
    tracesSampleRate: 0,
    integrations: [browserTracingIntegration()],
});

repository.addAxiosResponseInterceptor({
    onFail: (error) => {
        Sentry.captureException(error);
    },
});

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root') as HTMLElement
);
