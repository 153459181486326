import { $taskDeliverablesTree } from '@app/containers/pages/Task/store/states';
import { useForm, useRichTextFormField, useTextFormField } from '@app/hooks';
import {
    getRichStringRequiredValidator,
    getStringMaxLengthValidator,
    getStringMinLengthValidator,
    getStringRequiredValidator,
} from '@app/hooks/validation/functions';
import { Button, Card, Divider, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import InputFormField from '@components/InputFormField';
import Overlay from '@components/Overlay';
import RichEditorFormField from '@components/RichEditorFormField';
import { ModalProps } from '@modals/types';
import { useStore } from 'effector-react';
import React from 'react';
import { useCreateTextTaskDeliverable } from '../../../../hooks';
import { $currentPathLocation } from '../../../../store/states';

export interface CreateTaskDeliverableTextModalProps {
    taskId: ID;
}

type Props = ModalProps<CreateTaskDeliverableTextModalProps>;

const taskDeliverableTitleValidators = [
    getStringRequiredValidator(),
    getStringMinLengthValidator({ minStringLength: 5 }),
    getStringMaxLengthValidator({ maxStringLength: 100 }),
];
const taskDeliverableContentValidators = [getRichStringRequiredValidator()];

const CreateTaskDeliverableTextModal: React.FC<Props> = ({ closeModal, data }) => {
    const { createTextTaskDeliverable } = useCreateTextTaskDeliverable();

    const currentPathLocation = useStore($currentPathLocation);
    const taskDeliverableTree = useStore($taskDeliverablesTree);

    const taskDeliverableTitle = useTextFormField({
        id: 'task-deliverable-title',
        validators: taskDeliverableTitleValidators,
        initialValue: '',
    });

    const taskDeliverableContent = useRichTextFormField({
        id: 'task-deliverable-content',
        validators: taskDeliverableContentValidators,
        initialValue: '',
    });

    const form = useForm({
        fields: [taskDeliverableTitle, taskDeliverableContent],
        apiCall: async () => {
            try {
                await createTextTaskDeliverable({
                    path: currentPathLocation,
                    taskId: data!.taskId,
                    textContent: taskDeliverableContent.value,
                    title: taskDeliverableTitle.value,
                });

                return { response: true };
            } catch (e) {
                throw e;
            }
        },
        onSuccess: () => {
            closeModal?.();
        },
        onFailure: (error) => {
            // TODO: handle error
            console.error(error);
        },
    });

    if (!data) {
        closeModal?.();
        return null;
    }

    if (!taskDeliverableTree) {
        return null;
    }

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">Create task deliverable text</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={form.handleFormSubmit}>
                    <InputFormField
                        field={taskDeliverableTitle}
                        formGroupProps={{ label: 'Title' }}
                        inputProps={{ placeholder: 'Enter title' }}
                    />

                    <RichEditorFormField
                        field={taskDeliverableContent}
                        formGroupProps={{ label: 'Content' }}
                        richEditorProps={{ placeholder: 'Enter content' }}
                    />

                    <Flex justify="flex-end">
                        <Button className="mr-1" outlined onClick={closeModal}>
                            Cancel
                        </Button>

                        <Button
                            disabled={form.hasFieldErrors}
                            loading={form.isSubmitting}
                            type="submit"
                            intent={Intent.PRIMARY}
                        >
                            Create
                        </Button>
                    </Flex>
                </form>
            </Card>
        </Overlay>
    );
};

export default CreateTaskDeliverableTextModal;
