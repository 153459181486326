import { Button, Card, Checkbox, Divider, Elevation, FormGroup, Icon, Intent } from '@blueprintjs/core';
import { TimePicker } from '@blueprintjs/datetime';
import { DateInput3 } from '@blueprintjs/datetime2';
import Flex from '@components/Flex';
import Grid from '@components/Grid';
import Heading from '@components/Heading';
import Overlay from '@components/Overlay';
import DevText from '@components/Text';
import { ModalProps } from '@modals/types';
import { taskApi } from '@pages/Task/store/apis';
import { removeTaskDeadline, setTaskDeadline } from '@pages/Task/store/effects';
import { TimezoneUtils } from 'dy-frontend-shared/lib/utils';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

export interface SetTaskDeadlineModalProps {
    taskId: ID;
    workerTimezone: string;
    clientTimezone: string;
    deadlineTimezone: string;
    deliveryDays: number;
    deadlineValue: string | null;
    onSetDeadline?: () => void;
}

type Props = ModalProps<SetTaskDeadlineModalProps>;

/*
 TODO: check what they were sending to onDeadlinePick, onClose, onRemove callbacks on admin platform

 onDeadlinePick is a regular effect call, which we do in handleFormSubmit function
 onClose just sets set for isOpen to false
 onRemove is a regular effect call to remove current deadline
*/

const transitionDurationMS = 100;

const SetTaskDeadlineModal: React.FC<Props> = ({ closeModal, data }) => {
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);

    const [title, setTitle] = useState('Set new deadline');
    const [applyButtonText, setApplyButtonText] = useState('Apply deadline');
    const [step, setStep] = useState(1);
    const [editingMode, setEditingMode] = useState<null | string>(null);

    const [isEndOfTheDayTouched, setIsEndOfTheDayTouched] = useState(false);
    const [isEndOfTheDay, setIsEndOfTheDay] = useState(true);
    const [isDeadlineRemoving, setIsDeadlineRemoving] = useState(false);

    // IMPORTANT - Always convert to worker local timezone before setting date,
    // because js DATE obj toString() function always shows time in local time :)
    const [date, setDate] = useState<Date | null>(null);

    console.log('date: ', date);
    console.log('workerTimezone: ', data?.workerTimezone);
    console.log('clientTimezone: ', data?.clientTimezone);

    useEffect(() => {
        if (data!.deadlineValue !== null) {
            if (data!.deadlineTimezone.trim().length > 0) {
                if (data!.deadlineTimezone === data!.clientTimezone) {
                    setEditingMode('client');
                } else {
                    setEditingMode('worker');
                }
            } else {
                setEditingMode('worker');
            }
        }

        // eslint-disable-next-line
    }, [data!.deadlineTimezone]);

    useEffect(() => {
        if (data!.deadlineValue !== null) {
            let timezoneName = data!.workerTimezone;
            if (editingMode === 'client') {
                if (data!.clientTimezone.trim().length > 0) {
                    timezoneName = data!.clientTimezone;
                }
            }

            const dateWithClientTimezone = TimezoneUtils.getMomentInTimezone(data!.deadlineValue, timezoneName);

            let isEndOfTheDayToSet = false;
            if (dateWithClientTimezone.hours() === 23 && dateWithClientTimezone.minutes() === 59) {
                isEndOfTheDayToSet = true;
            }

            setTimeout(() => {
                setStep(3);
                setIsEndOfTheDay(isEndOfTheDayToSet);

                // TODO: check this one
                if (data!.deadlineValue) {
                    setDate(
                        new Date(
                            TimezoneUtils.getMomentInTimezone(data!.deadlineValue, data!.workerTimezone).format(
                                'YYYY-MM-DDTHH:mm:ss'
                            )
                        )
                    );
                } else {
                    setDate(null);
                }

                setTitle('Change current deadline');
                setApplyButtonText('Update deadline');
            }, transitionDurationMS * 2);
        }

        // eslint-disable-next-line
    }, [data!.deadlineValue]);

    useEffect(() => {
        if (editingMode !== null) {
            // 1st step was completed
            if (date === null && data!.clientTimezone.trim().length > 0) {
                calculateDeadlineAtDate(data!.clientTimezone);
            } else {
                // NOT first time changing editingMode (1st. step completed)
                if (data!.clientTimezone.trim().length === 0) {
                    calculateDeadlineAtDate(data!.workerTimezone);
                    return;
                }

                const dateWithClientTimezone = TimezoneUtils.getMomentInTimezone(
                    date ?? new Date(),
                    editingMode === 'client' ? data!.clientTimezone : data!.workerTimezone
                );

                if (dateWithClientTimezone.hours() === 23 && dateWithClientTimezone.minutes() === 59) {
                    setIsEndOfTheDay(true);
                } else {
                    setIsEndOfTheDay(false);
                }
            }
        }

        // eslint-disable-next-line
    }, [editingMode]);

    useEffect(() => {
        if (isEndOfTheDayTouched && date !== null) {
            if (isEndOfTheDay) {
                const dateWithTimezone = TimezoneUtils.getMomentInTimezone(
                    date,
                    editingMode === 'client' ? data!.clientTimezone : data!.workerTimezone
                );
                dateWithTimezone.set('hours', 23);
                dateWithTimezone.set('minutes', 59);
                setDate(
                    new Date(
                        TimezoneUtils.getMomentInTimezone(dateWithTimezone.format(), data!.workerTimezone).format(
                            'YYYY-MM-DDTHH:mm:ss'
                        )
                    )
                );
            }
        }

        // eslint-disable-next-line
    }, [isEndOfTheDay]);

    if (!data) {
        closeModal?.();
        return null;
    }

    const handleClosePicker = (
        newStep: number,
        newDate: Date | null,
        newIsEndOfTheDay: boolean,
        newEditingMode: string | null
    ) => {
        closeModal?.();

        setTimeout(() => {
            setDate(newDate);
            setStep(newStep);
            setEditingMode(newEditingMode);
            setIsEndOfTheDay(newIsEndOfTheDay);
        }, transitionDurationMS * 2);
    };

    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const deadlineAt = moment.utc(date).format();
        const deadlineTimezoneName = editingMode === 'client' ? data.clientTimezone : data.workerTimezone;

        setIsFormSubmitting(true);

        try {
            await setTaskDeadline({
                taskId: data.taskId,
                input: { deadline_at: deadlineAt, deadline_timezone_name: deadlineTimezoneName },
            });

            taskApi.setDeadline({ deadline_at: deadlineAt, deadline_timezone_name: deadlineTimezoneName });

            closeModal?.();
            data.onSetDeadline?.();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        } finally {
            setIsFormSubmitting(false);
        }
    };

    const handleRemoveDeadline = async () => {
        // TODO: here have to check is deadline already present or not and only then allow to remove it
        if (data.deadlineValue) {
            setIsRemoving(true);

            try {
                await removeTaskDeadline(data.taskId);
                taskApi.removeDeadline();
                closeModal?.();
            } catch (e) {
                // TODO: handle error
                console.error(e);
            } finally {
                setIsFormSubmitting(false);
            }
        }
    };

    const handleChangeTimeInput = (newDate: Date | null) => {
        if (newDate === null) return;

        let dateToSet = newDate;

        if (editingMode === 'client') {
            const [hoursDifference, minutesDifference] = TimezoneUtils.getHoursAndMinutesDifference(
                data.workerTimezone,
                data.clientTimezone
            );

            const momentDateToSet = moment(dateToSet);

            momentDateToSet.add(hoursDifference, 'hours');
            momentDateToSet.add(minutesDifference, 'minutes');

            dateToSet = new Date(momentDateToSet.format('YYYY-MM-DDTHH:mm:ss'));
        }

        // check If date in the past
        // if (
        //     dateToSet <
        //     new Date(
        //         getNowInTimezone(editingMode === 'client' ? clientTimezone : workerTimezone).format(
        //             'YYYY-MM-DDTHH:mm:ss'
        //         )
        //     )
        // ) {
        //     setDate(new Date(getMomentInTimezone(moment().format(), workerTimezone).format('YYYY-MM-DDTHH:mm:ss')));
        //     return;
        // }

        setDate(dateToSet);
    };

    const handleChangeDateInput = (newDateString: string | null) => {
        if (newDateString === null) return;

        let dateToSet = new Date(newDateString);

        if (editingMode === 'client') {
            const [hoursDifference, minutesDifference] = TimezoneUtils.getHoursAndMinutesDifference(
                data.workerTimezone,
                data.clientTimezone
            );

            const momentDateToSet = moment(dateToSet);

            momentDateToSet.add(hoursDifference, 'hours');
            momentDateToSet.add(minutesDifference, 'minutes');

            dateToSet = new Date(momentDateToSet.format('YYYY-MM-DDTHH:mm:ss'));
        }

        // check If date in the past
        // if (
        //     dateToSet <
        //     new Date(
        //         getNowInTimezone(editingMode === 'client' ? clientTimezone : workerTimezone).format(
        //             'YYYY-MM-DDTHH:mm:ss'
        //         )
        //     )
        // ) {
        //     setDate(new Date(getMomentInTimezone(moment().format(), workerTimezone).format('YYYY-MM-DDTHH:mm:ss')));
        //     return;
        // }

        if (date) {
            dateToSet.setHours(date.getHours());
            dateToSet.setMinutes(date.getMinutes());
        }

        setDate(dateToSet);
    };

    const getDateValue = (newDate: Date) => {
        let timezoneName = '';
        if (editingMode === 'client') {
            // Client editing mode
            if (data.clientTimezone) {
                timezoneName = data.clientTimezone;
            } else {
                timezoneName = data.workerTimezone;
            }
        } else {
            // Worker editing mode
            timezoneName = data.workerTimezone;
        }

        return new Date(TimezoneUtils.getMomentInTimezone(newDate, timezoneName).format('YYYY-MM-DDTHH:mm:ss'));
    };

    const calculateDeadlineAtDate = (timezoneName: string) => {
        // First time changing editingMode (1st. step completed)
        let daysToDeliver = data.deliveryDays;
        let currDay = TimezoneUtils.getDateInTimezone(timezoneName);

        while (daysToDeliver > 0) {
            if (currDay.weekday() !== 0 && currDay.weekday() !== 6) {
                // Not Saturday or Sunday
                daysToDeliver -= 1;
            }
            currDay = currDay.add(1, 'days');
        }

        let deliveryDate = currDay;

        while (deliveryDate.weekday() === 0 || deliveryDate.weekday() === 6) {
            deliveryDate = deliveryDate.add(1, 'days');
        }

        if (editingMode === 'worker') {
            const deliveryDateWithWorkerTimezone = TimezoneUtils.getMomentInTimezone(
                deliveryDate.format(),
                data.workerTimezone
            );
            if (deliveryDateWithWorkerTimezone.date() < deliveryDate.date()) {
                deliveryDateWithWorkerTimezone.add(1, 'days');
            }
            deliveryDate = deliveryDateWithWorkerTimezone;
        }

        deliveryDate.set('hours', 23);
        deliveryDate.set('minutes', 59);

        if (editingMode === 'worker') {
            setDate(
                new Date(
                    TimezoneUtils.getMomentInTimezone(deliveryDate.format(), data.workerTimezone).format(
                        'YYYY-MM-DDTHH:mm:ss'
                    )
                )
            );
        } else if (editingMode === 'client') {
            setDate(
                new Date(
                    TimezoneUtils.getMomentInTimezone(deliveryDate.format(), data.clientTimezone).format(
                        'YYYY-MM-DDTHH:mm:ss'
                    )
                )
            );
        }
    };

    const renderSelectTimezoneStep = () => {
        const renderSelectTimezoneCard = (title: string, mode: string, timezone: string, disabled = false) => {
            let content;

            const styles: React.CSSProperties = {
                height: '100%',
            };

            if (editingMode !== null) {
                if (editingMode !== mode) {
                    styles.opacity = 0.3;
                }
            }

            if (disabled) {
                content = (
                    <>
                        <DevText className="mb-1">{title}</DevText>
                        <DevText muted>
                            <Icon className="mr-small" icon="warning-sign" /> Cannot determine timezone
                        </DevText>
                    </>
                );
            } else {
                content = (
                    <>
                        <DevText className="mb-1">{title}</DevText>
                        <DevText muted className="mb-1">
                            {TimezoneUtils.getTimezoneGMTValue(timezone)}
                        </DevText>
                        <DevText>Now: {TimezoneUtils.getDateInTimezone(timezone).format('MMM, Do HH:mm')}</DevText>
                    </>
                );
            }

            return (
                <Card
                    compact
                    interactive={!disabled}
                    elevation={Elevation.ONE}
                    style={{ ...styles }}
                    onClick={() => {
                        if (!disabled) {
                            setEditingMode(mode);
                            setStep(3);
                        }
                    }}
                >
                    {content}
                </Card>
            );
        };

        return (
            <>
                <Heading className="mb-small" type="h5">
                    1. Select timezone
                </Heading>

                <Grid container>
                    <Grid lg={6} xs={12}>
                        {renderSelectTimezoneCard('Your timezone', 'worker', data.workerTimezone)}
                    </Grid>

                    <Grid lg={6} xs={12}>
                        {renderSelectTimezoneCard(
                            "Client's timezone",
                            'client',
                            data.clientTimezone,
                            data.clientTimezone.trim().length === 0
                        )}
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderPickDateAndTimeStep = () => {
        // TODO: check should we really use maxDate, like we did it in previous versions of BlueprintJS, now it says it'll show 6 months since now
        const renderDateInput = () => {
            return (
                <FormGroup
                    label="Date"
                    helperText={data.deliveryDays > 0 ? `Recommended: ${data.deliveryDays} days` : undefined}
                >
                    <DateInput3
                        fill
                        highlightCurrentDay
                        placeholder="Pick deadline date"
                        disabled={step < 2}
                        value={date ? moment(getDateValue(date)).format() : null}
                        dayPickerProps={{ firstWeekContainsDate: 1 }}
                        formatDate={
                            (date) =>
                                TimezoneUtils.getMomentInTimezone(
                                    date,
                                    editingMode === 'client' ? data.clientTimezone : data.workerTimezone
                                ).format('MMM Do, YYYY (dddd)') //
                        }
                        parseDate={(str) => {
                            return new Date(str);
                        }}
                        onChange={handleChangeDateInput}
                    />
                </FormGroup>
            );
        };

        const renderTimeInput = () => {
            let content;

            if (isEndOfTheDay) {
                content = (
                    <Flex align="center">
                        <DevText muted>End of the day</DevText>
                    </Flex>
                );
            } else {
                content = (
                    <TimePicker
                        disabled={step < 2 || isEndOfTheDay}
                        value={date ? getDateValue(date) : null}
                        onChange={handleChangeTimeInput}
                    />
                );
            }

            return <FormGroup label="Time">{content}</FormGroup>;
        };

        return (
            <>
                <Heading className="mb-small" type="h5">
                    2. Pick date and time
                </Heading>

                <Grid container>
                    <Grid lg={6} xs={12}>
                        {renderDateInput()}
                    </Grid>

                    <Grid lg={6} xs={12}>
                        {renderTimeInput()}
                    </Grid>
                </Grid>

                <Checkbox
                    label="End of the day"
                    checked={isEndOfTheDay}
                    disabled={step < 2}
                    onClick={() => {
                        if (!isEndOfTheDayTouched) setIsEndOfTheDayTouched(true);
                        setIsEndOfTheDay((prevValue) => !prevValue);
                    }}
                />
            </>
        );
    };

    const renderReviewNewDeadlineStep = () => {
        let workerLabel;
        let clientLabel;

        const showClientReview = data.clientTimezone.trim().length > 0;

        if (date === null) {
            console.log('Am i here');
            workerLabel = clientLabel = <span>Not set</span>;
        } else if (showClientReview) {
            const dateWithWorkerTimezone = TimezoneUtils.getMomentInTimezone(date, data.workerTimezone);
            const dateWithClientTimezone = TimezoneUtils.getMomentInTimezone(date, data.clientTimezone);

            let withYear = false;
            let workerLabelFormatter = 'MMM Do (dddd), HH:mm';
            let clientLabelFormatter = 'MMM Do (dddd), HH:mm';

            // check if year differs
            if (dateWithWorkerTimezone.year() !== dateWithClientTimezone.year()) {
                withYear = true;
                workerLabelFormatter = 'MMM Do YYYY (dddd), HH:mm';
                clientLabelFormatter = 'MMM Do YYYY (dddd), HH:mm';
            }

            workerLabel = `${dateWithWorkerTimezone.format(workerLabelFormatter)}`;
            clientLabel = `${dateWithClientTimezone.format(clientLabelFormatter)}`;

            // if any time equals 23:59, replace HH:mm with "End of the day" label
            if (dateWithWorkerTimezone.hours() === 23 && dateWithWorkerTimezone.minutes() === 59) {
                workerLabel = `${dateWithWorkerTimezone.format(
                    `MMM Do ${withYear ? 'YYYY' : ''} (dddd),`
                )} End of the day`;
            }

            if (dateWithClientTimezone.hours() === 23 && dateWithClientTimezone.minutes() === 59) {
                clientLabel = `${dateWithClientTimezone.format(
                    `MMM Do ${withYear ? 'YYYY' : ''} (dddd),`
                )}  End of the day`;
            }
        } else {
            const dateWithWorkerTimezone = TimezoneUtils.getMomentInTimezone(date, data.workerTimezone);

            const withYear = false;
            const workerLabelFormatter = 'MMM Do (dddd), HH:mm';

            workerLabel = `${dateWithWorkerTimezone.format(workerLabelFormatter)}`;
            clientLabel = 'Cannot determine timezone';

            // if any time equals 23:59, replace HH:mm with "End of the day" label
            if (dateWithWorkerTimezone.hours() === 23 && dateWithWorkerTimezone.minutes() === 59) {
                workerLabel = `${dateWithWorkerTimezone.format(
                    `MMM Do ${withYear ? 'YYYY' : ''} (dddd),`
                )} End of the day`;
            }
        }

        return (
            <>
                <Heading className="mb-small" type="h5">
                    3. Review new deadline
                </Heading>

                <Grid container>
                    <Grid lg={6} xs={12}>
                        <DevText muted className="mb-small">
                            Your timezone
                        </DevText>
                        <DevText>{workerLabel}</DevText>
                    </Grid>
                    <Grid lg={6} xs={12}>
                        <DevText muted className="mb-small">
                            Client's timezone
                        </DevText>
                        <DevText>{clientLabel}</DevText>
                    </Grid>
                </Grid>
            </>
        );
    };

    const renderButtons = () => {
        const commonButtons = (
            <>
                <Button
                    className="mr-1"
                    outlined
                    onClick={() => {
                        let stepToSet;
                        let dateToSet;
                        let isEndOfTheDayToSet;
                        let editingModeToSet;

                        if (data.deadlineValue === null) {
                            stepToSet = 1;
                            dateToSet = null;
                            isEndOfTheDayToSet = true;
                            editingModeToSet = null;
                        } else {
                            stepToSet = 3;
                            dateToSet = date;
                            isEndOfTheDayToSet = isEndOfTheDay;

                            if (data.deadlineTimezone.length > 0) {
                                if (data.deadlineTimezone === data.clientTimezone) {
                                    editingModeToSet = 'client';
                                } else {
                                    editingModeToSet = 'worker';
                                }
                            } else {
                                editingModeToSet = null;
                            }
                        }

                        handleClosePicker(stepToSet, dateToSet, isEndOfTheDayToSet, editingModeToSet);
                    }}
                >
                    Cancel
                </Button>

                <Button
                    disabled={step < 3}
                    loading={isFormSubmitting || isDeadlineRemoving}
                    className="mr-1"
                    type="submit"
                    intent={Intent.PRIMARY}
                >
                    {applyButtonText}
                </Button>
            </>
        );

        if (data.deadlineValue) {
            return (
                <Flex justify="space-between">
                    <Button outlined intent={Intent.DANGER} loading={isRemoving} onClick={handleRemoveDeadline}>
                        Remove deadline
                    </Button>

                    <div>{commonButtons}</div>
                </Flex>
            );
        } else {
            return <Flex justify="flex-end">{commonButtons}</Flex>;
        }
    };

    return (
        <Overlay isOpen onClose={closeModal}>
            <Card style={{ width: '558px' }}>
                <Flex className="mb-2" align="center" justify="space-between">
                    <Heading type="h4">{title}</Heading>
                    <Button minimal icon="cross" onClick={closeModal} />
                </Flex>

                <Divider className="mb-2" />

                <form onSubmit={handleFormSubmit}>
                    {/* 1. Select timezone */}
                    <div className="mb-3">{renderSelectTimezoneStep()}</div>

                    <Divider className="mb-2" />

                    {/* 2. Pick date and time */}
                    <div className="mb-2" style={{ opacity: step < 2 ? '0.3' : '1' }}>
                        {renderPickDateAndTimeStep()}
                    </div>

                    <Divider className="mb-2" />

                    {/* 3. Review new deadline */}
                    <div style={{ opacity: step < 3 ? '0.3' : '1', marginBottom: '3rem' }}>
                        {renderReviewNewDeadlineStep()}
                    </div>

                    {/* Buttons */}
                    {renderButtons()}
                </form>
            </Card>
        </Overlay>
    );
};

export default SetTaskDeadlineModal;
