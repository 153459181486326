import { fetchPickableTeamParticipantUsers, fetchTeam, fetchTeamOccupancy } from './effects';
import { setUserOccupancyByTeamParticipantUserIdMap } from './events';
import {
    $team,
    $teamOccupancy,
    $teamPickableParticipantUsers,
    $userOccupancyByTeamParticipantUserIdMap,
} from './states';

import '../pages/TeamTaskFeedback/store/init';
import moment from 'moment';

// Team pickable participant user list
$teamPickableParticipantUsers.on(fetchPickableTeamParticipantUsers.doneData, (_, data) => data);

// Team data
$team.on(fetchTeam.doneData, (_, data) => {
    return {
        ...data,
        participants: data.participants.sort((a, b) => moment.utc(a.assigned_at).diff(moment.utc(b.assigned_at))),
    };
});

// Team occupancy
$teamOccupancy.on(fetchTeamOccupancy.doneData, (_, data) => data);

// Set user occupancy by team participant user id map
$userOccupancyByTeamParticipantUserIdMap.on(setUserOccupancyByTeamParticipantUserIdMap, (_, data) => data);
