import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { UserUtils } from '@app/containers/components/Notifications/utils';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import {
    NotificationResource,
    TaskPublishResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints, taskPublishTypeInformation, taskQueueInformation } from '@app/data/consts';
import NotificationTemplate from '../../../NotificationTemplate';

export interface TaskPublishedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    taskPublish: TaskPublishResource | null;
    dismiss: () => Promise<void>;
}

type Props = TaskPublishedNotificationProps;

const TaskPublishedNotification: React.FC<Props> = ({ authorizedUserId, notification, taskPublish, dismiss }) => {
    const renderContent = () => {
        if (!taskPublish) {
            // This is bad, task is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Request publish was not found</DevText>;
        }

        // Get task
        const task = taskPublish.task;

        return (
            <div>
                <DevText running className="mb-1">
                    {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)} published{' '}
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.TASK_INFORMATION.replace(':taskId', task.id)}
                    >
                        {task.title}
                    </RouterLink>{' '}
                    to {taskQueueInformation[taskPublish.output_queue].title.toLowerCase()} queue via{' '}
                    {taskPublishTypeInformation[taskPublish.type].title.toLowerCase()} method
                </DevText>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="Request published"
            icon="send-to-graph"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default TaskPublishedNotification;
