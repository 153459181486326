import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { PlatformTaskFilterData } from '@app/containers/pages/Tasks/types';
import { $isQueryHydrationFinished } from '@app/containers/pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@app/containers/pages/Tasks/store/events';
import { initialPlatformTaskFilterData, platformFiltersInformation } from '@app/containers/pages/Tasks/consts';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { Radio, Position } from '@blueprintjs/core';
import { PlatformTaskFilterType } from '@app/containers/pages/Tasks/enums';
import TaskDeadlineState from '@app/containers/pages/Tasks/enums/TaskDeadlineState';

export interface TaskDeadlinePlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskDeadlinePlatformFilterProps;

const TaskDeadlinePlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [deadlineState, setDeadlineState] = useState<TaskDeadlineState | null>(platformTaskFilterData.deadlineState);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setDeadlineState(platformTaskFilterData.deadlineState);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.deadlineState === null) {
            setDeadlineState(null);
        }
    }, [platformTaskFilterData.deadlineState]);

    const getFilterTagLabel = () => {
        switch (deadlineState) {
            case TaskDeadlineState.EXCEEDED:
                return 'Deadline exceeded';
            case TaskDeadlineState.APPROACHING:
                return 'Deadline approaching';
            default:
                return '';
        }
    };

    const getFilterTagTitle = () => {
        if (deadlineState === null) {
            return 'Deadline';
        }

        return 'Deadline:';
    };

    const handleRemoveFilter = () => {
        setDeadlineState(null);
        setPlatformTaskFilterData({
            ...platformTaskFilterData,
            deadlineState: initialPlatformTaskFilterData.deadlineState,
        });
        setIsOpen(false);

        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_DEADLINE].isPinned) {
            // Platform task deadline filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformTaskFilterData.deadlineState !== deadlineState) {
            setPlatformTaskFilterData({ ...platformTaskFilterData, deadlineState });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_DEADLINE].isPinned) {
            return;
        }

        if (deadlineState === null) {
            onRemove();
        }
    };

    const renderRow = (key: number) => {
        return (
            <li key={key}>
                <Radio
                    checked={deadlineState === TaskDeadlineState.EXCEEDED}
                    className="mb-1"
                    label="Deadline exceeded"
                    onChange={() => setDeadlineState(TaskDeadlineState.EXCEEDED)}
                />

                <Radio
                    checked={deadlineState === TaskDeadlineState.APPROACHING}
                    label="Deadline approaching"
                    onChange={() => setDeadlineState(TaskDeadlineState.APPROACHING)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Deadline"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_DEADLINE].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskDeadlinePlatformFilter;
