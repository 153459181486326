import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import { PlatformTaskFilterData } from '@app/containers/pages/Tasks/types';
import { $isQueryHydrationFinished } from '@app/containers/pages/Tasks/store/states';
import { setPlatformTaskFilterData } from '@app/containers/pages/Tasks/store/events';
import { initialPlatformTaskFilterData, platformFiltersInformation } from '@app/containers/pages/Tasks/consts';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { Radio, Position } from '@blueprintjs/core';
import { PlatformTaskFilterType } from '@app/containers/pages/Tasks/enums';

export interface TaskArchivedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformTaskFilterData: PlatformTaskFilterData;
    onRemove: () => void;
}

export type Props = TaskArchivedPlatformFilterProps;

const TaskArchivedPlatformFilter: React.FC<Props> = ({ defaultIsOpen = false, platformTaskFilterData, onRemove }) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isOnlyArchivedVisible, setIsOnlyArchivedVisible] = useState<NonNullable<
        PlatformTaskFilterData['isArchived']
    > | null>(platformTaskFilterData.isArchived);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsOnlyArchivedVisible(platformTaskFilterData.isArchived);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformTaskFilterData.isArchived === null) {
            setIsOnlyArchivedVisible(null);
        }
    }, [platformTaskFilterData.isArchived]);

    const getFilterTagLabel = () => {
        if (isOnlyArchivedVisible === null) {
            return '';
        }

        if (isOnlyArchivedVisible) {
            return 'Only archived requests';
        } else {
            return 'Only unarchived requests';
        }
    };

    const getFilterTagTitle = () => {
        if (isOnlyArchivedVisible === null) {
            return 'Archived';
        }

        return 'Archived:';
    };

    const handleRemoveFilter = () => {
        setIsOnlyArchivedVisible(null);
        setPlatformTaskFilterData({
            ...platformTaskFilterData,
            isArchived: initialPlatformTaskFilterData.isArchived,
        });
        setIsOpen(false);
        if (!platformFiltersInformation[PlatformTaskFilterType.TASK_ARCHIVED].isPinned) {
            // Platform task archived filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformTaskFilterData.isArchived !== isOnlyArchivedVisible) {
            setPlatformTaskFilterData({ ...platformTaskFilterData, isArchived: isOnlyArchivedVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformFiltersInformation[PlatformTaskFilterType.TASK_ARCHIVED].isPinned) {
            return;
        }

        if (isOnlyArchivedVisible === null) {
            onRemove();
        }
    };

    const renderRow = (key: number) => {
        return (
            <li key={key}>
                <Radio
                    className="mb-1"
                    checked={isOnlyArchivedVisible !== null && !isOnlyArchivedVisible}
                    label="Only unarchived requests"
                    onChange={() => setIsOnlyArchivedVisible(false)}
                />

                <Radio
                    checked={isOnlyArchivedVisible !== null && isOnlyArchivedVisible}
                    label="Only archived requests"
                    onChange={() => setIsOnlyArchivedVisible(true)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Archived"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformFiltersInformation[PlatformTaskFilterType.TASK_ARCHIVED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default TaskArchivedPlatformFilter;
