import React from 'react';
import { ThemeProvider as DefThemeProvider } from 'styled-components';
import { base } from '@app/styles/themes';
import { GlobalStyles } from '@app/styles/global';

const ThemeProvider: React.FC = ({ children }) => {
    return (
        <DefThemeProvider theme={base}>
            <>
                <GlobalStyles />
                {children}
            </>
        </DefThemeProvider>
    );
};

export default ThemeProvider;
