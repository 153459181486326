import RouterLink from '@app/components/RouterLink';
import DevText from '@app/components/Text';
import { UserUtils } from '@app/containers/components/Notifications/utils';
import { Colors } from '@blueprintjs/core';
import React from 'react';
import NotificationTemplate from '../../../../NotificationTemplate';
import {
    NotificationResource,
    TaskQueueTransitionedActivityPayloadResource,
    TaskResource,
    TaskStateTransitionedActivityPayloadResource,
} from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import { Endpoints, taskStateInformation } from '@app/data/consts';
import TaskStateTag from '@app/containers/components/TaskStateTag';

export interface TaskStateTransitionedNotificationProps {
    authorizedUserId: ID;
    notification: NotificationResource;
    task: TaskResource | null;
    dismiss: () => Promise<void>;
}

type Props = TaskStateTransitionedNotificationProps;

const TaskStateTransitionedNotification: React.FC<Props> = ({ authorizedUserId, notification, task, dismiss }) => {
    const renderContent = () => {
        if (!task) {
            // This is bad, task is NOT found, which should not happen
            return <DevText color={Colors.RED2}>Request was not found</DevText>;
        }

        const payload: TaskStateTransitionedActivityPayloadResource | null = notification.activity.payload;
        if (!payload) {
            // This is bad, there should be payload, but for some reason it is not there
            return <DevText color={Colors.RED2}>Payload was not found</DevText>;
        }

        return (
            <div>
                <DevText running className="mb-1">
                    {UserUtils.getActorUserName(notification.activity.actor_user, authorizedUserId)} changed state for{' '}
                    <RouterLink
                        style={{ display: 'inline' }}
                        to={Endpoints.TASK_INFORMATION.replace(':taskId', task.id)}
                    >
                        {task.title}
                    </RouterLink>{' '}
                    from <TaskStateTag large={false} withCircle={false} state={payload.from} /> to{' '}
                    <TaskStateTag large={false} withCircle={false} state={payload.to} />
                </DevText>
            </div>
        );
    };

    return (
        <NotificationTemplate
            title="State transitioned"
            icon="box"
            at={notification.activity.at}
            content={renderContent()}
            dismiss={dismiss}
        />
    );
};

export default TaskStateTransitionedNotification;
