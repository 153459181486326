import React from 'react';
import { ModalProps } from '@modals/types';
import Overlay from '@components/Overlay';
import { Button, Card, Intent } from '@blueprintjs/core';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@app/components/Text';
import { useNavigate } from 'react-router-dom';
import { Endpoints, taskStateInformation } from '@app/data/consts';
import { $task } from '../../../store/states';
import { useStore } from 'effector-react';
import { TaskState } from 'dy-frontend-http-repository/lib/data/enums';

export interface TaskDeliverablePresenceSanityCheckModalProps {
    transitionState: () => any;
    transitionToState: TaskState;
}

type Props = ModalProps<TaskDeliverablePresenceSanityCheckModalProps>;

const TaskDeliverablePresenceSanityCheckModal: React.FC<Props> = ({ closeModal, data }) => {
    const navigate = useNavigate();

    const task = useStore($task);

    const handleModalClose = () => {
        closeModal?.();
    };

    if (!data || !task) {
        handleModalClose();
        return null;
    }

    const handleStateTransition = () => {
        data.transitionState();
        handleModalClose();
    };

    const handleGoToCreateTaskDeliverables = () => {
        navigate(Endpoints.TASK_DELIVERABLES_ALL.replace(':taskId', task.id));
        handleModalClose();
    };

    return (
        <Overlay isOpen onClose={handleModalClose}>
            <Card style={{ width: '558px' }}>
                <Heading className="mb-1" type="h5">
                    ATTENTION!
                </Heading>
                <Heading className="mb-2" type="h6">
                    No deliverables detected
                </Heading>

                <DevText className="mb-2">
                    You about to pass this request to {taskStateInformation[data.transitionToState].title}, however no
                    files, which are pending for review found. Are you sure you want to continue?
                </DevText>

                <Flex direction="row" align="center" justify="space-between">
                    <div className="mr-1">
                        <Button className="mr-1" intent={Intent.DANGER} onClick={handleStateTransition}>
                            Yes, I am sure
                        </Button>
                        <Button intent={Intent.PRIMARY} onClick={handleModalClose}>
                            No, cancel
                        </Button>
                    </div>

                    <Button minimal onClick={handleGoToCreateTaskDeliverables}>
                        Create deliverables
                    </Button>
                </Flex>
            </Card>
        </Overlay>
    );
};

export default TaskDeliverablePresenceSanityCheckModal;
