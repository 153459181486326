import PointMarker from '@app/containers/pages/Task/components/PointMarker';
import { Colors } from '@blueprintjs/core';
import { useStore } from 'effector-react';
import React, { createRef, HTMLAttributes, useEffect, useRef } from 'react';
import { setHighlightedTaskDeliverableCommentId } from '../../../../store/events';
import {
    $pointMarkerByTaskDeliverableCommentIdMap,
    $pointMarker,
    $highlightedTaskDeliverableCommentId,
    $highlightedPointMarkerTaskDeliverableCommentId,
    $rootTaskDeliverableCommentsByIdMap,
} from '../../../../store/states';

export interface PointMarkerBlockProps {
    containerRef: React.MutableRefObject<HTMLDivElement | null>;
}

export type Props = HTMLAttributes<HTMLDivElement> & PointMarkerBlockProps;

const PointMarkerBlock: React.FC<Props> = ({ containerRef, ...props }) => {
    const rootTaskDeliverableCommentsByIdMap = useStore($rootTaskDeliverableCommentsByIdMap);
    const pointMarkerByTaskDeliverableCommentIdMap = useStore($pointMarkerByTaskDeliverableCommentIdMap);
    const pointMarker = useStore($pointMarker);
    const highlightedTaskDeliverableCommentId = useStore($highlightedTaskDeliverableCommentId);
    const highlightedPointMarkerTaskDeliverableCommentId = useStore($highlightedPointMarkerTaskDeliverableCommentId);

    const taskDeliverablePointMarkersRef = useRef<{
        [taskDeliverableCommentId in ID]: React.RefObject<HTMLDivElement>;
    }>({});

    useEffect(() => {
        if (!pointMarkerByTaskDeliverableCommentIdMap) {
            return;
        }

        taskDeliverablePointMarkersRef.current = Object.keys(pointMarkerByTaskDeliverableCommentIdMap).reduce(
            (acc, taskDeliverableCommentId) => ({ ...acc, [taskDeliverableCommentId]: createRef() }),
            {}
        );
    }, [pointMarkerByTaskDeliverableCommentIdMap]);

    useEffect(() => {
        if (!highlightedPointMarkerTaskDeliverableCommentId) {
            return;
        }

        const highlightedPointMarker = Object.entries(taskDeliverablePointMarkersRef.current).find(
            ([taskDeliverableCommentId]) => taskDeliverableCommentId === highlightedPointMarkerTaskDeliverableCommentId
        )?.[1];

        if (highlightedPointMarker && highlightedPointMarker.current) {
            // Scroll to highlighted point marker
            highlightedPointMarker.current.scrollIntoView({
                block: 'center',
                behavior: 'smooth',
            });
        }

        setHighlightedTaskDeliverableCommentId(null);
    }, [highlightedPointMarkerTaskDeliverableCommentId]);

    const renderCurrentMarker = () => {
        if (!pointMarker) {
            return null;
        }

        return (
            <PointMarker
                container={containerRef.current ? containerRef.current.getBoundingClientRect() : null}
                x={pointMarker[0]}
                y={pointMarker[1]}
            />
        );
    };

    const renderMarkers = () => {
        const handleHighlightTaskDeliverableComment = (
            taskDeliverableCommentId: ID,
            e: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            e.stopPropagation();

            if (taskDeliverableCommentId === highlightedTaskDeliverableCommentId) {
                // Task deliverable comment with such ID is already highlighted
                return;
            }

            setHighlightedTaskDeliverableCommentId(taskDeliverableCommentId);
        };

        return Object.entries(pointMarkerByTaskDeliverableCommentIdMap).map(
            ([taskDeliverableCommentId, { marker, isArchived, index: markerIndex }]) => (
                <PointMarker
                    key={taskDeliverableCommentId}
                    content={
                        rootTaskDeliverableCommentsByIdMap
                            ? rootTaskDeliverableCommentsByIdMap[taskDeliverableCommentId].content
                            : undefined
                    }
                    color={isArchived ? Colors.RED3 : Colors.BLUE3}
                    container={containerRef.current ? containerRef.current.getBoundingClientRect() : null}
                    x={marker[0]}
                    y={marker[1]}
                    label={`${markerIndex}`}
                    ref={taskDeliverablePointMarkersRef.current[taskDeliverableCommentId]}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => handleHighlightTaskDeliverableComment(taskDeliverableCommentId, e)}
                />
            )
        );
    };

    return (
        <React.Fragment {...props}>
            {renderCurrentMarker()}
            {renderMarkers()}
        </React.Fragment>
    );
};

export default PointMarkerBlock;
