import React, { useEffect } from 'react';
import { useStore } from 'effector-react';
import { amountOfNotificationsOnPage } from './consts';
import { dismissAllNotifications, dismissNotification, fetchNotifications } from './store/effects';
import { $notifications } from './store/states';
import { notificationsApi } from './store/apis';
import { NotificationsWrapper, Header, List, Footer } from './styled';
import { NotificationResource } from 'dy-frontend-http-repository/lib/modules/Notification/resources';
import moment from 'moment';
import Flex from '@app/components/Flex';
import { Button, Classes, Divider, Icon, Intent, Spinner } from '@blueprintjs/core';
import Heading from '@app/components/Heading';
import DevText from '@app/components/Text';
import { TextFormatUtils } from 'dy-frontend-shared/lib/utils';
import notificationFactory from './consts/notificationFactory';
import { $authorizedUser } from '@app/containers/store/states';
import GenericNotification from './components/GenericNotification';
import { ToastUtils } from '@app/data/utils';
import { HTTPErrorType } from 'dy-frontend-http-repository/lib/data/enums';
import { HTTPErrorResponse } from 'dy-frontend-http-repository/lib/data/types';
import useTimeout from '@app/hooks/useTimeout';
import { LocalStorage } from '@app/data/enums';
import ConfirmationPopover from '@app/components/ConfirmationPopover';

export interface NotificationsProps {
    isOpen: boolean;
}

type Props = NotificationsProps;

const Notifications: React.FC<Props> = ({ isOpen, ...props }) => {
    const { isTimeoutPresent, handleStartTimeout } = useTimeout({
        timeoutKey: LocalStorage.REFRESH_NOTIFICATIONS_TIMEOUT,
        timeoutSeconds: 10,
        labelUpdateTimeoutSeconds: 1,
    });

    const authorizedUser = useStore($authorizedUser);
    const notifications = useStore($notifications);
    const isFetchingNotifications = useStore(fetchNotifications.pending);
    const isDismissProcessing = useStore(dismissNotification.pending);

    const handleRefetchNotifications = async () => {
        if (isTimeoutPresent) {
            return;
        }

        try {
            // Get cursor
            let startId: undefined | ID;
            if (notifications && notifications.list.length > 0) {
                startId = notifications.list[0].activity.id;
            }

            // Fetch notifications
            const fetchedNotifications = await fetchNotifications({
                pagination: {
                    _cursor: {
                        direction: 'asc',
                        limit: amountOfNotificationsOnPage,
                        start: startId,
                    },
                },
            });

            handleStartTimeout();

            // Add task messages at the end
            notificationsApi.addAtStart({ notifications: fetchedNotifications });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({ message: `Notifications were not found`, intent: Intent.DANGER });
            }
        }
    };

    const handleFetchNotifications = async () => {
        try {
            // Get start ID
            let startId: undefined | ID;
            if (notifications && notifications.cursor && notifications.cursor.next_cursor_start !== null) {
                startId = notifications.cursor.next_cursor_start;
            }

            // Fetch notifications
            const fetchedNotifications = await fetchNotifications({
                pagination: {
                    _cursor: {
                        direction: 'desc',
                        limit: amountOfNotificationsOnPage,
                        start: startId,
                    },
                },
            });

            // Add task messages at the end
            notificationsApi.addAtTailAfterSuccessfulFetch({ notifications: fetchedNotifications });
        } catch (e) {
            // Log
            console.error(e);

            const response = (e as any).response as HTTPErrorResponse;
            if (response.data.type === HTTPErrorType.MISSING) {
                // Show error message
                ToastUtils.showToast({ message: `Notifications were not found`, intent: Intent.DANGER });
            }
        }
    };

    useEffect(() => {
        // Get is notifications open flag
        if (!isOpen) {
            // Notifications closed -> Not need to do anything
            return;
        }

        if (notifications) {
            // Client reopened notifications, need to fetch new one from
            handleRefetchNotifications();
        } else {
            // First time opening notifications
            handleFetchNotifications();
        }
    }, [isOpen]);

    useEffect(() => {
        // This happens in the situation whenever all notifications were removed, but there are more notifications to show, which should be fetched
        if (notifications && notifications.list.length === 0 && notifications.cursor && notifications.cursor.has_more) {
            handleFetchNotifications();
        }
    }, [notifications]);

    if (!authorizedUser) {
        return null;
    }

    const handleDismissSingleNotification = async (id: ID) => {
        try {
            await dismissNotification({ ids: [id] });
            notificationsApi.dismiss({ id });
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const handleDismissAllNotifications = async () => {
        try {
            await dismissAllNotifications();
            notificationsApi.dismissAll();
        } catch (e) {
            // TODO: handle error
            console.error(e);
        }
    };

    const renderHeader = () => {
        return (
            <Flex direction="row" align="center">
                <Icon className="mr-1" icon="comparison" size={20} />
                <Heading type="h5">Notifications</Heading>
                <Button
                    minimal
                    loading={isFetchingNotifications || isDismissProcessing}
                    disabled={!notifications || isFetchingNotifications || isTimeoutPresent || isDismissProcessing}
                    className="ml-small"
                    icon="refresh"
                    onClick={handleRefetchNotifications}
                />
            </Flex>
        );
    };

    const renderNotificationDateHeader = (at: string) => {
        const notificationAtMoment = moment(at);

        const renderSuffix = () => {
            const commonSuffixProps = {
                inline: true,
                muted: true,
                className: 'ml-2',
            };

            const isStateTransitionDateToday = notificationAtMoment.isSame(moment(), 'day');
            if (isStateTransitionDateToday) {
                // It is today
                return <DevText {...commonSuffixProps}>Today</DevText>;
            }

            return (
                <DevText {...commonSuffixProps}>{TextFormatUtils.capitalize(notificationAtMoment.fromNow())}</DevText>
            );
        };

        return (
            <Heading className="mb-2" type="h5">
                {notificationAtMoment.format('D MMMM YYYY')}
                {renderSuffix()}
            </Heading>
        );
    };

    const renderNotifications = () => {
        if (!notifications) {
            // Notifications were NOT fetched yet
            return (
                <Flex direction="row" justify="center">
                    <Spinner />
                </Flex>
            );
        }

        if (notifications.list.length === 0) {
            // There are no new notifications
            return (
                <DevText muted running>
                    There are no notifications for you for now, but It'll start popping up soon
                </DevText>
            );
        }

        const shouldRenderNotificationAtDate = (currentNotification: NotificationResource, index: number) => {
            if (index === 0) {
                return true;
            }

            // Get previous notifications
            const previousNotification = notifications.list[index - 1];

            // Get previous and current notification at dates
            const previousNotificationAt = moment(previousNotification.activity.at);
            const currentNotificationAt = moment(currentNotification.activity.at);

            if (previousNotificationAt.isSame(currentNotificationAt, 'day')) {
                // Same day, no need to show new date header for notification
                return false;
            }

            return true;
        };

        const renderNotification = (shouldRenderAtDate: boolean, notification: NotificationResource, index: number) => {
            // Get notification renderer function
            const notificationRendererFunction = notificationFactory[notification.activity.type];

            // Get flag is date rendered at least once
            const isDateRenderedAtLeastOnce = index > 0;

            return (
                <>
                    {/* Date header */}
                    {shouldRenderAtDate && isDateRenderedAtLeastOnce && <Divider className="mt-4 mb-4" />}
                    {shouldRenderAtDate && renderNotificationDateHeader(notification.activity.at)}

                    {/* Notification */}
                    {!!notificationRendererFunction ? (
                        notificationRendererFunction(
                            notification,
                            notifications!.entity,
                            authorizedUser.user.id,
                            handleDismissSingleNotification
                        )
                    ) : (
                        <GenericNotification notification={notification} authorizedUserId={authorizedUser.user.id} />
                    )}
                </>
            );
        };

        return (
            <div>
                {notifications.list.map((notification, index) => {
                    // Get flag if the date should be rendered
                    const isDateVisible = shouldRenderNotificationAtDate(notification, index);

                    return (
                        <div className={index === 0 ? '' : 'mt-3'} key={notification.activity.id}>
                            {renderNotification(isDateVisible, notification, index)}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderShowMoreButton = () => {
        if (!notifications || !notifications.cursor || !notifications.cursor.has_more) {
            // Notification are NOT fetched yet or there are not cursor or no more notifications to be fetched
            return null;
        }

        return (
            <Flex className="mt-2" justify="center" align="center">
                <Button
                    loading={isFetchingNotifications}
                    outlined
                    icon="refresh"
                    rightIcon="refresh"
                    onClick={handleFetchNotifications}
                >
                    Show more
                </Button>
            </Flex>
        );
    };

    const renderDismissAllButton = () => {
        if (notifications === null || notifications.list.length === 0) {
            return null;
        }

        return (
            <ConfirmationPopover
                fill
                title="Dismiss all notifications?"
                description="When confirmed, all notifications will be dismissed and you'll not be able to read them again"
                actions={[
                    <Button
                        intent={Intent.DANGER}
                        className={Classes.POPOVER_DISMISS}
                        onClick={handleDismissAllNotifications}
                    >
                        Yes, dismiss all
                    </Button>,
                ]}
            >
                <Button fill icon="confirm">
                    Dismiss all
                </Button>
            </ConfirmationPopover>
        );
    };

    return (
        <NotificationsWrapper {...props}>
            <Header className="mb-2">{renderHeader()}</Header>

            <List className="custom-thin-scroll mb-2">
                {renderNotifications()}
                {renderShowMoreButton()}
            </List>

            <Footer>{renderDismissAllButton()}</Footer>
        </NotificationsWrapper>
    );
};

export default Notifications;
