import { Card, Elevation, Icon, Menu, MenuItem, Popover } from '@blueprintjs/core';
import Circle from '@components/Circle';
import Flex from '@components/Flex';
import Heading from '@components/Heading';
import DevText from '@components/Text';
import { PlanResource, PriceResource } from 'dy-frontend-http-repository/lib/modules/SubscriptionPlan/resources';
import { PriceUtils } from 'dy-frontend-shared/lib/utils';
import React, { HTMLAttributes } from 'react';

export interface AddSubscriptionPriceControlProps {
    plans: PlanResource[];
    selectedPrices: PriceResource[];
    disabledPriceIds?: ID[];
    onSelectPrice: (price: PriceResource) => void;
    shouldPriceBeActive?: (prices: PriceResource) => boolean;
}

export type Props = HTMLAttributes<HTMLDivElement> & AddSubscriptionPriceControlProps;

const AddSubscriptionPriceControl: React.FC<Props> = ({
    selectedPrices,
    disabledPriceIds = [],
    plans,
    onSelectPrice,
    shouldPriceBeActive,
    ...props
}) => {
    const getPriceTitle = (price: PriceResource) => {
        const priceRecurringInterval = PriceUtils.getRecurringInterval(
            price.recurring_interval,
            price.recurring_interval_count
        );

        return `${PriceUtils.formatPrice({
            price: price.unit_amount,
            shouldDisplayCents: true,
            currency: price.currency,
        })} / ${priceRecurringInterval}`;
    };

    const renderPopoverSubscriptionPlan = (plan: PlanResource, isLastPlanInList: boolean) => {
        const renderSubscriptionPlanPrice = (price: PriceResource) => {
            // Get price title
            const priceTitle = getPriceTitle(price);

            // Check if menu item active
            const isMenuItemActive = !!selectedPrices.find((p) => p.id === price.id);

            // Check if menu items should be disabled
            const isMenuItemDisabled =
                isMenuItemActive ||
                (shouldPriceBeActive && !shouldPriceBeActive(price)) ||
                disabledPriceIds.includes(price.id);

            const handleSelectMenuItem = () => {
                if (isMenuItemActive) {
                    // Menu item is selected
                    return;
                }

                onSelectPrice(price);
            };

            return (
                <MenuItem
                    key={price.id}
                    text={priceTitle}
                    active={isMenuItemActive}
                    disabled={isMenuItemDisabled}
                    onClick={handleSelectMenuItem}
                />
            );
        };

        return (
            <div key={plan.id} className={isLastPlanInList ? '' : 'mb-2'}>
                <Flex className="mb-1" align="center">
                    <Circle className="mr-1" color={plan.color} />
                    <Heading muted type="h5">
                        {plan.title}
                    </Heading>
                </Flex>

                <Menu>{plan.prices.map(renderSubscriptionPlanPrice)}</Menu>
            </div>
        );
    };

    const renderPopoverSubscriptionPlansContent = () => {
        return (
            <Card>{plans.map((plan, index) => renderPopoverSubscriptionPlan(plan, index === plans.length - 1))}</Card>
        );
    };

    return (
        <Popover fill content={renderPopoverSubscriptionPlansContent()} {...props}>
            <Card compact interactive elevation={Elevation.ONE}>
                <Flex align="center">
                    <Icon icon="add" className="mr-1" />
                    <DevText>Select price</DevText>
                </Flex>
            </Card>
        </Popover>
    );
};

export default AddSubscriptionPriceControl;
