import styled, { css } from 'styled-components';
import { FlexProps } from '@components/Flex';
import { breakpoints } from '@app/data/consts';

export const StyledFlex = styled.div<{
    $fullHeight: FlexProps['fullHeight'];
    $fullWidth: FlexProps['fullWidth'];
    $inline: FlexProps['inline'];
    $direction: FlexProps['direction'];
    $align: FlexProps['align'];
    $justify: FlexProps['justify'];
    $flexGrow: FlexProps['flexGrow'];
    $flexWrap: FlexProps['flexWrap'];
    $gap: FlexProps['gap'];
}>`
    /* Full height */
    ${({ $fullHeight }) => {
        // Not full height
        if (!$fullHeight) {
            return null;
        }

        if (typeof $fullHeight === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    height: ${$fullHeight.xl ? '100%' : 'auto'};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    height: ${$fullHeight.lg ? '100%' : 'auto'};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    height: ${$fullHeight.md ? '100%' : 'auto'};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    height: ${$fullHeight.sm ? '100%' : 'auto'};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    height: ${$fullHeight.xs ? '100%' : 'auto'};
                }
            `;
        } else {
            return css`
                height: 100%;
            `;
        }
    }}

    /* Full with */
    ${({ $fullWidth }) => {
        // Not full width
        if (!$fullWidth) {
            return null;
        }

        if (typeof $fullWidth === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    width: ${$fullWidth.xl ? '100%' : 'auto'};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    width: ${$fullWidth.lg ? '100%' : 'auto'};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    width: ${$fullWidth.md ? '100%' : 'auto'};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    width: ${$fullWidth.sm ? '100%' : 'auto'};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    width: ${$fullWidth.xs ? '100%' : 'auto'};
                }
            `;
        } else {
            return css`
                width: 100%;
            `;
        }
    }}

    /* Inline */
    ${({ theme, $inline }) => {
        // Inline was not passed
        if ($inline === undefined) {
            return null;
        }

        if (typeof $inline === 'object') {
            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    display: ${$inline.xl ? 'flex-inline' : 'flex'};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    display: ${$inline.lg ? 'flex-inline' : 'flex'};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    display: ${$inline.md ? 'flex-inline' : 'flex'};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    display: ${$inline.sm ? 'flex-inline' : 'flex'};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    display: ${$inline.xs ? 'flex-inline' : 'flex'};
                }
            `;
        } else {
            return css`
                display: ${$inline ? 'flex-inline' : 'flex'};
            `;
        }
    }}

    /* Direction */
    ${({ theme, $direction }) => {
        // Direction was not passed
        if ($direction === undefined) {
            return null;
        }

        if (typeof $direction === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    flex-direction: ${$direction.xl};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    flex-direction: ${$direction.lg};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    flex-direction: ${$direction.md};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    flex-direction: ${$direction.sm};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    flex-direction: ${$direction.xs};
                }
            `;
        } else {
            return css`
                flex-direction: ${$direction};
            `;
        }
    }}

    /* Align */
    ${({ theme, $align }) => {
        // Align was not passed
        if ($align === undefined) {
            return null;
        }

        if (typeof $align === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    align-items: ${$align.xl};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    align-items: ${$align.lg};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    align-items: ${$align.md};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    align-items: ${$align.sm};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    align-items: ${$align.xs};
                }
            `;
        } else {
            return css`
                align-items: ${$align};
            `;
        }
    }}

    /* Justify */
    ${({ theme, $justify }) => {
        // Align was not passed
        if ($justify === undefined) {
            return null;
        }

        if (typeof $justify === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    justify-content: ${$justify.xl};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    justify-content: ${$justify.lg};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    justify-content: ${$justify.md};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    justify-content: ${$justify.sm};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    justify-content: ${$justify.xs};
                }
            `;
        } else {
            return css`
                justify-content: ${$justify};
            `;
        }
    }}

    /* Flex grow */
    ${({ theme, $flexGrow }) => {
        // Flex grow was not passed
        if ($flexGrow === undefined) {
            return null;
        }

        if (typeof $flexGrow === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    flex-grow: ${$flexGrow.xl};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    flex-grow: ${$flexGrow.lg};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    flex-grow: ${$flexGrow.md};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    flex-grow: ${$flexGrow.sm};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    flex-grow: ${$flexGrow.xs};
                }
            `;
        } else {
            return css`
                flex-grow: ${$flexGrow};
            `;
        }
    }}

    /* Flex wrap */
    ${({ theme, $flexWrap }) => {
        // Flex wrap was not passed
        if ($flexWrap === undefined) {
            return null;
        }

        if (typeof $flexWrap === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    flex-wrap: ${$flexWrap.xl};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    flex-wrap: ${$flexWrap.lg};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    flex-wrap: ${$flexWrap.md};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    flex-wrap: ${$flexWrap.sm};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    flex-wrap: ${$flexWrap.xs};
                }
            `;
        } else {
            return css`
                flex-wrap: ${$flexWrap};
            `;
        }
    }}

    /* Gap */
    ${({ theme, $gap }) => {
        // Flex wrap was not passed
        if ($gap === undefined) {
            return null;
        }

        if (typeof $gap === 'object') {
            // Object

            return css`
                // XL - [1920, Infinity]
                @media screen and (min-width: ${breakpoints.xl}px) {
                    gap: ${$gap.xl};
                }

                // LG - [1280, 1920)
                @media screen and (min-width: ${breakpoints.lg}px) {
                    gap: ${$gap.lg};
                }

                // MD - [960, 1280)
                @media screen and (min-width: ${breakpoints.md}px) {
                    gap: ${$gap.md};
                }

                // SM - [600, 1280)
                @media screen and (min-width: ${breakpoints.sm}px) {
                    gap: ${$gap.sm};
                }

                // XS - [0, 600)
                @media screen and (min-width: ${breakpoints.xs}px) {
                    gap: ${$gap.xs};
                }
            `;
        } else {
            return css`
                gap: ${$gap};
            `;
        }
    }}
`;
