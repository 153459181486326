import React, { useState, useEffect } from 'react';
import { useStore } from 'effector-react';
import PlatformFilterButton from '@app/components/PlatformFilterButton';
import PlatformListFilter from '@app/components/PlatformListFilter';
import { Radio, Position } from '@blueprintjs/core';
import { $isQueryHydrationFinished } from '@app/containers/pages/Clients/store/states';
import { PlatformClientUserFilterData } from '@app/containers/pages/Clients/types';
import { setPlatformClientUserFilterData } from '@app/containers/pages/Clients/store/events';
import {
    initialPlatformClientUserFilterData,
    platformClientUserFiltersInformation,
} from '@app/containers/pages/Clients/consts';
import { PlatformClientUserFilterType } from '@app/containers/pages/Clients/enums';

export interface ClientUserArchivedPlatformFilterProps {
    defaultIsOpen?: boolean;
    platformClientUserFilterData: PlatformClientUserFilterData;
    onRemove: () => void;
}

export type Props = ClientUserArchivedPlatformFilterProps;

const ClientUserArchivedPlatformFilter: React.FC<Props> = ({
    defaultIsOpen = false,
    platformClientUserFilterData,
    onRemove,
}) => {
    const isQueryHydrationFinished = useStore($isQueryHydrationFinished);

    const [isOpen, setIsOpen] = useState(defaultIsOpen);
    const [isOnlyArchivedVisible, setIsOnlyArchivedVisible] = useState<NonNullable<
        PlatformClientUserFilterData['isArchived']
    > | null>(platformClientUserFilterData.isArchived);

    useEffect(() => {
        if (isQueryHydrationFinished) {
            setIsOnlyArchivedVisible(platformClientUserFilterData.isArchived);
        }

        // eslint-disable-next-line
    }, [isQueryHydrationFinished]);

    useEffect(() => {
        if (platformClientUserFilterData.isArchived === null) {
            setIsOnlyArchivedVisible(null);
        }
    }, [platformClientUserFilterData.isArchived]);

    const getFilterTagLabel = () => {
        if (isOnlyArchivedVisible === null) {
            return '';
        }

        if (isOnlyArchivedVisible) {
            return 'Only archived accounts';
        } else {
            return 'Only active accounts';
        }
    };

    const getFilterTagTitle = () => {
        if (isOnlyArchivedVisible === null) {
            return 'Archived';
        }

        return 'Archived:';
    };

    const handleRemoveFilter = () => {
        setIsOnlyArchivedVisible(null);
        setPlatformClientUserFilterData({
            ...platformClientUserFilterData,
            isArchived: initialPlatformClientUserFilterData.isArchived,
        });
        setIsOpen(false);
        if (!platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_ARCHIVED].isPinned) {
            // Platform client user archived filter is NOT pinned, so it can be removed
            onRemove();
        }
    };

    const handleApplyFilter = () => {
        if (platformClientUserFilterData.isArchived !== isOnlyArchivedVisible) {
            setPlatformClientUserFilterData({ ...platformClientUserFilterData, isArchived: isOnlyArchivedVisible });
        }
    };

    const handleCloseFilter = () => {
        setIsOpen(false);

        if (platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_ARCHIVED].isPinned) {
            return;
        }

        if (isOnlyArchivedVisible === null) {
            onRemove();
        }
    };

    const renderRow = (key: number) => {
        return (
            <li key={key}>
                <Radio
                    className="mb-1"
                    checked={isOnlyArchivedVisible !== null && !isOnlyArchivedVisible}
                    label="Only active accounts"
                    onChange={() => setIsOnlyArchivedVisible(false)}
                />

                <Radio
                    checked={isOnlyArchivedVisible !== null && isOnlyArchivedVisible}
                    label="Only archived accounts"
                    onChange={() => setIsOnlyArchivedVisible(true)}
                />
            </li>
        );
    };

    return (
        <PlatformListFilter<number>
            removable
            isOpen={isOpen}
            title="Archived"
            position={Position.BOTTOM_LEFT}
            list={[1]}
            itemRenderer={renderRow}
            onApply={handleApplyFilter}
            onClose={handleCloseFilter}
            onRemove={handleRemoveFilter}
        >
            <PlatformFilterButton
                icon={platformClientUserFiltersInformation[PlatformClientUserFilterType.CLIENT_ARCHIVED].icon}
                onClick={() => setIsOpen(!isOpen)}
                label={getFilterTagLabel()}
                title={getFilterTagTitle()}
            />
        </PlatformListFilter>
    );
};

export default ClientUserArchivedPlatformFilter;
